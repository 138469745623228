import { map, zipObject, find, reject, remove, cloneDeep, sumBy } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TransitionService, StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ListComponent, PagedListComponent } from 'commons/components/list';
import { NgResourceObject } from 'commons/interfaces';
import { MaxLengthValidator } from 'commons/validators';
import { ErrorModalService } from 'commons/services/error/error-modal.service';
import { NotificationModalComponent, ConfirmModalComponent } from 'commons/components/modals';

import {
	CustomFieldsService,
	CustomField,
	SystemField,
	EntityType,
	Layout,
	Group,
	SplitFieldsLayout,
	GroupAction,
} from '../../custom-fields.service';

@Component({
	templateUrl: './entity-fields.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => EntityFieldsComponent) },
	],
})
export class EntityFieldsComponent extends PagedListComponent {
	static listName = 'entity-fields';

	User: UserProfile;
	modalRef: BsModalRef;

	systemFields: Array<SystemField> = [];

	maxFields = 200;
	entityType: EntityType;
	titles = CustomFieldsService.EntityTitles;
	types: { [shortName: string]: string | boolean } = { $resolved: false };
	editingLayout: boolean;

	//Groups
	groupForm: RealmFormGroup = new RealmFormGroup({
		name: new RealmFormControl(
			'name',
			{ label: 'Group name' },
			Validators.compose([
				Validators.required,
				MaxLengthValidator(98),
			]),
		),
	});
	editingGroup: boolean = false;
	layout: NgResourceObject<Layout> = { fields: [], groups: [] };
	splitFieldsLayout: SplitFieldsLayout = {
		fields: [],
		fieldsCol2: [],
		groups: [],
	};
	columnNames: ['fields', 'fieldsCol2'] = ['fields', 'fieldsCol2'];
	activeGroupId: number = null;
	fieldsCount: number;

	get currentGroup(): Group {
		return find(this.splitFieldsLayout.groups, { id: this.activeGroupId });
	}

	constructor(
		transitionService: TransitionService,
		{ profile }: UserService,
		{ params: { entityType } }: UIRouterGlobals,
		public stateService: StateService,
		public modalService: BsModalService,
		public fieldsService: CustomFieldsService,
		private readonly errorModalService: ErrorModalService,
	) {
		super(transitionService, stateService);

		this.User = profile;
		this.entityType = entityType;
		this.systemFields = CustomFieldsService.SystemTypes[entityType];

		this.fieldsService.controlTypes().$promise.then((types) => {
			this.types = zipObject(map(types, 'shortName'), map(types, 'name'));
			this.types.$resolved = true;
		});

		if (!this.User.can('MANAGE_CUSTOM_FIELDS') && !this.User.can('CCM_MANAGE_CUSTOM_FIELDS')) {
			this.errorModalService.handleHttpError(403);
		}
	}

	async loadList(): Promise<void> {
		this.layout.$resolved = false;
		await (this.layout = this.fieldsService.layout(this.entityType)).$promise;
		for (const group of this.layout.groups) {
			group.editing = false;
		}

		this.fieldsCount = sumBy([{ fields: this.layout.fields }, ...this.layout.groups], 'fields.length');
		this.splitFieldsLayout = this.splitFieldsToCols();
	}

	splitFieldsToCols(): SplitFieldsLayout {
		const layout = cloneDeep(this.layout);
		const fieldsCol2 = remove(layout.fields, { displayColumn: 2 }) || [];

		const groups = map(layout.groups, (group) => {
			const newGroup = { ...cloneDeep(group), fieldsCol2: [] };
			newGroup.fields ||= [];
			newGroup.fieldsCol2 = remove(newGroup.fields, { displayColumn: 2 }) || [];
			return newGroup;
		});

		return { fields: layout.fields || [], fieldsCol2, groups };
	}

	joinFieldsFromCols(): Layout {
		const { fields, fieldsCol2, groups } = this.splitFieldsLayout;
		const layout = cloneDeep(this.layout);

		layout.fields = [...fields, ...fieldsCol2];
		for (const group of layout.groups) {
			const splitGroup = find(groups, { id: group.id });
			group.fields = [...splitGroup?.fields, ...splitGroup?.fieldsCol2];
		}

		return layout;
	}

	resetRowOrdering(): void {
		for (let i = 0; i < this.layout.fields.length; i++) {
			this.layout.fields[i].displayRow = i+1;
			this.layout.fields[i].groupId = null;
		}

		for (const group of this.layout.groups) {
			for (let i = 0; i < group.fields.length; i++) {
				group.fields[i].displayRow = i+1;
			}
		}
	}

	async saveLayout(): Promise<void> {
		this.layout.$resolved = false;

		this.layout = this.joinFieldsFromCols();
		this.resetRowOrdering();

		await this.fieldsService.layoutUpdate(this.entityType, cloneDeep(this.layout)).$promise;
		await this.loadList();
		this.cancelLayoutEdit();
	}

	editGroup(): void {
		this.editingGroup = !this.editingGroup;
	}

	renameGroup(name: string, id: number): void {
		this.activeGroupId = id;
		this.currentGroup.editing = true;
		this.groupForm.get('name').patchValue(name);
		this.editGroup();
	}

	async saveGroups(): Promise<void> {
		const { name } = this.groupForm.getRawValue();
		if (this.currentGroup?.name === name) {
			this.cancelEdit();
			return;
		}
		this.layout.$resolved = false;
		const group = { ...this.currentGroup, name };

		try {
			if (this.activeGroupId) {
				await this.fieldsService.updateGroup(this.activeGroupId, group).$promise;
				const currentGroupInLayout = find(this.layout.groups, { id: this.activeGroupId });
				currentGroupInLayout.name = group.name;
			} else {
				const newGroup = await this.fieldsService.createGroup(this.entityType, group).$promise;
				this.layout.groups.unshift(newGroup);
			}
			this.splitFieldsLayout = this.splitFieldsToCols();
			this.cancelEdit();
		} catch (e) {
			this.groupForm.setServerError(e.data || e);
			if (e.status === 403) {
				throw new HttpErrorResponse({ status: 403 });
			}
		}
		this.layout.$resolved = true;
	}

	async groupAction(id: number, action: GroupAction): Promise<void> {
		try {
			this.layout.$resolved = false;
			await this.fieldsService.groupAction(id, action).$promise;
			await this.loadList();
		} catch ({status}) {
			if (status === 403) {
				throw new HttpErrorResponse({ status: 403 });
			}
		}
	}

	cancelEdit(): void {
		this.editingGroup = false;
		this.groupForm.reset();
		this.activeGroupId && (this.currentGroup.editing = false);
		this.activeGroupId = null;
	}

	cancelLayoutEdit(): void {
		this.splitFieldsLayout = this.splitFieldsToCols();
		this.editingLayout = false;
	}

	async removeGroup(id: number): Promise<void> {
		const group = find(this.layout.groups, { id });
		if (group?.fields?.length) {
			this.modalRef = this.modalService.show(NotificationModalComponent, {
				initialState: {
					title: 'Remove Group',
					confirmText: 'Close',
					notification: 'All fields must first be removed from a group before a group can be deleted.',
					onConfirm: () => {
						this.modalRef.hide();
					},
				},
				class: 'modal-smd modal-new modal-min-body',
			});
		} else if (id) {
			this.modalRef = this.modalService.show(ConfirmModalComponent, {
				initialState: {
					title: 'Confirm to Proceed',
					message: `Are you sure you want to delete this Group? “${group.name}“`,
					confirmText: 'Yes',
					cancelText: 'No',
					onConfirm: async () => {
						this.layout.$resolved = false;
						this.modalRef.content.resolving = true;
						try {
							await this.fieldsService.removeGroup(id).$promise;
						} catch ({status}) {
							if (status === 403) {
								throw new HttpErrorResponse({ status: 403 });
							}
						}
						this.modalRef.hide();
						this.layout.groups = reject(this.layout.groups, { id })
						this.splitFieldsLayout = this.splitFieldsToCols();
						this.modalRef.content.resolving = false;
						this.layout.$resolved = true;
					},
				},
				class: 'modal-smd modal-new modal-min-body',
			});
		}
	}

	editLayout(): void {
		this.cancelEdit();
		this.editingLayout = true;
	}

	drop({ container, previousContainer, currentIndex, previousIndex }: CdkDragDrop<CustomField[]>, column: 1 | 2): void {
		if (previousContainer === container) {
			moveItemInArray(container.data, previousIndex, currentIndex);
		} else {
			transferArrayItem(previousContainer.data, container.data, previousIndex, currentIndex);
			container.data[currentIndex].displayColumn = column;
		}
	}

	setCursor(cursor: string): void {
		document.body.style.cursor = cursor;
	}

	removeCursor(): void {
		document.body.style.removeProperty('cursor');
	}
}
