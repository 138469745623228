import { map, find, some, cloneDeep, isEqual } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationModalComponent } from 'commons/components/modals';
import {
	ClientFinancialSettings,
	ClientFinancialSettingOption,
	ClientFinancialSettingsData, ClientFinancialSettingsItem,
} from 'commons/services/financial-documents.interface';

@Component({
	templateUrl: './client-financial-settings-modal.component.html',
})
export class ClientFinancialSettingsModalComponent extends NotificationModalComponent implements OnInit {
	guidanceText = 'All client specific settings will override global settings for the specified Channel.';
	confirmText = 'Close';
	resolving = true;
	onSave: (clientChannel: ClientFinancialSettingsData) => Promise<ClientFinancialSettings>
	settings$: Observable<ClientFinancialSettings>;
	settings: ClientFinancialSettings;
	options$: Observable<ClientFinancialSettingOption[]>;
	options: ClientFinancialSettingOption[];
	editing: boolean;
	closeDisabled: boolean;

	async ngOnInit(): Promise<void> {
		this.settings = cloneDeep(await this.settings$.toPromise());
		this.options = await this.options$.toPromise();
	}

	edit(channel: ClientFinancialSettingsData) {
		this.closeDisabled = true;
		channel.editing = true;
	}

	async cancel(channel: ClientFinancialSettingsData): Promise<void> {
		const initialChannelSettings = await this.getInitialChannelSettings(channel);
		channel.settings = cloneDeep(initialChannelSettings);
		channel.editing = false;
		this.updateCloseDisabled();
	}

	async save(channel: ClientFinancialSettingsData): Promise<void> {
		const initialChannelSettings = await this.getInitialChannelSettings(channel);
		channel.editing = false;

		if (isEqual(channel.settings, initialChannelSettings)) { return; }

		await this.onSave(channel);
		this.updateCloseDisabled();
	}

	async getInitialChannelSettings(item: ClientFinancialSettingsData): Promise<ClientFinancialSettingsItem[]> {
		const initialSettings = (await this.settings$.toPromise()).clientSettings;
		return find(initialSettings, { channelId: item.channelId }).settings;
	}

	updateCloseDisabled() {
		this.closeDisabled = some(this.settings.clientSettings, { editing: true });
	}

	updateDisabledStatus(selectedOptions: ClientFinancialSettingOption[] | ClientFinancialSettingsItem[]) {
		const hasNotApplicable = some(selectedOptions, { title: 'Not Applicable' });

		this.options = map(this.options, (option) => {
			if (hasNotApplicable) {
				option.disabled = option.title !== 'Not Applicable';
			} else if (selectedOptions?.length){
				option.disabled = option.title == 'Not Applicable';
			} else {
				delete option.disabled;
			}
			return option;
		});
	}
}
