import { NgModule } from '@angular/core';
import { ClientProductsComponent } from 'comergence/tpo/billing/client-products/client-products.component';
import { ClientProductsService } from 'comergence/tpo/billing/client-products/client-products.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,
        ComponentsModule,
    ],
    declarations: [
        ClientProductsComponent,
    ],
    providers: [
        ClientProductsService,
    ],
})
export class ClientProductsModule {
}
