<div>
    <h2 class="title-label">Products
        <div class="pull-right">
            <button type="button" class="btn btn-primary pull-right" *ngIf="userCanAddEdit" (click)="addProduct()">Add Product</button>
        </div>
    </h2>
</div>

<div class="rows-bordered remote-resource" [class.resolved]="resolved">
    <div class="row-fluid header">
        <div class="col-md-3">Product Name</div>
        <div class="col-md-a">Plan</div>
        <div class="col-md-1">Visibility</div>
        <div class="col-f-60 button-holder"></div>
    </div>
    <product-item class="row-fluid"
        *ngFor="let product of productList(); let productIndex = index"
        [item]="product"
        [userCanAddEdit]="userCanAddEdit"
        [organizationId]="organizationId"
        [productIndex]="productIndex"
        (onNewItemCancel)="cancelNewItem($event)"
        (onDelete)="deleteProduct($event)">
    </product-item>
</div>
