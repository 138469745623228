import { Pipe, PipeTransform } from '@angular/core';

declare let apiPath: string;

const defaultTs = Date.now();
@Pipe({
    name: 'lenderLogo',
})
export class LenderLogoPipe implements PipeTransform {
    ts: number = Date.now();
    transform(id: number, ts: number = defaultTs): string {
        return `${apiPath}/lenders/${id}/logo?nocache=${ts}`;
    }
}
