import { cloneDeep, some, omit, map } from 'lodash';
import { ChangeDetectorRef, Component, inject, Optional } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { StateService as $stateProvider } from 'angularjs-providers/state.provider';
import { UserService, UserProfile } from 'angularjs-providers/user.provider';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { CommentsResourceInterface } from 'commons/components/comments';
import { MaxLengthValidator } from 'commons/validators';
import { NgResourceObject, NgResourceArray } from 'commons/interfaces';
import { CodeValue, FilterDefault } from 'commons/components/list/list.interface';

import { CustomFieldsElement, LayoutElements } from 'shared/fields/custom-fields.service';
import { RelatedAccountComponent } from 'lender/contacts/related-account';

import {
	ActivitiesService,
	Activity,
} from '../../activities.service';
import { LenderContactsResourceService } from 'lender/contacts/lender-contacts-resource.service';
import { RelatedAccountResourceInterface } from 'lender/contacts/related-account/';
import { CommentType } from 'lender/common/interfaces/comment-type.interface';
import { ActivityComponent } from 'lender/crm/activities/$id/activity.component';

const defaultListPath = '^.list';

@Component({
	templateUrl: './details.component.html',
})
export class ActivityDetailsComponent {
	user: UserProfile;
	isNew: boolean;
	creating: boolean;
	activity: NgResourceObject<Activity> = {};
	activityInitial: NgResourceObject<Activity>;
	priorities: NgResourceArray<CodeValue>;
	statuses: NgResourceArray<CodeValue>;
	assignedTo: NgResourceArray<FilterDefault>;
	embedded: 'CONTACT' | 'COMPANY' | 'PROSPECT' | 'UNKNOWN' | false;
	listPath: string = defaultListPath;

	defaultActivity: NgResourceObject<Activity> = {
		priority: { id: null, name: null },
		status: { id: 'PL', name: null },
		assignedTo: { id: null, name: null },
		$resolved: true,
	};

	editing: { [name: string]: boolean } = {
		activity: false,
		additionalFields: false,
		relatedAccount: false,
		relatedContact: false,
		relatedProspect: false,
	};

	groupedFields: NgResourceObject<LayoutElements>;

	activityFull: RealmFormGroup = new RealmFormGroup({});

	activityForm: RealmFormGroup = new RealmFormGroup({
		name: new RealmFormControl(
			'name',
			{ label: 'Activity Name', updateOn: 'change' },
			[Validators.required],
		),
		dueDate: new RealmFormControl(
			'dueDate',
			{ label: 'Due Date', updateOn: 'change' },
			[Validators.required],
		),
		priority: new RealmFormControl(
			'priority',
			{ label: 'Priority', updateOn: 'change' },
			[Validators.required],
		),
		status: new RealmFormControl(
			'status',
			{ label: 'Status', updateOn: 'change' },
			[Validators.required],
		),
		assignedTo: new RealmFormControl(
			'assignedTo',
			{ label: 'Assigned to', updateOn: 'change' },
			[Validators.required],
		),
		description: new RealmFormControl(
			'description',
			{ label: 'Description', updateOn: 'change' },
		),
	});

	commentsForm: RealmFormGroup = new RealmFormGroup({
		comment: new RealmFormControl(
			'comment',
			{ label: 'Comment', updateOn: 'change' },
			Validators.compose([ Validators.required, MaxLengthValidator(2000) ]),
		),
		commentType: new RealmFormControl(
			'commentType',
			{ label: 'commentType', value: 'PUBLIC', updateOn: 'change' },
			[Validators.required],
		),
	});
	commentTypes: NgResourceArray<CommentType> = [];
	typeDescriptions: { [code: string]: CommentType };

	statusColors = {
		PL: 'success',
		IP: 'warning',
	};
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	modalRef: BsModalRef;
	activityId: number;
	tpoContext: boolean;
	isRegisteredTPO: boolean;

	constructor(
		public userService: UserService,
		public activitiesService: ActivitiesService,
		public contactService: LenderContactsResourceService,
		public modalService: BsModalService,
		public $state: $stateProvider,
		public stateService: StateService,
		public routerGlobals: UIRouterGlobals,
        @Optional() protected activityComponent: ActivityComponent,
	) {
        this.activityComponent?.setTitle('');
		this.user = userService.profile;
		this.isNew = stateService.includes('**.activities.new.**');
		this.isRegisteredTPO = stateService.includes('nmls.client.:id.**');
		this.tpoContext = stateService.includes('**.client.:id.**');

		switch (true) {
            case stateService.includes('**.contacts.:contactId.**'):
                this.embedded = 'CONTACT';
                this.listPath = '^.^.information';
                break;
			case this.tpoContext: /*!!! This expression is valid only after CONTACT check !!!*/
				this.embedded = 'COMPANY';
				this.listPath = '^';
				break;
			case stateService.includes('crm.prospects.**'):
				this.embedded = 'PROSPECT';
				this.listPath = '^.^';
				break;
			default:
				this.embedded = 'UNKNOWN';
				this.listPath = defaultListPath;
		}

		!this.isNew && (this.activityId = this.routerGlobals.params.activityId);

		this.isNew && this.edit();
		this.assignedTo = this.activitiesService.context.activities().assignedTo();
		this.setContext().then(() => {
			this.loadData();
			if (this.isNew) {
				this.activityFull.addControl('activityForm', this.activityForm);
				this.formInit();
			} else {
				this.activity?.$promise.then(() => {
					this.formInit();
					this.activityInitial = cloneDeep(this.activity);
				});
			}
		});
	}

	get relatedAccountResource(): RelatedAccountResourceInterface {
		return this.activitiesService.getRelatedAccountResource(this.activityId);
	}

	get commentsResource(): CommentsResourceInterface {
		return this.activitiesService.getCommentsResource(this.activityId);
	}

	async setContext(): Promise<void> {
		const { params: { tpoId, id, contactId } } = this.routerGlobals;
		switch (this.embedded) {
			case 'COMPANY': {
				const { nmlsId, companyName } = await this.activitiesService.companyInfo(id, !this.isRegisteredTPO).$promise;
				this.activitiesService.context.client(nmlsId);
				this.defaultActivity.relatedAccount = { nmlsId, name: companyName };
				break;
			}
			case 'CONTACT': {
				const isNmls = this.stateService.includes('**.nmls.**');
				const contact = await this.contactService.get({
					[isNmls ? 'nmlsId' : 'tpoId']: tpoId || id,
					contactId,
				}).$promise;

				const { relatedAccount, individualNmlsId, nmlsId, fullName: contactName } = contact;

				this.defaultActivity = {
					...this.defaultActivity,
					...(relatedAccount?.name ? { relatedAccount } : {}),
					relatedContact: {
						...contact,
						tpoId,
						contactId,
						contactName,
						individualNmlsId: individualNmlsId || nmlsId, // @Notice BE logic doesn't work correct. individualNmlsId and nmlsId are the same data.
						companyName: relatedAccount?.name,
					},
				};
				break;
			}
			case 'PROSPECT': {
				this.activitiesService.context.prospect(id);
				this.defaultActivity.relatedProspect = await this.activitiesService.prospect(id).$promise;
				const { nmlsId, companyName } = this.defaultActivity.relatedProspect;
				this.defaultActivity.relatedAccount = { nmlsId, name: companyName };
				break;
			}
		}
		this.defaultActivity.assignedTo.id = this.user.id;
	}

	loadData(): void {
		this.activity = this.isNew ? this.defaultActivity : this.activitiesService.get(this.activityId);
		this.priorities = this.activitiesService.priorities();
		this.statuses = this.activitiesService.statuses();
		this.commentTypes = this.activitiesService.commentType();
		this.commentTypes.$promise.then((types) => {
			this.typeDescriptions = types.reduce((result, value) => {
				result[value.code] = value.description;
				return result;
			}, {});
		});
	}

	formInit = (): void => {
        this.activityComponent?.setTitle(this.activity.name);
        this.activityForm.patchValue({
            name: this.activity?.name,
            dueDate: this.activity?.dueDate ? new Date(this.activity.dueDate) : null,
            priority: this.activity?.priority?.id,
            status: this.activity?.status?.id,
            assignedTo: this.activity?.assignedTo?.id,
            description: this.activity?.description,
        });
    }

	getFieldElementValues = (field: CustomFieldsElement): CustomFieldsElement => {
		const { value, ...customField } = field;
		return { customField, value } as CustomFieldsElement;
	}

	async saveAll(): Promise<void> {
		this.creating = true;
		const allFields = cloneDeep(this.groupedFields.fields);
		for (const group of this.groupedFields.groups) {
			allFields.push(...group.fields);
		}
		const customFieldValues = map(allFields, this.getFieldElementValues);

		try {
			const { name, id } = await this.activitiesService.create({ ...this.activity, customFieldValues }).$promise;
			const href = this.stateService.href('^.:activityId', { activityId: id });
			const link = `<a class="underlined text-success" href="${href}">View Activity</a>`;
			this.$state.notify(this.listPath, {
				notification: {
					type: 'alert-success',
					message: `Activity ${name} was created successfully. ${link}`,
                    timeout: 10000,
				},
			});
		} catch (e) {
			this.activityFull.setServerError(e.data || e)
			this.creating = false;
		}
	}

	cancelNewActivity = (): void => {
		this.stateService.go(this.listPath);
	};

	save(): void {
		this.activity.$resolved = false;
		this.activitiesService.update(this.activity).$promise.then(
			(data) => {
				this.activity = data;
				this.activityInitial = cloneDeep(data);
				this.formInit();
				this.editing.activity = false;
			},
			(error) => this.activityForm.setServerError(error),
		).finally(() => this.activity.$resolved = true);
	}

	edit(): void {
		this.editing.activity = true;
	}

	cancelEdit(): void {
		this.editing.activity = false;
		this.activity = cloneDeep(this.activityInitial);
        this.activity.$resolved = true;
		this.formInit();
		this.activityForm.markAsPristine();
		this.activityForm.markAsUntouched();
	}

	remove(): void {
		const name = `<strong>${this.activity.name}</strong>`;
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: `Delete Activity`,
				message: `Are you sure you want to delete the Activity ${name}?`,
				confirmText: 'Delete',
				onConfirm: (): void => {
					this.modalRef.content.resolving = true;
					this.activitiesService.delete(this.activityId).$promise.then(
						() => {
							const hasDetails = this.stateService.includes('**.details');
							this.$state.notify(`${hasDetails ? '^.' : ''}${this.listPath}`, {
								notification: {
									type: 'alert-warning',
									message: `Activity ${name} was deleted successfully.`,
									timeout: 5000,
								},
							});
							this.modalRef.hide();
							this.modalRef.content.resolving = false;
						},
						(err) => {
							this.modalRef.hide();
							this.activityForm.setServerError(err);
						});
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	showNotification(component: RelatedAccountComponent): void {
		if (component.account?.nmlsId === component.accountInitial?.nmlsId) { return; }
		const { tpoId, nmlsId } = this.activity.relatedAccount || {};
		const companyPath = `${tpoId ? '' : 'nmls.'}client.:id.activities`;
		const href = this.stateService.href(companyPath, { id: tpoId || nmlsId });
		const link = `<a class="underlined text-warning" href="${href}">View Account</a>`;
		const message = tpoId || nmlsId ?
			`Activity <b>${this.activity.name}</b> was related to another account. ${link}` :
			`Activity <b>${this.activity.name}</b> related account was removed.`;

		this.$state.notify('^.^', {
			notification: {
				type: 'alert-warning',
				message,
			},
		});
	}

	isCurtain = (omitKey: string = ''): boolean => !this.isNew && some(omit(this.editing, omitKey));

	hasError = (controlName: string): boolean => {
		const { invalid, touched } = this.activityForm.get(controlName);
		return invalid && touched;
	};

	getInputValue = ({ target }: Event): string => (target as HTMLInputElement).value;

    protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
    detectChanges = () => this.cdr.detectChanges();
}
