import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { SCProduct, SCProductOverview } from 'lender/solution-center/solution-center.service';

@Component({
    selector: 'sc-coc-products',
    templateUrl: './products.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCocProductsComponent {
    productOverview = input.required<SCProductOverview>();
    productList = input.required<SCProduct[]>();
    productClick = output<SCProduct>();
}
