import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { TaskListComponent } from './list';
import { PublicSignInComponent, PublicTaskListComponent } from './public';
import { TasksResourceService } from './tasks-resource.service';
import { TasksService } from './tasks.service';

const components = [
	TaskListComponent,
	PublicTaskListComponent,
	PublicSignInComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
    ],
    declarations: [
        ...components,
    ],
    providers: [
        TasksResourceService,
        TasksService,
    ],
    exports: [
        PublicSignInComponent,
    ]
})
export class TasksModule {}
