import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { ComergenceContactsPageComponent } from './contacts-page.component';
import { ComergenceContactsListComponent } from './contacts/list.component';
import { ComergenceOwnersListComponent } from './owners/list.component';
import { NewContactsModule } from 'shared/new-contacts';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        NewContactsModule,
    ],
    declarations: [
        ComergenceContactsPageComponent,
        ComergenceContactsListComponent,
        ComergenceOwnersListComponent,
    ],
    providers: []
})
export class ComergenceContactsModule {
}
