import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Warehouse, WarehousesService } from 'shared/account/information/warehouse/warehouses.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'warehouses-item',
    templateUrl: 'warehouses-item.component.html',
})
export class WarehousesItemComponent implements OnInit {
    @Input() public item: Warehouse;
    @Input() public accountInformation: AccountInformation;
    @Input() public userCanAddEdit: boolean;
    @Output() public onNewItemCancel: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();
    @Output() public afterSave: EventEmitter<void> = new EventEmitter<void>();
    editing: boolean = false;
    resolving: boolean;
    warehouseForm = new RealmFormGroup({
        companyName: new RealmFormControl(
            'companyName',
            {
                label: 'Company',
            },
            Validators.required,
        ),
        contactName: new RealmFormControl(
            'contactName',
            {
                label: 'Contact Name',
            },
        ),
        email: new RealmFormControl(
            'email',
            {
                label: 'email',
            },
            Validators.email,
        ),
        approvedLineAmount: new RealmFormControl(
            'approvedLineAmount',
            {
                label: 'Line Amount',
            },
            Validators.required,
        ),
        sellLoans: new RealmFormControl(
            'sellLoans',
            {
                label: 'Sell Loans',
            },
            Validators.required,
        ),
        relationshipStartDate: new RealmFormControl(
            'relationshipStartDate',
            {
                label: 'Start Date',
            },
            Validators.required,
        ),
        relationshipEndDate: new RealmFormControl(
            'relationshipEndDate',
            {
                label: 'End Date',
            },
        ),
    },
        { validators: this.relationshipDatesValidator }
    );

    relationshipDatesValidator(formGroup: UntypedFormGroup): ValidationErrors {
        const invalidDate: boolean = formGroup.controls['relationshipEndDate'].value && formGroup.controls['relationshipStartDate'].value
                                    && (formGroup.controls['relationshipEndDate'].value < formGroup.controls['relationshipStartDate'].value);
        const validatorError: ValidationErrors = { error: true };
        if(invalidDate) {
            formGroup.controls['relationshipEndDate'].markAsTouched();
            formGroup.controls['relationshipEndDate'].setErrors({ 'error' : true });
        }
        return invalidDate ? validatorError : null;
    }

    constructor(
        private warehousesService: WarehousesService,
    ) {}

    ngOnInit() {
        if (!this.item.id) {
            return this.editing = true;
        }
    }

    doEdit() {
        const formValue = ({ ...this.item } as any);

        this.warehouseForm.patchValue(formValue);
        this.warehouseForm.patchValue({relationshipStartDate: this.convertDateStringToDate(this.item.relationshipStartDate)});
        this.warehouseForm.patchValue({relationshipEndDate: this.convertDateStringToDate(this.item.relationshipEndDate)});

        this.editing = true;
    }

    doCancel() {
        this.editing = false;
        if (!this.item.id) {
            this.onNewItemCancel.next(this.item);
        }
    }

    doSave() {
        const result = this.warehouseForm.value;
        if(!this.item.id) {
            this.saveNewWarehouse(result);
        }
        else {
            this.saveChangedWarehouse(result);
        }
    }

    convertDateStringToDate(dateString) {
        if(dateString) {
            return moment(dateString).toDate();
        }
        return null;
    }

    private async saveChangedWarehouse(result: Warehouse) {
        try {
            this.resolving = true;
            const changedWarehouse: Warehouse = await this.warehousesService.updateWarehouse(this.accountInformation.identifier.tpoId, this.item.id, result).toPromise();
            this.item = changedWarehouse;
            this.afterSave.emit();
            this.editing = false;
        }
        catch(e) {
            this.warehouseForm.setServerError(e);
        }
        finally {
            this.resolving = false;
        }
    }

    private async saveNewWarehouse(result: Warehouse) {
        try {
            this.resolving = true;
            const newWarehouse: Warehouse = await this.warehousesService.addNewWarehouse(this.accountInformation.identifier.tpoId, result).toPromise();
            this.item = newWarehouse;
            this.afterSave.emit();
            this.editing = false;
        }
        catch(e) {
            this.warehouseForm.setServerError(e);
        }
        finally {
            this.resolving = false;
        }
    }
}
