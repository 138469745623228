import { Component } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { sortBy } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { InternalDocumentsService } from '../../internal-documents.service';

@Component({
	templateUrl: './list.component.html',
})
export class InternalDocumentsListComponent extends ListComponent {
	static listName = 'internalDocuments';

	User: any;
	list: any = [];

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public clientDocumentsService: InternalDocumentsService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.configureList();
	}

	configureList() {
		const {
			id: tpoId,
		} = this.stateService.params;

		this.defaultFilters = {
			tpoId,
		};

		this.omitParams = [
			...this.omitParams,
			'tpoId',
		];
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.clientDocumentsService.resource.list(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = sortBy(data, ['name']);
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	reloadList() {
		this.loadList(this.params);
	}
}
