<div class="overview mb20" *ngIf="productOverview()?.description as description">
    <quill-view [content]="description"></quill-view>
</div>

<ng-container *ngIf="productList() as products">
    <h3>Pricing</h3>
    <div class="product-list">
        <sc-coc-single-product
            *ngFor="let product of products"
            [product]="product"
            (action)="productClick.emit($event)"
        ></sc-coc-single-product>
    </div>
</ng-container>
