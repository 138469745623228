<ng-container *ngIf="companyData() as companyData">
    <div class="gray-head">
        <div class="flex-row gap mb20 align-top">
            <h2 class="flex-grow">{{ companyData.companyName }}</h2>
            <a  *ngIf="companyData.medialinks?.FB as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/fb.svg"></a>
            <a  *ngIf="companyData.medialinks?.IG as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/ig.svg"></a>
            <a  *ngIf="companyData.medialinks?.LI as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/li.svg"></a>
            <a  *ngIf="companyData.medialinks?.X as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/x.svg"></a>
            <a  *ngIf="companyData.medialinks?.YT as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/yt.svg"></a>
        </div>

        <ul class="tab-menu">
            <ng-container *ngFor="let tab of tabList">
                <li *ngIf="visibleTabs()[tab.code]" [class.active]="currentTab() === tab.code">
                    <a (click)="selectTab.emit(tab.code)"><b>{{tab.title}}</b></a>
                </li>
            </ng-container>
        </ul>
    </div>


    <div class="tab-inner" [ngSwitch]="currentTab()">
        <sc-coc-overview *ngSwitchCase="SC_CARD_TAB.OVERVIEW" [companyData]="companyData"></sc-coc-overview>
        <sc-coc-products *ngSwitchCase="SC_CARD_TAB.PRODUCTS"
                         [productOverview]="productOverview()"
                         [productList]="productList()"
                         (productClick)="productClick.emit($event)">
        </sc-coc-products>
        <sc-coc-channels *ngSwitchCase="SC_CARD_TAB.CHANNELS"
                         [channelList]="channelList()"
                         [applicationHistory]="applicationHistory()"
                         (channelAction)="channelClick.emit($event)"
                         (applicationHistoryAction)="applicationHistoryClick.emit($event)"
                         (sortApplicationHistoryAction)="sortApplicationHistoryAction.emit($event)">
        </sc-coc-channels>
    </div>
</ng-container>
