import { debounce, find, findIndex, isNil, isNull, reduce, escape } from 'lodash';
import { ChangeDetectorRef, Component, ElementRef, HostListener, inject, Input, ViewChild } from '@angular/core';

import { CacheOptions, realmCache } from 'utils/cache';

const getNodeString = (node, isTextNode, cutText = '') => {
	if (isTextNode) {
		return cutText || node.wholeText;
	}

	if (cutText) {
		node.innerHTML = cutText;
	}

	return node.outerHTML;
};

@Component({
	selector: 'show-more',
	templateUrl: './show-more.component.html',
})
export class ShowMoreComponent {
	@Input()
	cache: CacheOptions;

	@Input()
	text: string = '';

	@Input()
	class: string = '';

	@Input()
	maxHeight: number = 40;

	@Input()
	showMoreText: string = 'show more';

	@Input()
	ellipsisSymbol: string = '&hellip;';

	@Input()
	showAtLeastOneWord: boolean = false;

	@Input()
	escapeText: boolean = true;

	@Input()
	bypassShowMore: boolean = false;

	@ViewChild('showMoreElement', {static: true})
	showMoreElement: ElementRef;

	@ViewChild('showMoreTextHolder', {static: true})
	showMoreTextHolder: ElementRef;

	effectiveMaxHeight: number = 40;
	hideEllipsis: boolean = false;
	isTruncated: boolean = false;

    private readonly cd = inject(ChangeDetectorRef);

	buildEllipsis = debounce((forceCalculate = false) => {
		if (!forceCalculate && (!this.text || this.hideEllipsis)) {
			this.isTruncated = this.isOverflowed();
            this.cd.detectChanges();
			return;
		}

		const normalizedText = isNil(this.text)
			? ''
			: this.escapeText
				? escape(this.text)
				: this.text;

		const cachedValue = this.getCachedText(normalizedText);

		if (!isNull(cachedValue) && !this.hideEllipsis) {
			this.setHTML(`${cachedValue}`);
			return;
		}

		const text = `${normalizedText}`
			.replace(new RegExp('\r\n', 'g'), '<br>')
			.replace(new RegExp('\n', 'g'), '<br>');

		const appendString = `${this.ellipsisSymbol} `;
		// const beforeSet = performance.now();
		this.setHTML(text);

		this.isTruncated = this.isOverflowed();
		// If text does not overflow the container,
		// no need in show more link or hellips
		if (!this.isTruncated) {
            this.cd.detectChanges();
			return;
		}

		// const beforeSet2 = performance.now();
		// console.log(`time after first set: ${beforeSet2 - beforeSet}`);
		// full text is `${text}${ellipsisSymbol} ${showMoreText}`,
		// but showMoreText is hard coded in template, so no need to append it,
		// just `${ellipsisSymbol} `
		this.setHTML(`${text}${appendString}`);

		// const beforeSet3 = performance.now();
		// console.log(`time after second set with appendstring: ${beforeSet3 - beforeSet2}`);

		let renders = 1;
		const element = this.getElement();
		// two children - span for text and show more node
		const spanNode = element.children[0];
		// console.log(spanNode);
		// NOTICE: childNodes include text, so we actually can get all separate fragments
		// 'children' on other hands will return only DOM nodes, but skips own node text
		// assume simple html with one level of tags, like:
		// this.text = some text, <a>link</a> or <b>important text</b>
		// spanNode.childNodes also includes appendString as separate node, skip it
		const nodesToCheck = spanNode.childNodes.length === 1 ? Array.from(spanNode.childNodes) : Array.from(spanNode.childNodes).slice(0, spanNode.childNodes.length - 1);
		// console.log('nodesToCheck', nodesToCheck, spanNode, spanNode.childNodes);
		const { nodesContent, nodesStartingPositions, isTextNodes, nodesRange, amountOfSymbols } = reduce(nodesToCheck, (memo, node) => {
			const content = node.textContent;
			const innerWordsArray = content.split(' ');
			const amountOfSymbolsInNode = reduce(innerWordsArray, (inner, word) => {
				return inner + word.length;
			}, 0);

			const amountOfSpaces = + amountOfSymbolsInNode ? innerWordsArray.length : 0;

			return {
				amountOfSymbols: memo.amountOfSymbols + amountOfSymbolsInNode + amountOfSpaces,
				nodesContent: [ ...memo.nodesContent, content ],
				nodesStartingPositions: [ ...memo.nodesStartingPositions, memo.amountOfSymbols ],
				// 3 is text node
				isTextNodes: [ ...memo.isTextNodes, node.nodeType === 3 ],
				nodesRange: [ ...memo.nodesRange, memo.amountOfSymbols + amountOfSymbolsInNode + amountOfSpaces ],
			};
		}, { nodesContent: [], nodesStartingPositions: [], isTextNodes: [], amountOfSymbols: 0, nodesRange: [] });

		// const statisticsCollect = performance.now();
		// console.log(`time to collect statistics: ${statisticsCollect - beforeSet3}`);

		// assume we have trivial case when styles of links or other elements DO NOT affect line-height
		const compStyles = window.getComputedStyle(element);
		// 20.2px
		const lineHeight = `${compStyles.getPropertyValue('line-height')}`;
		// => 20.2
		const lineHeightNumeric = +(lineHeight.substring(0, lineHeight.length - 2));

		const fullContentHeight = this.getElementScrollHeight();
		const amountOfLinesForFullContent = fullContentHeight / lineHeightNumeric;
		const amountOfLinesVisible = this.maxHeight / lineHeightNumeric;

		// const timeForStyles = performance.now();
		// console.log(`time to detect styles and words: ${timeForStyles - statisticsCollect}`);
		// calc all symbols, and symbols per word
		const averageSymbolDensityPerLine = amountOfSymbols / amountOfLinesForFullContent;
		const inititalPositionToApply = averageSymbolDensityPerLine * amountOfLinesVisible;

		// const timeForWordCount = performance.now();
		// console.log(`time for word count: ${timeForWordCount - timeForStyles}`);

		// console.log(
		// 	'\n wordsArray', wordsArray,
		// 	'\n amountOfSymbols', amountOfSymbols,
		// 	'\n amountOfLinesForFullContent', amountOfLinesForFullContent,
		// 	'\n amountOfLinesVisible', amountOfLinesVisible,
		// 	'\n averageSymbolDensityPerLine', averageSymbolDensityPerLine,
		// 	'\n inititalPositionToApply', inititalPositionToApply,
		// 	'\n nodesContent', nodesContent,
		// 	'\n isTextNodes', isTextNodes,
		// 	'\n nodesStartingPositions', nodesStartingPositions,
		// 	'\n nodesRange', nodesRange,
		// );

		let symbolIndexToApply = Math.ceil(inititalPositionToApply);
		let found = false;
		const appliedIndexes = [];
		while ((found === false) && renders < 10) {
			renders++;
			const prevOverflowed = this.isOverflowed();

			const closeNodeEndIndex = findIndex(nodesStartingPositions, (nodeStartingPosition) => {
				return Math.abs(nodeStartingPosition - symbolIndexToApply) < 10;
			});
			const nodeEndPosition = nodesStartingPositions[closeNodeEndIndex];

			// if new suggested index is at end of another node, and this end node is not checked,
			// try this index
			if (closeNodeEndIndex > -1 && find(appliedIndexes, nodeEndPosition) === -1) {
				symbolIndexToApply = nodeEndPosition;
			}
			// else {
				// const nextWordIndex = findIndex(wordStartingPositions, (wordPosition, index) => {
				// 	const nextIndex = wordStartingPositions.length - 1 === index ? 10000000 : wordStartingPositions[index + 1];
				// 	console.log(symbolIndexToApply, wordPosition, nextIndex)
				// 	return symbolIndexToApply <= wordPosition && nextIndex >= symbolIndexToApply;
				// });
				// symbolIndexToApply = nextWordIndex;
			// }

			appliedIndexes.push(symbolIndexToApply);

			// console.log('symbolIndexToApply', symbolIndexToApply);

			let nodeIndexToCut = findIndex(nodesRange, (nodeRange) => {
				// console.log('nodeRange', nodeRange);
				return nodeRange >= symbolIndexToApply;
			});

			nodeIndexToCut = nodeIndexToCut === -1 ? nodesRange.length - 1 : nodeIndexToCut;

			// console.log('nodeIndexToCut', nodeIndexToCut);

			const nodesToLeaveString = reduce(nodesToCheck, (memo, node, index) => {
				const isTextNode = isTextNodes[index];

				if (index > nodeIndexToCut) {
					return memo;
				}

				// if (nodeIndexToCut === index) {
				// 	console.log('~~ node', node, getNodeString(node, isTextNode));
				// 	return `${memo}${getNodeString(node, isTextNode)}`;
				// }

				const textLength = symbolIndexToApply - nodesStartingPositions[index];
				const textCut = nodesContent[index].substring(0, textLength);

				const stringToAdd = getNodeString(node, isTextNode, textCut);
				// console.log('~~ cut', node, nodesStartingPositions[index], textLength, textCut, stringToAdd);

				return `${memo}${stringToAdd}`;
			}, '');

			this.setHTML(`${nodesToLeaveString}${appendString}`);

			const currentOverflowed = this.isOverflowed();
			// not too precise but allow to remove several renders to match last symbols
			if (prevOverflowed === true && currentOverflowed === false) {
				if (!this.hideEllipsis) {
					this.setCachedText(normalizedText, `${nodesToLeaveString}${appendString}`);
				}

				found = true;
			}

			if (currentOverflowed === true) {
				const currentHeight = this.getElementScrollHeight() || 1;
				const faktor = this.maxHeight / currentHeight;
				symbolIndexToApply = Math.ceil(symbolIndexToApply * faktor);
				// console.log(
				// 	'\n currentHeight', currentHeight,
				// 	'\n faktor', faktor,
				// 	'\n symbolIndexToApply', symbolIndexToApply,
				// );
			}

			// console.log('prevOverflowed: ', prevOverflowed, '; currentOverflowed: ', currentOverflowed);
			// console.log(renders, nodesToLeaveString);
		}

		// const timeForFindShowMore = performance.now();
		// console.log(`time for main loop: ${timeForFindShowMore - timeForWordCount}`);

		// this.setHTML(`${text}${appendString}`);

		// Set complete text and remove one word at a time, until there is no overflow
		// for (let i = 0; i < wordsStartingLength; i++) {
		// 	renders++;
		// 	// if last word reached, just show it
		// 	if (wordsStartingLength && i === (wordsStartingLength - 1) && this.showAtLeastOneWord) {
		// 		// let block to show one word(or long link)
		// 		this.effectiveMaxHeight = 10000;
		// 		this.setHTML(words[0]);
		// 		this.isTruncated = false;
		// 		break;
		// 	}

		// 	const wordsToCheck = words.slice(0, i + 1);
		// 	this.setHTML(wordsToCheck.join(' ') + appendString);

		// 	if (this.getElementScrollHeight() >= initialMaxHeight && this.isOverflowed()) {
		// 		this.setHTML(words.slice(0, i).join(' ') + appendString);
		// 		break;
		// 	}
		// }

		// console.log('amount of renders:', renders, nodesContent);
        this.cd.detectChanges();
	}, 75);

	ngOnChanges(change) {
		if (change.text || change.maxHeight || change.showMoreText) {
			this.effectiveMaxHeight = this.maxHeight;
			this.buildEllipsis();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize = () => {
		this.buildEllipsis();
	}

	clickEllipsis() {
		if (this.bypassShowMore) {
			return;
		}

		this.hideEllipsis = true;

		// last call with force to recalculate everything
		setTimeout(() => (this.buildEllipsis(true)), 75);
	}

	getStyles() {
		if (this.hideEllipsis) {
			return {};
		}

		return {
			maxHeight: `${this.effectiveMaxHeight}px`,
		};
	}

	isOverflowed = () => {
		const element = this.getElement();
		return isNull(element) ? false : (element.scrollHeight > element.clientHeight);
	}

	getElement = (isShowMoreTextHolder = false) => {
		const elementRef = isShowMoreTextHolder ? this.showMoreTextHolder : this.showMoreElement;
		if (!elementRef || !elementRef.nativeElement) {
			return null;
		}

		const element = elementRef.nativeElement as HTMLElement;
		return element;
	}

	getElementScrollHeight = () => {
		const element = this.getElement();
		return isNull(element) ? 0 : element.scrollHeight;
	}

	getElementClientHeight = () => {
		const element = this.getElement();
		return isNull(element) ? 0 : element.clientHeight;
	}

	setHTML(html) {
		const element = this.getElement(true);
		if (isNull(element)) {
			return;
		}

		element.innerHTML = html;
        this.cd.detectChanges();
    }

	getCachedText = (inputText): string => {
		if (!inputText) {
			return null;
		}

		if (!this.cache || !this.cache.name) {
			return null;
		}

		const cache = realmCache.get(this.cache);
		const text = cache.get(inputText);

		return text || null;
	}

	setCachedText = (key, inputString) => {
		if (!key || !inputString) {
			return null;
		}

		if (!this.cache || !this.cache.name) {
			return null;
		}

		const cache = realmCache.get(this.cache);

		cache.set(key, inputString);
	}
}
