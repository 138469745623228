import { defer, omit, pick, isEmpty } from 'lodash';
import { Component, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ClusterIconStyle, GoogleMap, MapInfoWindow, MapMarker, MapMarkerClusterer } from '@angular/google-maps';
import { UIRouter } from '@uirouter/core';
import { Observable, Subject, of } from 'rxjs';
import { skip, takeUntil, map, tap, take } from 'rxjs/operators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { googleLoader$ } from 'commons/services/3rd-party/google-maps.api.service';
import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';

import {
    MarketDataService,
    MarketService,
    LOCATION_PARAMS_KEYS,
    CompaniesListComponent,
} from '../../market';
import {
    GlobalMarketParams,
    MapLocation,
    MarketMapMarker,
    MarketLocation,
    MarketLocationParams,
    MarketMapMarkerInfo,
    MarketSrefParams,
} from '../market.interface';

import c1 from '~static/images/promo/market-map/c1.png';
import c2 from '~static/images/promo/market-map/c2.png';
import c3 from '~static/images/promo/market-map/c3.png';
import c4 from '~static/images/promo/market-map/c4.png';
import pinBlue from '~static/images/promo/market-map/pin-blue.png';
import pinBlueStar from '~static/images/promo/market-map/pin-blue-star.png';
import pinRed from '~static/images/promo/market-map/pin-red.png';
import pinRedStar from '~static/images/promo/market-map/pin-red-star.png';

@Component({
    templateUrl: './map.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => MarketMapComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => MarketMapComponent) },
    ],
})
export class MarketMapComponent extends NewPagedListComponent<MarketMapMarker> implements OnInit {
    static listName = 'marketMap' as const;

    mapResolved = false;
    markersLoaded = true;
    filtersAvailable: boolean;
    filtersVisible = false;
    hasProspectPermission: boolean;
    User: UserProfile;

    usCenter = { lat: 37.09024, lng: -95.712891 };
    mapOptions: google.maps.MapOptions = {
        // USA center
        center: {
            lat: 37.09024,
            lng: -95.712891,
        },
        disableDefaultUI: true,
        zoomControl: true,
        zoom: 4,
    };
    clusterOptions: ClusterIconStyle[] = [
        {
            url: c1,
            width: 36,
            height: 36,
            textColor: '#fff',
            textSize: 10,
        },
        {
            url: c2,
            width: 42,
            height: 42,
            textColor: '#fff',
            textSize: 12,
        },
        {
            url: c3,
            width: 48,
            height: 48,
            textColor: '#fff',
            textSize: 14,
        },
        {
            url: c4,
            width: 56,
            height: 56,
            textColor: '#fff',
            textSize: 16,
        },
    ];
    locationParams: MarketLocationParams;
    locationSrefParams: MarketSrefParams;
    showLegend: boolean;
    currentMarkerInfo: Promise<MarketMapMarkerInfo>;
    protected destroyed$: Subject<void> = new Subject();

    @ViewChild('googleMap', { read: GoogleMap }) googleMap: GoogleMap;
    @ViewChild('markerClusterer') markerClusterer: MapMarkerClusterer;
    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

    constructor(
        router: UIRouter,
        { profile: User }: UserService,
        private readonly marketService: MarketService,
        private readonly dataService: MarketDataService,
    ) {
        super(router);

        this.User = User;
        this.defaultParams = {
            lt: [],
            auth: undefined,
            bm: [],
            bt: [],
            ct: [],
            loant: [],
        };
        this.globalParams.push(...LOCATION_PARAMS_KEYS);
        this.dataService.setSrefParams(null);
        this.locationParams = this.dataService.getLocationParams(router.globals.params as GlobalMarketParams);
        this.hasProspectPermission = User.isLender && User.crmEnabled && User.can('ADD_PROSPECT');
    }

    async ngOnInit(): Promise<void> {
        await googleLoader$.toPromise();
        this.mapResolved = true;

        super.ngOnInit();

        this.dataService.getLocation$().pipe(
            skip(1),
            takeUntil(this.destroyed$),
        ).subscribe(([location, skipRedirect]: [MarketLocation, boolean]) => {
            if (location === undefined) {
                this.dataService.resetLocation();
                !skipRedirect && this.router.stateService.transitionTo(
                    'crm.market.map',
                    { ...this.dataService.getLocationEmptyParams(), [MarketMapComponent.listName]: null },
                );

                this.markerClusterer.markerClusterer.clearMarkers();
                this.googleMap.googleMap.setCenter(this.mapOptions.center);
                this.googleMap.googleMap.setZoom(this.mapOptions.zoom);

                return;
            }

            this.locationParams = this.dataService.getLocationParams(location);
            !skipRedirect && this.router.stateService.transitionTo('crm.market.map', this.locationParams);
        });
    }

    protected loadList(params: ListParams & MarketLocationParams): Observable<PagedData<MarketMapMarker[]>> {
        this.setLocationSrefParams();
        params = { ...params, ...this.locationParams };
        this.markersLoaded = false;

        if (!this.hasLocation(params)) {
            this.filtersAvailable = false;
            this.filtersVisible = false;
            this.markersLoaded = true;
            const emptyPage = PagedData.createEmptyPage([])
            return of(emptyPage);
        }

        return this.marketService.getMapData(params).pipe(
            map((mapLocations) => {
                return {
                    ...mapLocations,
                    data: mapLocations.data.map((mapLocation: MapLocation) => ({
                        ...mapLocation,
                        position: {
                            lat: mapLocation.latitude,
                            lng: mapLocation.longitude,
                        },
                        icon: {
                            anchor: new google.maps.Point(20, 45),
                            url: this.getMarkerIconUrl(mapLocation),
                            size: new google.maps.Size(40, 49),
                        },
                    })),
                };
            }),
            tap((mapLocations) => {
                defer(() => {
                    this.fitBounds();
                    this.markersLoaded = true;
                    this.filtersAvailable = true;

                    if (!mapLocations.data?.length) {
                        this.googleMap.googleMap.setCenter(this.mapOptions.center);
                        this.googleMap.googleMap.setZoom(this.mapOptions.zoom);
                    }
                });
            }),
        );
    }

    toggleFilters(): void {
        this.filtersVisible = !this.filtersVisible;
    }

    fitBounds(): void {
        this.markerClusterer.fitMapToMarkers(0);
    }

    async openInfoWindow(marker: MapMarker, { companyNmlsId, locationNmlsId, tpoId }: MarketMapMarker): Promise<void> {
        const { companyNmlsId: companyNmls, locationNmlsId: locationNmls } = await this.currentMarkerInfo || {};
        if (companyNmls !== companyNmlsId && locationNmls !== locationNmlsId) {
            this.currentMarkerInfo = this.marketService.getMarkerInfo(companyNmlsId, locationNmlsId).toPromise();
        }
        (await this.currentMarkerInfo).tpoId = tpoId;

        this.infoWindow.open(marker);
    }

    getMarkerIconUrl({ hasRelationship, prospectStatusForUser }: MapLocation) {
        const pins = { pinBlue, pinBlueStar, pinRed, pinRedStar };
        return pins[`pin${hasRelationship ? 'Red' : 'Blue'}${prospectStatusForUser === 'NONE' ?  '' : 'Star'}`];
    }

    hasLocation(params): boolean {
        return !this.dataService.isEmptyParams(params);
    }

    setLocationSrefParams() {
        const nonDefaultParams = this.pickNonDefaultParams(this.params);
        const filteredListParams = pick(nonDefaultParams, Object.keys(this.defaultParams));
        const listParams = omit(filteredListParams, ['page', 'size', 'sort']);

        if (this.hasLocation(this.locationParams)) {
            this.locationSrefParams = {
                ...this.locationParams,
                ...(!isEmpty(listParams) ? { [CompaniesListComponent.listName]: listParams } : {}),
            };
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this.destroyed$.next();
        this.destroyed$.complete();
    }

    reloadMap() {
        const center = this.googleMap.googleMap.getCenter();
        const zoom = this.googleMap.googleMap.getZoom();
        this.currentMarkerInfo = null;

        this.list.pipe(
            take(2),
        ).subscribe(() => {
            defer(() => {
                this.googleMap.googleMap.setCenter(center);
                this.googleMap.googleMap.setZoom(zoom);
            });
        });
        this.updateList();
    }
}
