import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

import { UserService } from 'angularjs-providers/user.provider';

import { RequestReviewModalComponent } from 'lender/home/sections/share-requests/request-review.modal';
import { LenderHomeService, ShareRequest } from 'lender/home/lender-home.service';

@Component({
    selector: 'investor-home-share-requests',
    templateUrl: './investor-home-share-requests.component.html',
})
export class InvestorHomeShareRequestsComponent implements OnInit, OnDestroy {
    @Output('count') count$ = new EventEmitter<number>();
    investorId: number;
    modalRef: BsModalRef;
    refresh$ = new Subject<void>();
    requests$: Observable<ShareRequest[]>;

    constructor(
        private readonly lenderHomeService: LenderHomeService,
        private readonly modalService: BsModalService,
        private readonly userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.investorId = this.userService.profile.organization.id;

        this.requests$ = this.refresh$.pipe(
            startWith(true),
            tap(() => this.count$.emit(Infinity)),
            switchMap(() => this.lenderHomeService.getShareRequests()),
            shareReplay(1),
            tap((list) => this.count$.emit(list.length)),
        );
    }

    reviewRequest(shareRequestId: number) {
        const initialState: Partial<RequestReviewModalComponent> = {
            id: shareRequestId,
            onFinalize: () => this.refresh$.next(),
        };
        this.modalRef = this.modalService.show(
            RequestReviewModalComponent, {
                class: 'modal-new modal-smd',
                initialState,
            }
        );
    }

    ngOnDestroy() {
        this.refresh$.complete();
        this.modalRef?.hide();
    }
}
