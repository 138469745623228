import { map, mapValues } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { NgResourceArray } from 'commons/interfaces';

import { FolderModalComponent, GroupsModalComponent, MoveTemplateModalComponent, RemoveFolderModalComponent } from '.';

import { Folder, Post, TemplatesService } from '../templates.service';

enum Type {
	FOLDER = 'folder',
	TEMPLATE = 'template',
}

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TemplatesListComponent) },
	],
})
export class TemplatesListComponent extends PagedListComponent {
	static listName = 'templates';

	canManage: boolean = false;
	list: NgResourceArray<Post> = [];
	tpoId: number;
	modalRef: BsModalRef;
	type = Type;
	folderId: number;
	folder: Folder;
	groupsCount: number;
	sharingReady: boolean;
	share = null;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public user: UserService,
		public templatesService: TemplatesService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);
		this.tpoId = this.user.profile.organization.id;
		this.canManage = user.profile.can('TPO_PB_MANAGE_TEMPLATES');

		this.setDefaultFilters();
		this.prepareFilters();
	}

	setDefaultFilters(): void {
		this.defaultFilters = {
			...this.defaultFilters,
			tagId: [],
			sharingType: null,
			shareTo: [],
		};
	}

	prepareFilters(): void {
		this.filters = {
			tagId: this.templatesService.tags(),
		};
	}

	getAppliedFiltersData(): void {
		if (this.params.sharingType) {
			this.share = {
				sharingType: { id: this.params.sharingType },
				shareTo: map(this.params.shareTo, (id) => ({ id })),
			};
		}
	}

	setSharingFilter(value): void {
		this.share = value;
		this.setFilter(value || { sharingType: null, shareTo: null });
	}

	onSharingFilterReady($event): void {
		this.sharingReady = $event;
		this.getAppliedFiltersData();
	}

	setFilter(filters, resetPage = true): void {
		const processedFilter = mapValues(filters, (value: any, key: string) => {
			switch (key) {
				case 'sharingType':
					return value?.id;
				default:
					return map(value || [], 'id');
			}
		});
		super.setFilter(processedFilter, resetPage);
	}

	resetFilters(): void {
		const { sharingType, shareTo } = this.defaultFilters;
		this.share = sharingType ? { sharingType, shareTo } : null;
		super.resetFilters();
	}

	async loadList(queryParams): Promise<void> {
		this.list.$resolved = false;
		this.list = await this.templatesService.templates(this.getClearParams(queryParams)).$promise;
	}

	getRowSref = ({ type }): string => (
		type === this.type.FOLDER ? '.folder.:folderId' : '.:templateId'
	)

	getRowSrefParams = ({ id, type }): any => (
		{ [`${type}Id`]: id }
	)

	preventEventBubbling(event: MouseEvent): void {
		event.preventDefault();
		event.stopPropagation();
	}

	addFolder(): void {
		const initialState = {
			onConfirm: (name, assetSharing): void => {
				this.modalRef.content.resolving = true;
				this.templatesService.folderCreate({ name, assetSharing }, () => {
						this.modalRef.hide();
						this.loadList(this.params);
					}, (error) => {
						this.modalRef.content.folderForm.setServerError(error);
					},
				);
			},
		};

		this.modalRef = this.modalService.show(FolderModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
			backdrop: 'static',
		});
	}

	async editFolder(post?: Post): Promise<void> {
		if (!this.canManage) { return }

		this.list.$resolved = false;
		post && (this.folder = await this.templatesService.folder(post?.id).$promise);

		const initialState = {
			title: 'Edit Folder',
			name: this.folder.name,
			assetSharing: this.folder.assetSharing,
			onConfirm: (name, assetSharing): void => {
				this.modalRef.content.resolving = true;
				this.templatesService.folderEdit(this.folder.id, { name, assetSharing }, () => {
						this.modalRef.hide();
						this.loadList(this.params);
					}, (error) => {
						this.modalRef.content.folderForm.setServerError(error);
					},
				);
			},
		};

		this.list.$resolved = true;
		this.modalRef = this.modalService.show(FolderModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}

	moveTo(templateId): void {
		const initialState = {
			folders: this.templatesService.folders(),
			onConfirm: (targetFolderId: number): void => {
				this.modalRef.content.resolving = true;
				this.templatesService.templateMove(templateId, targetFolderId, () => {
						this.modalRef.hide();
						this.loadList(this.params);
					}, (error) => {
						this.modalRef.content.folderForm.setServerError(error);
					},
				);
			},
		};

		this.modalRef = this.modalService.show(MoveTemplateModalComponent, {
			initialState,
			class: 'modal-smd modal-new modal-restore-link',
		});
	}

	removeFolder(folderId: number): void {
		this.list.$resolved = false;
		this.templatesService.folderRemove({ folderId }, ({ success }) => {
			if (success) {
				this.loadList(this.params);
				return;
			}

			const initialState = {
				folderId,
				folders: this.templatesService.folders(),
				onConfirm: (moveToFolder: number): void => {
					this.modalRef.content.resolving = true;
					this.templatesService.folderRemove(
						{ folderId, moveToFolder },
						() => {
							this.modalRef.hide();
							this.loadList(this.params);
						},
						(error) => {
							this.modalRef.content.folderForm.setServerError(error);
						}
					);
				},
			};

			this.modalRef = this.modalService.show(RemoveFolderModalComponent, {
				initialState,
				class: 'modal-smd modal-new modal-restore-link',
			});
			this.list.$resolved = true;
		});
	}

	showGroupsModal(): void {
		this.modalRef = this.modalService.show(GroupsModalComponent, {
			initialState: { assetSharing: this.folder.assetSharing },
			class: 'modal-smd modal-new',
		});
	}

	getSource(tpoId, item) {
		return `/api/rest/tpos/${tpoId}/social-media-post-templates/${item.id}/image-preview`;
	}
}
