import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { SolutionCenterComponent } from './solution-center.component';
import { InvestorSolutionCenterService } from 'lender/solution-center/solution-center.service';
import { SCOverviewComponent } from 'lender/solution-center/tabs/overview/overview.component';
import { SCProductsComponent } from 'lender/solution-center/tabs/products/products.component';
import { SCChannelsComponent } from 'lender/solution-center/tabs/channels/channels.component';

import { QuillModule } from 'ngx-quill';
import { ShareSolutionCenterModule } from 'shared/solution-center/solution-center.module';
import { ManageProductModalComponent } from "./tabs/products/manage-product-modal/manage-product-modal.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        QuillModule,
        ShareSolutionCenterModule,
        PipesModule,
    ],
    declarations: [
        SolutionCenterComponent,
        SCOverviewComponent,
        SCProductsComponent,
        SCChannelsComponent,
        ManageProductModalComponent,
    ],
    providers: [
        InvestorSolutionCenterService,
    ],
})
export class SolutionCenterModule {
}
