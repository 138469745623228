import { HostListener, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StateService } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { UserService } from 'angularjs-providers/user.provider';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';

import { ReviewsService } from 'shared/reviews/reviews.service';
import { ContactService } from 'shared/contact/contact.service';
import { FindingsService } from 'shared/findings/findings.service';

import { NewFindingForm } from './new-finding.form';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive()
export abstract class NewFindingComponent implements OnInit, OnDestroy {
	User: any;
	findingForm = NewFindingForm;
	contactName: string;
	mediaLinks: any;
	previewImageUrl: SafeUrl;
	screenshotError: string;
	objectUrl: any;
	resolved: boolean;
	bsDatepickerConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};

	newSocialMediaLinkShow$ = new BehaviorSubject<boolean>(false);
	unsubscribe$ = new Subject<void>();

	@ViewChild('mediaLinkSelectElement', { static: false, read: NgSelectComponent }) mediaLinkSelectElement: NgSelectComponent;

	constructor(
		public sanitizer: DomSanitizer,
		public stateService: StateService,
		public historyLogService: HistoryLogService,
		public userService: UserService,
		public reviewsService: ReviewsService,
		public contactService: ContactService,
		public findingsService: FindingsService,
	) {
		this.User = userService.profile;
	}

	ngOnInit(): void {
		this.init();

		// reset form because it has been instantiated in other file
		this.findingForm.reset();

		this.newSocialMediaLinkShow$
			.pipe(
				takeUntil(this.unsubscribe$),
			)
			.subscribe((show) => {
				const mediaLinkFormControl = this.findingForm.get('uiId');
				if (show) {
					this.mediaLinkSelectElement.close();

					// reset mediaLink fields and mark control touched to indicate previous activity
					this.findingForm.get('mediaLinkId').reset();
					mediaLinkFormControl.reset();
					mediaLinkFormControl.markAsTouched();

					mediaLinkFormControl.disable();
				} else {
					mediaLinkFormControl.enable();

					// mediaLink control is untouched on init
					if (mediaLinkFormControl.touched) {

						// mark mediaLink control untouched to prevent form error
						mediaLinkFormControl.markAsUntouched();

						this.mediaLinkSelectElement.open();
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async getMediaLinks({
		contactType,
		contactId,
		nmlsId,
	}) {
		const mediaLinksType = contactType === 'company' ?
			'digitalMedia' :
			'socialMedia';
		const params = contactId ?
			{
				type: 'CONTACTID',
				id: contactId,
			} :
			{
				type: 'NMLSID',
				id: nmlsId,
			};

		return this.findingsService.mediaLinks[mediaLinksType].get(
			params,
		).$promise;
	}

	abstract init(): void;

	setAccount(account): void {
		this.findingForm.patchValue({ mediaLinkId: account.linkId });
	}

	onSelectImage(files): void {
		if (files.length > 1) {
			this.screenshotError = 'You can upload only one file at once.';
			return;
		}

		this.setImage(files[0]);
	}

	// NOTICE: IE11 has no clue about ClipboardEvent
	// so dev task is broken
	@HostListener('window:paste', [ '$event' ])
	onPasteImage(event: ClipboardEvent | any): void {
		if (
			!this.previewImageUrl &&
			event.clipboardData &&
			event.clipboardData.files &&
			event.clipboardData.files.length
		) {
			this.setImage(event.clipboardData.files[0]);
		}
	}

	setImage(image: File): void {
		if (image.size > 20 * Math.pow(2, 20)) { // 20Mb
			this.screenshotError = 'You can upload only a file less than 20 Mb.';
			return;
		}

		if (this.isImageFile(image)) {
			this.hideError();
			this.objectUrl = URL.createObjectURL(image);
			this.previewImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.objectUrl);
			this.findingForm.patchValue({ screenshot: image });
		} else {
			this.screenshotError = 'Wrong file type, please use supported file types for upload: PNG, JPEG, JPG';
		}
	}

	hideError(): void {
		this.screenshotError = null;
	}

	// determine if the given File is an Image (according do its Mime-Type).
	isImageFile(file: File): boolean {
		return (file.type.search(/^image\//i) === 0 && file.name.search(/\.(jpe?g|png)$/i) !== -1);
	}

	clearImage(): void {
		URL.revokeObjectURL(this.objectUrl);
		this.previewImageUrl = null;
		this.findingForm.patchValue({ screenshot: null });
	}

	abstract create(finding): Promise<any>;

	submit(): void {
		const {
			originalPostDate,
			newSocialMediaLink,
			...formFields
		} = this.findingForm.value;
		const finding = {
			...formFields,
			...(originalPostDate && { originalPostDate: moment(originalPostDate).valueOf() }),
			...(newSocialMediaLink && { newSocialMediaLink: { link: newSocialMediaLink } }),
		};

		this.resolved = false;
		this.create(finding)
			.then((data) => {
				const { id: findingId } = data;
				this.stateService.go('social-compliance.findings.:actionType.:findingId', { findingId, actionType: 'edit', tab: 'ISSUES' }, { location: 'replace' });
			})
			.catch(({ data: response }) => {
				this.findingForm.setServerError(response);
			})
			.finally(() => {
				this.resolved = true;
			});
	}

	abstract cancel(): void;
}
