import { map, omit, omitBy, isArray } from 'lodash';
import { Component, forwardRef, Input } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { SharedUsersListComponent, SharedUsersResourceService as SharedUsersResource } from 'shared/user-management';

import { LenderUserStatuses } from 'lender/user-management/user-statuses';
import { CustomerInformation } from 'comergence/customers/services/customer-list/customer-information.service.interface';

@Component({
	templateUrl: './users.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ComergenceCustomerUsersListComponent) },
	],
})
export class ComergenceCustomerUsersListComponent extends SharedUsersListComponent {
	static listName = 'cCustomerUsers';

	@Input('customerInformation') information: CustomerInformation;

	customerType: any;
	organizationId: any;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public User: UserService,
		public SharedUsersResourceService: SharedUsersResource,
	) {
		super(transitionService, stateService, User);

		this.omitParams.push('customerType');
		this.omitParams.push('organizationId');

		this.customerType = this.stateService.params.customerType;
		this.organizationId = this.stateService.params.organizationId;

		this.setService();
		this.setDefaultFilters();
		this.prepareFilters();
	}

	setDefaultFilters() {
		this.defaultFilters = {
			...this.defaultFilters,
			status: [],
			customerType: this.customerType,
			organizationId: this.organizationId,
		};
	}

	prepareFilters() {
		this.filters = {
			status: [ ...LenderUserStatuses ],
		};
	}

	setService() {
		this.service = this.SharedUsersResourceService;
	}

	setFilter(filter, resetPage: boolean = true) {
		const pageParams = resetPage ? {page: 0} : {};

		if (filter.status) {
			filter.status = map(filter.status || [], 'id');
		}

		this.params$.next({...this.params$.getValue(), ...pageParams, ...filter});
	}

	getClearParams(queryParams) {
		const values = omitBy(omit(queryParams, '#', '$inherit'), (value) => (!value));

		values.status = values.status ? ( isArray(values.status) ? values.status : [ values.status ] ) : [];

		return values;
	}
}
