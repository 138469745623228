<form [formGroup]="productForm"
      class="remote-resource col-md-12"
      [class.resolved]="!resolving"
      (onValidSubmit)="doSave()"
>
    <div class="row-fluid">
        <div class="col-md-3 form-group" has-error>
            <div class="flex-row gap" [ngSwitch]="isEditing">
                <ng-container *ngSwitchDefault>
                    <div class="control-value flex-grow">{{ item().productName }}</div>
                    <a class="btn btn-icon btn-tertiary" *ngIf="userCanAddEdit()" (click)="doEdit()">
                        <span class="glyphicon glyphicon-pencil"></span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="true">
                    <input type="text" name="productName" formControlName="productName" class="form-control flex-grow" maxlength="50" tabindex="1" />
                    <button class="btn btn-icon btn-tertiary" type="submit">
                        <span class="glyphicon glyphicon-ok"></span>
                    </button>
                    <a class="btn btn-icon btn-tertiary" (click)="doCancel()">
                        <span class="glyphicon glyphicon-remove"></span>
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="col-md-a">
            <div class="control-value">{{ item().plan || '&ndash;' }}</div>
        </div>
        <div class="col-md-1">
            <toggle
                [ngModel]="item().visibilityAllowedByComergence"
                (ngModelChange)="toggleVisibility(item(), $event)"
                [disabled]="!item().productId || !userCanAddEdit()"
                [loading]="toggleLoading"
                [ngModelOptions]="{standalone: true}"
                >
            </toggle>
        </div>
        <div class="col-f-60 button-holder text-right">
            <button type="button" class="btn btn-tertiary btn-icon" *ngIf="item().productId && userCanAddEdit()" (click)="removeProduct(item(), productIndex())">
                <span class="glyphicon glyphicon-trash"></span>
            </button>
        </div>
    </div>
</form>
