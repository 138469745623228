import { Component } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { filter } from 'lodash';

import { UserService as UService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { SocialMediaRSSResourceService } from '../../social-media-rss-resource.service';

@Component({
	templateUrl: './published-list.component.html',
})
export class PublishedListComponent extends ListComponent {
	static listName = 'libraryPublished';

	list: any = [];
	User: any;
	tpoId: any;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public UserService: UService,
		public rssResource: SocialMediaRSSResourceService,
	) {
		super(transitionService, stateService);
		const { contentId } = this.stateService.params;

		this.defaultFilters = {
			...this.defaultFilters,
			size: 50,
			contentId,
		};

		this.User = UserService.profile;
		this.tpoId = this.User.organization.id;
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.rssResource.feeds.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			});
	}
}
