/* eslint-disable comma-dangle */
import SharedRoutes from './sharedRoutes';
import { UIRouter } from '@uirouter/core';

import { PasswordPolicyComponent } from 'shared/password-policy/password-policy.component';
import { LoginLogComponent, ActionLogComponent } from 'shared/user-management/action-log';
import { AccountInformationComponent } from 'shared/account/company-information';
import { NewContactAuditLogComponent } from 'shared/new-contacts/audit-log';
import { SharedQuestionnairesListComponent } from 'shared/questionnaires';
import { ChannelCardListComponent } from '../lender/clients/channels/channels-card-list/channel-card-list.component';
import { ApplicationsCommentsComponent } from 'lender/clients/$id/channels/applications/comments';
import {
	ApplicationsHistoryComponent
} from 'lender/clients/$id/channels/applications/history/applications-history.component';

// Contacts
import { LenderContactsPageComponent } from 'lender/contacts/contacts-page.component';
import { LenderContactsListComponent } from 'lender/contacts/contacts/list.component';
import { LenderOwnersListComponent } from 'lender/contacts/owners/list.component';

import { AppcodesComponent } from 'lender/appcodes/appcodes.component';
import { SolutionCenterComponent } from 'lender/solution-center/solution-center.component';
import { LenderContactsBoardComponent } from 'lender/contacts/contacts/board.component';
import { LenderUsersListComponent, LenderUserComponent, LenderMySettingsComponent } from 'lender/user-management';

import {
	DocusignComponent,
	GlobalDocumentComponent,
	GlobalDocumentDetailsComponent,
	GlobalDocumentHistoryComponent,
	GlobalDocumentListComponent,
} from 'lender/documents';

import {
	ApplicationsListComponent,
	ApplicationSummaryComponent,
} from 'lender/applications';
import { InvitesListComponent } from 'lender/invites';
import { AlertsComponent } from 'lender/alerts';
import {
	PagedClientsListComponent,
	// documents
	CommonDocumentsHistoryComponent,
	ClientDocumentsComponent,
	ClientDocumentListComponent,
	ClientDocumentDetailsComponent,
	ClientLibraryDocumentListComponent,
	ClientLibraryDocumentComponent,
	ClientLibraryDocumentHistoryComponent,
	InternalDocumentsListComponent,
	InternalDocumentDetailsComponent,
	InvestorClientComments,
} from 'lender/clients';
import {
	EditQuestionnairesComponent,
	ManageQuestionnairesComponent,
	QuestionnairesComponent,
	QuestionnaireComponent,
	QuestionFromBankComponent,
	QuestionnaireDocumentsComponent,
	QuestionBankComponent,
	QuestionBankCategoriesComponent,
	QuestionPreviewComponent,
} from 'lender/questionnaires';
import {
	ActivitiesListComponent,
	ActivityComponent,
	ActivityDetailsComponent,
	ActivityHistoryComponent,
} from 'lender/crm';
import {
	MsaListComponent,
	CompaniesListComponent,
	MarketMapComponent,
} from 'lender/crm/market'
import {
	SystemDetailsComponent,
} from 'shared/fields';
import { DocumentsComponent } from 'lender/documents/documents.component';
import { ClientsListComponent } from 'lender/clients/list/clients-list.component';
import { BulkDocumentRequestComponent } from 'lender/clients/list/document/bulk-document-request.component';
import { EmptyContainerComponent } from 'commons/components';
import { CommentsComponent } from 'commons/components/comments';
import { InvestorContactDetailsComponent } from 'lender/new-contacts/details/investor-contact-details.component';
import { InvestorGeneralInformationComponent } from 'lender/new-contacts/details/general-information/investor-general-information.component';

import { NewContactSocialAccountsComponent } from 'shared/new-contacts/social-accounts/social-accounts.component';
import { NewContactWebPresenceComponent } from 'shared/new-contacts/web-presence/web-presence.component';
import { IndividualPublishingPermissionsComponent } from 'tpo/social-compliance/publishing-permissions';
import { NewContactListsComponent } from 'shared/new-contacts/new-contact-lists/new-contact-lists.component';
import { PeopleCompanyDetailsComponent } from 'tpo/people/contacts/company-details/people-company-details.component';
import { TpoFinancialsComponent } from 'shared/account/financials/tpo-financials.component';
import { FinancialDocumentHistoryComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-history';
import { AccountInformationService, lenderInfo$ } from 'shared/account/company-information/account-information.service';
import { DbaListComponent } from 'shared/account/dba/dba-list.component';
import { FinancialDocumentRequestsHistoryComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-requests-history';
import { FederalRegistrationsListComponent } from 'shared/account/federal-registrations/federal-registrations-list.component';
import { LocationsListComponent } from 'shared/account/locations/locations-list.component';
import { InsuranceBondsComponent } from 'shared/account/information/insurance-bonds/insurance-bonds.component';
import { AgencyComponent } from 'tpo/account/agency/agency.component';
import { InvestorsComponent } from 'tpo/account/investors/investors.component';
import { SharedLicenseListComponent } from 'shared/account/licenses';
import { MarketComponent } from 'lender/crm/market';
import { InvestorCrmContactListComponent } from 'lender/crm/contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list/investor-crm-contact-list.component';
import { UserManagementHeaderComponent } from 'shared/user-management/header/user-management-header.component';
import { GlobalSearchHeaderComponent } from 'shared/global-search/global-search-header.component';
import { SearchClientsListComponent } from 'shared/global-search/clients/clients.component';
import { SearchIndividualsListComponent } from 'shared/global-search/individulals/individuals.component';
import { AffiliatesAddEditViewComponent } from 'tpo/account/affiliates/affiliates-add-edit-view.component';
import { LenderHomeComponent } from 'lender/home/lender-home.component';
import { AffiliatesListComponent } from 'tpo/account/affiliates/affiliates-list.component';
import { WarehousesComponent } from 'shared/account/information/warehouse/warehouses.component';
import { ChannelInviteComponent } from 'lender/clients/$id/channels/invite/invite.component';
import { InviteHistoryComponent } from 'lender/clients/$id/channels/invite-history/invite-history.component';
import { UnderwritingComponent } from 'tpo/account/underwriting/underwriting.component';
import { InvestorCrmContactListLandingPageComponent } from 'lender/crm/contact/investor-crm-contact-lists/investor-crm-contact-list-landing-page/investor-crm-contact-list-landing-page.component';
import { InvestorCrmContactListContactsComponent } from 'lender/crm/contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/investor-crm-contact-list-contacts.component';
import { ContactListContactsListComponent } from 'lender/crm/contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/contact-list-contacts-list/contact-list-contacts-list.component';
import { IndividualNmlsComponent } from 'shared/nmls/individual/individual-nmls.component';
import { ClientsHeaderComponent } from 'lender/clients/clients-header.component';
import { channelInfoResolver } from 'lender/clients/$id/channels/channels.service';
import { SingleChannelComponent } from 'lender/clients/$id/channels/single/single-channel.component';
import { ChannelLocationsComponent } from 'lender/clients/$id/channels/single/locations/channel-locations.component';
import { ChannelAuditLogComponent } from 'lender/clients/$id/channels/single/audit-log/channel-audit-log.component';
import { AlertComponent } from 'lender/alerts/alert/alert.component';
import { SharedTechOpsComponent } from 'shared/account/information/tech-ops/tech-ops.component';
import { ScorecardSummaryComponent } from 'shared/due-diligence/scorecard/scorecard-summary/scorecard-summary.component';
import { ScorecardSummaryOwnerComponent } from 'shared/due-diligence/scorecard/scorecard-summary-owners/scorecard-summary-owner.component';
import {
    ScorecardCommentsListComponent
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments-list.component';
import { ScorecardFindingComponent } from 'shared/due-diligence/scorecard/scorecard-finding/scorecard-finding.component';
import { ProspectsListComponent } from 'lender/prospects/list/prospects-list.component';
import { ProspectDetailsComponent } from 'lender/prospects/details/details.component';
import { ScorecardFindingsSummaryComponent } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-findings-summary.component';
import { ProspectRelatedContactsComponent } from 'lender/prospects/related-contacts/related-contacts.component';
import {
    ScorecardFindingHistoryComponent
} from 'shared/due-diligence/scorecard/scorecard-finding-history/scorecard-finding-history.component';
import { CommonProductionComponent } from 'shared/production/production.component';
import { ProspectsHistoryListComponent } from 'lender/prospects/history/prospect-history.component';
import { ManageChannelLandingPageComponent } from 'lender/manage-channels/manage-channel-landing-page.component';
import {
    ManageChannelAlertsComponent
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.component';
import { InvestorClientMenuContainerComponent } from 'lender/clients/$id/investor-client-menu-container.component';
import { ChannelHistoryComponent } from 'lender/manage-channels/channel-card-ownership-and-description/channel-card-history/channel-history.component';
import { ManageFinancialDocumentsComponent } from 'shared/financial-documents/management/manage-financial-documents.component';
import { FinancialsManagementHistoryComponent } from 'shared/financial-documents/management/history/financials-management-history.component';
import { ChannelsListComponent } from '../lender/clients/$id/channels/list/channels-list.component';
import { AccountHistoryListComponent } from 'shared/account/company-information/history/history.component';
import { InvestorChannelSharesComponent } from 'lender/channel-shares/investor-channel-shares/investor-channel-shares.component';
import { SCOverviewComponent } from 'lender/solution-center/tabs/overview/overview.component';
import { SCProductsComponent } from 'lender/solution-center/tabs/products/products.component';
import { SCChannelsComponent } from 'lender/solution-center/tabs/channels/channels.component';

const contactRoutes = (prefix: string) => ([
    {
        name: '.company',
        abstract: true,
        views: {
            '^': {
                component: EmptyContainerComponent,
            },
        },
        children: [
            {
                name: '.:contactId',
                url: '/{contactId:int}',
                component: PeopleCompanyDetailsComponent,
            },
        ],
    },
    {
        name: '.new?relatedAccount',
        params: {
            relatedAccount: {
                type: 'json',
            },
        },
        views: {
            '^': {
                component: InvestorGeneralInformationComponent,
            },
        },
    },
    {
        name: '.:contactId',
        url: '/{contactId:int}',
        redirectTo: `${prefix}.contacts.:contactId.information`,
        views: {
            '@': {
                component: InvestorContactDetailsComponent,
            },
        },
        children: [
            {
                name: '.information',
                component: InvestorGeneralInformationComponent,
            },
            {
                name: `.audit-log?${NewContactAuditLogComponent.listName}`,
                params: {
                    [NewContactAuditLogComponent.listName]: {
                        type: 'jsonWithDate',
                    },
                },
                reloadOnSearch: false,
                component: NewContactAuditLogComponent,
            },
            {
                name: `.social-media?${NewContactSocialAccountsComponent.listName}`,
                component: NewContactSocialAccountsComponent,
                reloadOnSearch: false,
                params: {
                    [NewContactSocialAccountsComponent.listName]: {
                        type: 'json',
                    },
                },
            },
            {
                name: `.web-presence?${NewContactWebPresenceComponent.listName}`,
                component: NewContactWebPresenceComponent,
                reloadOnSearch: false,
                params: {
                    [NewContactWebPresenceComponent.listName]: {
                        type: 'json',
                    },
                },
            },
            {
                name: `.lists?${NewContactListsComponent.listName}`,
                component: NewContactListsComponent,
                reloadOnSearch: false,
                params: {
                    [NewContactListsComponent.listName]: {
                        type: 'json',
                    },
                },
            },
            {
                name: `.publishing-permissions?{${IndividualPublishingPermissionsComponent.listName}:json}`,
                url: '/publishing-permissions/:linkId',
                reloadOnSearch: false,
                views: {
                    '@': {
                        component: IndividualPublishingPermissionsComponent,
                    }
                },
            },
            ...SharedRoutes.relatedActivities(`@`),
        ],
    },
]);

export default [
	{
		name: 'home',
        component: LenderHomeComponent,
	},
    {
        name: 'search?q',
        component: GlobalSearchHeaderComponent,
    },
    {
        name: `search.clients?${SearchClientsListComponent.listName}`,
        reloadOnSearch: false,
        component: SearchClientsListComponent,
        params: {
            [SearchClientsListComponent.listName]: {
                type: 'json',
            },
        },
    },
    {
        name: `search.individuals?${SearchIndividualsListComponent.listName}`,
        component: SearchIndividualsListComponent,
        reloadOnSearch: false,
        params: {
            [SearchIndividualsListComponent.listName]: {
                type: 'json',
            },
        },
    },
	{
		name: `documents`,
		component: DocumentsComponent,
		redirectTo: 'documents.list',
		children: [
			{
				name: `.list?${GlobalDocumentListComponent.listName}`,
				params: {
					[GlobalDocumentListComponent.listName]: { type: 'json' },
				},
				reloadOnSearch: false,
				views: {
					'@documents': {
						component: GlobalDocumentListComponent,
					},
				},
			},
			{
				name: `.docusign`,
				views: {
					'@documents': {
						component: DocusignComponent,
					},
				},
			},
			{
				name: '.new',
				views: {
					'@': {
						component: GlobalDocumentComponent,
					},
					'.new': {
						component: GlobalDocumentDetailsComponent,
					},
				},
			},
			{
				name: '.:documentId',
				params: {
					documentId: { type: 'int' },
				},
				views: {
					'@': {
						component: GlobalDocumentComponent,
					},
					'.:documentId': {
						component: GlobalDocumentDetailsComponent,
					},
				},
				children: [
					{
						name: `.history?${GlobalDocumentHistoryComponent.listName}`,
						params: {
							[GlobalDocumentHistoryComponent.listName]: { type: 'json' },
						},
						reloadOnSearch: false,
						component: GlobalDocumentHistoryComponent,
					},
				],
			},
		]
	},
	{
		name: 'financials',
		component: ManageFinancialDocumentsComponent,
	},
	{
		name: 'financials.document-type',
		redirectTo: 'financials.document-type.:documentTypeId.history',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'financials.document-type.:documentTypeId',
		redirectTo: 'financials.document-type.:documentTypeId.history',
		abstract: true,
		component: EmptyContainerComponent,
	},
    {
        name: `financials.document-type.:documentTypeId.history?${FinancialsManagementHistoryComponent.LIST_NAME}`,
		views: {
			'@': {
				component: FinancialsManagementHistoryComponent,
			},
		},
        reloadOnSearch: false,
        params: {
            [FinancialsManagementHistoryComponent.LIST_NAME]: {
                type: 'json',
            },
        },
    },
	{
		name: `applications?${ApplicationsListComponent.listName}`,
		params: {
			[ApplicationsListComponent.listName]: {
				type: 'json',
			},
		},
		component: ApplicationsListComponent,
		reloadOnSearch: false,
	},
	{
		name: 'clients',
		abstract: true,
		component: ClientsHeaderComponent,
		redirectTo: 'clients.list',
	},
	{
		name: `clients.list?${PagedClientsListComponent.listName}`,
		params: {
			[PagedClientsListComponent.listName]: {
				type: 'json',
			},
		},
		reloadOnSearch: false,
		component: ClientsListComponent,
	},
	{
		name: `clients.list.document`,
		url: null, // prevent changing url, so you cannot refresh to this state
		params: {
			channelId: { type: 'int' },
			tpoIds: {
				type: 'int',
				array: true,
			}
		},
		dynamic: true,
		views: {
			'@': {
				component: BulkDocumentRequestComponent,
			}
		}
	},
	{
		name: 'client',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'client.:id',
		abstract: true,
		views: {
			'@': {
				component: InvestorClientMenuContainerComponent,
			}
		},
		children: [
            ...SharedRoutes.relatedActivities(),
        ],
        resolve: [
            { token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
        ],
	},
	{
		name: 'client.:id.account',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'client.:id.account.information',
		component: AccountInformationComponent,
		children: [
			{
				name: `.history?${AccountHistoryListComponent.listName}`,
				reloadOnSearch: false,
				views: {
					'^':{
						component: AccountHistoryListComponent,
						resolve: [
							{ token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
						],
					}
				},
				params: {
					[AccountHistoryListComponent.listName]: {
						type: 'json',
					},
				},
			}
		],
	},
	{
		name: `client.:id.questionnaires?${SharedQuestionnairesListComponent.listName}`,
		reloadOnSearch: false,
		component: SharedQuestionnairesListComponent,
		params: {
			[SharedQuestionnairesListComponent.listName]: {
				type: 'json',
			},
		},
		resolve: [
			{ token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
		],
	},
	...SharedRoutes.SocialAccounts('client.:id.'),
	{
		name: `client.:id.contacts?${LenderOwnersListComponent.listName}&${LenderContactsListComponent.listName}`,
		component: LenderContactsPageComponent,
		reloadOnSearch: false,
		params: {
			[LenderOwnersListComponent.listName]: {
				type: 'json',
			},
			[LenderContactsListComponent.listName]: {
				type: 'json',
			},
		},
        children: contactRoutes('client.:id'),
	},
	{
		name: `invites?${InvitesListComponent.listName}`,
		params: {
			[InvitesListComponent.listName]: {
				type: 'jsonWithIntdate',
			},
		},
		component: InvitesListComponent,
		reloadOnSearch: false,
	},
	{
		name: `alerts?${AlertsComponent.listName}`,
		params: {
			[AlertsComponent.listName]: {
				type: 'jsonWithDate',
			},
		},
		component: AlertsComponent,
		reloadOnSearch: false,
	},
	//TODO: refactor to use NMLS everywhere
	{
		name: 'nmls',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'nmls.individual',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'nmls.individual.:nmlsId',
		params: {
			nmlsId: {
				type: 'int',
			},
		},
		component: IndividualNmlsComponent,
	},
	{
		name: 'nmls.client',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'nmls.client.:id',
		abstract: true,
		views: {
			'@': {
				component: InvestorClientMenuContainerComponent,
			}
		},
		resolve: [
			{ token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
		],
		children: SharedRoutes.relatedActivities('@'),
	},
	{
		name: `nmls.client.:id.contacts?${LenderOwnersListComponent.listName}&${LenderContactsListComponent.listName}`,
		component: LenderContactsPageComponent,
		reloadOnSearch: false,
		params: {
			[LenderOwnersListComponent.listName]: {
				type: 'json',
			},
			[LenderContactsListComponent.listName]: {
				type: 'json',
			},
		},
		children: contactRoutes('nmls.client.:id'),
	},
	{
		name: 'nmls.client.:id.account',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: `nmls.client.:id.account.information`,
		component: AccountInformationComponent,
		children: [
			{
				name: `.history?${AccountHistoryListComponent.listName}`,
				reloadOnSearch: false,
				views: {
					'^':{
						component: AccountHistoryListComponent,
						resolve: [
							{ token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
						],
					}
				},
				params: {
					[AccountHistoryListComponent.listName]: {
						type: 'json',
					},
				},
			}
		]
	},
	//ENDTODO
	//TODO: refactor to use NMLS everywhere
	{
        name: `nmls.client.:id.account.locations?{${LocationsListComponent.listName}:json}`,
        reloadOnSearch: false,
        component: LocationsListComponent,
		children: SharedRoutes.locations,
	},
	{
		name: `nmls.client.:id.account.dba?{${DbaListComponent.listName}:json}`,
        reloadOnSearch: false,
        component: DbaListComponent,
	},
    {
        name: 'nmls.client.:id.account.channels',
        component: ChannelCardListComponent,
    },
    {
        name: 'nmls.client.:id.account.old-channels',
        component: ChannelsListComponent,
    },
	{
		name: `nmls.client.:id.account.federal-registration?{${FederalRegistrationsListComponent.listName}:json}`,
		reloadOnSearch: false,
		component: FederalRegistrationsListComponent,
	},
	{
		name: `nmls.client.:id.account.licenses?${SharedLicenseListComponent.listName}`,
		params: {
			[SharedLicenseListComponent.listName]: {
				type: 'json',
			},
		},
		reloadOnSearch: false,
		component: SharedLicenseListComponent,
	},
	{
		name: 'nmls.client.:id.account.licenses.nmls',
        abstract: true,
        component: EmptyContainerComponent,
	},
	{
		name: 'nmls.client.:id.account.licenses.nmls.:nmlsId',
		views: {
			'@nmls.client.:id': {
				component: IndividualNmlsComponent,
			},
		},
	},
	{
		name: 'nmls.client.:id.production',
        params: {
            prefix: 'nmls.client.:id.',
        },
        component: CommonProductionComponent,
		resolve: [
			{ token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
		],
	},
	...SharedRoutes.SocialAccounts('nmls.client.:id.'),
	//ENDTODO
	{
		name: 'client.:id.account.channels-old',
        component: ChannelsListComponent,
	},
    {
        name: 'client.:id.account.channels',
        component: ChannelCardListComponent,
    },
    {
        name: `client.:id.account.channels.:channelId?${ChannelLocationsComponent.listName}`,
        resolve: [
            channelInfoResolver,
        ],
        views: {
            '@client.:id.account': {
                component: SingleChannelComponent,
            },
        },
    },
	{
		name: `client.:id.account.channels.:channelId.history?{${ChannelAuditLogComponent.listName}:json}`,
        reloadOnSearch: false,
		views: {
			'@client.:id.account': {
				component: ChannelAuditLogComponent,
			},
		},
	},
    {
        name: 'client.:id.account.channels.invite',
        abstract: true,
        component: EmptyContainerComponent,
    },
	{
		name: 'client.:id.account.channels.invite.:channelId',
		views: {
			'@client.:id.account': {
                component: ChannelInviteComponent,
            },
		},
	},
	{
		name: 'client.:id.account.channels.invite.:channelId.history',
		views: {
			'@client.:id.account': {
				component: InviteHistoryComponent,
			},
		},
	},
	{
		name: 'client.:id.account.channels.:channelId.applications',
        abstract: true,
        component: EmptyContainerComponent,
    },
	{
		name: 'client.:id.account.channels.:channelId.applications.:applicationId',
        abstract: true,
        component: EmptyContainerComponent,
    },
	{
		name: `client.:id.account.channels.:channelId.applications.:applicationId.comments?${CommentsComponent.listName}`,
		views: {
			'@client.:id.account': {
				component: ApplicationsCommentsComponent,
			},
		},
		params: {
			notification: null,
			[CommentsComponent.listName]: {
				type: 'json',
			},
		},
		reloadOnSearch: false,
	},
	{
		name: `client.:id.account.channels.:channelId.applications.:applicationId.history?${ApplicationsHistoryComponent.listName}`,
		views: {
			'@client.:id.account': {
				component: ApplicationsHistoryComponent,
			},
		},
		params: {
			notification: null,
			[ApplicationsHistoryComponent.listName]: {
				type: 'json',
			},
		},
		reloadOnSearch: false,
	},
	{
        name: `client.:id.account.locations?{${LocationsListComponent.listName}:json}`,
        reloadOnSearch: false,
        component: LocationsListComponent,
		children: SharedRoutes.locations,
	},
	{
		name: `client.:id.account.dba?{${DbaListComponent.listName}:json}`,
        reloadOnSearch: false,
        component: DbaListComponent,
	},
	{
		name: `client.:id.account.federal-registration?{${FederalRegistrationsListComponent.listName}:json}`,
		reloadOnSearch: false,
		component: FederalRegistrationsListComponent,
	},
	{
		name: `client.:id.account.licenses?${SharedLicenseListComponent.listName}`,
		params: {
			[SharedLicenseListComponent.listName]: {
				type: 'json',
			},
		},
		reloadOnSearch: false,
		component: SharedLicenseListComponent,
	},
	{
		name: 'client.:id.account.licenses.nmls',
        abstract: true,
        component: EmptyContainerComponent,
	},
	{
		name: 'client.:id.account.licenses.nmls.:nmlsId',
		views: {
			'@client.:id': {
				component: IndividualNmlsComponent,
			},
		},
	},
    {
        name: 'client.:id.reviews',
        abstract: true,
        component: EmptyContainerComponent,
    },
	{
		name: 'client.:id.reviews.scorecard',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'client.:id.reviews.scorecard.summary',
		component: ScorecardSummaryComponent,
	},
    {
        name: `client.:id.reviews.scorecard.comments?${ScorecardCommentsListComponent.listName}`,
        component: ScorecardCommentsListComponent,
        params: {
            [ScorecardCommentsListComponent.listName]: {
                type: 'json',
            },
        },
    },
	{
		name: 'client.:id.reviews.scorecard.owner',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: `client.:id.reviews.scorecard.owner.:ownerId?${ScorecardSummaryOwnerComponent.listName}`,
		reloadOnSearch: false,
        params: {
            [ScorecardSummaryOwnerComponent.listName]: {
                type: 'json',
            },
        },
		views: {
			'@client.:id.reviews': {
                component: ScorecardSummaryOwnerComponent,
			},
		},
	},
	{
		name: 'client.:id.reviews.scorecard.finding',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: `client.:id.reviews.scorecard.finding.:sType?${ScorecardFindingsSummaryComponent.listName}`,
		reloadOnSearch: false,
        params: {
            [ScorecardFindingsSummaryComponent.listName]: {
                type: 'json',
            },
        },
		views: {
			'@client.:id.reviews': {
                component: ScorecardFindingsSummaryComponent,
			},
		},
	},
	{
		name: 'client.:id.reviews.scorecard.finding.:sType.:sid',
		views: {
			'@client.:id.reviews': {
				component: ScorecardFindingComponent,
			},
		},
	},
	{
		name: 'client.:id.reviews.scorecard.finding.:sType.:sid.history',
		views: {
			'@client.:id.reviews': {
                component: ScorecardFindingHistoryComponent,
			},
		},
	},
	{
		name: `client.:id.reviews.alerts?${AlertsComponent.listName}`,
		params: {
			[AlertsComponent.listName]: {
				type: 'jsonWithDate',
			},
		},
		component: AlertsComponent,
		reloadOnSearch: false,
		children: [
			{
				name: '.:alertId',
				params: {
					alertId: { type: 'int' },
				},
				views: {
					'^': {
						component: AlertComponent,
					},
				},
			},
		],
	},
	{
		name: 'client.:id.relationships',
        abstract: true,
        component: EmptyContainerComponent,
	},
    {
        name: `client.:id.relationships.affiliates?${AffiliatesListComponent.listName}`,
        params: {
            [AffiliatesListComponent.listName]: {
                type: 'json',
            },
        },
        component: AffiliatesListComponent,
        children: [
            {
                name: '.:affiliateId',
                views:{
                    '^':{
                        component: AffiliatesAddEditViewComponent,
                    }
                }
            }
        ]
    },
    {
        name: 'client.:id.relationships.agency',
        component: AgencyComponent,
    },
    {
        name: 'client.:id.relationships.investors',
        component: InvestorsComponent,
    },
    {
        name: 'client.:id.relationships.warehouse',
        component: WarehousesComponent,
    },
    {
        name: 'client.:id.relationships.insuranceBonds',
        component: InsuranceBondsComponent,
    },
	{
		name: 'client.:id.relationships.underwriting',
        component: UnderwritingComponent,
	},
    {
        name: 'client.:id.relationships.tech-ops',
        component: SharedTechOpsComponent,
    },
	{
		name: 'client.:id.documents',
		component: ClientDocumentsComponent,
		children: [
			{
				name: `.list?${ClientDocumentListComponent.listName}`,
				params: {
					notification: null,
					[ClientDocumentListComponent.listName]: {
						type: 'json',
					},
				},
				reloadOnSearch: false,
				component: ClientDocumentListComponent,
				children: [
					{
						name: '.new',
						views: {
							'@client.:id': {
								component: ClientDocumentDetailsComponent,
							},
						},
					},
					...SharedRoutes.ClientDocuments('@client.:id'),
				],
			},
			{
				name: `.library?${ClientLibraryDocumentListComponent.listName}`,
				params: {
					[ClientLibraryDocumentListComponent.listName]: {
						type: 'json',
					},
				},
				reloadOnSearch: false,
				component: ClientLibraryDocumentListComponent,
				children: [
					{
						name: '.:libraryId',
						views: {
							'@client.:id': {
								component: ClientLibraryDocumentComponent,
							},
						},
						children: [
							{
								name: `.history?${ClientLibraryDocumentHistoryComponent.listName}`,
								params: {
									[ClientLibraryDocumentHistoryComponent.listName]: {
										type: 'json',
									},
								},
								reloadOnSearch: false,
								component: ClientLibraryDocumentHistoryComponent,
							},
						],
					},
				],
			},
			{
				name: `.internal-documents?${InternalDocumentsListComponent.listName}`,
				reloadOnSearch: false,
				component: InternalDocumentsListComponent,
				params: {
					[InternalDocumentsListComponent.listName]: {
						type: 'json',
					},
				},
				children: [
					{
						name: '.new',
						views: {
							'@client.:id': {
								component: InternalDocumentDetailsComponent,
							},
						},
					},
					{
						name: '.:documentId',
						params: {
							documentId: { type: 'int' },
						},
						views: {
							'@client.:id': {
								component: InternalDocumentDetailsComponent,
							},
						},
					},
					{
						name: `.history?${CommonDocumentsHistoryComponent.listName}`,
						url: '/history/:docId',
						reloadOnSearch: false,
						views: {
							'@client.:id': {
								component: CommonDocumentsHistoryComponent,
							},
						},
						params: {
							docId: { type: 'int' },
							docType: 'internal-documents', // Changes history end point
							[CommonDocumentsHistoryComponent.listName]: {
								type: 'json',
								value: null,
							},
						},
					},
				],
			},
		],
	},
	{
		name: 'client.:id.financials',
		reloadOnSearch: false,
		component: TpoFinancialsComponent,
		children: [
			{
				name: '.:documentType',
				abstract: true,
				component: EmptyContainerComponent,
				children: [
					{
						name: `.history?${FinancialDocumentHistoryComponent.listName}`,
						params: {
							documentType: {
								type: 'string',
								value: null,
							},
							[FinancialDocumentHistoryComponent.listName]: { type: 'json' },
						},
						reloadOnSearch: false,
						views: {
							'@client.:id': {
								component: FinancialDocumentHistoryComponent,
							},
						},
					},
					{
						name: `.requests?${FinancialDocumentRequestsHistoryComponent.listName}`,
						params: {
							documentType: {
								type: 'string',
								value: null,
							},
							[FinancialDocumentRequestsHistoryComponent.listName]: { type: 'json' },
						},
						reloadOnSearch: false,
						views: {
							'@client.:id': {
								component: FinancialDocumentRequestsHistoryComponent,
							},
						},
					},
				],
			},
		]
	},
	{
		name: `client.:id.comments?${CommentsComponent.listName}`,
		params: {
			notification: null,
			[CommentsComponent.listName]: {
				type: 'json',
			},
		},
		component: InvestorClientComments,
		reloadOnSearch: false,
	},
    {
        name: 'client.:id.application-summary',
        abstract: true,
        component: EmptyContainerComponent,
    },
    {
        name: 'client.:id.application-summary.:applicationId',
        params: {
            applicationId: { type: 'int' },
        },
        component: ApplicationSummaryComponent,
        children: [
            ...SharedRoutes.ClientDocuments(),
        ],
    },
	{
		name: 'client.:id.production',
		params: {
			prefix: 'client.:id.',
		},
		component: CommonProductionComponent,
		resolve: [
			{ token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
		],
	},
    {
        name: 'channels',
        component: ManageChannelLandingPageComponent,
    },
    {
        name: 'channel',
        abstract: true,
        component: EmptyContainerComponent,
    },
    {
        name: 'channel.:id',
        abstract: true,
        component: EmptyContainerComponent,
    },
    {
        name: 'channel.:id.alerts',
        component: ManageChannelAlertsComponent,
    },
    {
        name: `channel.:id.history?${ChannelHistoryComponent.listName}`,
        component: ChannelHistoryComponent,
        reloadOnSearch: false,
        params: {
            [ChannelHistoryComponent.listName]: {
                type: 'json',
            },
        },
    },
	{
		name: `question-bank?${QuestionBankComponent.listName}`,
		component: QuestionBankComponent,
		params: {
			notification: null,
			[QuestionBankComponent.listName]: {
				type: 'jsonWithDate',
			},
		},
		reloadOnSearch: false,
		children: [
			{
				name: `.manage-categories?${QuestionBankCategoriesComponent.listName}`,
				views: {
					'@': {
						component: QuestionBankCategoriesComponent,
					},
				},
			},
		],
	},
	{
		name: `questionnaires?${ManageQuestionnairesComponent.listName}`,
		component: ManageQuestionnairesComponent,
		params: {
			notification: null,
			[ManageQuestionnairesComponent.listName]: {
				type: 'jsonWithDate',
			},
		},
		reloadOnSearch: false,
		children: [
            {
                name: `.list?${QuestionnairesComponent.listName}`,
                views: {
                    '@': {
                        component: QuestionnairesComponent,
                    },
                },
                params: {
                    notification: null,
                    [QuestionnairesComponent.listName]: {
                        type: 'jsonWithDate',
                    },
                },
                reloadOnSearch: false,
            },
            {
                name: '.new',
                params: {
                    action: {
                        type: 'string',
                        value: 'new',
                    }
                },
                views: {
                    '@': {
                        component: QuestionnaireComponent,
                    },
                },
                children: [
                    {
                        name: '.questions',
                        component: EditQuestionnairesComponent,
                    },
                ],
            },
            {
				name: '.:questionnaireId',
				views: {
					'@': {
						component: QuestionnaireComponent,
					},
				},
                params: {
                    questionnaireId: {
                        type: 'int',
                        value: null,
                    },
                },
				children: [
					{
						name: `.documents?${QuestionnaireDocumentsComponent.listName}`,
                        component: QuestionnaireDocumentsComponent,
						params: {
							[QuestionnaireDocumentsComponent.listName]: {
								type: 'jsonWithDate',
							},
						},
					},
                    {
                        name: '.from-bank',
                        reloadOnSearch: false,
                        views: {
                            '@': {
                                component: QuestionFromBankComponent,
                            },
                        },
                    },
                    {
                        name: '.questions',
                        url: '/questions/:action',
                        reloadOnSearch: false,
                        component: EditQuestionnairesComponent,
                        children: [
                            {
                                name: '.preview',
                                views: {
                                    '@': {
                                        component: QuestionPreviewComponent,
                                    },
                                },
                            }
                        ],
                    },
				],
			},

		],
	},
	{
		name: 'my-settings',
		component: LenderMySettingsComponent,
	},
	{
		name: 'solution-center',
		component: SolutionCenterComponent,
        redirectTo: 'solution-center.overview',
        children: [
            {
                name: '.overview',
                url: null,
                component: SCOverviewComponent,
            },
            {
                name: '.products',
                url: null,
                component: SCProductsComponent,
            },
			{
				name: '.channels',
				url: null,
				component: SCChannelsComponent,
			},
        ],
	},
	{
		name: 'users-management',
        component: UserManagementHeaderComponent,
	},
	{
		name: `users-management.users?${LenderUsersListComponent.listName}`,
		reloadOnSearch: false,
		component: LenderUsersListComponent,
		params: {
			[LenderUsersListComponent.listName]: {
				type: 'json',
			},
		},
		children: [
			{
				name: '.:actionType',
                component: EmptyContainerComponent,
				redirectTo: 'users-management.users.:actionType.:id',
			},
			{
				name: `.:actionType.:id?${LoginLogComponent.listName}&${ActionLogComponent.listName}`,
				reloadOnSearch: false,
				views: {
					'@': {
						component: LenderUserComponent,
					},
				},
				params: {
					[LoginLogComponent.listName]: {
						type: 'jsonWithIntdate',
					},
					[ActionLogComponent.listName]: {
						type: 'jsonWithIntdate',
					},
				},
			},
		],
	},
	...SharedRoutes.roles,
	{
		name: `appcodes?${AppcodesComponent.listName}`,
		reloadOnSearch: false,
		component: AppcodesComponent,
		params: {
			[AppcodesComponent.listName]: {
				type: 'json',
			},
		},
	},
	{
		name: 'crm',
		abstract: true,
        component: EmptyContainerComponent,
	},
	{
		name: 'crm.market',
		component: MarketComponent,
		redirectTo: 'crm.market.msa',
		children: [
			{
				name: `.msa?${MsaListComponent.listName}&msa&hintText&countyName&objectType&stateCode&zip&cityName`,
				reloadOnSearch: false,
				component: MsaListComponent,
				params: {
					[MsaListComponent.listName]: {
						type: 'json',
					},
				},
			},
			{
				name: `.companies?${CompaniesListComponent.listName}&msa&hintText&countyName&objectType&stateCode&zip&cityName`,
				reloadOnSearch: false,
				component: CompaniesListComponent,
				params: {
					[CompaniesListComponent.listName]: {
						type: 'json',
					},
				},
			},
			{
				name: `.map?${MarketMapComponent.listName}&msa&hintText&countyName&objectType&stateCode&zip&cityName`,
				reloadOnSearch: false,
				params: {
					[MarketMapComponent.listName]: {
						type: 'json',
					},
				},
				component: MarketMapComponent,
			},
		]
	},
	/**
	 * TODO: redo once ui-router updated
	 * https://github.com/angular-ui/ui-router/commit/6becb12da895ee0e60050ed4635117d77ce27a96
	 */
	{
		name: 'crm.activities',
		abstract: true,
        component: EmptyContainerComponent,
	},
	{
		name: `crm.activities.list?${ActivitiesListComponent.listName}`,
		reloadOnSearch: false,
		component: ActivitiesListComponent,
		params: {
			[ActivitiesListComponent.listName]: {
				type: 'jsonWithDate',
			},
		},
	},
	{
		name: 'crm.activities.new',
		views: {
			'@': {
				component: ActivityDetailsComponent,
			},
		},
	},
	{
		name: 'crm.activities.:activityId',
		views: {
			'@': {
				component: ActivityComponent,
			},
		},
		redirectTo: 'crm.activities.:activityId.details',
		children: [
			{
				name: '.details',
				views: {
					'@^': {
						component: ActivityDetailsComponent,
					},
				},
			},
			{
				name: `.history?${ActivityHistoryComponent.listName}`,
				reloadOnSearch: false,
				views: {
					'@^': {
						component: ActivityHistoryComponent,
					},
				},
				params: {
					[ActivityHistoryComponent.listName]: {
						type: 'json',
					},
				},
			},
		],
	},
	...SharedRoutes.CustomFields(),
	{
		name: 'fields.:entityType.system',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'fields.:entityType.system.:systemType?edit',
		views: {
			'@': {
				component: SystemDetailsComponent,
			},
		},
	},
	{
		name: `crm.contacts?${LenderContactsBoardComponent.listName}`,
		component: LenderContactsBoardComponent,
		reloadOnSearch: false,
		params: {
			[LenderContactsBoardComponent.listName]: {
				type: 'json',
			},
		},
		children: contactRoutes('crm'),
	},
	{
		name: 'crm.contact',
		abstract: true,
        component: EmptyContainerComponent,
	},
	{
		name: `crm.contact.lists?${InvestorCrmContactListLandingPageComponent.listName}`,
        params: {
            notification: null,
            [InvestorCrmContactListLandingPageComponent.listName]: {
                type: 'json',
            },
        },
        reloadOnSearch: false,
        component: InvestorCrmContactListLandingPageComponent,
		children: [
			{
				name: '.:listId',
				params: {
					listId: {
						type: 'int',
					},
				},
				component: EmptyContainerComponent,
				redirectTo: 'crm.contact.lists.:listId.details',
				children: [
					{
						name: '.details',
						views: {
							'@': {
								component: InvestorCrmContactListComponent,
							},
						},
					},
					{
						name: `.contacts?${ContactListContactsListComponent.listName}`,
						params: {
							[ContactListContactsListComponent.listName]: {
								type: 'json',
							},
						},
						reloadOnSearch: false,
						views: {
							'@': {
								component: InvestorCrmContactListContactsComponent,
							},
						},
					},
					{
						name: '.clone',
						views: {
							'@': {
								component: InvestorCrmContactListComponent,
							},
						},
					},
				],
			},
			{
				name: '.new',
				views: {
					'@': {
						component: InvestorCrmContactListComponent,
					},
				},
			},
		],
	},
	{
		name: `crm.prospects?${ProspectsListComponent.listName}&q&page&prospectAssigneeId&prospectState&organizationChannelId`,
		reloadOnSearch: false,
		component: ProspectsListComponent,
		params: {
			[ProspectsListComponent.listName]: {
				type: 'json',
			},
		},
		children: [
			{
				name: `.:id?{${ProspectRelatedContactsComponent.listName}:json}&{${ActivitiesListComponent.listName}:json}`,
				reloadOnSearch: false,
				views: {
					'@': {
						component: ProspectDetailsComponent,
					},
				},
				children: SharedRoutes.relatedActivities('@'),
			},
		],
	},
    {
        name: `crm.prospects.:id.history?${ProspectsHistoryListComponent.listName}`,
        views: {
            '@': {
                component: ProspectsHistoryListComponent,
            }
        },
        params: {
            [ProspectsHistoryListComponent.listName]: {
                type: 'json',
            },
        },
    },
	{
		name: 'manage',
		component: PasswordPolicyComponent,
	},
	{
		name: 'shares',
		component: InvestorChannelSharesComponent,
	},
];
