import { Component, OnInit } from '@angular/core';
import { ClientProductsService } from 'comergence/tpo/billing/client-products/client-products.service';
import { ClientProduct } from 'comergence/tpo/billing/client-products/client-product.interface';
import { UIRouter } from '@uirouter/core';

@Component({
    templateUrl: 'client-products.component.html',
    selector: 'client-product-item',
})
export class ClientProductsComponent implements OnInit {
    clientProducts: ClientProduct[];
    tpoId: number;
    resolving: boolean = false;

    constructor(
        private clientProductsService: ClientProductsService,
        private readonly uiRouter: UIRouter,
    ) {
    }

    ngOnInit(): void {
        this.tpoId = this.uiRouter.globals.params.id;
        this.getCustomerProduct();
    }

    private getCustomerProduct() {
        this.resolving = true;
        this.clientProducts = this.clientProductsService.getClientProductList(this.tpoId);
        this.resolving = false;
    }
}
