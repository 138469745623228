/* eslint-disable comma-dangle */
import { UserService } from 'angularjs-providers/user.provider';


import { RolesListComponent } from 'shared/roles/list/roles-list.component';
import { RoleComponent } from 'shared/roles/role/role.component';

import {
	SocialComplianceComponent,
	FeedComponent,
	AuditFindingsComponent,
	AuditsDetailsComponent,
	AuditsInformationComponent,
	AuditsListComponent,
	AuditSocialAccountsComponent,
	AuditsService,
	hasAuditsListPermissions,
	OrdersListComponent,
	OrderDetailsComponent,
	TriggersListComponent,
	TriggerDetailsComponent,
	TriggerHistoryComponent,
} from 'shared/social-compliance';

//end: Contacts
import { CompanyPublishingPermissionsComponent } from 'tpo/social-compliance/publishing-permissions';
import { SocialAccountsCompanyComponent, SocialAccountsComponent, SocialAccountsIndividualsComponent, SocialAccountsInventoryComponent, } from 'tpo/social-accounts';
import { TemplateEditComponent, TemplateViewComponent } from 'tpo/social-compliance/publisher';

import {
	ClientDocumentComponent,
	ClientDocumentDetailsComponent,
	ClientDocumentHistoryComponent,
} from 'lender/clients';
import { ActivitiesListComponent, ActivityComponent, ActivityDetailsComponent, ActivityHistoryComponent, } from 'lender/crm';

import { FindingComponent, NewReviewFindingComponent, } from 'shared/findings';
import { AuditChecklistComponent, ReviewAuditLogComponent, } from 'shared/reviews';

import { renameKey } from './helpers/rename-key';

import { EmptyContainerComponent } from 'commons/components';
import { locationInfoResolver } from 'shared/account/locations/locations.service';
import { LocationHeaderComponent } from 'shared/account/locations/single/header.component';
import { LocationOriginatorsComponent } from 'shared/account/locations/single/originators/originators.component';
import { LocationDbaComponent } from 'shared/account/locations/single/dba/dba.component';
import { DbaListComponent } from 'shared/account/dba/dba-list.component';
import { LocationOriginatorListComponent } from 'shared/account/locations/single/originators/originators/originators-list.component';
import { LocationBranchManagerListComponent } from 'shared/account/locations/single/originators/branch-managers/branch-manager-list.component';
import { LocationLicensesComponent } from 'shared/account/locations/single/licenses/licenses.component';
import { SharedLicenseListComponent } from 'shared/account/licenses';
import { StateDeclaration } from '@uirouter/angular';
import { CustomFieldsEntitiesComponent, DetailsComponent, EntityFieldsComponent } from 'shared/fields';
import { LocationsChannelsComponent } from 'shared/account/locations/single/channels/locations-channels.component';


export const NO_URL = Symbol('NO_URL');
export interface RealmStateDeclaration extends StateDeclaration {
	component?: {};
    controller?: string;
    templateUrl?: string;
    children?: Array<RealmStateDeclaration>;
    isNew?: boolean;
    isFullPageView?: boolean;
    free?: boolean;
    views?: {};
    class?: string;
}

const SharedRoutes = {
	roles: [
		{
			name: `users-management.roles?${RolesListComponent.listName}`,
			component: RolesListComponent,
			params: {
				createdRole: null,
				[RolesListComponent.listName]: {
					type: 'json',
				},
			},
		},
		{
			name: 'users-management.roles.:actionType',
            component: EmptyContainerComponent,
			redirectTo: 'users-management.roles.:actionType.:roleId',
		},
		{
			name: 'users-management.roles.:actionType.:roleId',
			views: {
				'@': {
					component: RoleComponent
				}
			},
		},
	],
	relatedActivities: (viewPath: string = '.'): Array<RealmStateDeclaration> => [
		{
			name: `.activities?${ActivitiesListComponent.listName}`,
			reloadOnSearch: false,
			component: ActivitiesListComponent,
			params: {
				[ActivitiesListComponent.listName]: {
					type: 'jsonWithDate'
				},
			}
		},
		{
			name: '.activities.new',
			views: {
				[viewPath]: {
					component: ActivityDetailsComponent
				}
			}
		},
		{
			name: '.activities.:activityId',
			views: {
				[viewPath]: {
					component: ActivityComponent,
				}
			},
			/* HA-HA transition.targetState().name() will return the current route
			(for example "crm.contacts.tpo.:id.:contactId.activities.:activityId") no more pathPrefix for such case */
			redirectTo: (transition) => `${ transition.targetState().name() }.details`,
			children: [
				{
					name: '.details',
					views: {
						'@^': {
							component: ActivityDetailsComponent
						}
					}
				},
				{
					name: `.history?${ActivityHistoryComponent.listName}`,
					reloadOnSearch: false,
					views: {
						'@^': {
							component: ActivityHistoryComponent
						}
					},
					params: {
						[ActivityHistoryComponent.listName]: {
							type: 'json'
						}
					},
				},
			],
		}
	],
	production: [],
	locations: [
		{
			name: '.:branchId',
            resolve: [
                locationInfoResolver,
            ],
            abstract: true,
			views: {
				'^': {
					component: LocationHeaderComponent,
				}
			},
            children: [
                {
                    reloadOnSearch: false,
                    name: `.originators?{${LocationOriginatorListComponent.listName}:json}&{${LocationBranchManagerListComponent.listName}:json}`,
                    component: LocationOriginatorsComponent,
                },
                {
                    reloadOnSearch: false,
                    name: `.dba?{${DbaListComponent.listName}:json}`,
                    component: LocationDbaComponent,
                },
                {
                    reloadOnSearch: false,
                    name: `.licenses?{${SharedLicenseListComponent.listName}:json}`,
                    component: LocationLicensesComponent,
                },
                {
                    reloadOnSearch: false,
                    name: `.channels?{${LocationsChannelsComponent.listName}:json}`,
                    component: LocationsChannelsComponent,
                },
            ],
		},
	],
	SocialCompliance: {
		name: 'social-compliance',
		component: SocialComplianceComponent,
		children: [
			{
				name: '.findings',
				component: EmptyContainerComponent,
				children: [
					{
						name: '.new?reviewId',
						params: {
							reviewId: { type: 'int' },
						},
						component: NewReviewFindingComponent,
					},
					{
						name: '.:actionType',
						component: EmptyContainerComponent,
						children: [
							{
								name: '.:findingId?tab',
								params: {
									findingId: { type: 'int' },
									tab: null,
								},
								resolve: {
									// finding: FindingResolver,
								},
								isFullPageView: true,
								views: {
									'@': {
										component: FindingComponent,
									},
								},
							},
						],
					},
				],
			},
			{
				name: `.reviews?${AuditsListComponent.listName}`,
				params: {
					[AuditsListComponent.listName]: {
						type: 'jsonWithDate',
					}
				},
				reloadOnSearch: false,
				component: AuditsListComponent,
				resolve: {
					reviewStatuses: [UserService, AuditsService, (userService, auditsService) => {
						if (hasAuditsListPermissions(userService.profile)) {
							return auditsService.reviewStatuses().$promise;
						}
					}],
					auditStatuses: [UserService, AuditsService, (userService, auditsService) => {
						if (hasAuditsListPermissions(userService.profile)) {
							return auditsService.auditStatuses().$promise;
						}
					}],
				},
			},
			{
				name: `.orders?${OrdersListComponent.listName}`,
				params: {
					[OrdersListComponent.listName]: {
						type: 'json',
					}
				},
				reloadOnSearch: false,
				component: OrdersListComponent,
			},
			{
				name: `.orders.:id?${OrderDetailsComponent.listName}`,
				params: {
					[OrderDetailsComponent.listName]: {
						type: 'json',
					}
				},
				reloadOnSearch: false,
				views: {
					'@social-compliance': {
						component: OrderDetailsComponent,
					}
				}
			},
			{
				name: '.orders.:id.reviews',
				abstract: true,
				views: {
					'@social-compliance': {
						component: EmptyContainerComponent,
					}
				}
			},
			{
				name: '.orders.:id.reviews.:reviewId',
				url: '/reviews/:reviewId',
				component: AuditsDetailsComponent,
				children: [
					{
						name: `.information?${AuditFindingsComponent.listName}`,
						reloadOnSearch: false,
						component: AuditsInformationComponent,
						params: {
							[AuditFindingsComponent.listName]: {
								type: 'json',
								value: {},
								squash: true,
							},
						},
					},
					{
						name: '.social-accounts',
						url: `/social-accounts/:linkListType?${AuditSocialAccountsComponent.listName}`,
						params: {
							[AuditSocialAccountsComponent.listName]: {
								type: 'json',
								value: {},
								squash: true,
							},
							linkListType: {
								type: 'string',
								value: 'social',
								dynamic: true,
							},
						},
						reloadOnSearch: false,
						component: AuditSocialAccountsComponent,
					},
					{
						name: `.checklist?${AuditChecklistComponent.listName}`,
						params: {
							[AuditChecklistComponent.listName]: {
								type: 'json',
							},
						},
						component: AuditChecklistComponent,
					},
					{
						name: `.archive?${FeedComponent.listName}`,
						params: {
							[FeedComponent.listName]: {
								type: 'jsonWithDate',
							}
						},
						reloadOnSearch: false,
						component: FeedComponent,
					},
					{
						name: `.audit-log?${ReviewAuditLogComponent.listName}`,
						params: {
							[ReviewAuditLogComponent.listName]: {
								type: 'json',
							},
						},
						component: ReviewAuditLogComponent,
					},
				],
			},
			{
				name: `.triggers?${TriggersListComponent.listName}`,
				params: {
					[TriggersListComponent.listName]: {
						type: 'json',
					}
				},
				reloadOnSearch: false,
				component: TriggersListComponent,
				children: [
					{
						name: '.new',
						params: {
                            isNew: true,
						},
						views: {
							'@social-compliance': {
								component: TriggerDetailsComponent,
							},
						},
					},
					{
						name: '.:triggerId',
						views: {
							'@social-compliance': {
								component: TriggerDetailsComponent,
							},
						},
					},
					{
						name: '.:triggerId.clone',
						params: {
							isNew: true,
						},
						views: {
							'@social-compliance': {
								component: TriggerDetailsComponent,
							},
						},
					},
					{
						name: `.:triggerId.history?${TriggerHistoryComponent.listName}`,
						params: {
							[TriggerHistoryComponent.listName]: {
								type: 'json',
							}
						},
						views: {
							'@social-compliance': {
								component: TriggerHistoryComponent,
							},
						},
					},
				],
			},
		],
	},
	SocialAccounts: (pathPrefix: string): Array<RealmStateDeclaration> => {
		return [
			{
				name: `${pathPrefix}social-accounts`,
				component: SocialAccountsComponent,
				children: [
					{
						name: `.publishing-permissions`,
						url: `/publishing-permissions/:linkId?${CompanyPublishingPermissionsComponent.listName}`,
                        params: {
                            [CompanyPublishingPermissionsComponent.listName]: {
                                type: 'json',
                            },
                        },
						reloadOnSearch: false,
						views: {
							'@': {
								component: CompanyPublishingPermissionsComponent,
							}
						},
					},
					{
						name: `.company`,
						url: `/company/:linkListType?${SocialAccountsCompanyComponent.listName}`,
						reloadOnSearch: false,
						params: {
							[SocialAccountsCompanyComponent.listName]: {
								type: 'json',
								value: {},
								squash: true,
							},
							linkListType: {
								type: 'string',
								value: 'social',
								dynamic: true,
							},
						},
						views: {
							[`@${pathPrefix}social-accounts`]: {
								component: SocialAccountsCompanyComponent,
							}
						},
					},
					{
						name: `.individuals?${SocialAccountsIndividualsComponent.listName}`,
						views: {
							[ `@${pathPrefix}social-accounts` ]: {
								component: SocialAccountsIndividualsComponent,
							}
						},
						reloadOnSearch: false,
						params: {
							[SocialAccountsIndividualsComponent.listName]: {
								type: 'json',
							},
						},
					},
					{
						name: `.inventory?${SocialAccountsInventoryComponent.listName}`,
						views: {
							[ `@${pathPrefix}social-accounts` ]: {
								component: SocialAccountsInventoryComponent,
							}
						},
						reloadOnSearch: false,
						params: {
							[SocialAccountsInventoryComponent.listName]: {
								type: 'json',
							},
						},
					},
				]
			},
		]
	},
	ClientDocuments: (view = '@'): Array<RealmStateDeclaration> => ([
		{
			name: '.:documentId',
			url: '',
			params: {
				id: { type: 'int' }, // tpoId
				documentId: { type: 'int' },
			},
			redirectTo: '.:documentId.details',
			views: {
				[view]: {
					component: ClientDocumentComponent,
				},
			},
			children: [
				{
					name: '.details',
					url: '/:documentId',
					/**
					 *  TODO
					 *  now ClientDocumentDetailsComponent includes own header and replaces ClientDocumentComponent container
					 *  refactor ClientDocumentDetailsComponent to consume ClientDocumentComponent container
					 */
					views: {
						[view]: {
							component: ClientDocumentDetailsComponent,
						},
					},
				},
				{
					name: `.history`,
					url: `/:documentId/history?${ClientDocumentHistoryComponent.listName}`,
					params: {
						[ClientDocumentHistoryComponent.listName]: {
							type: 'json',
						},
					},
					reloadOnSearch: false,
					component: ClientDocumentHistoryComponent,
				},
			],
		},
	]),
	CustomFields: (pathPrefix?: string): Array<RealmStateDeclaration> => {
		return [
			{
				name: `fields?${CustomFieldsEntitiesComponent.listName}`,
				isNew: true,
				views: {
					'@': {
						component: CustomFieldsEntitiesComponent,
					},
				},
				params: {
					[CustomFieldsEntitiesComponent.listName]: {
						type: 'json',
					},
				},
			},
			{
				name: `fields.:entityType?${EntityFieldsComponent.listName}`,
				views: {
					'@': {
						component: EntityFieldsComponent,
					},
				},
				params: {
					[EntityFieldsComponent.listName]: {
						type: 'json',
					},
				},
			},
			{
				name: 'fields.:entityType.custom',
				abstract: true,
				component: EmptyContainerComponent,
			},
			{
				name: `fields.:entityType.custom.new`,
				views: {
					'@': {
						component: DetailsComponent,
					},
				},
			},
			{
				name: 'fields.:entityType.custom.:id?edit',
				views: {
					'@': {
						component: DetailsComponent,
					},
				},
			},
		]
	},
	templates: [
		{
			name: '.new?campaignId',
			isFullPageView: true,
			views: {
				'@': {
					component: TemplateEditComponent
				}
			}
		},
		{
			name: '.:templateId',
			isFullPageView: true,
			views: {
				'@': {
					component: TemplateViewComponent
				}
			},
			children: [
				{
					name: '.edit',
					isFullPageView: true,
					views: {
						'@': {
							component: TemplateEditComponent
						},
					}
				}
			]
		}
	]
};

export default SharedRoutes;

