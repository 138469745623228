<div class="archive"
     *ngIf="(User?.can('TPO_SMC_VIEW_ALL') && User.smGlobalArchiveEnabled || User.isComergence || this.context === feedContexts.AUDIT); else noPermissionsSection">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<span class="text-muted">Search</span>
			</div>
			<div class="col-sm-3">
				<span class="text-muted">Post Date After</span>
			</div>
			<div class="col-sm-3">
				<span class="text-muted">Post Date Before</span>
			</div>
			<div class="col-sm-3">
				<span class="text-muted" *ngIf="this.context === feedContexts.GLOBAL">Individuals</span>
			</div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<search-list placeholder="Enter Keyword"></search-list>
			</div>
			<div class="col-sm-3">
				<input
					type="text"
					class="form-control"
					bsDatepicker
					[bsConfig]="bsConfig"
					[bsValue]="params.postStartDate"
					[maxDate]="params.postEndDate"
					(bsValueChange)="setFilter({ postStartDate: $event })"
					placeholder="mm/dd/yyyy"
				>
			</div>
			<div class="col-sm-3">
				<input
					type="text"
					class="form-control"
					bsDatepicker
					[bsConfig]="bsConfig"
					[bsValue]="params.postEndDate"
					[minDate]="params.postStartDate"
					(bsValueChange)="setFilter({ postEndDate: $event })"
					placeholder="mm/dd/yyyy"
				>
			</div>
			<div class="col-sm-3">
				<ng-select
					*ngIf="this.context === feedContexts.GLOBAL"
					[(ngModel)]="params.reviewedIndividual"
					(change)="setFilter({ reviewedIndividual: $event })"
					[items]="filters.reviewedIndividual?.$promise | async"
					[loading]="!filters.reviewedIndividual?.$resolved"
					bindValue="shortName"
					bindLabel="name"
					[multiple]="true"
					[searchable]="true"
					placeholder="All Individuals"
				></ng-select>
			</div>
		</div>
		<div class="mode-switchers btn-group" *ngIf="this.context === feedContexts.AUDIT">
			<a
				class="btn"
				[ngClass]="params.hasTriggers === 'false' ? 'btn-primary' : 'btn-default'"
				(click)="setFilter({ hasTriggers: 'false' })"
			>
				All Posts
				<span *ngIf="count">({{count.totalCount}})</span>
			</a>
			<a
				class="btn"
				[ngClass]="params.hasTriggers === 'true' ? 'btn-primary' : 'btn-default'"
				(click)="setFilter({ hasTriggers: 'true' })"
			>
				Flagged Posts
				<span *ngIf="count">({{count.flaggedCount}})</span>
			</a>
		</div>
		<div class="rows-white" *ngIf="filtersApplied">
			<br *ngIf="this.context === feedContexts.AUDIT"><!--@Todo - remove after tabs implementation-->
			<div
				*ngIf="filtersApplied"
				class="row-fluid yellow-bg"
			>
				<div class="col-sm-12 va-m text-center">
					Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results.
				</div>
			</div>
		</div>
        <div
            *ngIf="list.$resolved && !list.length"
            class="row-white"
        >
            <br>
            <div class="col-sm-12 va-m text-center text-bigger text-muted">
                {{(filtersApplied || filters.q) ? 'No matches found' : 'No posts'}}
            </div>
        </div>
	</div>

	<div class="remote-resource" [class.resolved]="list.$resolved">
		<div class="rows-white post-list">
			<div
				*ngFor="let item of list"
				class="row-fluid post-container remote-resource"
				[ngClass]="{ resolved: item.$resolved }"
			>
				<div class="post-container-header clearfix">
					<div class="pull-left flex-row gap-m">
						<div class="pull-left flex-row gap-m">
							<ng-container *ngIf="item?.owners as owners">
								<div
									*ngIf="owners[0] as primaryOwner"
									class="primary-owner"
									[class.margined]="primaryOwner?.contactId && primaryOwner?.nmlsId"
								>
									<ng-container *ngIf="primaryOwner?.contactId; else contactLabelTpl">
										<contact-link
											[data]="getContactData(primaryOwner)"
										></contact-link>
									</ng-container>
									<ng-template #contactLabelTpl>
										{{primaryOwner?.name}}
									</ng-template>

									<ng-container *ngIf="primaryOwner?.nmlsId as nmlsId">
										<div class="nmls">
											NMLS ID:
											<ng-container *ngIf="primaryOwner?.contactId; else nmlsLabel">
												<a
													uiSref="nmls.individual.:nmlsId"
													[uiParams]="{ nmlsId: nmlsId }"
												>
													{{ nmlsId }}
												</a>
											</ng-container>
											<ng-template #nmlsLabel>
												{{ nmlsId }}
											</ng-template>
										</div>
									</ng-container>
								</div>

								<a
									*ngIf="owners.length > 1"
									class="owners"
									(click)="showOwners(owners)"
								>
									<span class="c-icon c-icon-users"></span>
									View more
								</a>
							</ng-container>
						</div>
					</div>

					<div class="pull-right flex-row right gap">
						<span
							*ngIf="item.reviewedDate"
							class="text-muted"
						>
							<span class="glyphicon glyphicon-ok-sign text-success"></span>
							Reviewed on {{item.reviewedDate | date : 'MMMM d, yyyy \'at\' HH:mm a'}}
						</span>

						<button
							*ngIf="!item.findingId && item.canReviewByCCM && User.isComergence"
							type="button"
							class="btn btn-secondary flex-button"
							(click)="reviewPost(item)"
						>
							Review
						</button>

						<span
							*ngIf="item?.findings?.length"
							class="text-danger clickable"
							[popover]="popoverTemplate"
							[popoverContext]="{ findings: item?.findings }"
							[outsideClick]="true"
						>
						    {{item?.findings?.length}} {{item?.findings?.length == 1 ? 'finding' : 'findings'}}
						</span>

						<a
							*ngIf="item.findingId && !item?.findings?.length"
							class="btn btn-outline-danger flex-button"
							uiSref="social-compliance.findings.:actionType.:findingId"
							[uiParams]="{ findingId: item?.findingId, actionType: 'edit' }"
						>
							View Finding
						</a>

						<button
							*ngIf="!item.findingId && item.canAddFinding && (User?.can('CCM_SMC_VIEW_ALL') && review?.auditStatus?.condition !== 'C' || User?.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE'))"
							type="button"
							class="btn btn-primary flex-button"
							(click)="createFinding(item)"
						>
							Add Finding
						</button>

						<button
							type="button"
							class="btn btn-icon btn-tertiary flex-button"
							(click)="showPostHistoryModal(item)"
						>
							<span class="c-icon c-icon-back-in-time text-big"></span>
						</button>
					</div>
				</div>
				<sm-post>
					<sm-post-header [post]="item">
						<button
							*ngIf="item.canAddLink && review?.reviewStatus !== 'C' && User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS')"
							type="button"
							class="btn btn-secondary add-link"
							(click)="showAddLinkModal(item)"
						>
							<span class="glyphicon glyphicon-plus small-icon"></span> Add Link
						</button>

						<span *ngIf="item.hasTriggers" class="c-icon c-icon-notification"></span>
					</sm-post-header>

					<sm-post-message [post]="item"></sm-post-message>

					<sm-post-attachments [post]="item"></sm-post-attachments>

					<sm-post-comments [post]="item"></sm-post-comments>
				</sm-post>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[1,10,25,50]"></new-paging>
		</div>
	</div>
</div>

<ng-template #noPermissionsSection>
    <archive-no-permissions></archive-no-permissions>
</ng-template>


<ng-template #popoverTemplate let-findings="findings">
    <div class="finding-holder" *ngFor="let finding of findings">
		<span class="label"
              [class.label-success]="finding.status && finding.condition === 'Closed'"
              [class.label-warning]="!finding.status || finding.condition !== 'Closed'"
        >{{finding.status}}</span>
        &nbsp;&nbsp;
        <span>{{finding.statusDate | date:'MM/dd/yyyy '}}</span>
        &nbsp;&nbsp;
        <a
            uiSref="social-compliance.findings.:actionType.:findingId"
            [uiParams]="{ findingId: finding.id, actionType: 'edit' }"
        >View</a>
    </div>
</ng-template>
