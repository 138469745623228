import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { UserProfile } from 'angularjs-providers/user.provider';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import {
    buffer,
    distinctUntilChanged,
    mergeMap,
    take,
    debounceTime,
} from 'rxjs/operators';

declare let window: Window;
const {
    application_monitoring_appinsight_enabled: enabled,
    application_monitoring_appinsight_key: key,
} = window.env;

export type BERequest = {
    url: string;
    duration?: number;
};

export type AppState = {
    name: string;
    url: string;
};

@Injectable({
    providedIn: 'root',
})
export class AppInsightsStatisticsService {
    public appInsights: ApplicationInsights;
    private requests$ = new Subject<Observable<BERequest>>();
    private state$ = new Subject<AppState>();

    constructor(
    ) {
        if (enabled && environment.production) {
            this.initInsights();
        }
    }

    private initInsights(): void {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: key,
                disableCookiesUsage: true,
                enableAutoRouteTracking: false,
            },
        });
        this.appInsights.loadAppInsights();
        const screen = 'screen_';
        const visibleArea = 'visible_';
        this.appInsights.trackMetric({ name: `${screen}Width`, average: window.screen.width });
        this.appInsights.trackMetric({ name: `${screen}Height`, average: window.screen.height });
        this.appInsights.trackMetric({ name: `${screen}ColorDepth`, average: window.screen.colorDepth });
        this.appInsights.trackMetric({ name: `${visibleArea}Width`, average: window.innerWidth });
        this.appInsights.trackMetric({ name: `${visibleArea}Height`, average: window.innerHeight });

        this.state$.pipe(
            distinctUntilChanged((a, b) => a.name === b.name),
        ).subscribe((state) => {
            const startTime = Date.now();
            const bufferWindow$ = this.requests$.pipe(
                debounceTime(1000),
                take(1),
            )

            this.requests$.pipe(
                mergeMap(res => res),
                buffer(bufferWindow$),
                take(1),
            ).subscribe((...requests) => {
                const duration = Date.now() - startTime;

                this.appInsights.trackPageView({
                    name: state.name,
                    uri: state.url,
                    properties: {
                        duration,
                    },
                });

                requests.forEach(([req], id) => {
                    // request is undefined on http error, so skip tracking
                    if (!req) return;

                    this.appInsights.trackDependencyData({
                        id: id.toString(),
                        name: req.url,
                        responseCode: 200,
                        duration: req.duration,
                    });
                });
            });
        })
    }

    public setUser(user: UserProfile): void {
        // ignore if appInsights is not initialized
        if (!this.appInsights) return;

        const {
            id: userId,
            organization: {
                id: organizationId,
            },
        } = user;
        this.appInsights.setAuthenticatedUserContext(
            `${userId}`,
            `${organizationId}`,
        );
    }

    public trackRequest(request: Observable<BERequest>): void {
        // ignore if appInsights is not initialized
        if (!this.appInsights) return;

        this.requests$.next(request);
    }

    public trackState(state: AppState): void {
        // ignore if appInsights is not initialized
        if (!this.appInsights) return;

        this.state$.next(state);
    }
}
