import { extend } from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, Input } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { UserActionsService } from '../../users/user-actions.service';

@Component({
	selector: 'user-action-log',
	templateUrl: './action-log.component.html',
})
export class ActionLogComponent extends PagedListComponent implements OnInit {
	static listName = 'actionLog';

	list: any = [];
	@Input('params') loadParams: any;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public service: UserActionsService,
	) {
		super(transitionService, stateService);
		this.list.$resolved = false;
	}

	ngOnInit() {
		const {userId, type} = this.loadParams;
		const now = new Date();

		extend(
			this.defaultFilters,
			{ userId, action: type === 'audit-trail' ? 'activity-logs' : type },
			{

				dateFrom: moment(now).subtract(6, 'days').startOf('day').toDate(),
				dateTo: moment(now).endOf('day').toDate(),
			},
		);
		super.ngOnInit();
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		// process params
		const params = {...queryParams};
		delete params.actionType;
		params.dateFrom = params.dateFrom.getTime();
		params.dateTo = params.dateTo.getTime();

		return this.service.actionLog(params).$promise
			.then((result) => {
				this.list = result;
				this.list.$resolved = true;
			})
			.catch(() => {
				this.list.$resolved = true;
			});
	}
}
