<div class="mb20">
    <h3 class="flex-row gap">
        <span>Channels</span>
        <span class="text-muted">{{channelList()?.length || 0}}</span>
    </h3>
    <ng-container *ngFor="let channel of channelList()">
        <div class="channel-block flex-row gap-m">
            <div class="flex-grow">
                <h4 class="flex-row gap">
                    <span class="glyphicon glyphicon-briefcase text-muted"></span>
                    <span class="ellipsis mxw100">{{channel.name}}</span>
                </h4>
                <show-more class="description text-muted" [text]="channel.description"></show-more>

                <div class="contact-person flex-row">
                    <div  class="flex-row gap-sm"
                          *ngIf="channel.channelsStatus === SC_CHANNEL_STATUS.ACTIVE ||
                                 channel.channelsStatus === SC_CHANNEL_STATUS.PENDING ||
                                 channel.channelsStatus === SC_CHANNEL_STATUS.PENDING_RENEWAL">
                        <span class="text-muted">Assigned:</span>&nbsp;
                        {{channel.accountExecutiveName}}
                    </div>
                    <ng-container *ngIf="channel.screeningRequired">
                        <div class="ellipsis mxw100" *ngIf="channel.contactName">
                            <span class="text-muted">Contact Person:</span>&nbsp;
                            {{channel.contactName}}
                        </div>
                        <div class="flex-row gap-sm" *ngIf="channel.phone">
                            <span class="text-muted glyphicon glyphicon-earphone"></span>&nbsp;
                            {{channel.phone | tel}}
                        </div>
                        <div class="ellipsis mxw100" *ngIf="channel.email">
                            <span class="text-muted glyphicon glyphicon-envelope"></span>&nbsp;
                            <a href="mailto:{{channel.email}}">{{channel.email}}</a>
                        </div>
                        <div class="ellipsis mxw100" *ngIf="channel.webSite">
                            <span class="glyphicon glyphicon-link text-muted"></span>&nbsp;
                            <a href="{{channel.webSite | url}}" target="_blank">{{channel.webSite}}</a>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="actions" [ngSwitch]="channel.hasRelationships">
                <ng-container *ngSwitchCase="true">
                    <ng-container [ngSwitch]="channel.channelsStatus">
                        <ng-container *ngSwitchCase="SC_CHANNEL_STATUS.ACTIVE">
                            <label class="active">
                                <span class="glyphicon glyphicon-ok-sign"></span>
                                <div class="status-text">Active<br/>
                                    <small>Renewal on {{channel.renewalDate | date:'MM/dd/yyyy'}}</small>
                                </div>
                            </label>
                        </ng-container>

                        <ng-container *ngSwitchCase="SC_CHANNEL_STATUS.SUSPENDED">
                            <label class="suspend">
                                <span class="glyphicon glyphicon-minus-sign"></span>

                                <div class="status-text">Renewal Suspended</div>
                            </label>
                        </ng-container>

                        <ng-container *ngSwitchCase="SC_CHANNEL_STATUS.PENDING">
                            <label class="pending">
                                <span class="glyphicon glyphicon-time"></span>

                                <div class="status-text">Pending</div>
                            </label>
                        </ng-container>

                        <ng-container *ngSwitchCase="SC_CHANNEL_STATUS.PENDING_RENEWAL">
                            <label class="pending">
                                <span class="glyphicon glyphicon-time"></span>

                                <div class="status-text">Pending Renewal</div>
                            </label>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <ng-container *ngIf="!channel.screeningRequired && !channel.isEligibilityDateInFuture">
                        <button type="button" class="btn btn-primary"
                                (click)="channelAction.emit({channel: channel, action: SC_CHANNEL_ACTION.APPLY})">Apply
                        </button>
                    </ng-container>

                    <ng-container *ngIf="channel.screeningRequired">
                        <button type="button" class="btn btn-primary"
                                (click)="channelAction.emit({channel: channel, action: SC_CHANNEL_ACTION.CONTACT})">
                            Contact Us
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
<h3 class="flex-row gap">
    <span>Application History</span>
    <span class="text-muted">{{applicationHistory()?.length || 0}}</span>
</h3>

<div class="rows-bordered">
    <div class="row-fluid header">
        <span class="col-md-3 sortable" (click)="onSortClick('channel.name')" [ngClass]="theOrder['channel.name']">
            <b>Channel</b>
        </span>
        <span class="col-sm-a sortable" (click)="onSortClick('applicationStatus.name')" [ngClass]="theOrder['applicationStatus.name']">
            <b>Application Status</b>
        </span>
        <span class="col-sm-a sortable" (click)="onSortClick('actionDate')" [ngClass]="theOrder['actionDate']">
            <b>Status Date</b>
        </span>
        <span class="col-sm-a sortable" (click)="onSortClick('applicationType')" [ngClass]="theOrder['applicationType']">
            <b>App Type</b>
        </span>
        <span class="col-f-100"></span>
    </div>
    <div class="row-fluid" *ngFor="let item of applicationHistory()">
        <span class="col-md-3 break-word">
            {{item.channel.name}}
        </span>
        <span class="col-sm-a break-word" [ngClass]="item.applicationStatus.id == 'A' ? 'text-success' : ''">
            {{item.applicationStatus.name}}
        </span>
        <span class="col-sm-a">
            {{item.actionDate | date : 'MM/dd/yyyy'}}
        </span>
        <span class="col-sm-a">
            {{item.applicationType == 'NEW' ? 'New' : 'Renewal'}}
        </span>
        <span class="col-f-100 break-word actions text-center">
            <a *ngIf="item.editable"
               class="btn btn-primary ng-scope"
               (click)="applicationHistoryAction.emit({applicationHistory: item, action: SC_CHANNEL_HISTORY_ACTION.EDIT})">
                <span class="glyphicon glyphicon-pencil"></span> Edit
            </a>
            <a *ngIf="item.viewable"
               class="view"
               (click)="applicationHistoryAction.emit({applicationHistory: item, action: SC_CHANNEL_HISTORY_ACTION.PRINT})">
                View
            </a>
            <div *ngIf="!item.editable && !item.viewable">&ndash;</div>
        </span>
    </div>
    <div class="row-fluid" *ngIf="applicationHistory()?.length == 0 ">
        <span class="col-sm-12 text-muted text-center text-bigger text-weak">No Application History</span>
    </div>
</div>
