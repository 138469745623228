<div class="overview mb20" *ngIf="companyData().description || companyData().hasMarketingLogo">
    <img *ngIf="companyData().hasMarketingLogo" class="pull-right" [ngClass]="'iw' + companyData().marketingLogoPageWidth" [src]="companyData().marketingLogo">
    <quill-view [content]="companyData().description"></quill-view>
</div>
<div class="flex-row contact-data mb20" *ngIf="companyData().companyContactName || companyData().companyPhone || companyData().companyEmail">
    <div *ngIf="companyData().companyContactName" class="flex-row gap-sm">
        <span class="glyphicon glyphicon-user text-muted"></span>
        <span>{{ companyData().companyContactName }}</span>
    </div>
    <div *ngIf="companyData().companyPhone" class="flex-row gap-sm">
        <span class="glyphicon glyphicon-earphone text-muted"></span>
        <span>{{ companyData().companyPhone | tel }}</span>
    </div>
    <div *ngIf="companyData().companyEmail" class="flex-row gap-sm">
        <span class="glyphicon glyphicon-envelope text-muted"></span>
        <span>{{ companyData().companyEmail }}</span>
    </div>
    <div *ngIf="companyData().companyWebsite" class="flex-row gap-sm">
        <span class="glyphicon glyphicon-link text-muted"></span>
        <a [href]="companyData().companyWebsite | hrefReady" target="_blank">{{ companyData().companyWebsite }}</a>
    </div>
</div>
<div *ngIf="labels()" class="label-tags">
    <span class="label-tag" *ngFor="let label of labels()">{{ label }}</span>
</div>
