import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { SharedUserManagementModule } from 'shared/user-management';

import { ComergenceUsersListComponent } from './ccm-users-list';
import { ComergenceUserComponent, ComergenceUserDetailsComponent, ReportingLicensesService } from './ccm-user';
import { CreateReadOnlyUserComponent } from 'comergence/ccm-user-management/create-read-only-user/create-read-only-user.component';
import { CreateReadOnlyUserDetailsComponent } from 'comergence/ccm-user-management/create-read-only-user/create-read-only-user-details/create-read-only-user-details.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        BsDropdownModule.forRoot(),
        SharedUserManagementModule,
    ],
    declarations: [
        ComergenceUsersListComponent,
        ComergenceUserComponent,
        ComergenceUserDetailsComponent,
		CreateReadOnlyUserComponent,
		CreateReadOnlyUserDetailsComponent,
    ],
    providers: [
        ComergenceUsersListComponent,
        ReportingLicensesService,
    ]
})
export class ComergenceUserManagementModule {}
