import { forIn, map, mapValues, some } from 'lodash';
import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { TransitionService, StateService, UIRouterGlobals } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { LenderContactsResourceService } from 'lender/contacts/lender-contacts-resource.service';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { NgResourceArray } from 'commons/interfaces';

import { ActivitiesService, Activity } from '../activities.service';

const defaultUrl = '^.:activityId.details';

@Component({
	templateUrl: './list.component.html',
	selector: 'related-activities',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ActivitiesListComponent) },
	],
})
export class ActivitiesListComponent extends PagedListComponent implements OnInit {
	static listName = 'activities';
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	user: UserProfile;

	activities: NgResourceArray<Activity> = [];
	statusColors = {
		PL: 'success',
		IP: 'warning',
	};

	tpoContext: boolean;

	isCRM: boolean;
	embedded: 'CONTACT' | 'COMPANY' | 'PROSPECT' | 'UNKNOWN' | false;

	currentDate = new Date().setHours(0, 0, 0, 0);
	moreFilters = false;
	prospectState: string;
	url = defaultUrl;
	urlNew: string;
	pageSizes = [10, 25, 50];

	filtersAvailable = {
		search: false,
		priority: false,
		assignedTo: false,
		status: false,
		indent: false,
	};

	get someFilterAvailable(): boolean {
		return some(this.filtersAvailable);
	}

	set allFiltersAvailable(value: boolean) {
		for (const key in this.filtersAvailable) {
			this.filtersAvailable[key] = value;
		}
	}

	constructor(
		transitionService: TransitionService,
		public stateService: StateService,
		public routerGlobals: UIRouterGlobals,
		public userService: UserService,
		public activitiesService: ActivitiesService,
		public contactService: LenderContactsResourceService,
        public _cd: ChangeDetectorRef,
	) {
		super(transitionService, stateService);

		const { includes } = stateService;
		this.tpoContext = includes('**.client.:id.**');
		this.isCRM = includes('**.crm.**');
		switch (true) {
			case includes('crm.activities.**'):
				this.embedded = false;
				break;
			case includes('**.:contactId.**') || includes('**.originator.:nmlsId.**'):
				this.embedded = 'CONTACT';
				break;
			case this.tpoContext: /*!!! This expression is valid only after CONTACT check !!!*/
				this.embedded = 'COMPANY';
				break;
			case includes('crm.prospects.**'):
				this.embedded = 'PROSPECT';
				break;
			default:
				this.embedded = 'UNKNOWN';
		}

		this.user = userService.profile;
	}

	ngOnInit(): void {
		this.setContext().then(() => {
			this.someFilterAvailable && this.configureFilters();
			super.ngOnInit();
		});
	}

	async setContext(): Promise<void> {
		const { params: { tpoId, id, contactId } } = this.routerGlobals;
		switch (this.embedded) {
			case false:
				this.activitiesService.context.activities();

				this.urlNew = '^.new';
				this.allFiltersAvailable = true;
				break;
			case 'CONTACT': {
				const isNmls = this.stateService.includes('**.nmls.**');
				const { organizationContactId } = await this.contactService.get({
					[isNmls ? 'nmlsId' : 'tpoId']: tpoId || id,
					contactId,
				}).$promise;
				this.activitiesService.context.contact(organizationContactId);

				this.url = '^.activities.:activityId';
				this.urlNew = '^.activities.new';
				this.filtersAvailable.search = true;
				this.filtersAvailable.indent = true;
				break;
			}
			case 'COMPANY': {
				const isClientNmls = this.stateService.includes('nmls.client.:id.**');
				const { nmlsId } = await this.activitiesService.companyInfo(id, !isClientNmls).$promise;
				this.activitiesService.context.client(nmlsId);

				this.url = '.:activityId.details';
				this.urlNew = '.new';
				this.allFiltersAvailable = true;
				break;
			}
			case 'PROSPECT': {
				this.activitiesService.context.prospect(id);

				this.prospectState = (await this.activitiesService.prospect(id).$promise).prospectState;
				this.url = '.activities.:activityId';
				this.pageSizes.unshift(5);
				this.defaultFilters.size = 5;
				if (this.prospectState == 'O') {
					this.filtersAvailable.search = true;
					this.filtersAvailable.indent = true;
					this.urlNew = '.activities.new';
				}
				break;
			}
		}
	}

	configureFilters(): void {
		const { priority, status, assignedTo } = this.filtersAvailable
		this.filters = {
			...(priority ? { priorityId: this.activitiesService.priorities() } : {}),
			...(status ? { statusId: this.activitiesService.statuses() } : {}),
			...(assignedTo ? { assignedToId: this.activitiesService.assignedFilter() } : {}),
		};

		this.defaultFilters = {
			...this.defaultFilters,
			priorityId: [],
			statusId: [],
			assignedToId: [],
			dueDateAfter: null,
			dueDateBefore: null,
		};
	}

	async loadList(queryParams): Promise<void> {
		this.activities.$resolved = false;
		this.activities = await this.activitiesService.query(this.processParams(queryParams)).$promise;
        this._cd.markForCheck();
	}

	processParams(params): any {
		return mapValues(this.getClearParams(params), (value, key) => {
				switch (key) {
					case 'dueDateAfter': case 'dueDateBefore':
						return value.getTime();
					default:
						return value;
				}
			},
		);
	}

	setFilter(params, resetPage: boolean = true): void {
		forIn(params, (value, key) => {
			switch (key) {
				case 'dueDateAfter': case 'dueDateBefore':
					break;
				case 'assignedToId':
					params[key] = map(value || [], 'id');
					break;
				default:
					params[key] = map(value || [], 'shortName');
			}
		});

		super.setFilter(params, resetPage);
	}

	toggleFilters(): void {
		this.moreFilters = !this.moreFilters;
	}
}
