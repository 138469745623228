import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { TpoMonitoringBoardListComponent } from './list';
import { TPOFindingsResourceService } from './findings-resource.service';
import { TPOFindingsService } from './findings.service';
import { NewContactsModule } from 'shared/new-contacts';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        PipesModule,
        NewContactsModule,
    ],
    declarations: [
        TpoMonitoringBoardListComponent,
    ],
    providers: [
        TPOFindingsResourceService,
        TPOFindingsService,
    ]
})
export class TpoFindingsModule {}
