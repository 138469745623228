import { Component, Optional } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { NgResourceArray } from 'commons/interfaces';

import { ActivityHistoryService, History } from './history.service';
import { ActivityComponent } from 'lender/crm/activities/$id/activity.component';

@Component({
	templateUrl: './history.component.html',
})
export class ActivityHistoryComponent extends PagedListComponent{
	static listName = 'activityHistory';

	list: NgResourceArray<History> = [];

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public activityHistoryService: ActivityHistoryService,
        @Optional() protected activityComponent: ActivityComponent,
	) {
		super(transitionService, stateService);

		this.defaultFilters = {
			...this.defaultFilters,
			activityId: this.stateService.params.activityId,
		}
        this.activityComponent?.setTitle('History');
	}

	async loadList(queryParams): Promise<void> {
		this.list.$resolved = false;
		this.list = await this.activityHistoryService.history(queryParams).$promise;
	}
}
