import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { ClientDocumentsModule } from 'lender/clients';

import { TpoFinancialsComponent } from 'shared/account/financials/tpo-financials.component';
import { FinancialDocumentHistoryComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-history';
import { TpoAccountFinancialDocumentListComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-account-financial-document-list.component';
import { TpoFinancialDocumentItemComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-item/tpo-financial-document-item.component';
import { TpoFinancialSummaryComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-summary/tpo-financial-summary.component';
import { TpoFinancialDocumentUploadModalComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-item/tpo-financial-document-upload-modal/tpo-financial-document-upload-modal.component';
import { RequestFinancialDocumentModalComponent } from 'shared/account/financials/request-document-modal/request-document-modal.component';
import { ClientFinancialSettingsModalComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-item/client-financial-settings-modal/client-financial-settings-modal.component';
import {
	RequestFinancialDocumentHistoryModalComponent,
} from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-history/request-financial-document-history-modal';
import { FinancialDocumentRequestsHistoryComponent } from 'shared/account/financials/tpo-financial-document-list/tpo-financial-requests-history';

import { WaiveFinancialModalComponent } from './waive-financial-modal/waive-financial-modal.component';
import { WaiveFinancialHistoryModalComponent } from './tpo-financial-document-list/tpo-financial-document-history/waive-financial-history-modal/waive-financial-history-modal.component';

const commonModules = [
	CommonModule,
	ComponentsModule,
	DirectivesModule,
	FormsModule,
	ReactiveFormsModule,
    ClientDocumentsModule,
	PipesModule,
];
const components = [
	TpoFinancialsComponent,
	TpoAccountFinancialDocumentListComponent,
	TpoFinancialDocumentItemComponent,
	TpoFinancialSummaryComponent,
	TpoFinancialDocumentUploadModalComponent,
	ClientFinancialSettingsModalComponent,
    RequestFinancialDocumentModalComponent,
	RequestFinancialDocumentHistoryModalComponent,
	FinancialDocumentHistoryComponent,
	FinancialDocumentRequestsHistoryComponent,
	WaiveFinancialModalComponent,
	WaiveFinancialHistoryModalComponent,
];

@NgModule({
	imports: [
		...commonModules,
		BsDropdownModule.forRoot(),
	],
	exports: [
		...components,
	],
	declarations: [
		...components,
	],
	providers: [
	],
})
export class TpoFinancialsModule {}
