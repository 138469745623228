<div class="remote-resource" [class.resolved]="!resolving">
    <div class="sticky-filters mb20">
        <div class="wrapper">
            <div class="row-fluid filter-labels">
                <div class="col-md-a">
                    <div class="text-muted">Search</div>
                </div>
                <div class="col-md-a">
                    <div class="text-muted">Tags</div>
                </div>
                <div class="col-md-a">
                    <div class="text-muted">Loan Programs</div>
                </div>
                <div class="col-md-a">
                    <div class="text-muted">Investors</div>
                </div>
                <div class="col-f-120"></div>
            </div>
            <div class="row-fluid filter-selectors">
                <div class="col-md-a">
                    <div class="has-feedback">
                        <search-list placeholder="Name, product"></search-list>
                    </div>
                </div>
                <div class="col-md-a">
                    <ng-select
                        placeholder="All Tags"
                        [ngModel]="params.tags"
                        [items]="filters.tags | async"
                        (change)="setFilter({ tags: $event })"
                        [loading]="!(filters.tags | async)"
                        [closeOnSelect]="false"
                        bindValue="code"
                        bindLabel="name"
                        [multiple]="true"
                    ></ng-select>
                </div>
                <div class="col-md-a">
                    <ng-select
                        placeholder="All Loan Programs"
                        [ngModel]="params.loanPrograms"
                        [items]="filters.loanPrograms | async"
                        (change)="setFilter({ loanPrograms: $event })"
                        [loading]="!(filters.loanPrograms | async)"
                        [closeOnSelect]="false"
                        bindValue="code"
                        bindLabel="name"
                        [multiple]="true"
                    ></ng-select>
                </div>
                <div class="col-md-a">
                    <ng-select
                        placeholder="Select Investors"
                        [ngModel]="params.investorType"
                        [items]="filters.investorType | async"
                        (change)="setFilter({ investorType: $event })"
                        [loading]="!(filters.investorType | async)"
                        [closeOnSelect]="false"
                        bindValue="code"
                        bindLabel="name"
                        [multiple]="false"
                    ></ng-select>
                </div>
                <div class="col-f-120 va-m">
                    <a *ngIf="filtersApplied" (click)="resetFilters()">Reset filters</a>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <div class="flex-row align-top">
            <ng-container *ngIf="!(list | async)?.length">
                <span class="col-md-12 text-muted text-center text-bigger text-weak">No matches found</span>
            </ng-container>
            <div class="company-list">
                <sc-company-card
                    *ngFor="let company of list | async; index as i; last as last"
                    [class.mb20]="!last"
                    [class.selected]="selectedCardIndex === i"
                    (click)="selectCard(i, company)"
                    [companyData]="company">
                </sc-company-card>
            </div>
            <div class="company-preview flex">
                <sc-company-overview-card
                    class="remote-resource"
                    [class.resolved]="!isCardResolving()"
                    *ngIf="selectedCardIndex !== null"
                    [currentTab]="currentTab()"
                    [visibleTabs]="visibleTabs()"
                    [companyData]="cardData()"
                    [productList]="productList()"
                    [productOverview]="productOverview()"
                    [channelList]="channelList()"
                    [applicationHistory]="applicationHistoryList()"
                    (productClick)="contactRequestIssued($event)"
                    (channelClick)="channelAction($event)"
                    (applicationHistoryClick)="applicationHistoryAction($event)"
                    (sortApplicationHistoryAction)="applicationHistoryList.set(sortApplicationHistoryOrder(applicationHistoryList(), $event))"
                    (selectTab)="selectTab($event)"
                ></sc-company-overview-card>
            </div>
        </div>
    </div>
</div>
