import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';

import { ReportingSwitcherSectionComponent } from './section';

import { SharedReportingSwitcherService } from './reporting-switcher.service';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        ReportingSwitcherSectionComponent,
    ],
    providers: [
        SharedReportingSwitcherService,
    ],
    exports: [
        ReportingSwitcherSectionComponent,
    ]
})
export class ReportingSwitcherModule {}
