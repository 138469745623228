import { Component, forwardRef, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { map, mapValues } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { GlobalDocument } from '../documents.interface';
import { GlobalDocumentsService } from '../documents.service';
import { InvestorChannelsService } from '../channels.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => GlobalDocumentListComponent) },
	],
})
export class GlobalDocumentListComponent extends PagedListComponent implements OnInit {
	static listName = 'globalDocumentList';
	list: NgResourceArray<GlobalDocument> = [];
	user: UserProfile;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		private documentsService: GlobalDocumentsService,
		private channelsService: InvestorChannelsService,
	) {
		super(transitionService, stateService);

		this.user = userService.profile;
	}

	ngOnInit() {
		this.configureList();

		super.ngOnInit();
	}

	configureList(): void {
		Object.assign(
			this.filters,
			{
				channels: this.channelsService.getChannels({ activeOnly: true }),
			},
		);

		Object.assign(
			this.defaultFilters,
			{
				channels: [],
			},
		);
	}

	setFilter(filters) {
		const processedFilters = mapValues(filters, (value, key) => {
			switch (key) {
				case 'channels':
					return map(value, 'id');
				default:
					return value;
			}
		});
		super.setFilter(processedFilters);
	}

	async loadList(queryParams) {
		this.list.$resolved = false;
		try {
			this.list = await this.documentsService.getDocuments(
				this.getClearParams(queryParams),
			).$promise;
		} catch (e) {}
		this.list.$resolved = true;
	}
}
