<div class="client-assigned-questionnaires-holder clearfix"
	[ngClass]="{ 'remote-resource': hasOwnLoader, 'resolved': list.$resolved }"
>
	<div class="client-assigned-questionnaires row-fluid clearfix" *ngFor="let questionnaire of list">
		<div class="coldp-md-8 client-assigned-questionnaires-info">
			<div class="row">
				<div class="col-sm-3 logo">
					<img
						class="img-rounded img-responsive"
                        src="{{questionnaire.lender?.lenderId | lenderLogo}}"
						alt="{{questionnaire.lender?.lenderName}}"
					/>
				</div>
				<div class="col-sm-9">
					<h3>{{questionnaire.lender?.lenderName}}</h3>
					<small>{{questionnaire.name}}</small>
				</div>
			</div>
		</div>
		<div class="coldp-md-16 client-assigned-questionnaires-tbd-holder">
			<div class="horizontal-flex-group">
				<div class="client-assigned-questionnaires-tbd no-space">
					<div class="callout callout-warning">
						<h4>Required Steps</h4>
						<span class="text-muted">Review and Submit Questionnaire</span>
					</div>
				</div>
				<div class="client-assigned-questionnaires-status no-space">
					<div>
						<a class="btn btn-primary"
							*ngIf="questionnaire.channel"
							uiSref="application.:id.questionnaire"
							[uiParams]="{id: questionnaire.channel?.id}"
						>
							<span class="glyphicon glyphicon-pencil"></span>
							Edit Questionnaire
						</a>
						<a class="btn btn-primary"
							*ngIf="!questionnaire.channel"
							uiSref="account.questionnaires.:questionnaireId"
							[uiParams]="{questionnaireId: questionnaire.id}"
						>
							<span class="glyphicon glyphicon-pencil"></span>
							Edit Questionnaire
						</a>
					</div>
					<div>
						<a class="btn btn-default upload-documents-button"
							[href]="questionnaire.documentsLink"
						>
							Upload Documents
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
