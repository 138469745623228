import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { InvitesListComponent } from './list';
import { InvitesResourceService } from './invites.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        ComponentsModule,
        DirectivesModule,
        PipesModule,
    ],
    declarations: [
        InvitesListComponent,
    ],
    providers: [
        InvitesResourceService,
    ]
})
export class InvitesModule {}
