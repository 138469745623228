import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { CreateAppcodeModalComponent } from './modals';
import { AppcodesComponent } from './appcodes.component';
import { AppcodesResourceService } from './appcodes-resource.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        ComponentsModule,
        DirectivesModule,
    ],
    declarations: [
        CreateAppcodeModalComponent,
        AppcodesComponent,
    ],
    providers: [
        CreateAppcodeModalComponent,
        AppcodesResourceService,
    ]
})
export class AppcodesModule {
}
