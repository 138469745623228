import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { SharedUsersListComponent, SharedUsersResourceService } from 'shared/user-management';

import { ccmColumnsConfig } from './ccm-columns-config';
import { UserManagementCapabilitiesService, CreateUserConfig } from 'commons/services/user-capabilities/user-management-capabilities.service';

@Component({
	templateUrl: './ccm-users-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ComergenceUsersListComponent) },
	],
})
export class ComergenceUsersListComponent extends SharedUsersListComponent {
	static listName = 'cUsers';

	// ColumnsManagerMixin
	// GOTCHA: objects and arrays should have default value to properly work with mixins
	localStorageName = 'ComergenceUsersCols';
	columns = new Map();

	userCanCreateUsers: boolean = false;
	createUserSref: string = '';
	createUserSrefParams: any = {};
	createUserSrefUIOptions: any = { inherit: false, reload: true };

	constructor(
		private readonly userManagementService: UserManagementCapabilitiesService,
		public transitionService: TransitionService,
		public stateService: StateService,
		public User: UserService,
		public SharedUsersResource: SharedUsersResourceService,
	) {
		super(transitionService, stateService, User);

		this.setService();
		this.readColumnsConfig();
	}

	// Override
	ngOnInit(): void {
    	const createUserConfig: CreateUserConfig = this.userManagementService.determineCreateUserConfig(this.User.profile);
		this.userCanCreateUsers = createUserConfig.userCanCreateUsers;
		this.createUserSref = createUserConfig.createUserSref;
		this.createUserSrefParams = createUserConfig.createUserSrefParams;
		this.createUserSrefUIOptions = createUserConfig.createUserSrefUIOptions;

		super.ngOnInit();
	}

	setService() {
		this.service = this.SharedUsersResource;
	}

	readColumnsConfig() {
		this.prepareColumns(ccmColumnsConfig);
	}
}
