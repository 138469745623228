import { mapValues, forIn, map, filter } from 'lodash';
import { Component, forwardRef, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { socialMediaNetworksConfig } from 'commons/components/sm-icons';
import { NgResourceObject, NgResourceArray } from 'commons/interfaces';

import { CodeValueService } from 'shared/code-value.service';
import { FindingsItem } from 'shared/findings/$findingId/finding.interface';

import { TPOFindingsService } from '../findings.service';
import { SourceTypes } from 'shared/new-contacts/compliance/findings/finding-source/finding-source.component';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoMonitoringBoardListComponent) },
	],
})
export class TpoMonitoringBoardListComponent extends PagedListComponent implements OnInit {
	static listName = 'monitoring-board';

	helpLink = '/help/authenticated/tpo/smc/findings-board';
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	user: UserProfile;
	hasViewAllPermission: boolean;

	findings: NgResourceArray<FindingsItem> = [];
	icons = socialMediaNetworksConfig;
	statuses = {
		'In Progress': {
			color: 'label-warning',
			title: 'In Progress',
		},
		'Closed': {
			color: 'label-success',
			title: 'Closed',
		},
	};
    source = Object.entries(SourceTypes).map(([id, label]) => ({ id, label }));
	openFindingsCount: NgResourceObject<{ value?: string }> = {};

	moreFilters: boolean = false;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public userService: UserService,
		public findingsService: TPOFindingsService,
		public CodeValue: CodeValueService,
	) {
		super(transitionService, stateService);
		this.user = userService.profile;
		this.hasViewAllPermission = this.user.can('TPO_SMC_VIEW_ALL');
	}

	ngOnInit(): void {
		if (this.hasViewAllPermission) {
			this.openFindingsCount = this.findingsService.getOpenFindingsCount();
			this.configureFilters();
			this.setInitialFilters().finally(() => {
				this.getAppliedFiltersData();
				super.ngOnInit();
			});
		}
	}

	configureFilters(): void {
		this.filters = {
			status: this.findingsService.getStatuses(),
			accountType: this.findingsService.getAccountTypes(),
			entity: this.findingsService.getEntities(),
			state: [],
			locationId: [],
			source: this.source,
		};

		this.defaultFilters = {
			...this.defaultFilters,
			from: null,
			to: null,
			status: [],
			accountType: [],
			entity: null,
			state: null,
			locationId: null,
			source: [],
		};
	}

	getAppliedFiltersData(): void {
		this.filters.entity.$promise.then(() => {
			this.getStates(this.params);
			this.getBranches(this.params);
		});
	}

	setInitialFilters(): Promise<void> {
		return this.filters.status.$promise.then((status) => {
			const inProgress = filter(status, { condition: 'In Progress' });
			// @Notice we can't use the setFilter here because loadList will be executed twice
			!this.stateService.params[this.listName] && (this.stateService.params[this.listName] = {
				status: map(inProgress, 'id'),
			});
		});
	}

	loadList(queryParams): any {
		this.findings.$resolved = false;
		return this.findingsService.list(this.processParams(queryParams)).$promise
			.then((data) => {
				this.findings = data;
			}).finally(() => {
				this.findings.$resolved = true;
			});
	}

	processParams(params): any {
		return mapValues(this.getClearParams(params), (value, key) => {
				switch (key) {
					case 'from': case 'to':
						value.setHours(0, 0, 0);
						return value.getTime();
					default:
						return value;
				}
			},
		);
	}

	setFilter(params, resetPage: boolean = true): void {
		forIn(params, (value, key) => {
			switch (key) {
				case 'from': case 'to':
					break;
				case 'entity':
					params[key] = value?.id;
					this.getStates(params);
					break;
				case 'state':
					params[key] = map(value, 'shortName');
					this.getBranches(params);
					break;
				default:
					params[key] = map(value || [], 'id');
			}
		});

		super.setFilter(params, resetPage);
	}

	getStates(params): void {
		if (params?.entity === 'individual') {
			this.filters.state = this.CodeValue.get({ code: 'State' });
		} else {
			params.state = this.defaultFilters.state;
			params.locationId = this.defaultFilters.locationId;
		}
	}

	getBranches(params): void {
		if (params?.state?.length) {
			this.filters.locationId = this.findingsService.getLocations(params.state);
		} else {
			params.locationId = this.defaultFilters.locationId;
		}
	}

	toggleFilters(): void {
		this.moreFilters = !this.moreFilters;
	}
}
