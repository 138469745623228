<div class="row">
    <div class="col-md-12">
        <h4>HQ Verification Results
            <div class="result-controls pull-right gap-sm margin-bottom-sm">
                <button type="button" class="btn btn-primary" [disabled]="!isLoaded" (click)="reverifyResult()">
                    Reverify
                </button>
                <button id="downloadBtn" type="button" class="btn btn-tertiary btn-icon" (click)="exportReport()">
                    <span class="glyphicon glyphicon-download-alt"></span>
                </button>
            </div>
        </h4>
    </div>
</div>
<div class="rows-striped">
    <div class="row-fluid">
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">
                    <i class="glyphicon glyphicon-warning-sign text-danger"></i>
                    Not registered in Comergence
                </label>
                <div class="col-md-5">
                    <span class="control-value">{{dataMatchHqVerificationResult.result.notRegisteredHQCount}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">
                    <i class="glyphicon glyphicon-warning-sign text-danger"></i>
                    Already has relationship in selected channel
                </label>
                <div class="col-md-5">
                    <span class="control-value" *ngIf="isApplicable">{{dataMatchHqVerificationResult.result.hasRelashionshipInSelectedChannelCount}}</span>
                    <span class="control-value text-danger text-small" *ngIf="!isApplicable">Not Applicable</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row-fluid">
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">
                    <i class="glyphicon glyphicon-warning-sign text-warning"></i>
                    Account Assigned not found in Comergence
                </label>
                <div class="col-md-5">
                    <span class="control-value" *ngIf="isApplicable">{{dataMatchHqVerificationResult.result.aaNotFoundCount}}</span>
                    <span class="control-value text-danger text-small" *ngIf="!isApplicable">Not Applicable</span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">
                    <i class="glyphicon glyphicon-warning-sign text-warning"></i>
                    Already has relationship in other channels
                </label>
                <div class="col-md-5">
                    <span class="control-value" *ngIf="isApplicable">{{dataMatchHqVerificationResult.result.hasRelashionshipInOtherChannelCount}}</span>
                    <span class="control-value text-danger text-small" *ngIf="!isApplicable">Not Applicable</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row-fluid">
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">
                    <i class="glyphicon glyphicon-warning-sign text-danger"></i>
                    Duplicates
                </label>
                <div class="col-md-5">
                    <span class="control-value">{{dataMatchHqVerificationResult.result.duplicatesCount}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">
                    <i class="glyphicon glyphicon-warning-sign text-danger"></i>
                    Invalid date
                </label>
                <div class="col-md-5">
                    <span class="control-value">{{dataMatchHqVerificationResult.result.invalidDateCount}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
