import { Component, forwardRef, Input, Output, EventEmitter  } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent, NotificationModalComponent } from 'commons/components/modals';
import { ListComponent } from 'commons/components/list';

import { InvitesResourceService } from '../invites.service';

@Component({
	templateUrl: './invites-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => InvitesListComponent) },
	],
	selector: 'invites',
})
export class InvitesListComponent extends ListComponent {
	static listName = 'invites';

	@Input() hasOwnLoader = true;
	@Output('onLoaded') _onLoaded = new EventEmitter<boolean>();

	User: any;
	list: any = [];
	modalRef: BsModalRef;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		private modalService: BsModalService,
		private invitesService: InvitesResourceService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	loadList(queryParams): void {
		this._setLoaded(false);
		return this.invitesService.list.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this._setLoaded(true);
			});
	}

	accept(invite): void {
		const { inviteId, channelId } = invite;

		invite.$resolving = true;
		return this.invitesService.invite.accept({
			inviteId,
		}).$promise
			.then(
				() => {
					this.stateService.go('new-application', { channelId });
				},
				() => {
					this.modalService.show(
						NotificationModalComponent,
						{
							initialState: {
								title: 'Notification',
								notification: 'This Invite is no longer available.',
								onConfirm: (params?) => invite.$resolving = false,
							},
							class: 'modal-smd modal-new',
						}
					);
				}
			);
	}

	decline({ inviteId, lenderName, channelName }): void {
		this.modalRef = this.modalService.show(
			ConfirmModalComponent,
			{
				initialState: {
					title: 'Decline Invitation',
					message: `Are you sure you want to decline invitation from <b>${lenderName}, ${channelName}</b>?`,
					confirmText: 'Yes',
					onConfirm: () => {
						this.modalRef.content.resolving = true;
						this.invitesService.invite.decline({
							inviteId,
						}).$promise
							.then(() => {
								this.loadList(this.params);
							})
							.finally(() => {
								this.modalRef.hide();
							});
					},
					cancelText: 'No',
				},
				class: 'modal-smd modal-new',
			}
		);
	}

	_setLoaded(isLoaded: boolean): void {
		this.list.$resolved = isLoaded;

		this._onLoaded.emit(isLoaded);
	}
}
