import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { CcmCustomerProduct } from 'comergence/ccm-customer-product/ccm-customer-product.interface';
import { Observable } from 'rxjs';

declare let apiPath: string;
@Injectable()
export class CcmCustomerProductsService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    public getCustomerProductDetails(organizationId: number): Observable<CcmCustomerProduct[]> {
        return this.http.request<CcmCustomerProduct[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/products`
        );
    }

    public updateCustomerProductName(productId: number, organizationId: number, updatedProduct: CcmCustomerProduct): Observable<CcmCustomerProduct> {
        return this.http.request<CcmCustomerProduct>(
            'PUT',
            `${apiPath}/organizations/${organizationId}/products/${productId}/product-name`,
            {},
            updatedProduct
        );
    }

    public updateCustomerProductVisibilityAllowedByComergence(productId: number, organizationId: number, visibilityAllowedByComergence: boolean): Observable<CcmCustomerProduct> {
        return this.http.request<CcmCustomerProduct>(
            'PUT',
            `${apiPath}/organizations/${organizationId}/products/${productId}/product-visibility`,
            {},
            visibilityAllowedByComergence
        );
    }

    public addCustomerProduct(organizationId: number, newProduct: CcmCustomerProduct): Observable<CcmCustomerProduct> {
        return this.http.request<CcmCustomerProduct>(
            'POST',
            `${apiPath}/organizations/${organizationId}/products`,
            {},
            newProduct
        );
    }

   public removeCustomerProduct(organizationId: number, productId: number): Observable<CcmCustomerProduct> {
       return this.http.request<CcmCustomerProduct>(
           'DELETE',
           `${apiPath}/organizations/${organizationId}/products/${productId}`
       );
    }
}
