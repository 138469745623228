import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { CcmCustomerProductsService } from 'comergence/ccm-customer-product/ccm-customer-products.service';
import { UIRouterGlobals } from '@uirouter/core';
import { CcmCustomerProduct } from 'comergence/ccm-customer-product/ccm-customer-product.interface';
import { UserService } from 'angularjs-providers/user.provider';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'ccm-customer-products',
    templateUrl: 'ccm-customer-products.component.html',
})
export class CcmCustomerProductsComponent implements OnInit {
    public customerType: string;
    public organizationId: number;
    resolved: boolean = false;
    public productList: WritableSignal<CcmCustomerProduct[]> = signal<CcmCustomerProduct[]>([]);
    userCanAddEdit: boolean = false;

    constructor(
        public ccmProductsService: CcmCustomerProductsService,
        public routerGlobals: UIRouterGlobals,
        private readonly userService: UserService,
    ) {
        this.customerType = this.routerGlobals.params.customerType;
        this.organizationId = this.routerGlobals.params.organizationId;
        this.userCanAddEdit = this.userService.profile.can('CCM_MANAGE_CUSTOMERS');
    }

    async ngOnInit(): Promise<void> {
        this.loadProducts();
    }

    async loadProducts() {
        try {
            this.productList.set(await firstValueFrom(this.ccmProductsService.getCustomerProductDetails(this.organizationId)))
        } finally {
            this.resolved = true;
        }
    }

    addProduct() {
        this.productList.update(
            (newProductItem: CcmCustomerProduct[]) => {
                newProductItem.push({
                    productId: null,
                    productName: null,
                    plan: null,
                    visibilityAllowedByComergence: false,
                });
                return newProductItem;
            }
        );
    }

    deleteProduct(deletedProductIndex: number) {
        this.productList.update((updatedProductItem: CcmCustomerProduct[]) => {
                updatedProductItem.splice(deletedProductIndex, 1);
                return updatedProductItem;
            }
        );
    }

    cancelNewItem(item: CcmCustomerProduct) {
        if(item.productId) {
            return;
        }

        const itemIndex: number = this.productList().indexOf(item);
        if (itemIndex != -1) {
            this.productList.update((updatedProductItem: CcmCustomerProduct[]) => {
                    updatedProductItem.splice(itemIndex, 1);
                    return updatedProductItem;
                }
            );
        }
    }
}
