import { reject } from 'lodash';
import { Component, forwardRef, TemplateRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent } from 'commons/components/list/list.component';

import { QuestionBankCategoryWithCount } from 'lender/questionnaires/manage/questionnaires.interface';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

import { ManageBankCategoryModalComponent } from 'lender/questionnaires/bank/categories/modals/manage-modal.component';
import { RemoveEmptyBankCategoryModalComponent } from 'lender/questionnaires/bank/categories/modals/remove-empty-modal.component';
import { RemoveBankCategoryWithQuestionsModalComponent } from 'lender/questionnaires/bank/categories/modals/remove-with-questions-modal.component';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => QuestionBankCategoriesComponent) },
	],
})
export class QuestionBankCategoriesComponent extends ListComponent {
	static listName = 'question-bank-categories';

	modalRef: BsModalRef;

	User: UserProfile;
	list: NgResourceArray<QuestionBankCategoryWithCount> = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		protected questionnairesService: LenderManageQuestionnaireService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.questionnairesService.bankCategoriesWithCount(queryParams)
			.$promise
			.then((data: NgResourceArray<QuestionBankCategoryWithCount>): void => {
				this.list = data;
			});
	}

	showSimpleCategoryModal = (
		modalComponent: typeof ManageBankCategoryModalComponent | typeof RemoveEmptyBankCategoryModalComponent,
		category?: QuestionBankCategoryWithCount,
	): void => {
		const initialState = {
			category,
			onCancel: () => {
				this.modalRef.hide();
			},
			onConfirm: () => {
				this.loadList(this.params);
			},
		};

		this.modalRef = this.modalService.show(modalComponent as any , {
			initialState,
			class: 'modal-smd',
			backdrop: 'static',
		});
	};

	rename = (category: QuestionBankCategoryWithCount): void => {
		this.showSimpleCategoryModal(ManageBankCategoryModalComponent, category);
	};

	create = (): void => {
		this.showSimpleCategoryModal(ManageBankCategoryModalComponent);
	};

	remove = (category: QuestionBankCategoryWithCount): void => {
		if (category.questionCount > 0) {
			this.removeCategoryWithQuestions(category);
			return;
		}

		this.removeEmptyCategory(category);
	}

	removeEmptyCategory = (category: QuestionBankCategoryWithCount): void => {
		this.showSimpleCategoryModal(RemoveEmptyBankCategoryModalComponent, category);
	}

	removeCategoryWithQuestions = (category: QuestionBankCategoryWithCount): void => {
		const initialState = {
			category,
			destinationCategories: reject(this.list, (entry: QuestionBankCategoryWithCount) => {
				return entry.id === category.id;
			}),
			onCancel: () => {
				this.modalRef.hide();
			},
			onConfirm: () => {
				this.loadList(this.params);
			},
		};

		this.modalRef = this.modalService.show(RemoveBankCategoryWithQuestionsModalComponent, {
			initialState,
			class: 'modal-smd',
			backdrop: 'static',
		});
	}
}
