import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list';
import { ListComponent } from 'commons/components/list/list.component';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { NgResourceArray } from 'commons/interfaces';

import { AppcodesEntry, AppcodeStatus, AppcodeFormValue } from './appcodes.interface';
import { AppcodesResourceService } from './appcodes-resource.service';
import { CreateAppcodeModalComponent } from './modals';

@Component({
	templateUrl: './appcodes.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => AppcodesComponent) },
	],
})
export class AppcodesComponent extends PagedListComponent {
	static listName = 'appcodes';

	appcodes: NgResourceArray<AppcodesEntry> = [];
	User: UserProfile;

	APPCODE: Record<string, AppcodeStatus> = {
		ACTIVE: 'ACTIVE',
		EXPIRED: 'EXPIRED',
		FULFILLED: 'FULFILLED',
		CANCELED: 'CANCELLED',
	};
	modalRef: BsModalRef;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		public modalService: BsModalService,
		public applicationCodes: AppcodesResourceService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	loadList(queryParams) {
		this.appcodes.$resolved = false;

		return this.applicationCodes.get(this.getClearParams(queryParams)).$promise
			.then((result) => {
				this.appcodes = result;
				this.noMatches = !result.length && queryParams.q;
			});
	}

	cancelCode(code: AppcodesEntry): void {
		const initialState = {
			title: 'Cancel Code',
			message: `Are you sure you want to cancel <strong>${code.appCode}</strong> code?`,
			confirmText: 'Yes',
			cancelText: 'No',
			onConfirm: () => {
				this.modalRef.content.resolving = true;

				this.applicationCodes.put({
					applicationCodeId: code.id,
					status: 'CANCELLED',
				}, {}, () => {
					this.modalRef.content.resolving = false;

					this.loadList(this.params);

					this.modalRef.hide();
				}, () => {
					this.modalRef.content.resolving = false;
				});
			},
			onCancel: () => {
				this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState,
			class: 'modal-smd',
		});
	}

	createCode(): void {
		const initialState = {
			onCancel: () => {
				this.modalRef.hide();
			},
			onConfirm: (formValue: AppcodeFormValue): void => {
				this.applicationCodes.save(formValue, () => {
					this.modalRef.content.resolving = false;
					this.loadList(this.params);

					this.modalRef.hide();
				}, ({ data }) => {
					this.modalRef.content.resolving = false;
					this.modalRef.content.appcodeForm.setServerError(data);
				});
			},
		};

		this.modalRef = this.modalService.show(CreateAppcodeModalComponent, {
			initialState,
			class: 'modal-md',
			backdrop: 'static',
		});
	}
}
