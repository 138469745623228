import { forEach } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';

// import { ClientDocumentsService } from '../client-documents.service';
// import { ClientLibraryDocumentsService } from '../library/library-documents.service';
import { InternalDocumentsService } from '../internal-documents.service';

// TODO: deprecated
// Used only in internal documents
@Component({
	selector: 'lender-global-document-history',
	templateUrl: './common-documents-history.component.html',
})
export class CommonDocumentsHistoryComponent extends PagedListComponent implements OnInit {
	static listName = 'documentsHistory';

	list: any = [];
	document: any = {};

	get resource() {
		switch (this.defaultFilters.docType) {
			// case 'library-documents':
			// 	return this.libraryDocumentsResource.document;
			// case 'internal-documents':
			// 	return this.internalDocumentsResource.one;
			// default:
			// 	return this.clientDocumentsResource.one;
			default:
				return this.internalDocumentsResource.one;
		}
	}

	get urlPrefix(): string {
		switch (this.defaultFilters.docType) {
			case 'library-documents':
				return 'doclib';
			case 'internal-documents':
				return 'internal-documents';
			case 'client-documents':
				return 'docmanagement/client-documents';
			default:
				return 'docmanagement';
		}
	}

	constructor(
		public User: UserService,
		// public clientDocumentsResource: ClientDocumentsService,
		// public libraryDocumentsResource: ClientLibraryDocumentsService,
		public internalDocumentsResource: InternalDocumentsService,
		transitionService: TransitionService,
		stateService: StateService,
	) {
		super(transitionService, stateService);

		const {
			id: tpoId,
			docId,
			docType,
		} = stateService.params;

		this.defaultFilters = {
			...this.defaultFilters,
			tpoId,
			docId,
			docType,
		};
	}

	ngOnInit() {
		super.ngOnInit();

		const {
			tpoId,
			docId,
			docType,
		} = this.params$.getValue();
		this.document = this.resource.get({
			tpoId,
			docId,
			docType,
		});
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.internalDocumentsResource.history.query(
			this.getClearParams(queryParams)
		).$promise
			.then((result) => {
				this.list = result;
				forEach(this.list, (document) => {
					document.documentLink = this.getDocumentLink(document);
				});
			});
	}

	getDocumentLink(item): string {
		const {
			id: lenderId,
		} = this.User?.profile?.organization;

		if (!lenderId) {
			return '#';
		}

		const {
			tpoId,
		} = this.params;

		return `/api/rest/lenders/${lenderId}/tpos/${tpoId}` +
			`/${this.urlPrefix}/${item.investorInternalDocumentId}` +
			(item.historyId ? `/history/${item.historyId}` : '') +
			`/uploadedDocument`;
	}
}
