import { UntypedFormControl, ValidatorFn, Validators, AsyncValidatorFn } from '@angular/forms';

import { ServerValidator } from '../validators/server.validator';

interface FormState {
	label?: string;
	value?: any;
	disabled?: boolean;
	updateOn?: 'change' | 'blur' | 'submit';
}

class RealmFormControl extends UntypedFormControl {
	fieldName: string;
	label: string;
	serverError: string;
	_pendingValue: any;

	get showError() {
		return this.touched && this.invalid;
	}

	constructor(
		fieldName: string,
		formState: FormState,
		validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
		asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
	) {
		const { label = '', updateOn = 'blur', ...restFormState } = formState;
		// TODO let's assume we do not have server-side validator endpoints, so our validation is always on js side, but with blur delay
        super(
            Object.keys(restFormState).length ? { value: null, disabled: false, ...restFormState } : null,
            {
                validators: Validators.compose([
                    ...Array.isArray(validatorOrOpts) ? validatorOrOpts : [validatorOrOpts],
                    // add server validation in the end
                    ServerValidator(),
                ]),
                asyncValidators: asyncValidator,
                updateOn,
            });

        if (asyncValidator) {
			// tslint:disable-next-line:no-console
			console.warn('Please, update RealmFormControl to support async validation + update on blur');
		}

		let prevValue = this.value;
		this.valueChanges.subscribe((value) => {
			// reset serverError
			if (value !== prevValue && this.serverError) {
				this.serverError = '';
				this.updateValueAndValidity();
			}

			prevValue = value;
		});

		this.label = label || '';
		this.fieldName = fieldName;
	}
}

const createRealmFormControl = (
	fieldName: string,
	formState: object = {},
	validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
	asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
) => {
	return { [fieldName]: new RealmFormControl(fieldName, formState, validatorOrOpts, asyncValidator) };
};

export { RealmFormControl, createRealmFormControl };
