import { forEach, now } from 'lodash';
import {Component, Injector, forwardRef} from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService as UService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { PostTypeResourceService } from './post-type-resource.service';
import { PostsTypeConfig, PostsTypeMap } from '../../posts-type-list';

interface Post {
	id: number;
	text: string;
	sentDate: number;
	updatedDate: number;
	updatedBy: string;
	scheduledDate: any;
	scheduledZoneTime?: any;
	timezone: any;
	hasImagePreview: boolean;
	imagePreviewLink: string;
	isVideoThumbnail: boolean;
}

interface PostResource extends Array<Post> {
	$promise?: Promise<Post[]>;
	$resolved?: boolean;
}

@Component({
	templateUrl: './post-type.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => PostTypeComponent) },
	],
})
export class PostTypeComponent extends PagedListComponent {
	// TODO: this will allow params to preserve between drafts, scheduled, posted
	// consider to create 3 separate classes
	static listName = 'posts';

	postTypesConfig: PostsTypeMap = PostsTypeConfig;
	User: any;
	list: PostResource = [];
	accounts: any = [];
	postType: any = {};
	tpoId: any;

	constructor(
		public injector: Injector,
		public transitionService: TransitionService,
		public stateService: StateService,
		public UserService: UService,
		public postsResource: PostTypeResourceService,
	) {
		super(transitionService, stateService);

		this.User = UserService.profile;
		this.tpoId = this.User.organization.id;
		const { postType: type } = stateService.params;
		this.postType = this.postTypesConfig.get(type);

		this.defaultFilters = {
			...this.defaultFilters,
			size: 10,
			status: this.postType.resourceId,
		};

		this.accounts = postsResource.posts.accounts();
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		const TimezonesService = this.injector.get('TimezonesService');

		return this.postsResource.posts.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.accounts.$promise.then(() => {
					forEach(data, (item) => {
						if (item.scheduledDate) {
							const timezoneName = TimezonesService.getZoneAbbr(item.timezone);
							const timezoneTime = TimezonesService.convertTimezoneToUTC(item.scheduledDate, item.timezone, 'MM/DD/YYYY, hh:mm A');
							item.scheduledZoneTime = `${ timezoneTime } ${ timezoneName }`;
						}
					});

					this.list = data;
					this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
				});
			});
	}

	overdue(item) {
		return item.scheduledDate < now();
	}
}
