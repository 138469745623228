import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    EffectRef,
    ElementRef,
    HostListener,
    Injector,
    input,
    model,
    OnDestroy,
    output,
    Renderer2,
    runInInjectionContext,
    signal,
    untracked,
    viewChild,
} from '@angular/core';
import { isEqual, throttle } from 'lodash';

export type Point = {
    x: number;
    y: number;
};

export type Size = {
    w: number;
    h: number;
};

export type Rect = Point & Size;

export type Line = {
    start: Point;
    end: Point;
};

const clamp = (value: number, min: number, max: number) => Math.max(Math.min(value, max), min);
const minSide = 20;
const throttleTime = 50;

@Component({
    selector: 'realm-image-cropper',
    templateUrl: 'image-cropper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperComponent implements OnDestroy {
    image = input.required<string>();
    imageEl = viewChild<ElementRef>('imgRef');
    imgRect = signal<Rect>({ x: 0, y: 0, w: 0, h: 0 }, { equal: isEqual });
    imgSize = computed(() => {
        this.imgRect();
        const img = <HTMLImageElement>this.imageEl()?.nativeElement;
        const { naturalWidth: w, naturalHeight: h } = img;
        if (w && h)
            return { w, h };

        return { w: 1, h: 1 };
    }, { equal: isEqual });
    crop = model<Line>({
        start: { x: 0, y: 0 },
        end: { x: 1, y: 1 },
    });
    cropZoneRel = computed<Rect>(() => {
        const { start: { x, y }, end: { x: ex, y: ey } } = this.crop();
        return {
            x,
            y,
            w: ex - x,
            h: ey - y,
        };
    }, { equal: isEqual });
    cropStyle = computed(() => {
        const { start: { x: sx, y: sy }, end: { x: ex, y: ey } } = this.crop();
        const { x: ix, y: iy, w: iw, h: ih } = this.imgRect();
        const
            x = ix + sx * iw,
            y = iy + sy * ih,
            w = (ex - sx) * iw,
            h = (ey - sy) * ih;

        return { left: `${x}px`, top: `${y}px`, width: `${w}px`, height: `${h}px` };
    });
    minHeight = input<number>(0);
    maxHeight = input<number>(Infinity);
    aspectRange = input<[number, number]>([-Infinity, Infinity]);
    protected moveEffect: EffectRef = null;

    private _moveProcess = false;
    get moveProcess() {
        return this._moveProcess;
    }

    set moveProcess(value: boolean) {
        this._moveProcess = value;
        switch (this._moveProcess) {
            case true:
                this.renderer2.addClass(document.body, 'unselectable');
                break;
            case false:
                this.renderer2.removeClass(document.body, 'unselectable');
                break;
        }
    }

    referencePoint = signal<Point>({ x: 0, y: 0 });
    currentPoint = signal<Point>({ x: 0, y: 0 });

    croppedUrl: string;
    update = output<string>();

    protected canvas: OffscreenCanvas = new OffscreenCanvas(0, 0);
    protected ctx = this.canvas.getContext('2d');

    @HostListener('window:resize', ['$event']) resize() {
        this.updateImageRect();
    }

    @HostListener('document:mouseup')
    private onmouseup() {
        if (this.moveEffect) {
            this.moveProcess = false;
            this.moveEffect.destroy();
            this.moveEffect = null;
        }
    }

    @HostListener('document:mousemove', ['$event'])
    private onmousemove(event: MouseEvent) {
        if (!this.moveProcess) return;
        const { screenX: x, screenY: y } = event;
        this.currentPoint.set({ x, y });
    }

    constructor(
        private readonly injector: Injector,
        private readonly renderer2: Renderer2,
    ) {
        // update cropped image
        effect(() => {
            const img = <HTMLImageElement>this.imageEl()?.nativeElement;
            const { w,  h } = this.imgSize();
            const { x: sx, y: sy, w: sw, h: sh } = this.cropZoneRel();
            const height = clamp(h * sh, this.minHeight(), this.maxHeight());
            const ix = w * sx,
                iy = h * sy,
                iw = w * sw,
                ih = h * sh;
            const ratio = h ? height / (h * sh) : 1; // prevent Infinity when not initialized
            const width = Math.round(w * sw * ratio);

            untracked(async () => {
                await this.updateCanvas(img, width, height, ix, iy, iw, ih);
            });
        });
    }

    updateCanvas = throttle(async (img: HTMLImageElement, width: number, height: number, ix: number, iy: number, iw: number, ih: number) => {
        //ignoring non initialized values
        if (!img || !width) return;
        this.canvas.width = width;
        this.canvas.height = height;
        this.ctx.clearRect(0, 0, width, height);
        this.ctx.drawImage(
            img,
            ix, iy, iw, ih, // source image rect
            0, 0, this.canvas.width, this.canvas.height, // canvas rect
        );
        const blob = await this.canvas.convertToBlob();
        const newUrl = URL.createObjectURL(blob);
        if (this.croppedUrl) {
            //preventing memory leaks
            URL.revokeObjectURL(this.croppedUrl);
        }
        this.croppedUrl = newUrl;
        this.update.emit(newUrl);
    }, throttleTime, { leading: false, trailing: true });

    imageLoad() {
        this.updateImageRect();
    }

    updateImageRect() {
        const {
            offsetLeft: x,
            offsetTop: y,
            offsetWidth: w,
            offsetHeight: h,
        } = <HTMLImageElement>this.imageEl().nativeElement;
        this.imgRect.set({ x, y, w, h });

        //Respect aspect ratio limits
        const [minRatio, maxRatio] = this.aspectRange();
        const { start: { x: sx, y: sy }, end: { x: ex, y: ey } } = this.crop();
        const
            cw = (ex - sx) * w,
            ch = (ey - sy) * h,
            ratio = cw / ch;
        if (ratio < minRatio) {
            const y = cw * minRatio / h + sy;
            this.crop.update(({ start }) => ({ start, end: { x: ex, y } }));
        } else if (ratio > maxRatio) {
            const x = ch * maxRatio / w + sx;
            this.crop.update(({ start }) => ({ start, end: { x, y: ey } }));
        }
    }

    commonDragHandler(event: MouseEvent) {
        this.moveProcess = true;
        event.stopPropagation();
        event.preventDefault();
        const { screenX: x, screenY: y } = event;
        this.currentPoint.set({ x, y });
        this.referencePoint.set({ x, y });
    }

    initSizeDrag(event: MouseEvent) {
        this.commonDragHandler(event);
        const { start: { x: sx, y: sy }, end: { x: ex, y: ey } } = this.crop();
        const { w: iw, h: ih } = this.imgRect();
        const
            min: Point = { x: sx + (minSide / iw), y: sy + (minSide / ih) },
            max: Point = { x: 1, y: 1 },
            asx = sx * iw,
            asy = sy * ih;

        runInInjectionContext(this.injector, () => {
            this.moveEffect = effect(() => {
                const { x: rx, y: ry } = this.referencePoint();
                const { x: px, y: py } = this.currentPoint();
                const [minRatio, maxRatio] = this.aspectRange();

                untracked(() => {
                    const
                        dx = (px - rx) / iw,
                        dy = (py - ry) / ih;
                    let
                        x = clamp(ex + dx, min.x, max.x),
                        y = clamp(ey + dy, min.y, max.y);
                    const
                        cw = x * iw - asx,
                        ch = y * ih - asy,
                        ratio = cw / ch;

                    if (ratio < minRatio) {
                        y = cw * minRatio / ih + sy;
                    } else if (ratio > maxRatio) {
                        x = ch * maxRatio / iw + sx;
                    }
                    this.crop.update(({ start }) => ({ start, end: { x, y } }));
                });
            });
        });
    }

    initPositionDrag(event: MouseEvent) {
        this.commonDragHandler(event);
        const { start: { x: sx, y: sy }, end: { x: ex, y: ey } } = this.crop();
        const
            cw = ex - sx,
            ch = ey - sy;
        const { w: iw, h: ih } = this.imgRect();
        const minS: Point = { x: 0, y: 0 };
        const maxS: Point = { x: 1 - cw, y: 1 - ch };
        const minE: Point = { x: cw, y: ch };
        const maxE: Point = { x: 1, y: 1 };

        runInInjectionContext(this.injector, () => {
            this.moveEffect = effect(() => {
                const { x: rx, y: ry } = this.referencePoint();
                const { x: px, y: py } = this.currentPoint();

                untracked(() => {
                    const
                        dx = (px - rx) / iw,
                        dy = (py - ry) / ih;
                    this.crop.set({
                        start: {
                            x: clamp(sx + dx, minS.x, maxS.x),
                            y: clamp(sy + dy, minS.y, maxS.y),
                        },
                        end: {
                            x: clamp(ex + dx, minE.x, maxE.x),
                            y: clamp(ey + dy, minE.y, maxE.y),
                        },
                    });
                });
            });
        });
    }

    ngOnDestroy() {
        this.renderer2.removeClass(document.body, 'unselectable');
        this.moveEffect?.destroy();
    }
}
