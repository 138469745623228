import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';

import { AuditChecklistModule } from './$reviewId';
import { ReviewAuditLogComponent } from './$reviewId/audit-log/audit-log.component';
import { ReviewsService } from './reviews.service';

@NgModule({
    imports: [
        AuditChecklistModule,
        CommonModule,
        PipesModule,
    ],
    declarations: [
        ReviewAuditLogComponent,
    ],
    providers: [
        ReviewsService,
    ]
})
export class SharedReviewsModule {}
