import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NumberValidator } from 'commons/validators';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { PhoneValidator } from 'commons/validators/phone.validator';

import { CodeValueService } from 'shared/code-value.service';
import { CompanyResourceService } from 'tpo/people/contacts/company-details/company-details-resource.service';
import { hasContactPermission } from 'tpo/contacts/contact-permissions';


@Component({
	templateUrl: './people-company-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleCompanyDetailsComponent {
    protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
    editing: boolean = false;
    saving: boolean = false;
	isEmbedded: boolean;
	states: any;
	User: UserProfile;
	company: any;
	companyForm = new RealmFormGroup({
		companyName: new RealmFormControl(
			'companyName',
			{ label: 'Company Name' },
			Validators.required,
		),
		address: new RealmFormControl(
			'address',
			{ label: 'Street' },
			Validators.required,
		),
		phone: new RealmFormControl(
			'phone',
			{ label: 'Phone' },
			Validators.compose([Validators.required, PhoneValidator()]),
		),
		suite: new RealmFormControl(
			'suite',
			{ label: 'Suite' },
		),
		fax: new RealmFormControl(
			'fax',
			{ label: 'Fax' },
			PhoneValidator('Fax should be 10 digits'),
		),
		city: new RealmFormControl(
			'city',
			{ label: 'City' },
			Validators.required,
		),
		businessTaxId: new RealmFormControl(
			'businessTaxId',
			{ label: 'Business Tax ID' },
			Validators.compose([
				Validators.required,
				NumberValidator('Business Tax ID should be 9 digits', 9),
			]),
		),
		state: new RealmFormControl(
			'state',
			{ label: 'State' },
			Validators.required,
		),
		zip: new RealmFormControl(
			'city',
			{ label: 'Zip' },
			Validators.required,
		),
	});

	constructor(
		public stateService: StateService,
		public companyResourceService: CompanyResourceService,
		public userService: UserService,
		public codeValueService: CodeValueService,
	) {
		this.isEmbedded = this.stateService.includes('**.wizard.**') || this.stateService.includes('**.application.**');
		this.states = this.codeValueService.get({code: 'State'});
        this.User = this.userService.profile;
        this.company = this.companyResourceService.get(
            this.getParams(),
            (response) => {
                this.companyForm.reset({ ...response });
                this.cdr.detectChanges();
            });
    }

    getParams = () => ({
        tpoId: this.User.isTpo ? this.User.organization.id : this.stateService.params.id,
        id: this.stateService.params.contactId,
    });

    async save() {
        Object.assign(
            this.company,
            this.companyForm.getRawValue(),
        );

        this.saving = true;
        try {
            this.company = await this.companyResourceService.save({ ...this.getParams(), ...this.company }).$promise;
            this.companyForm.reset(this.company);
        } catch ({ data: response }) {
            this.companyForm.setServerError(response);
        } finally {
            this.saving = false;
			this.toggleMode();
        }
    }

	cancel() {
		this.companyForm.reset({ ...this.company });
		this.toggleMode();
	}

	toggleMode() {
		this.editing = !this.editing;

        this.cdr.detectChanges();
	}

	hasPermission(permission) {
		return hasContactPermission(this.userService.profile, permission);
	}
}
