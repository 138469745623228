import { ChangeDetectionStrategy, Component, computed, HostBinding, input } from '@angular/core';
import { SCCustomerCard } from 'shared/solution-center/solution-center.common';

@Component({
    selector: 'sc-company-card',
    templateUrl: './company-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCompanyCardComponent {
    companyData = input.required<SCCustomerCard>();
    labels = computed<string[]>(() => {
        const { loanPrograms, tags } = this.companyData();

        return [
            ...loanPrograms.map(({ name }) => name),
            ...tags.map(({ name }) => name),
        ];
    });
    isPreview = input<boolean>(false);
    @HostBinding('class.preview-mode') get preview() {
        return this.isPreview();
    }
}
