import { map, includes, forEach } from 'lodash';
import { Component, OnInit, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { CodeValueService } from 'shared/code-value.service';

import { SocialAccountsResourceService } from '../social-accounts-resource.service';

@Component({
	templateUrl: './inventory.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => SocialAccountsInventoryComponent) },
	],
})
export class SocialAccountsInventoryComponent extends PagedListComponent implements OnInit {
	static listName = 'accountsInventory';

	modalRef: BsModalRef;
	User: UserProfile;
	list: any = [];
	tpoId: number;
	contactTypes = [{ type: 'I', name: 'Individual' }, {type: 'C', name: 'Company'}];
	exportStatus: boolean;
	hasIndividual: boolean;
	regLink = /(?:(?:(?:https?:)\/\/)?(www\.)?)((?:(?:(?:[@A-Za-z0-9\-]+)\.)+)(?:(?:[A-Za-z]+)\b)(?:(?:\/[A-Za-z0-9%\-_.?&=#]*)*))/;
    newMenu: boolean;
	hasAccess: boolean;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		public modalService: BsModalService,
		public socialAccountsService: SocialAccountsResourceService,
		public CodeValue: CodeValueService,
	) {
		super(transitionService, stateService);
		this.User = userService.profile;
		this.newMenu = this.User.isTpo;
		this.tpoId = this.User.organization.id;

		this.hasAccess = this.User.can('TPO_SMC_VIEW_SOCIAL_INVENTORY');
		if (!this.hasAccess) { return; }

		this.filters = {
			states: this.CodeValue.get({ code: 'State' }),
			accountTypes: this.socialAccountsService.inventory.accountTypes.get({tpoId: this.tpoId})
				.$promise.then()
				.then((data: any): void => {
					this.filters.accountTypes = data;
				}),
			locationNmlsIds: [],
			entityTypes: [],
		};

		this.defaultFilters = {
			...this.defaultFilters,
			states: null,
			locationNmlsIds: null,
			accountTypes: null,
			entityTypes: ['I'],
		};

		this.omitParams = [
			...this.omitParams,
			'entityTypes',
		];
	}

	ngOnInit() {
		if (!this.hasAccess) { return; }
		super.ngOnInit();
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		const params: any = {
			...this.getClearParams(queryParams),
			tpoId: this.tpoId,
		};

		return this.socialAccountsService.inventory.list.get(params)
			.$promise
			.then((data: any): void => {
				this.list = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
				this.hasIndividual = !params.entityTypes.length || includes(params.entityTypes, 'I');
			});
	}

	getBranches(state) {
		this.socialAccountsService.inventory.locations.get({tpoId: this.tpoId, state})
			.$promise
			.then((data: any): void => {
				forEach(data, (location) => {
					location.displayName += ` ${location.zip}`;
				});
				this.filters.locationNmlsIds = data;
			});
	}

	setFilter(filters, resetPage: boolean = true) {
		this.exportStatus = false;
		const pageParams = resetPage ? { page: 0 } : {};

		if (filters.locationNmlsIds) {
			filters.locationNmlsIds = map(filters.locationNmlsIds, 'id');
		}

		if (filters.states) {
			filters.states = map(filters.states, 'shortName');
			if (filters.states.length > 0) {
				this.getBranches(filters.states);
			} else {
				filters.states = null;
				filters.locationNmlsIds = null;
			}
		}

		if (filters.accountTypes) {
			filters.accountTypes = map(filters.accountTypes, 'id');
		}

		if (filters.entityTypes) {
			filters.entityTypes = map(filters.entityTypes, 'type');
		}

		this.params$.next({ ...this.params$.getValue(), ...pageParams, ...filters });
	}

	export() {
		if (!this.exportStatus && !this.noMatches) {
			this.exportStatus = true;
			const { q, states, locationNmlsIds, accountTypes, entityTypes } = this.params;
			const filteredParams = this.getClearParams({ tpoId: this.tpoId, q, states, locationNmlsIds, accountTypes, entityTypes });
			this.socialAccountsService.inventory.export.get(filteredParams);
		}
	}

	setExportStatusToFalse = () => {
		this.exportStatus = false;
	}
}
