import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService as UService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { GroupNameModalComponent } from '../group-name-modal.component';
import { GroupsResourceService } from '../groups-resource.service';

@Component({
	templateUrl: './groups-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => GroupsListComponent) },
	],
})
export class GroupsListComponent extends PagedListComponent {
	static listName = 'groups';

	groups: any = [];
	User: any;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public groupsResource: GroupsResourceService,
		public modalService: BsModalService,
		public UserService: UService,
	) {
		super(transitionService, stateService);

		this.defaultFilters = {
			...this.defaultFilters,
			size: 10,
		};

		this.User = UserService.profile;
	}

	loadList(queryParams) {
		this.groups.$resolved = false;
		return this.groupsResource.groups.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.groups = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	createGroup = () => {
		const initialState = {
			title: 'Create Group',
			onSubmit: (data) => {
				const resource = this.groupsResource.groups.create({}, data);
				resource.$promise
					.then(({ id: groupId }) => {
						this.stateService.go('.:groupId', { groupId });
					});
				// return resource $promise to process error
				return resource.$promise;
			},
		};
		this.modalService.show(GroupNameModalComponent, { initialState, class: 'modal-sm' });
	}
}
