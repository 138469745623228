import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { AbstractRoleNavComponent } from '../abstract-role-nav.component';
import { NavigationMenuItem } from 'utils/navigation/navigation.interface';

import { InvestorSharesAccessRulesService } from 'commons/services/system-rules/system-access-rules/investor-shares-access-rules.service';
import { LenderIssuesStatusService } from 'lender/status/lender-issues-status.service';

@Component({
	selector: 'customer-usernav',
	templateUrl: './role-usernav.component.html',
})
export class CustomerUsernavComponent extends AbstractRoleNavComponent {
	isTPOLimited: boolean;

	constructor(
		stateService: StateService,
		userService: UserService,
        private readonly investorSharesAccessRulesService: InvestorSharesAccessRulesService,
        private readonly lenderIssuesStatusService: LenderIssuesStatusService,
	) {
		super(stateService, userService);

		this.isTPOLimited = userService.profile.organization.type === 'TPO_LIMITED';
	}

    // Override
    protected rebuildMenuItems(user: UserProfile): NavigationMenuItem[] {
        const menuItems: NavigationMenuItem[] = [];
        menuItems.push({
            sref: 'my-settings',
            title: 'My Settings',
        });

        if (user.can('VIEW_USERS')) {
            menuItems.push({
                sref: 'users-management.users',
                title: 'User Management',
            });
        } else if (user.can('VIEW_ROLES')) {
            menuItems.push({
                sref: 'users-management.roles',
                title: 'Manage Users',
            });
        }

        const scEnabled = window.env['featureToggle_solution-center_enabled'] === 'true';
        if (user.can('MANAGE_SOLUTION_CENTER') && scEnabled) {
            menuItems.push({
                sref: 'solution-center',
                title: 'Solution Center',
            });
        }

        //has TPO service
        if (user.isLender) {
            if (user.can('VIEW_GLOBAL_DOCUMENTS')) {
                menuItems.push({
                    sref: 'documents',
                    title: 'Manage Documents',
                    hasAlert: this.lenderIssuesStatusService.documents.docusign.get(),
                });
            }

            // TODO: Correct permission when it's implemented
             if (user.can('TPO_VIEW_GLOBAL_FINANCIAL')) {
                menuItems.push({
                    sref: 'financials',
                    title: 'Manage Financials',
                });
             }

            if (user.can('VIEW_CHANNEL_SETTINGS')) {
                menuItems.push({
                    sref: 'channels',
                    title: 'Manage Channels',
                });
            }

            if (user.can('VIEW_GLOBAL_QUESTIONNAIRE')) {
                menuItems.push({
                    sref: 'questionnaires',
                    title: 'Manage Questionnaires',
                });
            }

            if (user.can('MANAGE_CUSTOM_FIELDS')) {
                menuItems.push({
                    sref: 'fields',
                    title: 'Custom Fields',
                });
            }

            if (user.can('VIEW_APPLICATION_CODES')) {
                menuItems.push({
                    sref: 'appcodes',
                    title: 'Application Codes',
                });
            }

			if (user.can('EDIT_PASSWORD_SECURITY')) {
				menuItems.push({
					sref: '#/manage',
					rawHref: true,
					title: 'Password Policy',
				});
			}

            if (this.investorSharesAccessRulesService.userHasAccessToInvestorShares(user)) {
                menuItems.push({
                    sref: 'shares',
                    title: 'Investor Sharing',
                });
            }
        }

		menuItems.push({
			title: 'Logout',
            onClick: () => this.userService.logout(),
		});

        return menuItems;
    }

}
