import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PhoneValidator } from 'commons/validators';
import * as moment from 'moment';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { UnderwritingInterface } from 'tpo/account/underwriting/underwriting.interface';
import { UnderwritingService } from 'tpo/account/underwriting/underwriting.service';


@Component({
    selector: 'underwriting-service-item',
    templateUrl: 'underwriting-service-item.component.html',
})
export class UnderwritingServiceItemComponent implements OnInit {
    @Input() public item: UnderwritingInterface;
    @Input() public accountInformation: AccountInformation;
    @Input() public userCanAddEdit: boolean;
    @Output() public onNewItemCancel: EventEmitter<UnderwritingInterface> = new EventEmitter();
    @Output() public afterSave: EventEmitter<void> = new EventEmitter<void>();

    isEditing: boolean = false;
    resolving: boolean;
    isValid: boolean;
    labelDateFormat: string = 'MM/dd/YYYY';
    dateFormat: string = 'MM/DD/YYYY';

    underwritingServiceForm = new RealmFormGroup({
            companyName: new RealmFormControl(
                'companyName',
                {
                    label: 'Company Name',
                },
                Validators.required,
            ),
            phone: new RealmFormControl(
                'phone',
                {
                    label: 'Phone',
                },
                PhoneValidator(),
            ),
            contactName: new RealmFormControl(
                'contactName',
                {
                    label: 'Contact Name',
                },
            ),
            relationshipStartDate: new RealmFormControl(
                'relationshipStartDate',
                {
                    label: 'Start Date',
                },
                Validators.required,
            ),
            relationshipEndDate: new RealmFormControl(
                'relationshipEndDate',
                {
                    label: 'End Date',
                },
            ),
            id: new RealmFormControl(
                'id',
                {
                    label: 'id',
                },
            ),
            type: new RealmFormControl(
                'type',
                {
                    label: 'type',
                }
            ),
        },
        { validators: this.relationshipDatesValidator }
    );

    relationshipDatesValidator(formGroup: UntypedFormGroup): ValidationErrors {
        const datesAreNotValid = ((formGroup.controls['relationshipEndDate'].value && formGroup.controls['relationshipStartDate'].value) && (formGroup.controls['relationshipEndDate'].value < formGroup.controls['relationshipStartDate'].value));
        if(datesAreNotValid) {
            formGroup.controls['relationshipEndDate'].setErrors({ 'error' : true });
        }
        return ( datesAreNotValid ? { error: true } : null );
    }

    constructor(private underwritingService: UnderwritingService) {}

    ngOnInit() {
        if (!this.item.id) {
            this.isEditing = true;
        }
        this.underwritingServiceForm.patchValue(this.item);
        this.underwritingServiceForm.patchValue({relationshipStartDate: this.toDate(this.item.relationshipStartDate)});
        this.underwritingServiceForm.patchValue({relationshipEndDate: this.toDate(this.item.relationshipEndDate)});
    }

    cancel() {
        this.isEditing = false;
        if (!this.item.id) {
            this.onNewItemCancel.next(this.item);
        }
    }

    enableEdit() {
        this.isEditing = true;
    }

    async save() {
        const result: UnderwritingInterface = this.underwritingServiceForm.value;
        if (!this.item.id) {
            this.addNewService(result);
        }
        else {
            this.saveChangedService(result);
        }
    }

    async addNewService(result: UnderwritingInterface) {
        const newService = this.formatService(result);
        try {
            this.resolving = true;
            this.item = await this.underwritingService.createServices(this.accountInformation.identifier.tpoId, newService).toPromise();
            this.afterSave.emit();
            this.isEditing = false;
        }
        catch(e) {
            this.underwritingServiceForm.setServerError(e);
        }
        finally {
            this.resolving = false;
        }
    }

    async saveChangedService(result: UnderwritingInterface) {
        const newService = this.formatService(result);
        try {
            this.resolving = true;
            this.item = await this.underwritingService.updateServices(this.accountInformation.identifier.tpoId, newService, this.item.id).toPromise();
            this.afterSave.emit();
            this.isEditing = false;
        }
        catch(e) {
            this.underwritingServiceForm.setServerError(e);
        }
        finally {
            this.resolving = false;
        }
    }

    toDate(dateString) {
        if (dateString) {
            return moment(dateString).toDate();
        }
    }

    formatService(newService: UnderwritingInterface): UnderwritingInterface {
        if (newService.relationshipStartDate) {
            newService.relationshipStartDate = moment(newService.relationshipStartDate).unix() * 1000;
        }
        if (newService.relationshipEndDate) {
            newService.relationshipEndDate = moment(newService.relationshipEndDate).unix() * 1000;
        }
        return newService;
    }
}
