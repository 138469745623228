import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ListComponent } from 'commons/components/list';
import { HistoryDocumentListComponent } from 'shared/documents';

import { ClientLibraryHistoryDocument } from '../../library-documents.interface';
import { ClientLibraryDocumentsService } from '../../library-documents.service';
import { RequestDocumentHistoryModalComponent } from 'lender/clients/$id/documents/common';

@Component({
	templateUrl: '../../../common/history/document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ClientLibraryDocumentHistoryComponent) },
	],
})
export class ClientLibraryDocumentHistoryComponent extends HistoryDocumentListComponent<ClientLibraryHistoryDocument> {
	static listName = 'clientLibraryDocumentHistory';
	modalRef: BsModalRef;

	listResource = this.documentsService.getDocumentHistory;

	configureList = (): void => {
		const {
			id: tpoId,
			libraryId,
		} = this.uiRouterGlobals.params;
		Object.assign(
			this.defaultFilters,
			{
				tpoId,
				libraryId,
			},
		);

		this.omitParams.push('tpoId', 'libraryId');
	};

	getDownloadLink = ({ libraryHistoryLogId }: ClientLibraryHistoryDocument): string => {
		const {
			id: tpoId,
			libraryId,
		} = this.uiRouterGlobals.params;
		return this.documentsService.getDownloadLink({
			tpoId,
			libraryId,
			historyId: libraryHistoryLogId,
			type: 'uploadedDocument',
		});
	};

	showRequestHistoryModal({ libraryRequestHistoryLogId }: ClientLibraryHistoryDocument): void {
		const {
			id: tpoId,
			libraryId,
		} = this.uiRouterGlobals.params;
		this.modalRef = this.modalService.show(
			RequestDocumentHistoryModalComponent,
			{
				initialState: {
					snapshotData: this.documentsService.getDocumentRequestsHistory({
						tpoId,
						libraryId,
						libraryRequestHistoryLogId,
					}),
				},
				class: 'modal-new modal-smd request-history-modal',
			},
		);
	}

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: ClientLibraryDocumentsService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);
	}
}
