import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import {
    CustomRequiredValidator, FileSizeValidator,
    PhoneValidator, SmLinkValidator,
} from 'commons/validators';
import { SmLinksRegExp } from 'commons/regexp/sm-links';

export const OverviewForm = new RealmFormGroup(
    {
        companyName: new RealmFormControl(
            'companyName',
            {
                label: 'Company Display Name',
                updateOn: 'change',
            },
            Validators.required,
        ),
        companyContactName: new RealmFormControl(
            'companyContactName',
            {
                label: 'Company Contact',
                updateOn: 'change',
            },
        ),
        companyPhone: new RealmFormControl(
            'companyPhone',
            {
                label: 'Company Phone',
                updateOn: 'change',
            },
            PhoneValidator(),
        ),
        companyEmail: new RealmFormControl(
            'companyEmail',
            {
                label: 'Email',
                updateOn: 'change',
            },
            Validators.email,
        ),
        companyWebsite: new RealmFormControl(
            'companyWebsite',
            {
                label: 'Company Website',
                updateOn: 'change',
            },
        ),
        tags: new RealmFormControl(
            'tags',
            {
                label: 'Tags',
                updateOn: 'change',
            },
        ),
        loanPrograms: new RealmFormControl(
            'loanPrograms',
            {
                label: 'Loan Programs',
                updateOn: 'change',
            },
        ),
        companyAddress: new RealmFormControl(
            'companyAddress',
            {
                label: 'Company Address',
                updateOn: 'change',
            },
        ),
        description: new RealmFormControl(
            'description',
            {
                label: 'About Us',
                updateOn: 'change',
            },
        ),
        medialinks: new RealmFormGroup(
            {
                FB: new RealmFormControl(
                    'FB',
                    {
                        label: 'Facebook URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.FB)],
                ),
                IG: new RealmFormControl(
                    'IG',
                    {
                        label: 'Instagram URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.IG)],
                ),
                LI: new RealmFormControl(
                    'LI',
                    {
                        label: 'LinkedIn URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.LI)],
                ),
                YT: new RealmFormControl(
                    'YT',
                    {
                        label: 'YouTube URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.YT)],
                ),
                X: new RealmFormControl(
                    'X',
                    {
                        label: 'X URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.X)],
                ),
            },
        ),
        originalCompanyLogo: new RealmFormControl(
            'originalCompanyLogo',
            {
                label: 'Logo',
            },
            FileSizeValidator(20 * Math.pow(2, 20)),
        ),
        companyLogo: new RealmFormControl(
            'companyLogo',
            {
                label: 'Company Logo',
            }, [
                CustomRequiredValidator('Please upload {field}'),
            ],
        ),
        isCompanyLogoModified: new RealmFormControl(
            'isCompanyLogoModified',
            {},
        ),
        companyLogoCoordinates: new RealmFormControl(
            'companyLogoCoordinates',
            {},
        ),
        hasCompanyLogo: new RealmFormControl(
            'hasCompanyLogo',
            {},
        ),
        originalMarketingLogo: new RealmFormControl(
            'originalMarketingLogo',
            {
                label: 'Logo',
            },
            FileSizeValidator(20 * Math.pow(2, 20)),
        ),
        marketingLogo: new RealmFormControl(
            'marketingLogo',
            {
                label: 'Marketing Content',
            },
        ),
        marketingLogoCoordinates: new RealmFormControl(
            'marketingLogoCoordinates',
            {},
        ),
        hasMarketingLogo: new RealmFormControl(
            'hasMarketingLogo',
            {},
        ),
        isMarketingLogoModified: new RealmFormControl(
            'isMarketingLogoModified',
            {},
        ),
        marketingLogoPageWidth: new RealmFormControl(
            'marketingLogoPageWidth',
            {
                label: 'Marketing Content Ratio',
                updateOn: 'change',
            },
        ),
    },
);
