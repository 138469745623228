import { cloneDeep, pick, find, isEqual, reject, omit } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { TransitionService, StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { StateService as $stateProvider } from 'angularjs-providers/state.provider';
import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { CustomRequiredValidator } from 'commons/validators';
import { NgResourceArray, NgResourceObject } from 'commons/interfaces';

import {
	CustomFieldsService,
	CustomField,
	ControlType,
	EntityType,
	Group,
	CustomFieldChangeValue,
} from '../../../custom-fields.service';
import { FieldRemoveModalComponent } from './remove-modal/field-remove-modal.component';
import { EditValuesModalComponent } from './edit-values-modal/edit-values-modal.component';

@Component({
	templateUrl: './details.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => DetailsComponent) },
	],
})
export class DetailsComponent extends PagedListComponent {
	static listName = 'entity-fields-details';
	isNew: boolean;
	editMode: boolean;

	User: UserProfile;
	modalRef: BsModalRef;

	field: NgResourceObject<Partial<CustomField>> = {
		controlTypeId: null,
		fieldDescription: null,
		fieldName: null,
		helpText: null,
		required: null,
		valuesList: [],
		changeValues: [],
		visible: true,
		groupId: null,
	};
	fieldInitial: NgResourceObject<Partial<CustomField>> = cloneDeep(this.field);
	fieldId: number;
	entityType: EntityType;
	titles = CustomFieldsService.EntityTitles;
	types: NgResourceArray<ControlType> = [];
	step: 1 | 2;
	typesConfig = CustomFieldsService.customFieldsConfig;

	maxNameLength = 25;
	maxDescriptionLength = 3000;
	maxHelpLength = 300;

	fieldForm: RealmFormGroup = new RealmFormGroup({
		controlTypeId: new RealmFormControl(
			'controlTypeId',
			{ label: 'Type', updateOn: 'change' },
			[Validators.required],
		),
		fieldName: new RealmFormControl(
			'fieldName',
			{ label: 'Field Name', updateOn: 'change' },
			[Validators.required, Validators.maxLength(this.maxNameLength)],
		),
		fieldDescription: new RealmFormControl(
			'fieldDescription',
			{ label: 'Field Description', updateOn: 'change' },
			[Validators.maxLength(this.maxDescriptionLength)],
		),
		helpText: new RealmFormControl(
			'helpText',
			{ label: 'Field Help Text', updateOn: 'change' },
			[Validators.maxLength(this.maxHelpLength)],
		),
		valuesList: new RealmFormArray([]),
		required: new RealmFormControl(
			'required',
			{ label: 'Required Field', updateOn: 'change' },
			[Validators.required],
		),
		groupId: new RealmFormControl(
			'groupId',
			{ label: 'Group', updateOn: 'change' },
		),
		visible: new RealmFormControl(
			'visible',
			{ label: 'Required Field', updateOn: 'change', value: true },
		),
	});

	valueListSubscription: Subscription;
	groups: NgResourceArray<Group> = [];
	removedChangeValues: CustomFieldChangeValue[] = [];

	get controlTypeId(): RealmFormControl {
		return this.fieldForm.get('controlTypeId') as RealmFormControl;
	}

	get valuesList(): RealmFormArray {
		return this.fieldForm.get('valuesList') as RealmFormArray;
	}

	get groupName(): string {
		return find(this.groups, { id: this.field.groupId })?.name
	}

	get isArrayTypeField(): boolean {
		return ['Droplist', 'MultipleSelection'].indexOf(this.controlTypeId.value) !== -1
	}

	constructor(
		transitionService: TransitionService,
		{ profile }: UserService,
		{ params: { entityType, id } }: UIRouterGlobals,
		public stateService: StateService,
		public modalService: BsModalService,
		public fieldsService: CustomFieldsService,
		public $state: $stateProvider,
	) {
		super(transitionService, stateService);

		this.User = profile;
		this.entityType = entityType;
		this.fieldId = id;
		this.isNew = stateService.includes('**.new.**');
		this.step = this.isNew ? 1 : 2;
		this.editMode = this.isNew;

		this.groups = this.fieldsService.groups(this.entityType);
	}

	async loadList(): Promise<void> {
		if (!this.isNew) {
			this.field.$resolved = false;
			this.field = await this.fieldsService.getField(this.fieldId).$promise;
			this.fieldInitial = cloneDeep(this.field);
			this.formInit();
			this.duplicatesValidator();
			return;
		}
		this.types.$resolved = false;
		this.types = await this.fieldsService.controlTypes().$promise;
	}

	formInit = (): void => {
		this.fieldForm.patchValue(
			pick(
				this.field,
				'controlTypeId',
				'fieldName',
				'fieldDescription',
				'helpText',
				'required',
				'groupId',
				'visible'
			)
		);

		if (this.isArrayTypeField) {
			this.valuesList.clear();

			for (const value of this.field.valuesList) {
				this.addNewControl(value);
			}
		}
	};

	duplicatesValidator(): void {
		this.valueListSubscription = this.valuesList.valueChanges.subscribe((values) => {
			const indexesOfDuplicates = values
				.map((val, i, arr) => val && arr.indexOf(val) !== i && i)
				.filter((val) => !!val);
			if (indexesOfDuplicates.length) {
				for (const index of indexesOfDuplicates) {
					this.valuesList.controls[index].setErrors({
						notUnique: {
							template: '<b>Values List</b> should have only unique values.',
						},
					});
				}
			} else {
				this.valuesList.updateValueAndValidity({ emitEvent: false });
			}
		});
	}

	trackByIndex = (index: number): number => index;

	async add(): Promise<void> {
		this.types.$resolved = false;
		try {
			await this.fieldsService.addField(this.entityType, this.field).$promise;

			this.$state.notify('^.^', {
				notification: {
					type: 'alert-success',
					message: `Custom field has been successfully created`,
				},
			});
		} catch (e) {
			this.fieldForm.setServerError(e.data || e);
		}
		this.types.$resolved = true;
	}

	async getUpdateRecordsStrategy(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.modalRef = this.modalService.show(EditValuesModalComponent, {
				initialState: {
					onSave: (hasUpdateRecords: boolean) => {
						this.modalRef.hide();
						resolve(hasUpdateRecords);
					},
					onCancel: () => {
						this.modalRef.hide();
						reject();
					},
				},
				class: 'modal-smd modal-new',
			});
		});
	}

	async save(): Promise<void> {
		this.field.$resolved = false;
		if (this.isArrayTypeField && !isEqual(this.field.valuesList, this.fieldInitial.valuesList)) {
			try {
				const hasUpdateRecords = await this.getUpdateRecordsStrategy();
				if (!hasUpdateRecords) {
					delete this.field.changeValues;
				} else {
					this.field.changeValues = reject(
						[...this.field.changeValues, ...this.removedChangeValues],
						({ to, from }) => from === '' || from === to
					) as CustomFieldChangeValue[];
					this.removedChangeValues = [];
				}
			} catch (e) {
				this.field.$resolved = true;
				return;
			}
		}

		try {
			this.field = await this.fieldsService.updateField(this.fieldId, cloneDeep(this.field)).$promise;
			this.fieldInitial = cloneDeep(this.field);
			this.formInit();
			this.editMode = false;
		} catch (e) {
			this.fieldForm.setServerError(e.data || e);
			this.field.$resolved = true;
		}
	}

	edit(): void {
		if (this.isArrayTypeField) {
			this.field.changeValues = this.fieldInitial.valuesList.map(
				value => ({ from: value, to: value })
			);
		}
		this.editMode = true;
	}

	cancelEdit(): void {
		this.field = cloneDeep(this.fieldInitial);
		this.fieldForm.reset();
		this.formInit();
		this.editMode = false;
		this.field.$resolved = true;
	}

	remove(): void {
		this.modalRef = this.modalService.show(FieldRemoveModalComponent, {
			initialState: {
				entityName: this.titles[this.entityType],
				onConfirm: async (): Promise<void> => {
					this.modalRef.content.resolving = true;
					try {
						await this.fieldsService.removeField(this.fieldId).$promise;
						this.$state.notify('^.^', {
							notification: {
								type: 'alert-warning',
								message: `Custom field has been successfully removed.`,
								timeout: 5000,
							},
						});
						this.modalRef.content.resolving = false;
					} catch (e) {
						this.fieldForm.setServerError(e.data || e);
					}
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	getInputValue = ({ target }: Event): string => (target as HTMLInputElement).value;

	hasError(fieldName: string): boolean {
		const { invalid, touched } = this.fieldForm.get(fieldName) || {};
		return this.editMode && invalid && touched;
	}

	async toggleVisibility(): Promise<void> {
		this.field.$resolved = false;
		try {
			this.field = await this.fieldsService.fieldAction(
				this.fieldId,
				this.field.visible ? 'hide' : 'show',
			).$promise;
		} catch ({ data }) {
			this.fieldForm.setServerError(data);
		}
	}

	next(): void {
		if (this.isArrayTypeField) {
			this.duplicatesValidator();
			this.addNewValue();
		}

		this.step = 2;
	}

	back(): void {
		const selectedType = { controlTypeId: this.controlTypeId.value };
		this.field = cloneDeep({ ...this.fieldInitial, ...selectedType });
		this.fieldForm.reset(selectedType);
		this.valueListSubscription?.unsubscribe();
		this.valuesList.clear();

		this.step = 1;
	}

	move(i: number, step: number): void {
		const { value } = this.valuesList;
		[ value[i], value[i + step] ] = [ value[i + step], value[i] ];
		this.valuesList.setValue(value);
		this.field.valuesList = value;

		const { changeValues } = this.field;
		[changeValues[i], changeValues[i + step]] = [changeValues[i + step], changeValues[i]];
	}

	addNewControl(value = ''): void {
		const { length } = this.valuesList;
		const control = new RealmFormControl(
			`fieldValue${length}`,
			{ label: `Field Value`, updateOn: 'change', value },
			[
				CustomRequiredValidator('<b>Field Value</b> should not be empty.'),
				Validators.maxLength(25),
			],
		);
		this.valuesList.push(control);
	}

	addNewValue(value = ''): void {
		this.addNewControl(value);
		this.field.valuesList.push(value);
		this.field.changeValues.push({ from: '', to: value });
	}

	removeItem(i: number): void {
		const { changeValues, valuesList } = this.field;
		const removedItem = changeValues.splice(i, 1)[0];
		this.removedChangeValues.push({ ...removedItem, to: '' });

		valuesList.splice(i, 1);
		this.valuesList.removeAt(valuesList.length);
		this.valuesList.patchValue(valuesList);
	}

	changeValueListInput(i: number, $event: Event) {
		this.field.changeValues[i].to = this.field.valuesList[i] = this.getInputValue($event);
	}
}
