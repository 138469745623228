import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService, UIRouter } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { some } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { ListComponent } from 'commons/components/list';

import { SharedSocialMediaService } from 'shared/social-media/social-media.service';

import {
	SmNetworkConnector,
	FacebookConnector,
	TwitterConnector,
	LinkedInConnector,
	YoutubeConnector,
	InstagramConnector,
	GmbConnector,
} from 'tpo/social-accounts/connect/connectors';
import { PublishingPermissionsResourceService } from 'tpo/social-compliance/publishing-permissions';

import { SocialAccountsConnectComponent } from './connect-component';
import { SocialAccountsResourceService } from '../social-accounts-resource.service';
import { SocialConnectResourceService } from './social-connect-resource.service';
import { SocialAccountLink } from './link.class';

@Component({
	templateUrl: './social-accounts-company.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => SocialAccountsCompanyComponent) },
	],
	host: {
		'(window:message)': 'onMessage($event)',
	},
})
export class SocialAccountsCompanyComponent extends SocialAccountsConnectComponent {
	static listName = 'accountsCompany';

	accountInfo: any;
	monitoringBillingEnabled = false;
	canManage: false;
	canManageRss = false;
	canManageMonitoring = false;
    newMenu: boolean;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		$state: $stateProvider,
		modalService: BsModalService,
		sharedSocialMediaService: SharedSocialMediaService,
        uiRouter: UIRouter,
		socialConnectService: SocialConnectResourceService,
		private socialAccountsService: SocialAccountsResourceService,
		publishingPermissionsResourceService: PublishingPermissionsResourceService,
	) {
		super(
			transitionService,
			stateService,
			userService,
			$state,
			modalService,
			sharedSocialMediaService,
            uiRouter,
			socialConnectService,
		);

		this.permissionsResource = publishingPermissionsResourceService.company;
        this.newMenu = this.User.isTpo;

		this.networks = {
			FB: new FacebookConnector({
				click: (entry = null) => this.connectFacebookPage(entry),
			}),
			TW: new TwitterConnector({
				url: '/connect/twitter?forceConnect=true&connectAsDigitalMedia=true',
				click: () => this.connectCommon(this.networks.TW),
			}),
			LI: new LinkedInConnector({
				url: '/connect/linkedin?forceConnect=true&connectAsDigitalMedia=true',
				click: (entry = null) => this.connectLinkedIn(this.networks.LI, entry),
			}),
			YT: new YoutubeConnector({
				url: '/youtube/dm-connect',
				click: () => this.connectCommon(this.networks.YT),
			}),
			IG: new InstagramConnector({
				click: () => this.showConnectInstagramModal(),
			}),
			GMB: new GmbConnector({
				url: '/googlemb/dm-connect-flow',
				click: (entry = null) => this.connectGMB(this.networks.GMB, entry),
				isDM: true,
			}),
		};

		const companyName = this.User.organization.name;
		this.connectModalState = {
			resolving: false,
			title: 'Company Social Media',
			companyName,
			message: `Only select company accounts that are owned by ${companyName} to be connected here.`,
			services: [
				this.networks.GMB,
				this.networks.FB,
				this.networks.TW,
				this.networks.LI,
				this.networks.YT,
				this.networks.IG,
			]
				.map((value: SmNetworkConnector) => ({ ...value, name: value instanceof GmbConnector ? value.name : companyName })),
		};

		this.canManage = (
			this.User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA') ||
			this.User.can('CCM_SMC_MANAGE_DIGITAL_MEDIA')
		);
		this.canManageRss = this.User.can('CCM_SMC_MANAGE_DIGITAL_MEDIA');
		this.canManageMonitoring = this.User.can('TPO_SMC_ORDER_MONITORING');
	}

	getPermissionParams(account) {
		const { linkId } = account;
		const { id } = this.stateService.params;
		const tpoId = this.User.organization.type === 'TPO' ? this.User.organization.id : id;

		return {
			tpoId,
			linkId,
		};
	}

	toMonitoring() {
		if (!this.monitoringBillingEnabled) {
			this.showAccessDeniedModal();
			return;
		}

		if (!this.canManageMonitoring) {
			this.showPermissionModal();
			return;
		}

		this.stateService.go('social-compliance.monitoring-settings.company', { edit: 'true' });
	}

	postInit() {
		this.getAccountInfo()
			.then(() => {
				const nmlsId = this.isTpo ? this.User.organization.nmlsId : this.accountInfo.nmlsId;

				this.defaultFilters.nmlsId = nmlsId;

				this.socialMediaServiceParams = {
					mediaType: 'digital-media',
					entityIdType: 'NMLSID',
					entityId: nmlsId,
				};

				this.socialAccountsService.company.billing.get({
					organizationId: this.accountInfo.organizationId || this.accountInfo.nmlsId,
				}).$promise
					.then(({ enabled }) => {
						this.monitoringBillingEnabled = enabled;
					})
					.finally(() => {
						super.deferredInit();
					});
			});
	}

	getAccountInfo = (): Promise<void> =>
		new Promise((resolve) => {
			if (this.isTpo || !this.stateService.includes('**.nmls.**')) {
				this.socialAccountsService.accountInfo.get({
					id: this.isTpo ? this.User.organization.id : this.stateService.params.id,
				}, (accountInfo) => {
					this.accountInfo = accountInfo;
					resolve();
				});

				return;
			}

			this.socialAccountsService.nmlsCompanyInfo.get({
				nmlsId: this.stateService.params.id,
			}, (accountInfo) => {
				this.accountInfo = accountInfo;
				resolve();
			});
		})

	getListParams = (queryParams) => {
		const basicParams = {
			...this.getClearParams(queryParams),
			linkListType: this.apiLinkMap[queryParams.linkListType] || 'SOCIAL_ACCOUNTS',
		};

		if (this.isTpo) {
			return basicParams;
		}

		return {
			...basicParams,
			nmlsId: this.accountInfo.nmlsId,
			tpoId: this.stateService.params.id,
		};
	}

	calculateClasses() {
		super.calculateClasses();
		if (this.isRemoved || this.isLender) {
			this.columnClass.account = 'col-sm-9';
		} else if (this.isComergence) {
			this.columnClass.account = 'col-sm-4';
		} else /* isTpo*/ {
			this.columnClass.account = `col-sm-${(this.isSA ? 4 : 7)}`;
		}
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		if (!this.deferredResolved) {
			return;
		}

		return this.socialAccountsService.company.links.get(
			this.getListParams(queryParams),
		)
			.$promise
			.then((data: any): void => {
				this.list = data;
				this.isPublisherOnThePage = some(this.list, (entry: SocialAccountLink) => (entry.isPublisherActive));
			});
	}

	onConnectFinish(data?): void {
		if (
			this.User.smListingEnabled
			&& (
				data?.GMB?.pages?.names?.length
				|| data?.FB?.pages
			)
		) {
			this.stateService.go('listings.company');
			return;
		}

		super.onConnectFinish();
	}
}
