import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { QuillModule } from 'ngx-quill';
import { ScCompanyCardComponent } from 'shared/solution-center/company-card/company-card.component';
import { ScCompanyOverviewCardComponent } from 'shared/solution-center/company-overview-card/company-overview-card.component';
import { ScCocOverviewComponent } from 'shared/solution-center/company-overview-card/partials/overview/overview.component';
import { ScCocProductsComponent } from 'shared/solution-center/company-overview-card/partials/products/products.component';
import { ScCocSingleProductComponent } from 'shared/solution-center/company-overview-card/partials/products/single-product.component';
import { ScCocChannelsComponent } from 'shared/solution-center/company-overview-card/partials/channels/channels.component';

const exportComponents = [
    ScCompanyCardComponent,
    ScCompanyOverviewCardComponent,
    ScCocOverviewComponent,
    ScCocProductsComponent,
    ScCocSingleProductComponent,
    ScCocChannelsComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        QuillModule,
        PipesModule,
    ],
    declarations: [
        ...exportComponents,
    ],
    providers: [
    ],
    exports: [
        ...exportComponents,
    ],
})
export class ShareSolutionCenterModule {
}
