import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { SharedUserManagementModule } from 'shared/user-management';
import { BounceEmailService } from 'shared/bounce-email.service';

import { ComergenceCustomerUsersListComponent } from './list';
import { ComergenceCustomerUserComponent, ComergenceCustomerUserDetailsComponent } from './user';

import { ComergenceCustomerResourceService } from './comergence-customer-resource.service';
import { OrganizationResourceService } from './organization-resource.service';
import { NewContactsModule } from 'shared/new-contacts';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        BsDropdownModule.forRoot(),
        SharedUserManagementModule,
        NewContactsModule,
    ],
    declarations: [
        ComergenceCustomerUsersListComponent,
        ComergenceCustomerUserComponent,
        ComergenceCustomerUserDetailsComponent,
    ],
    providers: [
        ComergenceCustomerUsersListComponent,
        BounceEmailService,
        ComergenceCustomerResourceService,
        OrganizationResourceService,
    ]
})
export class ComergenceCustomerUserManagementModule {}
