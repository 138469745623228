import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { filter, find, forEach, values } from 'lodash';

import { HttpErrorResponse } from '@angular/common/http';

import { UserService as UService } from 'angularjs-providers/user.provider';
import { StaticValuesService } from 'angularjs-providers/static-values.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';

import {  RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';

import { IPermissionsResourceService, PublishingPermissionsResourceService } from './publishing-permissions-resource.service';

// HACK: For some weird reason this is not buildable in JIT-mode without some decorator
// UPD: Angular 9 requires explicit constructor
// @Mixin([])
export abstract class PublishingPermissionsComponent extends PagedListComponent {
	static listName = 'publishingPermissions';

	account: any = {};
	permissions: any = [];
	nonPermissionPublishers: any = [];
	User: any;
	editing: boolean = false;
	saving: boolean = false;
	isAddFormVisible: boolean = false;
	canManage: boolean = true;
	isCompany: boolean = false;
	isIndividual: boolean = false;
	newEntry;
	editAccounts: {
		changed?: boolean,
		originalPermission?: any,
		permission?: {
			id?: number,
			name?: string,
		}} = {};
	publisherPermissions: any;
	modalRef: BsModalRef;
	permissionParams: {
		tpoId: number,
		linkId: number,
		id?: number,
	};
	createParams: {
		tpoId: number,
		linkId?: number,
		id?: number,
	};
	isSomethingChangedOnEdit: boolean;
	public permissionsResource: IPermissionsResourceService;
	entryAddForm: RealmFormGroup;
	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public publishingPermissionsResourceService: PublishingPermissionsResourceService,
		public UserService: UService,
		public StaticValues: StaticValuesService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService);
		this.User = UserService.profile;
		this.setupResources();

		this.defaultFilters = {
			...this.defaultFilters,
			...this.permissionParams,
			size: 10,
		};

		this.omitParams = [
			...this.omitParams,
		];

		this.publisherPermissions = StaticValues.get({
			code: 'PublisherPermissions',
		});

		this.getNonPermissionsUsers();
		this.permissions.$resolved = false;

		this.permissionsResource.links.get(
			this.permissionParams,
		).$promise
			.then((data) => {
				this.account = data;
			});
	}

	abstract setupResources(): void;
	abstract fillNewEntry(): void;

    static throwHttpErrorResponse(status: number): void {
        throw new HttpErrorResponse({ status });
    }

	loadList(queryParams) {
		this.permissions.$resolved = false;
		return this.permissionsResource.permissions.list(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.permissions = data;

				data.forEach((item) => {
					if (!this.User.isTpo) {
						item.tpoId = this.stateService.params.id;
					}

					item.contactName = item.fullName;
					delete item.fullName;

					const account = this.editAccounts[item.id];
					if (!account) {
						this.editAccounts[item.id] = {
							...item,
							originalPermission: item.permission.id,
							changed: false,
						};
					} else if (!account.changed) {
						// set edit permission if it was not changed by user explicitly,
						// it could potentially change between pages load
						account.permission.id = account.originalPermission = item.permission.id;
					}
				});

				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			})
			.finally(() => {
				this.permissions.$resolved = true;
			});
	}

	setChanged(id) {
		this.editAccounts[id].changed = true;
	}

	setEdit(isEditing: boolean) {
		this.isAddFormVisible = false;

		const isSomethingChanged = find(values(this.editAccounts), (entry: {changed}) => {
			return entry.changed;
		});

		if (isEditing || !isSomethingChanged) {
			this.editing = !!isEditing;
			return;
		}
		this.showCancelConfirmation();
	}

	setAddUsersFormVisible(isVisible) {
		this.isAddFormVisible = !!isVisible;

		this.entryAddForm && this.entryAddForm.markAsPristine();
		if (!isVisible) {
			this.entryAddForm.reset();
		}
	}

	getNonPermissionsUsers() {
		this.nonPermissionPublishers = this.permissionsResource.noPermissions.list(this.permissionParams);
		return this.nonPermissionPublishers.$promise;
	}

	create() {
		if (this.saving === true) {
			return;
		}

		this.permissions.$resolved = false;
		this.saving = true;
		this.fillNewEntry();
		this.permissionsResource.links.create(this.createParams, this.newEntry,
			() => {
				this.setAddUsersFormVisible(false);
				forkJoin([
					this.loadList(this.params),
					this.getNonPermissionsUsers(),
				]).subscribe(() => {
					this.saving = false;
				});
			}, ({ data, status }) => {
                if (status === 403) {
                    PublishingPermissionsComponent.throwHttpErrorResponse(403);
                }
				this.saving = false;
				this.permissions.$resolved = true;
				this.entryAddForm.setServerError(data);
			});
	}

	saveAccounts() {
		if (!this.isSomethingChangedOnEdit) {
			return;
		}

		const accountsToSave = filter(values(this.editAccounts), (entry) => {
			return entry.changed === true;
		});

		this.permissions.$resolved = false;
		this.saving = true;

		this.permissionsResource.permissions.update(this.permissionParams, accountsToSave,
			() => {
				forEach(values(this.editAccounts), (entry) => {
					entry.changed = false;
				});

				forkJoin([
					this.loadList(this.params),
					this.getNonPermissionsUsers(),
				]).subscribe(() => {
					this.saving = false;
					this.editing = false;
				});
			}, ({ data, status }) => {
                if (status === 403) {
                    PublishingPermissionsComponent.throwHttpErrorResponse(403);
                }
				this.permissions.$resolved = true;
				this.saving = false;
				this.entryAddForm.setServerError(data);
			},
		);
	}

	removeAccount(item) {
		if (this.saving === true) {
			return;
		}

		const initialState = {
			title: 'Revoke Permission',
			message: 'Are you sure you want to revoke this permission?',
			confirmText: 'Confirm',
			cancelText: 'Cancel',
			onConfirm: () => {
				this.saving = true;
				this.permissions.$resolved = false;

				this.modalRef.hide();

				this.permissionsResource.permissions.remove({
						...this.permissionParams,
						userId: item.id,
					}, () => {
						forkJoin([
							this.loadList(this.params),
							this.getNonPermissionsUsers(),
						]).subscribe(() => {
							this.saving = false;
						});
					}, ({data, status}) => {
                        if (status === 403) {
                            PublishingPermissionsComponent.throwHttpErrorResponse(403);
                        }
						this.saving = false;
						this.permissions.$resolved = true;
						this.entryAddForm.setServerError(data);
					},
				);
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	showCancelConfirmation() {
		const initialState = {
			title: 'Confirm Cancellation',
			message: 'If you cancel, your changes will not be saved',
			confirmText: 'Cancel without Saving',
			cancelText: 'Return to Edit',
			onConfirm: () => {
				this.modalRef.hide();
				this.editing = !this.editing;

				forEach(values(this.editAccounts), (entry) => {
					entry.permission.id = entry.originalPermission;
					entry.changed = false;
				});
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd' });
	}
}
