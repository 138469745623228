import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { PublisherPostPreviewModule } from 'tpo/social-compliance/publisher/posts/$postType/$postId/posts-preview/post-preview.module';
import { PublisherPostViewModule } from 'tpo/social-compliance/publisher/posts/$postType/$postId/view/post-view.module';
import { PublisherPostEditModule } from 'tpo/social-compliance/publisher/posts/$postType/$postId/edit/post-edit.module';
import { CampaignsModule } from 'tpo/social-compliance/publisher/campaigns/campaigns.module';

import { CalendarResourceService } from './calendar-resource.service';
import { CalendarHeaderComponent } from './header';

import { CalendarMonthComponent } from './month';
import { CalendarPostListComponent } from './month/post-list';
import { CalendarPostEditComponent } from './month/post-edit';
import { CalendarDayComponent } from './month/day';

import { CalendarYearComponent } from './year';
import { CalendarCampaignEditComponent } from './year/campaign-edit';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        PublisherPostPreviewModule,
        PublisherPostViewModule,
        PublisherPostEditModule,
        CampaignsModule,
    ],
    declarations: [
        CalendarDayComponent,
        CalendarMonthComponent,
        CalendarYearComponent,
        CalendarPostListComponent,
        CalendarCampaignEditComponent,
        CalendarHeaderComponent,
        CalendarPostEditComponent,
    ],
    providers: [
        CalendarResourceService,
    ]
})
export class PublisherCalendarModule {}
