import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';
import { QuestionnairesService } from 'shared/questionnaires/questionnaires.service';
import { QuestionnairesListEntry } from 'shared/questionnaires/questionnaires.interface';
import { TpoDocumentListComponent } from 'tpo/documents';

type DocumentLinkParams = {
	lenderId: number;
	channelId?: number[];
	questionnaireId: number;
}

@Component({
	templateUrl: './client-assigned-questionnaires-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ClientAssignedQuestionnairesListComponent) },
	],
	selector: 'client-assigned-questionnaires',
})
export class ClientAssignedQuestionnairesListComponent extends ListComponent {
	static listName = 'client-assigned-questionnaires';

	@Input() hasOwnLoader = true;
	@Output('onLoaded') _onLoaded = new EventEmitter<boolean>();

	User: UserProfile;
	list: NgResourceArray<QuestionnairesListEntry & { documentsLink?: string; }> = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		private questionnairesService: QuestionnairesService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	loadList(queryParams) {
		this._setLoaded(false);
		return this.questionnairesService.getAllInProgress(
			this.getClearParams({
				...queryParams,
				tpoId: this.User.organization.id,
			}),
			(data) => {
				this.list = data.map((questionnaire) => {
					const data: DocumentLinkParams = {
						lenderId: questionnaire.lender?.lenderId,
						questionnaireId: questionnaire.questionnaireId,
					}

					if (questionnaire.channel) {
						data.channelId = [ questionnaire.channel?.id ];
					}
					// prepare link to documents on data fetch
					return {
						...questionnaire,
						documentsLink: this.stateService.href('documents.list', {
							[TpoDocumentListComponent.listName]: data,
						}),
					};
				});
			}
		).$promise
			.finally(() => {
				this._setLoaded(true);
			});
	}

	_setLoaded(isLoaded: boolean): void {
		this.list.$resolved = isLoaded;

		this._onLoaded.emit(isLoaded);
	}
}
