import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { TaskNotificationModule} from 'shared/social-compliance/task-notification';
import { SupportingDocumentsModule } from 'shared/supporting-documents';

import { TpoArchiveModule } from 'tpo/social-compliance/archive';
import { TasksModule } from 'tpo/social-compliance/tasks';

import { FindingsService } from './findings.service';

import {
	NewSocialMediaLinkComponent,
	NewReviewFindingComponent,
	NewContactFindingComponent,
} from './new';
import {
	FindingService,
	FindingComponent,
	FindingDetailsComponent,
	StatusModalComponent,
	FindingSupportingDocumentsComponent,
	IssueListComponent,
	IssueComponent,
	TaskComponent,
	PostPreviewComponent,
	FindingScreenshotComponent,
	FindingModelService,
	PostComponent,
	FindingAuditLogComponent,
	FindingAuditLogDetailsModalComponent,
	IssueEditModalComponent,
	FindingChecklistModule,
} from './$findingId';

const components = [
	NewReviewFindingComponent,
	NewContactFindingComponent,
	FindingComponent,
	StatusModalComponent,
	FindingAuditLogComponent,
	FindingAuditLogDetailsModalComponent,
	IssueEditModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        FindingChecklistModule,
        PipesModule,
        TpoArchiveModule,
        TasksModule,
        TaskNotificationModule,
        SupportingDocumentsModule,
    ],
    declarations: [
        ...components,
        NewSocialMediaLinkComponent,
        FindingDetailsComponent,
        FindingSupportingDocumentsComponent,
        IssueListComponent,
        IssueComponent,
        TaskComponent,
        PostPreviewComponent,
        FindingScreenshotComponent,
        PostComponent,
    ],
    providers: [
        FindingsService,
        FindingService,
        FindingModelService,
    ]
})
export class SharedFindingsModule {}
