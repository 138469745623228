import { Component, OnInit, forwardRef } from '@angular/core';
import { TransitionService, StateService} from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { map, find } from 'lodash';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';

import { GroupsResourceService } from '../../../groups-resource.service';

@Component({
	templateUrl: './group-contacts-manage.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => GroupContactsManageComponent) },
	],
})
export class GroupContactsManageComponent extends PagedListComponent implements OnInit {
	static listName = 'groupContactsManage';

	contacts: any = [];
	selectedContactIds: any = [];
	updatedContacts: any = [];
	modalRef: BsModalRef;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public modalService: BsModalService,
		public groupsResource: GroupsResourceService,
	) {
		super(transitionService, stateService);

		const { groupId } = stateService.params;

		this.defaultFilters = {
			...this.defaultFilters,
			size: 10,
			branchId: [],
			state: [],
			groupId,
		};

		this.omitParams = [
			...this.omitParams,
			'groupId',
		];

		this.filters = {
			state: this.groupsResource.groupContactCandidates.getStates({ groupId }),
			branchId: [],
		};

		// get all selected contacts
		this.groupsResource.groupContacts.getIds({ groupId }).$promise
			.then((data) => {
				this.selectedContactIds = data;
			});
	}

	ngOnInit() {
		super.ngOnInit();

		this.getBranches(this.params.state);
	}

	setFilter(filters, resetPage: boolean = true) {
		if (filters.branchId) {
			filters.branchId = map(filters.branchId, 'id');
		}

		if (filters.state) {
			filters.state = map(filters.state, 'id');
			if (filters.state.length) {
				this.getBranches(filters.state);
				filters.branchId = [];
			} else {
				filters.state = [];
				filters.branchId = [];
			}
		}

		super.setFilter(filters, resetPage);
	}

	getBranches(state) {
		const { groupId } = this.stateService.params;
		this.filters.branchId = this.groupsResource.groupContactCandidates.getBranches({ groupId, state });
	}

	loadList(queryParams) {
		this.contacts.$resolved = false;
		return this.groupsResource.groupContactCandidates.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.contacts = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	toggleContact(contact) {
		const { contactId } = contact;

		const selectedContactIndex = this.selectedContactIds.indexOf(contactId);
		if (selectedContactIndex !== -1) {
			this.selectedContactIds.splice(selectedContactIndex, 1);
		} else {
			this.selectedContactIds.push(contactId);
		}

		const updatedContactsItem = find(this.updatedContacts, { contactId }) as any;
		if (updatedContactsItem) {
			updatedContactsItem.isSelected = !updatedContactsItem.isSelected;
		} else {
			this.updatedContacts.push({
				...contact,
				isSelected: !contact.isSelected,
			});
		}
	}

	save(forceUpdate = false) {
		const { groupId } = this.stateService.params;
		this.contacts.$resolved = false;

		const params = { groupId } as any;
		if (forceUpdate) {
			params.forceUnenroll = true;
		}

		this.groupsResource.groupContacts.update(
			params,
			this.updatedContacts,
		).$promise
			.then(() => {
				this.stateService.go('^', {sort: null, page: null, size: null});
			}).catch((e) => {
				if (e.status !== 400) {
					return;
				}

				this.confirmRemoval(e.data && e.data.message);
			});
	}

	confirmRemoval(message) {
		this.contacts.$resolved = true;
		const initialState = {
			message,
			onCancel: () => {
				this.modalRef.hide();
			},
			onConfirm: () => {
				this.contacts.$resolved = false;
				this.modalRef.hide();
				this.save(true);
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd' });
	}
}
