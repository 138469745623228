import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { NotificationModalComponent } from 'commons/components/modals';
import { NgResourceArray } from 'commons/interfaces';

import { CustomFieldsService, FieldsEntity } from '../custom-fields.service';

@Component({
	templateUrl: './custom-fields-entities.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CustomFieldsEntitiesComponent) },
	],
})
export class CustomFieldsEntitiesComponent extends PagedListComponent {
	static listName = 'custom-fields-entities';
	User: UserProfile;

	list: NgResourceArray<FieldsEntity> = [];
	modalRef: BsModalRef;

	constructor(
		transitionService: TransitionService,
		public stateService: StateService,
		{ profile }: UserService,
		public modalService: BsModalService,
		public fieldsService: CustomFieldsService,
	) {
		super(transitionService, stateService);

		this.User = profile;
	}

	async loadList(queryParams: unknown): Promise<void> {
		this.list.$resolved = false;
		this.list = await this.fieldsService.entities(this.getClearParams(queryParams)).$promise;
	}

	showPermissionModal = (): void => {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Sorry! Access Denied',
				confirmText: 'Ok',
				notification: `<div class="denied-lock"></div>
					<p>You must have the Concierge Service activated in order to access.</p>
				`,
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-403',
		});
	}

	checkPermission(item: FieldsEntity): void  {
		if (item.permissionType === 'CRM' && !this.User.crmEnabled) {
			this.showPermissionModal();
			return;
		}
	}
}
