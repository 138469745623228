import { Component, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { forEach, omitBy, omit } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { DocumentsServiceInterface } from '../../documents.service.interface';

declare let apiPath: string;

@Component({
	templateUrl: './document-history.component.html',
})
export class SupportDocumentHistoryComponent extends PagedListComponent implements OnInit {
	static listName = 'supportDocumentHistory';

	list: any = [];
	document: any;
	documentService: DocumentsServiceInterface;

	constructor(
		public User: UserService,
		public modalRef: BsModalRef,
		transitionService: TransitionService,
		stateService: StateService,
	) {
		super(transitionService, stateService);

		// NOTICE: this component rendering in pop-up,
		// no need to write params to url
		this.useLocation = false;
	}

	ngOnInit() {
		this.defaultFilters = {
			...this.defaultFilters,
			...this.params,
			docId: this.document.id,
		};

		super.ngOnInit();
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.documentService.getDocumentHistory(this.getClearParams(queryParams)).$promise
			.then((result) => {
				this.list = result;
				forEach(this.list, (document) => {
					document.historyLink = this.getDocumentLink(document);
				});
			});
	}

	getClearParams(queryParams) {
		return omitBy(omit(queryParams, '#', '$inherit', 'actionType'), (value) => (!value));
	}

	getDocumentLink(item) {
		const { id } = item;
		return this.documentService.getDocumentHistoryDownloadLink({ ...this.params, id })
	}
}
