import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { map, mapValues } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { ClientDocument } from '../client-documents.interface';
import { ClientDocumentsService } from '../client-documents.service';

@Component({
	selector: 'client-document-list',
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ClientDocumentListComponent) },
	],
})
export class ClientDocumentListComponent extends PagedListComponent implements OnInit {
	static listName = 'clientDocumentList';
	list: NgResourceArray<ClientDocument> = [];
	user: UserProfile;
	isEditable = false;

	@Input() tpoId?: number;
	@Input() inline?: boolean;
	@Input('filters') additionalFilters: Record<string, any> = [];

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		private documentsService: ClientDocumentsService,
	) {
		super(transitionService, stateService);

		this.user = userService.profile;
	}

	async ngOnInit(): Promise<void> {
		if (!this.tpoId) {
			const { id: tpoId } = this.uiRouterGlobals.params;
			this.tpoId = tpoId;
		}

		try {
			const { value } = await this.documentsService.documents.isEditable({
				tpoId: this.tpoId,
			}).$promise;
			this.isEditable = value;
		} catch (e) {}

		this.configureList();

		super.ngOnInit();
	}

	configureList(): void {
		const { tpoId } = this;
		Object.assign(
			this.filters,
			{
				channelId: this.documentsService.getClientSpecificChannels({ tpoId }),
				questionnaireId: this.documentsService.getClientSpecificQuestionnaires({ tpoId }),
			},
		);

		Object.assign(
			this.defaultFilters,
			{
				tpoId,
				size: 25,
				channelId: [],
				questionnaireId: [],
			},
			this.additionalFilters,
		);

		this.omitParams.push('tpoId', Object.keys(this.additionalFilters));
	}

	setFilter(filters) {
		const processedFilters = mapValues(filters, (value, key) => {
			switch (key) {
				case 'channelId':
					return map(value, 'id');
				case 'questionnaireId':
					return value?.id;
				default:
					return value;
			}
		});
		super.setFilter(processedFilters);
	}

	async loadList(queryParams) {
		this.list.$resolved = false;
		try {
			this.list = await this.documentsService.getDocuments(
				this.getClearParams(queryParams),
			).$promise;
		} catch (e) {}
		this.list.$resolved = true;
	}

    reloadPage() {
        this.loadList(this.params);
    }
}
