import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { SocialMediaModule } from 'shared/social-media';

import { SocialAccountsModule } from 'tpo/social-accounts';

import { PostsTypeListModule } from '../posts-type-list';

// Services
import { CampaignsResourceService } from './campaigns-resource.service';
import { CampaignService } from './$campaignId/campaign.service';

// Components
import {
	CampaignsListComponent,
	EnrollAccountsModalComponent,
} from './list';

import {
	CampaignComponent,
	CampaignEditComponent,
	CampaignDetailsComponent,
	CampaignTemplatesComponent,
	CampaignFolderComponent,
	CampaignParticipantsComponent,
	CampaignShareToEditComponent,
	CampaignShareToViewComponent,
} from './$campaignId';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        PostsTypeListModule,
        SocialMediaModule,
        SocialAccountsModule,
    ],
    declarations: [
        CampaignsListComponent,
        EnrollAccountsModalComponent,
        CampaignComponent,
        CampaignEditComponent,
        CampaignDetailsComponent,
        CampaignTemplatesComponent,
        CampaignFolderComponent,
        CampaignParticipantsComponent,
        CampaignShareToEditComponent,
        CampaignShareToViewComponent,
    ],
    providers: [
        CampaignsResourceService,
        CampaignService,
    ],
    exports: [
        CampaignShareToEditComponent,
        CampaignShareToViewComponent,
    ]
})
export class CampaignsModule {}
