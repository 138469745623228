<div class="remote-resource" [class.resolved]="resolved">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
        <h4 class="modal-title">Authorization Request <ng-container *ngIf="request"> - {{ request.investorFromName }}</ng-container></h4>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <div>
                {{ request?.message }}
            </div>
            <br><br>
            <label class="checkbox-inline">
                <input type="checkbox" formControlName="check"/>
                I understand and acknowledge that <b>{{User.organization.name}}</b> confidential information will be shared with <b>{{request?.investorToName}}</b>.
            </label>
        </form>
    </div>
    <div class="modal-footer text-right">
        <button class="btn btn-primary" [disabled]="this.form.invalid" (click)="accept()">Accept</button>
        <button class="btn btn-danger-wired" (click)="decline()">Decline</button>
    </div>
</div>
