import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { NgResourceObject } from 'commons/interfaces';
import { RealmFormGroup, RealmFormControl } from 'commons/forms';
import { NumberValidator, PhoneValidator } from 'commons/validators';

import { accountInformationConfig } from 'shared/account/company-information/details/account-information.config'
import { AccountInformationService, AccountInformation } from 'shared/account/company-information/account-information.service';
import { EntityType } from 'shared/fields/custom-fields.service';

@Component({
	templateUrl: './account-information.component.html',
})

export class AccountInformationComponent implements OnInit, OnDestroy {
	@Input() id?: number;
	@Input() isRegistered?: boolean;
	@Input() hasAccess?: boolean = true;
	@Input() isPreviewMode?: boolean = false; //@TODO: remove it with account section
    @Input('lenderInfo') lenderInfo: AccountInformation;

    protected destroyed$ = new Subject<void>();
	permission: boolean;
	canEditForm: boolean;

	editing: boolean = false;
	saving: boolean = false;

	User: UserProfile;
	details: NgResourceObject<AccountInformation>;
    entityTypeId: EntityType;

	detailsForm = new RealmFormGroup({
		phone: new RealmFormControl(
			'phone',
			{ label: 'Company Phone' },
			Validators.compose([Validators.required, PhoneValidator()]),
		),
		fax: new RealmFormControl(
			'fax',
			{ label: 'Company Fax' },
			PhoneValidator('Company Fax should be 10 digits'),
		),
		businessTaxId: new RealmFormControl(
			'businessTaxId',
			{ label: 'Business Tax ID' },
			Validators.compose([
				Validators.required,
				NumberValidator('Business Tax ID should be 9 digits', 9),
			]),
		),
		mersId: new RealmFormControl(
			'mersId',
			{ label: 'MERS ID' },
		),
		fDICNCUACertificationNumber: new RealmFormControl(
			'fDICNCUACertificationNumber',
			{ label: 'FDIC / NCUA Certificate' },
		),
	});

	constructor(
		public stateService: StateService,
		public accountInformation: AccountInformationService,
		public userService: UserService,
		public routerGlobals: UIRouterGlobals,
	) {
		this.User = userService.profile;
		const permissionKey = accountInformationConfig[this.User.organization.type];
		this.permission = permissionKey ? this.User.can(permissionKey.permissions.editing) : true;
		this.canEditForm ??= permissionKey?.canEditForm;
        this.entityTypeId = this.User.isComergence ? 'CCM_TPO' : 'Company';
	}

	ngOnInit(): void {
		this.isRegistered ??= !this.stateService.includes('**.nmls.**'); // ?? if null or undefined
		this.id ??= this.routerGlobals.params.id || this.User.organization.id;
		this.loadData();
	}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    loadData(): void {
		this.details = this.lenderInfo;
		this.detailsForm.reset({ ...this.details });
	}

	save(): void {
		if (this.saving) {
			return;
		}

		this.saving = true;

		Object.assign(
			this.details,
			this.detailsForm.getRawValue(),
		);

		this.accountInformation.update(this.details)
			.$promise.then((response) => {
				this.detailsForm.reset({ ...response });
				this.toggleMode();
				this.saving = false;
			}, ({data: response}) => {
				this.detailsForm.setServerError(response);
				this.saving = false;
			});
	}

	cancel(): void {
		this.detailsForm.reset({
			...this.details,
		});
		this.toggleMode();
	}

	toggleMode(): void {
		this.editing = !this.editing;
	}

	public hasError(controlName: string): boolean {
		if (!this.editing) {
			return false;
		}

		const { invalid, touched } = this.detailsForm.get(controlName);
		return invalid && touched;
	}

    getInputValue = ({ target }: Event): string => (target as HTMLInputElement).value;
}
