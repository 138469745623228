import { forIn, map } from 'lodash';
import { Component, forwardRef, Injector } from '@angular/core';
import { IPromise } from 'angular';
import { StateService, TransitionService } from '@uirouter/core';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { NgResourceArray } from 'commons/interfaces';

import { ReviewWidgetService, Widget } from './widgets.service';
import { ReviewWidgetResourceService } from './widgets-resource.service';
import { widgetFilters } from './widget-filters'

@Component({
	templateUrl: './widgets.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => WidgetsListComponent) },
	],
})
export class WidgetsListComponent extends PagedListComponent {
	static listName = 'widgets-list';

	widgets: NgResourceArray<Widget> = [];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public injector: Injector,
		public widgetsService: ReviewWidgetService,
		public reviewWidgetResourceService: ReviewWidgetResourceService,
	) {
		super(transitionService, stateService);
	}

	ngOnInit(): void {
		this.configureFilters();
		super.ngOnInit();
	}

	configureFilters(): void {
		const { entityTypes } = widgetFilters;

		this.filters = {
			accountTypes: this.widgetsService.widgetsAccountTypes(),
			entityTypes,
		};

		this.defaultFilters = {
			...this.defaultFilters,
			accountTypes: [],
			entityTypes: [],
		};
	}

	loadList(params): IPromise<void> {
		this.widgets.$resolved = false;
		const normalizedParams = this.reviewWidgetResourceService.getParamsFromSources(params);

		return this.widgetsService.list(this.getClearParams(normalizedParams)).$promise
			.then((data: NgResourceArray<Widget>) => {
				this.widgets = data;
			}).finally(() => {
				this.widgets.$resolved = true;
			});
	}

	setFilter(params, resetPage: boolean = true): void {
		forIn(params, (value, key) => {
			switch (key) {
				default:
					params[key] = map(value || [], 'id');
			}
		});

		super.setFilter(params, resetPage);
	}
}
