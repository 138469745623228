import { isFunction } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const defaultMessage = 'Not valid link';

type LinkTemplateStringFn = (value: string | number | null) => string;

export function SmLinkValidator(regex: RegExp, messageTemplate: string | LinkTemplateStringFn = ''): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
        const isValid = !value || !!`${value}`.match(regex);
        const message = isFunction(messageTemplate) ? messageTemplate(value) : messageTemplate;

        return isValid ? null : {
            phone: {
                value,
                message: message || defaultMessage,
            },
        };
    };
}
