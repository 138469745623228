import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { ClientProduct } from 'comergence/tpo/billing/client-products/client-product.interface';
declare let apiPath;

//TODO: change service once BE is ready
@Injectable()
export class ClientProductsService {
    constructor(
        protected http: RealmHttpClient,
    ) {
    }

    getClientProductList(tpoId: number): ClientProduct[] {
        return clientProducts;
    }
}

//TODO: remove it once BE is ready
const clientProducts: ClientProduct[] = [
    {
        productId: 1,
        companyName: 'Social Media Audits',
        productName: 'Reach150',
        purchased: 'Invoiced',
        users: 2,
    },
    {    productId: 2,
        companyName: 'Social Media Audits',
        productName: 'Reach150',
        purchased: 'Invoiced',
        users: 2,
    },
]
