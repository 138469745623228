import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { ListComponent } from 'commons/components/list';
import { HistoryDocumentListComponent } from 'shared/documents';

import { GlobalHistoryDocument } from '../../documents.interface';
import { GlobalDocumentsService } from '../../documents.service';

@Component({
	templateUrl: '../../../../shared/documents/document-history/document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => GlobalDocumentHistoryComponent) },
	],
})
export class GlobalDocumentHistoryComponent extends HistoryDocumentListComponent<GlobalHistoryDocument> {
	static listName = 'globalDocumentHistory';

	listResource = this.documentsService.getDocumentHistory;

	configureList = (): void => {
		const {
			documentId,
		} = this.uiRouterGlobals.params;
		Object.assign(
			this.defaultFilters,
			{
				documentId,
			},
		);

		this.omitParams.push('documentId');
	};

	getDownloadLink = (params) => this.documentsService.getTemplateDownloadLink(params);

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		transitionService: TransitionService,
		stateService: StateService,
		private documentsService: GlobalDocumentsService,
	) {
		super(transitionService, stateService);
	}
}
