import { map, mapValues, reduce } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list';

import { ListComponent } from 'commons/components/list/list.component';

import { LenderAlertsService } from 'lender/alerts/alerts.service';

import { AlertsModalComponent } from '../alerts-modal';

const calculateBatchItems = (num, item) => {
	num += item.batchChecked ? 1 : 0;
	return num;
};

@Component({
	templateUrl: './alerts.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => AlertsComponent) },
	],
})
export class AlertsComponent extends PagedListComponent {
	static listName = 'alerts';

	list: any = [];
	User: UserProfile;
	modalRef: BsModalRef;
	moreFilters = false;
	batchEditing: boolean;
	batch: boolean;
	batchCount = 0;
	statuses = {
		UNDER_REVIEW: 'Under Review',
		NEW: 'New',
		CLOSED: 'Closed',
	};

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		public modalService: BsModalService,
		public lenderAlertsService: LenderAlertsService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		const { id: tpoId } = stateService.params;

		this.filters = {
			status: map(this.statuses, (value, key) => {
				return {id: key, name: value};
			}),
			channel: this.lenderAlertsService.channels({ activeOnly: true }),
			createdDateFrom: null,
			createdDateTo: null,
			typeId: this.lenderAlertsService.types(),
			category: [],
			licenseType: [],
		};

		tpoId && (this.defaultFilters.tpoId = tpoId);
		this.defaultFilters = {
			...this.defaultFilters,
			status: null,
			channel: null,
			createdDateFrom: null,
			createdDateTo: null,
			typeId: null,
			category: null,
			licenseType: null,
		};
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.lenderAlertsService.list(this.processParams(queryParams))
			.$promise
			.then((data: any): void => {
				this.list = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	setFilter(filters, resetPage: boolean = true) {
		const pageParams = resetPage ? { page: 0 } : {};

		mapValues(filters, (value: any, key: string) => {
			if (key === 'typeId') {
				filters.typeId = filters.typeId && filters.typeId.id;
				if (filters.typeId && filters.typeId.length > 0) {
					this.filters.category = this.lenderAlertsService.subtypes({ typeId: filters.typeId });
					this.filters.licenseType = this.lenderAlertsService.licenseTypes({ typeId: filters.typeId });
				} else {
					filters.typeId = null;
					filters.category = null;
					filters.licenseType = null;
				}
			} else if (['createdDateFrom', 'createdDateTo'].indexOf(key) === -1) {
				filters[key] = map(value || [], 'id');
			}
		});

		this.params$.next({ ...this.params$.getValue(), ...pageParams, ...filters });
	}

	processParams(params) {
		return mapValues(this.getClearParams(params), (value, key) => {
			if (['createdDateFrom', 'createdDateTo'].indexOf(key) !== -1) {
				return (value && value.getTime) ? value.getTime() : value;
			} else {
				return value;
			}
		});
	}

	unBatch() {
		this.batch = false;
		this.batchCount = reduce(this.list, calculateBatchItems, 0);
	}

	batchAll() {
		this.list.forEach((item, i, list) => {
			item.batchChecked = this.batch;
			if (i === list.length - 1) {
				this.batchCount = reduce(this.list, calculateBatchItems, 0);
			}
		});
	}

	proceed() {
		const initialState = {
			statuses: this.filters.status,
			onConfirm: (alertStatus) => {
				this.modalRef.content.resolving = true;

				const newStatuses = reduce(this.list, (memo, { batchChecked, alertId }) => {
					if (batchChecked) {
						memo.push({ alertId, alertStatus });
					}
					return memo;
				}, []);

				this.lenderAlertsService.update(newStatuses).$promise.then(() => {
					this.loadList(this.processParams(this.params)).then(() => {
						this.batchEditing = false;
						this.modalRef.content.resolving = false;
						this.modalRef.hide();
						this.batchCount = 0;
					});
				}, ({data}) => {
					this.modalRef.content.resolving = false;
					this.modalRef.content.alertsForm.setServerError(data);
				});
			},
		};

		this.modalRef = this.modalService.show(AlertsModalComponent, {
			initialState,
			class: 'modal-smd modal-new modal-restore-link',
			backdrop: 'static',
		});
	}

	toggleFilters() {
		this.moreFilters = !this.moreFilters;
	}
}
