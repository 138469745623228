import { differenceWith, keys, last, map } from 'lodash';
import { Component } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { PagedListComponent } from 'commons/components/list';
import { NgPagedResource, NgResourceArray } from 'commons/interfaces';

import { afterOption, Question, QuestionBankCategory, QuestionType } from '../../../questionnaires.interface';
import { LenderManageQuestionnaireService } from '../../../questionnaires.service';
import { getAfterOptions, getHints } from './question-places';


@Component({
	selector: 'question-from-bank',
	templateUrl: './from-bank.component.html',
})
export class QuestionFromBankComponent extends PagedListComponent {
	static listName = 'question-from-bank';

	QuestionType = QuestionType;
	list: Array<Question & { checked?: boolean }> = [];
	categories: NgPagedResource<QuestionBankCategory> = [];
	addedQuestions: NgResourceArray<Question> = [];

	resolving: boolean;
	questionnaireId: number;
	afterOptions: afterOption[] = [{ i: 0, name: 'First' }];
	hints: Record<number, string>;
	place = 0;

	checkedIds: Record<number, boolean> = {};
	checkedCount: number = 0;

	constructor(
		transitionService: TransitionService,
		public stateService: StateService,
		protected questionnairesService: LenderManageQuestionnaireService,
		{ params: { questionnaireId } }: UIRouterGlobals,
	) {
		super(transitionService, stateService);

		this.questionnaireId = questionnaireId;
		this.categories = this.questionnairesService.bankCategories();
	}

	async loadList(queryParams): Promise<void> {
		this.resolving = true;

		try {
			await (
				this.addedQuestions = this.questionnairesService.questions({ questionnaireId: this.questionnaireId })
			).$promise;
			const bankQuestions: NgPagedResource<Question> = await this.questionnairesService.bankQuestions(
				this.getClearParams(queryParams)
			).$promise;
			this.list = differenceWith(
				bankQuestions,
				this.addedQuestions,
				({ id }, { questionBankQuestionId }) => id === questionBankQuestionId,
			);
			this.placeInit();
		} catch (e) {
			// error
		}

		this.resolving = false;
	}

	placeInit(): void {
		this.afterOptions = [this.afterOptions[0], ...getAfterOptions(this.addedQuestions)];
		this.hints = getHints(this.afterOptions);

		this.place = last(this.afterOptions).i;
	}

	async add(): Promise<void> {
		if (!this.checkedCount) {
			return;
		}

		this.resolving = true;

		const questionBankIds = keys(this.checkedIds).map((id) => parseInt(id, 10));

		try {
			await this.questionnairesService.addQuestionsFromBank(
				{ questionnaireId: this.questionnaireId },
				{
					afterQuestionId: this.afterOptions[this.place].id,
					questionBankIds,
				},
			).$promise;

			this.stateService.go(
				'questionnaires.:questionnaireId.questions',
				{ action: 'edit', questionnaireId: this.questionnaireId }
			);
		} catch (e) {
			this.resolving = false;
		}
	}

	setCategories(categories: QuestionBankCategory[]): void {
		const categoriesIds: number[] = map(categories, 'id');

		this.setFilter({ categoryId: categoriesIds });
	}

	checkQuestion(id: number, value: boolean | undefined): void {
		if (!value) {
			delete this.checkedIds[id];
		} else {
			this.checkedIds[id] = true;
		}

		this.checkedCount = keys(this.checkedIds).length;
	}

	clear(): void {
		this.checkedIds = {};
		this.checkedCount = 0;
	}
}
