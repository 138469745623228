import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LenderChannelContactInfo } from 'tpo/solution-center/tpo-sc-types';
import { Observable } from 'rxjs';

@Component({
	templateUrl: './contact-information-modal.component.html',
})
export class ContactInformationModalComponent {

    lenderChannelContactInfo: Observable<LenderChannelContactInfo>;

	public constructor(private readonly modalRef: BsModalRef) {
	}

    public hide(): void {
        this.modalRef.hide();
    }
}
