import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';
import { LenderHomeService, ShareRequestApprovalRequest } from 'lender/home/lender-home.service';
import { InvestorTerminatedLendersAcceptModalComponent } from './investor-terminated-lenders-accept.modal.component';

@Component({
    selector: 'investor-terminated-lenders-accept',
    templateUrl: './investor-terminated-lenders-accept.component.html',
})
export class InvestorTerminatedLendersAcceptComponent implements OnInit, OnDestroy {
    @Output('count') count$ = new EventEmitter<number>();
    investorId: number;
    modalRef: BsModalRef;
    refresh$ = new Subject<void>();
    requests$: Observable<ShareRequestApprovalRequest[]>;

    constructor(
        private readonly lenderHomeService: LenderHomeService,
        private readonly modalService: BsModalService,
        private readonly userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.investorId = this.userService.profile.organization.id;

        this.requests$ = this.refresh$.pipe(
            startWith(true),
            tap(() => this.count$.emit(Infinity)),
            switchMap(() => this.lenderHomeService.getShareRequestsApprovals()),
            shareReplay(1),
            tap((list) => this.count$.emit(list.length)),
        );
    }

    ngOnDestroy() {
        this.refresh$.complete();
        this.modalRef?.hide();
    }

    reviewRequest({ id, channelFrom, approvalStatus, approvalAssigned, renewalDate }: ShareRequestApprovalRequest) {
        const initialState: Partial<InvestorTerminatedLendersAcceptModalComponent> = {
            id,
            channelFrom,
            approvalStatus,
            approvalAssigned,
            renewalDate,
            onFinalize: () => this.refresh$.next(),
        };
        this.modalRef = this.modalService.show(
            InvestorTerminatedLendersAcceptModalComponent, {
                class: 'modal-new modal-smd',
                initialState,
            }
        );
    }
}
