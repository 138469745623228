import { Component, Input } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { flatten, flatMap, merge, map } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { RealmFormArray } from 'commons/forms/form-array.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

interface GroupColumnConfig {
	label: string;
	fieldName: string;
}

@Component({
	selector: 'issues-list',
	templateUrl: './issues-list.component.html',
})
export class IssuesListComponent extends ListComponent {
	static listName = 'issues';

	User: any;
	issues: any = [];
	issuesAllSelected: boolean[] = [];
	issuesForm = new RealmFormArray([]);
	editing: boolean;
	resolving: boolean;

	@Input() filtersConfig: any;
	@Input() fetchIssues: any;
	@Input() updateIssues: any;
	@Input() groupColumnConfig: GroupColumnConfig = {
		label: 'Group',
		fieldName: 'group',
	};

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	loadList(queryParams) {
		this.issues.$resolved = false;
		return this.fetchIssues(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.issues = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			})
			.finally(() => {
				this.issues.$resolved = true;
			});
	}

	// setFilter = (filterName) =>
	// 	(filterValue): void => {
	// 		this.params$.next({
	// 			...this.params$.getValue(),
	// 			[filterName]: filterValue,
	// 		});
	// 	}

	initForm(): void {
		const {
			issues,
			issuesForm: form,
		} = this;

		// console.log(issues);

		issues.forEach(({ checklistObservations }): void => {
			const issueGroup = new RealmFormControl(
				'observations',
				{
					value: checklistObservations.reduce((acc, { organizationChecklistId, turnedOn }) => {
						if (turnedOn) {
							acc.push(organizationChecklistId);
						}

						return acc;
					}, []),
				},
			);

			this.issuesAllSelected.push(this.getAllSelected(issueGroup.value, { checklistObservations }));
			form.push(issueGroup);
		});
	}

	getAllIds({ checklistObservations }): number[] {
		return map(checklistObservations, 'organizationChecklistId');
	}

	getAllSelected = (checklistObservations: number[], { checklistObservations: originalObservations }): boolean => {
		return checklistObservations.length === originalObservations.length;
	}

	checkboxClick(index: number) {
		this.issuesAllSelected[index] = this.getAllSelected(this.issuesForm.at(index).value, this.issues[index]);
	}

	toggleSelectAll(index: number): void {
		const newValue = this.issuesAllSelected[index] ? [] : this.getAllIds(this.issues[index]);
		this.issuesForm.at(index).patchValue(newValue);
		this.issuesAllSelected[index] = !this.issuesAllSelected[index];
	}

	resetForm(): void {
		const { issuesForm: form } = this;

		this.issuesAllSelected = [];
		form.clear(); // remove controls from formArray
		form.reset(); // remove server errors
	}

	toggle(editing = !this.editing): void {
		this.editing = editing;

		if (editing) {
			this.initForm();
		} else {
			this.resetForm();
		}
	}

	submit(): void {
		const {
			issues,
			issuesForm: form,
		} = this;
		const selectedObservations = flatten(form.value);
		const updatedIssues = issues.map((issue) => ({
			...issue,
			checklistObservations: issue.checklistObservations.map((observation) => ({
				...observation,
				turnedOn: selectedObservations.includes(observation.organizationChecklistId),
			})),
		}));
		const body = flatMap(updatedIssues, 'checklistObservations');

		this.resolving = true;
		this.updateIssues(body).$promise
			.then(() => {
				merge(issues, updatedIssues);
				this.toggle(false);
			})
			.catch(({ data }) => {
				form.setServerError(data);
			})
			.finally(() => {
				this.resolving = false;
			});
	}
}
