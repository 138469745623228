import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { map, mapValues, assign, omit, isEqual } from 'lodash';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ColumnsManagerMixin } from 'commons/mixins';
import { ListComponent, PagedListComponent } from 'commons/components/list';

import { Mixin } from 'utils/mixin.decorator';

import { AuditsService } from '../audits.service';
import { statusesConfig } from '../../statusesConfig';
import { columnsConfig } from './columns-config';
import { Audit, AuditReview } from '../audits.service.interface';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuditAssignedUserModalComponent } from './audit-assigned-user-modal/audit-assigned-user-modal.component';
import { AuditStatusModalComponent } from '../info';
import { BulkAuditStatusModalComponent } from './audit-status-modal/audit-status-modal.component';

export const hasPermissions = (User: UserProfile): boolean => (
	User.can('CCM_SMC_VIEW_ALL') || User.can('TPO_SMC_VIEW_ALL')
);

@Mixin([ ColumnsManagerMixin ])
@Component({
	templateUrl: './audits-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => AuditsListComponent) },
	],
})
export class AuditsListComponent extends PagedListComponent implements OnInit, ColumnsManagerMixin {
	static listName = 'audits';

	User: UserProfile;
	helpLink = '/help/authenticated/tpo/smc/review-board';
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	statusesConfig = statusesConfig;
	list: any = [];

	initialFilters = {};

	StorageService;
	localStorageName = 'AuditList';
	columns = new Map();
	columns$;
    columnValues: () => [];
	prepareColumns;
	toggleColumn;
	getColumnVisibility;

    canEdit: boolean;
    editing: boolean = false;
    selectedAudits: Audit[] = [];
    someAuditsAreSelected: boolean = false;

	@Input() reviewStatuses;
	@Input() auditStatuses;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public auditsService: AuditsService,
        public bsModalService: BsModalService,
	) {
		super(transitionService, stateService);

		this.readColumnsConfig();
		this.User = userService.profile;
        this.canEdit = this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE');
	}

	ngOnInit(): void {
		if (this.hasPermissions()) {
			this.configureFilters();

			this.setInitialFilters();

			super.ngOnInit();
		}
	}

	isSomeFiltersApplied = (queryParams) => {
		return !isEqual(omit(queryParams, this.omitParams), omit(this.initialFilters, this.omitParams));
	}

	resetFilters() {
		this.params$.next({...this.params, ...omit(this.initialFilters, this.omitResetParams)});
	}

	configureFilters(): void {
		this.filters = {
			assigned: this.auditsService.assignees(),
			reviewStatus: this.reviewStatuses,
			auditStatus: this.auditStatuses,
			dateFrom: null,
			dateTo: null,
		};

		this.defaultFilters = {
			...this.defaultFilters,
			assigned: [],
			reviewStatus: [],
			auditStatus: [],
			dateFrom: null,
			dateTo: null,
		};
	}

	readColumnsConfig() {
		this.prepareColumns(columnsConfig);
	}

	setInitialFilters(): void {
		const reviewStatus = this.User.isComergence ?
			this.filters.reviewStatus.filter(({ code }) => code !== 'C') :
			[];

		const auditStatus = this.User.isTpo ?
			this.filters.auditStatus.filter(({ condition }) => condition === 'I') :
			[];

		assign(this.defaultFilters, this.processFilter({
			reviewStatus,
			auditStatus,
		}));

		assign(this.initialFilters, this.defaultFilters, this.processFilter({
			reviewStatus: [],
			auditStatus: [],
		}));
	}

	processFilter(filter): any {
		return mapValues(filter, (value: any, key: string) => {
			switch (key) {
				case 'assigned':
					return map(value || [], 'id');
				case 'reviewStatus':
					return map(value || [], 'code');
				case 'auditStatus':
					return map(value || [], 'status');
				default:
					return value;
			}
		});
	}

	setFilter(filter, resetPage = true): void {
		super.setFilter(this.processFilter(filter), resetPage);
	}

	processParams(params) {
		return mapValues(
			this.getClearParams(params),
			(value, key) => {
				switch (key) {
					case 'dateFrom':
					case 'dateTo':
						return value.getTime();
					default:
						return value;
				}
			},
		);
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.auditsService.list(
			this.processParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	hasPermissions(): boolean {
		return hasPermissions(this.User);
	}

	createOrder(): void {
		this.stateService.go('social-compliance.orders.new');
	}

    toggleEditing() {
        this.editing = !this.editing;
        if(!this.editing) {
            this.setSelectedAudits([]);
        }
    }

    selectAudit(audit) {
        if (this.selectedAudits?.includes(audit)) {
            // This filter is used to just make a copy of the list without the selected audit
            this.setSelectedAudits(this.selectedAudits.filter(a => a !== audit));
        } else {
            this.addAuditToSelection(audit);
        }
    }

    onSelectAll(event) {
        const { target: { checked } } = event;
        if ( checked ) {
            this.setSelectedAudits(this.list);
        } else {
            this.setSelectedAudits([]);
        }
    }

    editAssignedUsers() {
        const initialState: Partial<AuditAssignedUserModalComponent> = {
            audits: this.selectedAudits,
            tpoId: this.User.organization.id,
            callbackOnSave: () => {
                this.reloadPage();
            },
        };
        this.bsModalService.show(
            AuditAssignedUserModalComponent,
            {
                initialState,
                class: 'modal-smd modal-new',
            });
    }

    editStatus() {
        const initialState: Partial<BulkAuditStatusModalComponent> = {
            audits: this.selectedAudits,
            tpoId: this.User.organization.id,
            auditStatuses: this.auditStatuses,
            callbackOnSave: () => {
                this.reloadPage();
            },
        };
        this.bsModalService.show(
            BulkAuditStatusModalComponent,
            {
                initialState,
                class: 'modal-smd modal-new',
            });
    }

    private addAuditToSelection(audit) {
        this.selectedAudits.push(audit);
        this.setSomeAuditsAreSelected();
    }

    private setSelectedAudits(newAuditList: Audit[]) {
        this.selectedAudits = [...newAuditList];
        this.setSomeAuditsAreSelected();
    }

    private setSomeAuditsAreSelected() {
        this.someAuditsAreSelected = this.selectedAudits.length > 0;
    }

    private reloadPage() {
        this.toggleEditing();
        this.loadList(this.params);
    }
}
