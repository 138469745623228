import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HasErrorDirective, OnValidSubmitDirective, RealmPhoneNumberAccessor, TrimValueAccessor, ValidFloatAccessor } from 'commons/directives/forms';
import { NotificationDirective } from 'commons/directives/notification.directive';
import { FixedSrefDirective } from 'commons/directives/fixedSref.directive';
import { SrcTestDirective } from 'commons/directives/src-test.directive';
import { BgImageDirective } from 'commons/directives/bg-image.directive';
import { SrcBlobDirective } from 'commons/directives/src-blob.directive';
import { NgxInitDirective } from 'commons/directives/ngx-init.directive';
import { SortableHeaderDirective } from 'commons/directives/sortable-header.directive';
import { IsColumnEnabledDirective } from 'commons/directives/is-column-enabled.directive';
import { FileDropDirective } from 'commons/directives/file-drop.directive';
import { ViewTemplateDirective } from 'commons/directives/view-template.directive';
import { CopyClipboardDirective } from 'commons/directives/copy-clipboard.directive';
import { ADisabledDirective } from 'commons/directives/a-disabled.directive';

const  commonDirectives = [
    ADisabledDirective,
    ValidFloatAccessor,
    OnValidSubmitDirective,
    NotificationDirective,
    TrimValueAccessor,
    FixedSrefDirective,
    SrcTestDirective,
    BgImageDirective,
    SrcBlobDirective,
    NgxInitDirective,
    SortableHeaderDirective,
    IsColumnEnabledDirective,
    FileDropDirective,
    ViewTemplateDirective,
    HasErrorDirective,
    CopyClipboardDirective,
    RealmPhoneNumberAccessor,
];

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
    ],
    declarations: [
        ...commonDirectives,
    ],
    exports: [
        ...commonDirectives,
    ],
    providers: [],
})

export class DirectivesModule {}
