import { Component } from '@angular/core';
import { Validators } from '@angular/forms';

import { UIRouter } from '@uirouter/core';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator, PhoneValidator } from 'commons/validators';

import { SCCustomerCardOverview } from 'shared/solution-center/solution-center.common';
import { TPOSolutionCenterContactRequestBody } from 'tpo/solution-center/tpo-sc-types';

@Component({
    templateUrl: './product-contact-us-modal.component.html',
})
export class ProductContactUsModalComponent {
    submit: (body: TPOSolutionCenterContactRequestBody) => Promise<void>;
    cancel: () => void;
    cardData: SCCustomerCardOverview;

    resolving = false;

    protected organizationId: number;
    protected productId: number;

    protected readonly contactRequestForm: RealmFormGroup = new RealmFormGroup({
        name: new RealmFormControl(
            'name',
            {
                label: 'Name',
            },
            Validators.compose([ MaxLengthValidator(1000), Validators.required ]),
        ),
        email: new RealmFormControl(
            'email',
            {
                label: 'Email',
            },
            Validators.compose([ MaxLengthValidator(100), Validators.email, Validators.required ]),
        ),
        phone: new RealmFormControl(
            'phone',
            {
                label: 'Phone',
            },
            Validators.compose([ PhoneValidator(), Validators.required ]),
        ),
    })

    constructor(
        private readonly router: UIRouter,
    ) {
        this.organizationId = this.router.globals.params.organizationId;
        this.productId = this.router.globals.params.productId;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async onSubmit(): Promise<void> {
        try {
            this.resolving = true;
            const body: TPOSolutionCenterContactRequestBody = this.contactRequestForm.value;

            await this.submit(body);
        } catch (e) {
            const message: string = (
                e?.error?.message ||
                e?.error ||
                e?.message
            );
            this.contactRequestForm.setServerError({ message: message });
        } finally {
            this.resolving = false;
        }
    }

    onCancel() {
        this.cancel();
    }
}
