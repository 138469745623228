import { cloneDeep, map, zipObject, remove, find, isEqual } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { TransitionService, StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { CustomRequiredValidator } from 'commons/validators';
import { ConfirmModalComponent } from 'commons/components/modals';
import { NgResourceArray } from 'commons/interfaces';

import { CodeValueService } from 'shared/code-value.service';

import {
	CustomFieldsService,
	ProspectStatus,
	ProspectEvent,
	SystemField,
	ControlType,
} from '../../../custom-fields.service';

interface FormGroupValues {
	name?: string;
	stateId?: string;
	eventId?: string;
}

interface RealmFormGroupArray extends RealmFormArray {
	controls: Array<RealmFormGroup>,
}

@Component({
	templateUrl: './system-details.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => SystemDetailsComponent) },
	],
})
export class SystemDetailsComponent extends PagedListComponent {
	static listName = 'entity-system-fields-details';
	isNew: boolean;
	editMode: boolean;

	User: UserProfile;
	modalRef: BsModalRef;

	list: NgResourceArray<ProspectStatus> = [];
	states: NgResourceArray<ControlType> = [];
	events: NgResourceArray<ProspectEvent> = [];
	item: SystemField;
	types: { [shortName: string]: string | boolean } = { $resolved: false };

	stateColor = {
		O:	'success',
		C:	'warning',
		CL:	'danger',
		R:	'danger',
	}
	maxNameLength = 25;

	statusesFormArray: RealmFormGroupArray = new RealmFormArray([]) as RealmFormGroupArray;
	systemFieldForm: RealmFormGroup = new RealmFormGroup({
		statusesFormArray: this.statusesFormArray,
	});
	initialValues: FormGroupValues = {};

	constructor(
		transitionService: TransitionService,
		{ profile }: UserService,
		{ params: { entityType, systemType } }: UIRouterGlobals,
		public stateService: StateService,
		public modalService: BsModalService,
		public fieldsService: CustomFieldsService,
		public CodeValue: CodeValueService,
	) {
		super(transitionService, stateService);

		this.User = profile;
		this.editMode = this.isNew;

		this.fieldsService.controlTypes().$promise.then((types) => {
			this.types = zipObject(map(types, 'shortName'), map(types, 'name'));
			this.types.$resolved = true;
		});

		this.states = this.CodeValue.get({ code: 'CRMProspectState', sorted: true }, (data) => {
			remove(data, { name: 'Removed' });
		});

		this.events = this.fieldsService.getProspectEvents();

		this.item = find(CustomFieldsService.SystemTypes[entityType], { systemType });
	}

	async loadList(): Promise<void> {
		this.list.$resolved = false;
		this.list = await this.fieldsService.getProspectStatuses().$promise;
		this.formInit();
	}

	formInit = (): void => {
		for (const status of this.list) {
			this.addFormGroup(status);
		}
		this.initialValues = cloneDeep(this.statusesFormArray.getRawValue()) as FormGroupValues;
	};

	addFormGroup({ name = '', stateId = null, eventId = 'None' }: FormGroupValues = {}): void {
		const eventName = `event_${this.statusesFormArray.length}`;
		this.statusesFormArray.push(
			new RealmFormGroup({
				name: new RealmFormControl(
					'name',
					{ label: 'Status Name', updateOn: 'change', value: name },
					[
						Validators.maxLength(this.maxNameLength),
						CustomRequiredValidator('Please enter <b>Status Name</b>.'),
					],
				),
				stateId: new RealmFormControl(
					'stateId',
					{ label: 'System State', updateOn: 'change', disabled: eventId !== 'None', value: stateId },
					[ CustomRequiredValidator('Please select <b>System State</b>.') ],
				),
				[eventName]: new RealmFormControl(
					eventName,
					{ label: 'System Event', updateOn: 'change', value: eventId },
				),
			})
		);
	}

	edit(): void {
		this.editMode = true;
	}

	cancelEdit(): void {
		this.systemFieldForm.reset();
		this.statusesFormArray.clear();
		this.formInit();
		this.editMode = false;
	}

	renameKey(object: {}, oldKey: string, newKey: string): void {
		delete Object.assign(object, { [newKey]: object[oldKey] })[oldKey];
	}

	async save(): Promise<void> {
		const values = this.statusesFormArray.getRawValue();
		for (let i = 0; i < values.length; i++) {
			this.renameKey(values[i], `event_${i}`, 'eventId' )
		}

		try {
			this.list = await this.fieldsService.updateProspectStatuses(values).$promise;
			this.cancelEdit();
		} catch ({ data }) {
			if (data.errorDetails?.length) {
				for (const error of data.errorDetails) {
					// from the backend we get a property with a name and a sequence number like "event_5"
					// so we parse the name and get its sequence number to know which FormArray element has an error
					const index = error.name.split('_')[1] || 0;
					if (index === 0 && data.errorDetails[0].name === 'event') {
						data.errorDetails[0].name = 'event_0';
					}
					(this.statusesFormArray.at(index) as RealmFormGroup).setServerError(data);
				}
			}
		}
	}

	isUnchanged = (): boolean => isEqual(this.initialValues, this.statusesFormArray.getRawValue());

	getStateEntity = (shortName: string): ControlType => find(this.states, { shortName });

	getEventEntity = (id: string): ProspectEvent => find(this.events, { id });

	hasError(i: number, fieldName: string): boolean {
		const { invalid, touched } = this.statusesFormArray.at(i).get(fieldName) || {};
		return this.editMode && invalid && touched;
	}

	move(i: number, step: number): void {
		const controls = this.statusesFormArray.controls;
		[ controls[i], controls[i + step] ] = [ controls[i + step], controls[i] ];
		this.statusesFormArray.patchValue(controls);
	}

	removeLine(i: number): void {
		this.statusesFormArray.removeAt(i);
	}

	submitForm(): void {
		if (this.isUnchanged()) {
			this.cancelEdit();
		} else {
			this.modalRef = this.modalService.show(ConfirmModalComponent, {
				initialState: {
					title: 'Are you sure you want to apply the changes?',
					message: 'You are about to make changes to the mapping related to your prospect statuses, are you sure you would like to make this change?',
					confirmText: 'Yes',
					cancelText: 'No',
					onConfirm: (): void => {
						this.modalRef.content.resolving = true;
						this.save().finally(() => {
							this.modalRef.hide();
						});
					},
				},
				class: 'modal-smd modal-new',
			});
		}
	}

	setState(index: number): void {
		const formGroup = this.statusesFormArray.at(index);
		const eventId = formGroup.get(`event_${index}`).value;
		const stateIdField = formGroup.get('stateId');
		if (eventId !== 'None') {
			stateIdField.patchValue(this.getEventEntity(eventId).stateId);
			stateIdField.disable();
		} else {
			stateIdField.enable();
		}
	}
}
