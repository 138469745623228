import { Component, forwardRef, Input } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { DBA, DbaService } from 'shared/account/dba/dba.service';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData, RealmParams } from 'commons/services/http';

@Component({
    selector: 'dba-list',
    templateUrl: './dba-list.component.html',
    viewProviders: [
        // TODO: Used for SearchListComponent, change it's definition once old ListComponent will be removed
        { provide: ListComponent, useExisting: forwardRef(() => DbaListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => DbaListComponent) },
    ],
})
export class DbaListComponent extends NewPagedListComponent<DBA> {
    static listName = 'dbaList';
    @Input() private lenderInfo: AccountInformation;
    @Input() private externalParams: RealmParams = {};
    @Input() declare useLocation: boolean;
    directAccess: boolean;

    constructor(
        router: UIRouter,
        private dbaService: DbaService,
    ) {
        super(router);
        const { component } = router.globals.current as any;
        this.directAccess = component === this.constructor;
    }

    protected loadList(params: ListParams): Observable<PagedData<DBA[]>> {
        const { identifier } = this.lenderInfo;
        return this.dbaService.getList(identifier, { ...this.externalParams, ...params });
    }
}
