<ng-container *ngIf="isCommentsSection">
	<div class="comments-filter btn-group mb15 flex-row">
		<ng-container *ngFor="let switcher of switchers">
			<button *ngIf="switcher.visible"
					[ngClass]="switcher.label === activeSwitcher.label ? 'btn btn-primary' : 'btn btn-default'"
					(click)="changeResource(switcher)"
					tabindex="0"
					role="button">
				{{ switcher.label }}
			</button>
		</ng-container>

		<i *ngIf="activeSwitcher.help"
		   class="glyphicon glyphicon-question-sign"
		   [tooltip]="activeSwitcher.help">
		</i>
	</div>

	<ng-content></ng-content>
</ng-container>
