import { Component, Input, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { SupportingDocumentsComponent } from 'shared/supporting-documents';

import { AuditsService } from '../../audits/audits.service';

@Component({
	selector: 'audit-supporting-documents',
	templateUrl: './supporting-documents.component.html',
})
export class AuditSupportingDocumentsComponent extends SupportingDocumentsComponent implements OnInit {
	static listName = 'auditSupportingDocuments';

	@Input() review: any;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		modalService: BsModalService,
		service: AuditsService,
	) {
		super(transitionService, stateService, userService, modalService, service);
	}

	configureList() {
		this.omitParams = [
			...this.omitParams,
			'tpoId',
			'reviewId',
		];
	}

	ngOnInit() {
		this.review.$promise.then(() => {
			const idParams = this.getIdParams();

			this.defaultFilters = {
				...idParams,
			};

			super.ngOnInit();
		})
	}

	getIdParams() {
		const { reviewId } = this.stateService.params;
		const { tpoId } = this.review;

		return { reviewId, tpoId };
	}
}
