import { AfterViewInit, Component, forwardRef, Inject, Renderer2, ChangeDetectorRef } from '@angular/core';
import { IPromise } from 'angular';
import { DOCUMENT } from '@angular/common';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'environments/environment';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { NgResourceArray, NgResourceObject } from 'commons/interfaces';

import { ReviewWidgetService, Widget, Source } from '../widgets.service';

@Component({
	templateUrl: './details.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => WidgetDetailsComponent) },
	],
})
export class WidgetDetailsComponent extends PagedListComponent implements AfterViewInit {
	static listName = 'widget-details-accounts';

	sources: NgResourceArray<Source> = [];
	widget: NgResourceObject<Widget>;
	widgetId: number;
	modalRef: BsModalRef;

	widget_client_publicApiHost = window.env.widget_client_publicApiHost;
	scriptCodeGenerated: string = '';

	constructor(
		transitionService: TransitionService,
		public stateService: StateService,
		{ params: { widgetId } }: UIRouterGlobals,
		public modalService: BsModalService,
		public widgetsService: ReviewWidgetService,
		private renderer2: Renderer2,
		@Inject(DOCUMENT) private document: Document,
        protected readonly cd: ChangeDetectorRef,
	) {
		super(transitionService, stateService);
		this.widgetId = widgetId;
		this.widget = this.widgetsService.widget(this.widgetId);
	}

	loadList(params): IPromise<void> {
		this.sources.$resolved = false;
		params.widgetId = this.widgetId;
		return this.widgetsService.widgetSources(this.getClearParams(params)).$promise
			.then((data) => {
				this.sources = data;
			}).finally(() => {
				this.sources.$resolved = true;
			});
	}

	ngAfterViewInit(): void {
        this.widget.$promise.then(() => {
            this.cd.detectChanges();
		this.loadScript(`${this.widget_client_publicApiHost}/comergence-widget.latest.js?${+new Date()}`)
			.then(() => {
				// window.comergenceWidget?.setDebugMode(true);
				window.comergenceWidget?.clearCache();
				window.comergenceWidget?.useCache(false);
				window.comergenceWidget?.start();
			});
        });
	}

	getWidgetCode(id?: string): string {
		if (!id || !window.comergenceWidget) {
			return '';
		}

		return window.comergenceWidget?.getInjectWidgetCode(id);
	}

	get scriptCode(): string {
		if (this.scriptCodeGenerated) {
			return this.scriptCodeGenerated;
		}

		if (!window.comergenceWidget) {
			return '';
		}

		this.scriptCodeGenerated = window.comergenceWidget?.getInjectScriptCode();


		return this.scriptCodeGenerated;
	}

	ngOnDestroy(): void {
		window.comergenceWidget?.stop();
	}

	loadScript(url: string): Promise<void> {
		return new Promise((resolve, reject) => {
			// already added
			if (window.comergenceWidget) {
				resolve();
				return;
			}

			const script = this.renderer2.createElement('script');
			script.id = 'comergence-widget-source';
			script.src = url;
			script.dataset['exposeToGlobal'] = true;
			// use proxy on level of webpack/dev.babel.js for localhost
			script.dataset['apiBaseUrl'] = environment.production ? window.env.widget_client_publicApiHost : '';
			script.dataset['styleUrl'] = window.env.widget_client_publicApiHost;
			script.onload = resolve;
			script.onerror = reject;
			this.renderer2.appendChild(this.document.body, script);
		});
	}

	remove = (): void => {
		const initialState = {
			message: 'You can delete this widget however, the code will still be embedded on any site where this code/widget was used. Would you still like to delete?',
			confirmText: 'Confirm',
			cancelText: 'Cancel',
			title: 'Delete Widget',
			onConfirm: () => {
				this.modalRef.content.resolving = true;
				this.widgetsService.remove(this.widgetId,
					() => {
						this.stateService.go('^');
						this.modalRef.content.resolving = false;
						this.modalRef.hide();
					},
					(error) => {
						this.modalRef.content.errorText = error;
						this.modalRef.content.resolving = false;
					},
				);
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	};
}
