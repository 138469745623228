import { Component, forwardRef, Input } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { NgPagedResource, NgResourceObject } from 'commons/interfaces';

import { AuditsService } from '../../audits.service';
import { AuditFinding, AuditReview } from '../../audits.service.interface';

@Component({
	selector: 'audit-findings',
	templateUrl: './audit-findings.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => AuditFindingsComponent) },
	],
})
export class AuditFindingsComponent extends PagedListComponent {
	static listName = 'audit-findings';

	User: UserProfile;
	tpoId: number;
	reviewId: number;
	list: NgPagedResource<AuditFinding> = [];
	statuses = {
		'In Progress': {
			color: 'label-warning',
			title: 'In Progress',
		},
		'Closed': {
			color: 'label-success',
			title: 'Closed',
		},
	};
	canNotify: boolean;

	@Input() review: NgResourceObject<AuditReview>;

	get showNotify(): boolean {
		return this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE');
	}

	get canAddFinding(): boolean {
		return (
            (
                (this.User.can('CCM_SMC_VIEW_ALL') ||
                    this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')) &&
				this.review?.auditStatus?.condition !== 'C' // review available after OnInit
			)
		);
	}

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public auditsService: AuditsService,
		{ profile: User }: UserService,
	) {
		super(transitionService, stateService);
		this.User = User;
		this.tpoId = User.organization.id;
		this.reviewId = Number(stateService.params.reviewId);

		this.configureList();

		if (this.showNotify) {
			const {
				tpoId,
				reviewId,
			} = this;
			this.auditsService.canNotify({
				tpoId,
				reviewId,
			}).$promise
				.then(({ canNotify }) => {
					this.canNotify = canNotify;
				});
		}
	}

	private configureList(): void {
		const { reviewId } = this;
		Object.assign(
			this.defaultFilters,
			{
				reviewId,
				size: 25,
			}
		);

		this.omitParams.push('reviewId');
	}

	loadList(params) {
		this.list.$resolved = false;
		return this.auditsService.auditFindings(
			this.getClearParams(params)
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	getSource(tpoId, item) {
		return `/api/rest/${ this.User.isTpo ? 'tpos/'+tpoId+'/' : '' }social-media-compliance/finding/${item.id}/screen/image-preview`;
	}
}
