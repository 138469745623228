import { ChangeDetectionStrategy, Component, forwardRef, signal, WritableSignal } from '@angular/core';

import { UIRouter } from '@uirouter/core';
import { isEqual, map, mapValues, orderBy } from 'lodash';

import { firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';
import { ListParams, NewListComponent } from 'commons/components/new-list/list.component';
import { ErrorModalService } from 'commons/services/error/error-modal.service';

import {
    LenderSolutionCenterService,
    SCCustomerProductInformation,
} from 'tpo/solution-center/solution-center.service';
import {
    LenderChannel,
    Tag,
    LoanProgram,
    InvestorType,
    allwaysVisibleTabs,
    SC_CARD_TAB,
    SCCustomerCard,
    SCCustomerCardOverview,
    SolutionCenterVisibleTabs,
    SC_CHANNEL_ACTION,
    SC_CHANNEL_HISTORY_ACTION,
} from 'shared/solution-center/solution-center.common';
import {
    SCProduct,
    SCProductOverview,
} from 'lender/solution-center/solution-center.service';
import { ApplicationHistory, TPOSolutionCenterContactRequestBody } from '../tpo-sc-types';
import { ProductContactUsModalComponent } from '../tabs/products/product-contact-us-modal/product-contact-us-modal.component';
import {
    ContactInformationModalComponent
} from '../tabs/channels/contact-information-modal/contact-information-modal.component';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    selector: 'solution-center-list',
    templateUrl: './solution-center-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => LenderSolutionCenterListComponent) },
        { provide: NewListComponent, useExisting: forwardRef(() => LenderSolutionCenterListComponent) },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LenderSolutionCenterListComponent extends NewListComponent<SCCustomerCard> {
    static listName = 'solutionCenterSearch' as const;
    selectedCardIndex: number = -Infinity;

    public cardData: WritableSignal<SCCustomerCardOverview> = signal<SCCustomerCardOverview>(null, { equal: isEqual });
    public productOverview: WritableSignal<SCProductOverview> = signal<SCProductOverview>(null, { equal: isEqual });
    public productList: WritableSignal<SCProduct[]> = signal<SCProduct[]>(null, { equal: isEqual });
    public channelList: WritableSignal<LenderChannel[]> = signal<LenderChannel[]>(null, { equal: isEqual });
    public applicationHistoryList: WritableSignal<ApplicationHistory[]> = signal<ApplicationHistory[]>(null, { equal: isEqual });
    public visibleTabs: WritableSignal<SolutionCenterVisibleTabs> = signal<SolutionCenterVisibleTabs>(null, { equal: isEqual });
    public currentTab: WritableSignal<SC_CARD_TAB> = signal<SC_CARD_TAB>(null, { equal: isEqual });
    public readonly isCardResolving = signal<boolean>(false);

    private modalRef: BsModalRef;
    private User: UserProfile;

    private organizationId: number;
    private investorId: number;

    filters: Partial<{
        tags: Observable<Tag[]>,
        loanPrograms: Observable<LoanProgram[]>,
        investorType: Observable<InvestorType[]>,
    }> = {};

    private applicationHistorySortReversed = true;

    constructor(
        uiRouter: UIRouter,
        private readonly errorModalService: ErrorModalService,
        private readonly modalService: BsModalService,
        private readonly globalNotificationService: GlobalNotificationsService,
        protected readonly lenderSolutionCenterService: LenderSolutionCenterService,
        { profile }: UserService,
    ) {
        super(
            uiRouter,
        );
        this.User = profile;
        this.prepareFilters();
    }

    prepareFilters() {
        this.filters = {
            tags: this.lenderSolutionCenterService.getAvailableTags(),
            loanPrograms: this.lenderSolutionCenterService.getAvailableLoanPrograms(),
            investorType: this.lenderSolutionCenterService.getAvailableInvestorsTypes(),
        };

        this.defaultParams = {
            ...this.defaultParams,
            tags: [],
            loanPrograms: [],
            investorType: [],
        }
    }

    setFilter(filters: ListParams): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const processedFilters = mapValues(filters, (value: any, key: string) => {
            if (key === 'tags' || key === 'loanPrograms') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return map(value || [], 'code');
            } else if (key === 'investorType') {
                return value?.code;
            }

            return value;
        });

        super.setFilter(processedFilters);
    }

    loadList(params: ListParams): Observable<SCCustomerCard[]> {
        // Reset selected card index
        this.selectedCardIndex = -Infinity;
        return this.lenderSolutionCenterService.getCustomersList(params)
            .pipe(tap((list) => {
                if (list.length) {
                    this.selectCard(0, list[0]);
                } else {
                    this.resetActiveCard();
                }
            }));
    }

    selectCard(index: number, customer: SCCustomerCard): void {
        // ignore if the same card is selected
        if (this.selectedCardIndex === index) {
            return;
        }

        this.selectedCardIndex = index;
        this.organizationId = customer.organizationId;
        this.loadNewCard(customer);
    }

    async loadNewCard(customer: SCCustomerCard): Promise<void> {
        const { organizationId } = customer;
        this.isCardResolving.set(true);
        this.resetActiveCard();
        const cardData = await firstValueFrom(
            this.lenderSolutionCenterService.getCustomerOverview(organizationId),
        );
        const { availableTabs, investorId } = cardData;
        this.investorId = investorId;
        const tabs =  Object.fromEntries( [...allwaysVisibleTabs, ...availableTabs].map(value => [value, true]));
        this.cardData.set(cardData);
        this.visibleTabs.set(tabs);
        this.isCardResolving.set(false);
    }

    resetActiveCard(): void {
        this.currentTab.set(SC_CARD_TAB.OVERVIEW);
        this.cardData.set(null);
        this.productOverview.set(null);
        this.productList.set(null);
        this.channelList.set(null);
        this.applicationHistoryList.set(null);
    }

    selectTab(tab: SC_CARD_TAB): void {
        this.currentTab.set(tab);
        switch (tab) {
            case SC_CARD_TAB.PRODUCTS:
                this.loadProducts();
                break;
            case SC_CARD_TAB.CHANNELS:
                this.loadChannels();
                this.loadApplicationHistory();
                break;
        }
    }

    async loadProducts() {
        // Skip is already loaded
        if (this.productOverview()) return;

        // Implement product loading
        this.resolving = true;

        try {
            const productsPage: SCCustomerProductInformation = await firstValueFrom(this.lenderSolutionCenterService.getProductsInformation(this.organizationId));

            this.productOverview.set(productsPage.overview);

            this.productList.set(productsPage.tpoCustomerProducts);
        } finally {
            this.resolving = false;
        }
    }

    async loadChannels() {
        // Skip is already loaded
        if (this.channelList()) return;

        // Implement channel loading
        this.resolving = true;

        try {
            const channels = await firstValueFrom(this.lenderSolutionCenterService.getChannels(this.investorId));

            this.channelList.set(channels);
        } finally {
            this.resolving = false;
        }
    }

    async loadApplicationHistory() {
        if (this.applicationHistoryList()) return;

        this.resolving = true;

        try {
            const history = this.User.can('TPO_APPLICATIONS') ?
                await firstValueFrom(this.lenderSolutionCenterService.getApplicationHistory(this.investorId)) : [];

            const sortedHistory = this.sortApplicationHistoryOrder(history, 'actionDate');
            this.applicationHistoryList.set(sortedHistory);
        } finally {
            this.resolving = false;
        }
    }

    contactRequestIssued(product: SCProduct): void {
        const initialState = {
            submit: async (body: TPOSolutionCenterContactRequestBody): Promise<void> => {
                await firstValueFrom(
                    this.lenderSolutionCenterService.issueContactRequest(this.organizationId, product.productId, body),
                );

                this.modalRef.hide();

                this.globalNotificationService.add({ type: GlobalNotificationType.POSITIVE, message: 'Successfully Submitted' });
            },
            cancel: (): void => {
                this.modalRef.hide();
            },
            cardData: this.cardData(),
        };

        this.modalRef = this.modalService.show(ProductContactUsModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    async contactUs(channel: LenderChannel): Promise<void> {
        const initialState = {
            lenderChannelContactInfo: await this.lenderSolutionCenterService.getChannelContactInfo(this.organizationId, channel.id),
        };

        this.modalRef = this.modalService.show(ContactInformationModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    channelAction({ channel, action }): void {
        if (!this.User.can('TPO_APPLICATIONS')) {
            this.errorModalService.handleHttpError(403, false);
            return;
        }

        switch (action) {
            case SC_CHANNEL_ACTION.CONTACT:
                this.contactUs(channel);
                break;
            case SC_CHANNEL_ACTION.APPLY:
                this.router.stateService.go('new-application', { channelId: channel.id });
                break;
        }
    }

    applicationHistoryAction({ applicationHistory, action }): void {
        switch (action) {
            case SC_CHANNEL_HISTORY_ACTION.EDIT:
                this.router.stateService.go('new-application', { channelId: applicationHistory.channel.id });
                break;
            case SC_CHANNEL_HISTORY_ACTION.PRINT:
                const link = `tpo-applications/channels/${applicationHistory.channel.id}/applications/${applicationHistory.applicationId}/print`;
                window.open(link, '_blank');
                break;
        }
    }

    sortApplicationHistoryOrder(historyList: ApplicationHistory[], columnKey: string): ApplicationHistory[] {
        const sorted = orderBy(historyList, columnKey, this.applicationHistorySortReversed ? 'desc' : 'asc');
        this.applicationHistorySortReversed = !this.applicationHistorySortReversed;
        return sorted;
    }
}
