import { isEqual } from 'lodash';
import { firstValueFrom } from 'rxjs';
import {
    AfterViewInit,
    OnDestroy,
    Component,
    computed,
    effect,
    ElementRef,
    HostListener,
    Signal,
    signal,
    untracked,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'commons/components/modals';

import {
    allwaysVisibleTabs,
    LenderChannel,
    SC_CARD_TAB,
    SC_TABS,
    SolutionCenterVisibleTabs,
} from 'shared/solution-center/solution-center.common';
import {
    InvestorSolutionCenterService,
    InvestorCustomerCard, SCProductOverview, SCProduct,
} from 'lender/solution-center/solution-center.service';


@Component({
    selector: 'solution-center',
    templateUrl: './solution-center.component.html',
})
export class SolutionCenterComponent implements AfterViewInit, OnDestroy {
    public cardData: WritableSignal<InvestorCustomerCard> = signal<InvestorCustomerCard>(null, { equal: isEqual });
    public productOverview: WritableSignal<SCProductOverview> = signal<SCProductOverview>(null, { equal: isEqual });
    public productList: WritableSignal<SCProduct[]> = signal<SCProduct[]>(null, { equal: isEqual });
    public channelList: WritableSignal<LenderChannel[]> = signal<LenderChannel[]>(null, { equal: isEqual });
    public visibleTabs: Signal<SolutionCenterVisibleTabs>;
    public currentTab: Signal<SC_CARD_TAB>;

    public visibility = false;
    public disableVisibilityToggle = false;
    public resolving = true;
    public visibilityResolving = false;
    public tabList = SC_TABS;
    public modalRef: BsModalRef;
    public User: UserProfile;

    previewScale = signal<number>(1.0);
    previewMaxHeight = signal<string>('100%');
    private scalableElement = viewChild('scalableElement', { read: ElementRef });
    @HostListener('window:resize') private onResize() {
        this.updateScale();
    }

    constructor(
        private readonly userService: UserService,
        protected readonly investorSolutionCenterService: InvestorSolutionCenterService,
        protected readonly uiRouterGlobals: UIRouterGlobals,
        public modalService: BsModalService,
    ) {
        this.User = this.userService.profile;
        this.visibleTabs = computed(() => {
            const { availableTabs = [] } = this.cardData() || {};
            const tabs = [...allwaysVisibleTabs, ...availableTabs];
            return Object.fromEntries(tabs.map(value => [value, true]));
        });
        const success = toSignal(this.uiRouterGlobals.success$);
        this.currentTab = computed(() => {
            const state = success()?.targetState()?.name();
            const tab = SC_TABS.find(({ investorRoute }) => state === investorRoute);
            return tab?.code;
        });
        this.init();
    }

    init() {
        this.loadOverview();
        effect(() => {
            const tab = this.currentTab();

            untracked(() => {
                switch (tab) {
                    case SC_CARD_TAB.OVERVIEW:
                        return this.loadOverview();
                    case SC_CARD_TAB.PRODUCTS:
                        return this.loadProducts();
                    case SC_CARD_TAB.CHANNELS:
                        return this.loadChannels();
                }
            })
        });

    }

    private observer: ResizeObserver;
    ngAfterViewInit() {
        this.observer = new ResizeObserver(() => this.updateScale());
        this.observer.observe(this.scalableElement().nativeElement as Element);
    }

    ngOnDestroy() {
        this.observer.disconnect();
    }

    updateScale() {
        const padding = 30;
        const el = this.scalableElement().nativeElement as Element;
        const scale = (el.parentElement.clientWidth - padding * 2) / el.clientWidth;
        const height = Math.ceil(el.clientHeight * scale);
        this.previewScale.set(Math.min(scale, 1));
        this.previewMaxHeight.set(`${height}px`);
    }

    async loadOverview() {
        if (this.cardData()) return;
        this.resolving = true;
        this.cardData.set(await firstValueFrom(this.investorSolutionCenterService.getCustomerData()));
        this.resolving = false;
        this.updateVisibilityToggle();
    }

    async loadChannels() {
        if (this.channelList()) return;
        this.resolving = true;
        this.channelList.set(await firstValueFrom(this.investorSolutionCenterService.getActiveChannels()));
        this.resolving = false;
        this.updateVisibilityToggle();
    }

    async loadProducts() {
        if (this.productOverview()) return;
        this.resolving = true;
        const [ overview, list] = await Promise.all([
            await firstValueFrom(this.investorSolutionCenterService.getProductOverview()),
            await firstValueFrom(this.investorSolutionCenterService.getProductList()),
        ]);
        this.productOverview.set(overview);
        this.productList.set(list);
        this.resolving = false;
    }

    updateCard(delta: Partial<InvestorCustomerCard>) {
        this.cardData.update(card => ({ ...card, ...delta }));
    }

    async changeVisibility() {
        this.visibilityResolving = true;
        this.cardData.update(card => ({ ...card, ...{ visible: !this.cardData().visible } }));
        await firstValueFrom(this.investorSolutionCenterService.updateVisibility(this.cardData().visible));
        this.visibilityResolving = false;
    }

    updateVisibilityToggle() {
        this.disableVisibilityToggle = (this.cardData().companyName && this.cardData().companyLogo) ? false : true;
    }

    changeVisibilityConfirmModal(): void {
        this.modalRef = this.modalService.show(
            ConfirmModalComponent,
            {
                initialState: {
                    title: 'Solution Center',
                    message: 'Clients will not see you in the Solution Center. Are you sure you want to proceed?',
                    confirmText: 'Yes',
                    onConfirm: () => {
                        this.changeVisibility();
                        this.modalRef.hide();
                    },
                },
                class: 'modal-smd modal-new',
            },
        );
    }

    onChangeVisibility() {
        if (this.cardData().visible) {
            this.changeVisibilityConfirmModal();
            return;
        }
        this.changeVisibility();
    }
}
