import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { chain,  mapValues } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { AccountInformation } from 'shared/account/company-information/account-information.service';

import { ApplicationsListComponent } from 'lender/applications';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';

import { AssignQuestionnairesModalComponent } from '../assign-modal/';
import { SnapshotModalComponent } from '../snapshot-modal/';
import { QuestionnairesService } from '../questionnaires.service';
import {
	QuestionnairesListEntry,
	QuestionnairesList,
	QuestionnaireStatus,
	QuestionnaireAssignPayload,
	QuestionnaireContactsList,
	QuestionnaireLenderContactsList,
} from '../questionnaires.interface';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => SharedQuestionnairesListComponent) },
	],
})
export class SharedQuestionnairesListComponent extends PagedListComponent implements OnInit {
	static listName = 'questionnairesList';
	list: QuestionnairesList = [];
	user: UserProfile = this.userService.profile;
	cc: QuestionnaireLenderContactsList;
	contacts: QuestionnaireContactsList;
	questionnaireStatus = QuestionnaireStatus;
	modalRef: BsModalRef;
	applicationListName = ApplicationsListComponent.listName;
	@Input() lenderInfo: AccountInformation;

	get isInvestor(): boolean {
		return this.user.isLender;
	}

	get isLender(): boolean {
		return this.user.isTpo;
	}

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		private userService: UserService,
		private questionnairesService: QuestionnairesService,
		private modalService: BsModalService,
	) {
		super(transitionService, stateService);
	}

	ngOnInit(): void {
		this.configureList();

		super.ngOnInit();
	}

	private configureList(): void {
		const params = {
			...this.lenderInfo.identifier,
		};

		Object.assign(
			this.defaultFilters,
			{
				...params,
			},
		);

		this.omitParams.push('tpoId');

		this.configureFilters();
	}

	private configureFilters(): void {
		const params = {
            ...this.lenderInfo.identifier,
		};

		Object.assign(
			this.filters,
			{
				...(this.isInvestor && { questionnaireId: this.questionnairesService.getQuestionnairesFilter(params) }),
				...(this.isLender && { lenderId: this.questionnairesService.getInvestorsFilter(params) }),
				statusId: this.questionnairesService.getStatusesFilter(params),
			},
		);

		Object.assign(
			this.defaultFilters,
			{
				...(this.isInvestor && { questionnaireId: [] }),
				...(this.isLender && { lenderId: [] }),
				statusId: [],
			},
		);
	}

	setFilter(filters) {
		const processedFilters = mapValues(filters, (value, key) => {
			switch (key) {
				case 'questionnaireId':
					return value.map(({ id }) => id);
				case 'lenderId':
					return value.map(({ lenderId }) => lenderId);
				case 'statusId':
					return value.map(({ id }) => id);
				default:
					return value;
			}
		});
		super.setFilter(processedFilters);
	}

	async loadList(queryParams) {
		this.list.$resolved = false;
		try {
			this.list = await this.questionnairesService.list.get(
				this.getClearParams(queryParams)
			).$promise;
		} catch (e) {}
		this.list.$resolved = true;
	}

	async showSnapshotModal(assignmentId: number): Promise<void> {
		this.list.$resolved = false;

		const [, assignments]: [void, QuestionnaireAssignPayload] = await Promise.all([
			!this.contacts || !this.cc ? this.loadResources() : Promise.resolve(),
			this.questionnairesService.list.getAssignment({ assignmentId, tpoId: this.params.tpoId }).$promise,
		]);

		const { notifyUponCompletionRealmUserIds, ccRealmUserIds, toContactIds, message, scheduledDate } = assignments;
		const assignmentUsers = chain(this.contacts)
			.keyBy('contactId')
			.at(toContactIds)
			.value();
		const ccInternalUsers = chain(this.cc)
			.keyBy('realmUserId')
			.at(ccRealmUserIds)
			.value();
		const toInternalUsers = chain(this.cc)
			.keyBy('realmUserId')
			.at(notifyUponCompletionRealmUserIds)
			.value();

		this.list.$resolved = true;

		this.modalRef = this.modalService.show(SnapshotModalComponent, {
			initialState: {
				snapshotData: { assignmentUsers, ccInternalUsers, toInternalUsers, message, scheduledDate },
			},
			class: 'modal-new modal-smd snapshot-modal',
		});
	}

	async loadResources(): Promise<void> {
		const { tpoId } = this.params;
		([this.contacts, this.cc] = await Promise.all([
			this.questionnairesService.contacts.get({ tpoId, activeOnly: false }).$promise,
			this.questionnairesService.lenderContacts.get({
				tpoId,
				lenderId: this.user.organization.id,
				activeOnly: false,
			}).$promise,
		]));
	}

	assignQuestionnaire(
		questionnaire?: QuestionnairesListEntry,
		schedule = false
	): void {
		const initialState = {
			questionnaire,
			schedule,
			reload: () => {
				this.configureFilters();
				this.loadList(this.params);
			},
			getResourceParams: () => {
				return this.lenderInfo.identifier;
			},
		};

		this.modalRef = this.modalService.show(AssignQuestionnairesModalComponent, {
			initialState,
			backdrop: 'static',
			class: 'modal-new modal-smd',
		});
	}

	openApplication({
		channel: { id },
	}: QuestionnairesListEntry): void {
		const href= this.stateService.href('applications', {
			[this.applicationListName]: { q: this.lenderInfo.nmlsId, channelName: [id] },
		});
		window.open(href, '_blank');
	}

	cancelQuestionnaire(questionnaire: QuestionnairesListEntry): void {
		const { id, questionnaireAssignmentId, status } = questionnaire;
		const initialState = {
			title: 'Cancel Questionnaire',
			message: 'Are you sure you would like to remove this questionnaire? This action cannot be undone.',
			confirmText: 'Confirm',
			cancelText: 'Cancel',
			onConfirm: () => {
				this.modalRef.content.resolving = true;

				this.questionnairesService.list.remove({
					id,
					questionnaireAssignmentId,
                    ...this.lenderInfo.identifier,
				}, () => {
					Object.assign(
						status,
						{
							id: this.questionnaireStatus.Cancelled,
							name: 'Cancelled',
						}
					);
					questionnaire.cancelable = false;
					this.modalRef.hide();
				}, () => {
					this.modalRef.content.resolving = true;
				});
			},
			onCancel: () => {
				this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}

	getDescription(entry: QuestionnairesListEntry): string {
		if (!entry.channel) {
			return 'Additional Questionnaire';
		}

		const applicationType = entry.applicationType || '';
		return `${entry.channel.name} ${applicationType}`;
	}

	isHrefSkipped(entry: QuestionnairesListEntry): boolean {
		// no link on investor side for scheduled questionnaires
		return !this.user.isTpo && (entry?.status?.id === this.questionnaireStatus.Scheduled);
	}

	isPrintLink(entry: QuestionnairesListEntry): boolean {
		return !this.user.isTpo ||
			(entry?.status?.id === this.questionnaireStatus.Completed) ||
			(entry?.status?.id === this.questionnaireStatus.Cancelled);
	}

    cancelBubble(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    }

    getTarget(entry: QuestionnairesListEntry): string {
       return !this.isHrefSkipped(entry) && this.isPrintLink(entry) ? '_blank' : '';
    }

	getHref(entry: QuestionnairesListEntry): string {
		if (this.isHrefSkipped(entry)) {
			return 'javascript:void';
		}

		if (this.isPrintLink(entry)) {
			return `/tpos/questionnaires/${entry?.id}/print`;
		}

		if (this.user.isTpo
			&& (entry?.status?.id === this.questionnaireStatus.InProgress)
		) {
			return this.stateService.href(
				'.:questionnaireId',
				{ questionnaireId: entry?.id },
			);
		}

		return 'javascript:void';
	}

	toDetails(entry: QuestionnairesListEntry, { target, preventDefault }: MouseEvent): void {
		if (this.isHrefSkipped(entry) || (target as HTMLElement).closest('.dropdown-toggle, .dropdown-menu')) {
            preventDefault();
			return;
		}
	}
}
