import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { SharedQuestionnairesListComponent } from './list';
import { AssignQuestionnairesModalComponent } from './assign-modal';
import { SnapshotModalComponent } from './snapshot-modal';
import { QuestionnairesService } from './questionnaires.service';

const components = [
	SharedQuestionnairesListComponent,
	AssignQuestionnairesModalComponent,
	SnapshotModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        PipesModule,
        BsDropdownModule.forRoot(),
    ],
    declarations: [
        ...components,
    ],
    providers: [
        QuestionnairesService,
    ]
})
export class SharedQuestionnairesModule {}
