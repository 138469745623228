import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { SharedProspectingModule } from 'shared/prospecting/prospecting.module';

import {
	MarketService,
	MarketDataService,
	MarketComponent,
	MsaListComponent,
	CompaniesListComponent,
	MarketFiltersComponent,
	MarketSearchComponent,
	MarketMapComponent,
} from '.';


@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		PipesModule,
		DirectivesModule,
		FormsModule,
		ReactiveFormsModule,
		SharedProspectingModule,
		GoogleMapsModule,
	],
	declarations: [
		MarketComponent,
		MsaListComponent,
		CompaniesListComponent,
		MarketFiltersComponent,
		MarketSearchComponent,
		MarketMapComponent,
	],
	providers: [
		MarketService,
		MarketDataService,
	],
})

export class MarketModule {}
