import { Component, forwardRef, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { map, mapValues } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent, PagedListComponent } from 'commons/components/list';

import { OrdersService } from '../orders.service';

import { statusesConfig } from '../../statusesConfig';

@Component({
	templateUrl: './orders-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => OrdersListComponent) },
	],
})
export class OrdersListComponent extends PagedListComponent implements OnInit {
	static listName = 'orders';

	User: any;
	helpLink = '/help/authenticated/tpo/smc/orders-board';
	statusesConfig = statusesConfig;
	list: any = [];

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public ordersService: OrdersService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
	}

	ngOnInit(): void {
		if (this.hasPermissions()) {
			this.configureFilters();

			super.ngOnInit();
		}
	}

	configureFilters(): void {
		this.filters = {
			...(this.User.isComergence && {
				status: this.ordersService.statuses(),
			}),
		};

		this.defaultFilters = {
			...this.defaultFilters,
			...(this.User.isComergence && {
				status: [],
			}),
		};
	}

	setFilter(filter, resetPage = true): void {
		const processedFilter = mapValues(filter, (value: any, key: string) => {
			switch (key) {
				case 'status':
					return map(value || [], 'code');
				default:
					return value;
			}
		});

		super.setFilter(processedFilter, resetPage);
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.ordersService.list(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	hasPermissions(): boolean {
		return this.User.can('CCM_SMC_VIEW_ALL') || this.User.can('TPO_SMC_VIEW_ALL');
	}

	createOrder(): void {
		this.stateService.go('social-compliance.orders.new');
	}
}
