import { trim, mapValues, map } from 'lodash';
import moment from 'moment';
import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent, InfiniteScrollForPagedResourceComponent } from 'commons/components/list';
import { socialMediaNetworksConfig } from 'commons/components/sm-icons';

import { TPOArchiveService } from '../archive.service';
import { PostHistoryModalComponent } from '../post-history-modal';
import { OwnersModalComponent } from '../owners-modal';
import { ArchivePost } from './post.interface';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoArchiveListComponent) },
	],
})
export class TpoArchiveListComponent extends InfiniteScrollForPagedResourceComponent {
	static listName = 'archive';
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};

	modalRef: BsModalRef;

	helpLink = '/help/authenticated/tpo/smc/archive';
	smConfigs = socialMediaNetworksConfig;
	User: any;

	moreFilters: boolean = false;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public modalService: BsModalService,
		public userService: UserService,
		public archiveService: TPOArchiveService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
		if (!this.User?.can('TPO_SMC_VIEW_ALL')) return

		this.omitParams.push('notification');

		this.filters = {
			postStartDate: null,
			postEndDate: null,
			entity: this.archiveService.getEntities(),
			mediaContentType: this.archiveService.getMediaContentTypes(),
			source: this.archiveService.getAccountTypes(),
		};

		this.defaultFilters = {
			...this.defaultFilters,
			postStartDate: null,
			postEndDate: null,
			entity: `ALL_${this.getAllEntityId()}`,
			mediaContentType: [],
			source: [],
		};
	}

	getAllEntityId() {
		if (this.User.isTpo) {
			return this.User.organization.organizationId;
		}

		// TODO: add support for other roles in future
	}

	processParams(params) {
		return mapValues(this.getClearParams(params), (value, key) => {
			if (['postStartDate', 'postEndDate'].indexOf(key) !== -1) {
				return value ? moment(value).startOf('day').format('x') : value;
			} else {
				return value;
			}
		});
	}

	loadList(queryParams) {
		if (!this.User?.can('TPO_SMC_VIEW_ALL')) return
		this.shouldResetList = this.resolveCount && (this.params$.getValue().page === 0);

		this.archiveService.list(this.processParams(queryParams))
			.$promise.then((result) => {
				this.list = result;

				this.resolveCount++;
			});
	}

	setFilter(filters, resetPage: boolean = true) {
		this.resolveCount = 0;
		this.list && (this.list.$resolved = false);

		const processedFilter = mapValues(filters, (value: any, key: string) => {
			switch (key) {
				case 'entity':
					return value.shortName;
				case 'mediaContentType':
					return map(value || [], 'shortName');
				case 'source':
					return map(value || [], 'id');
				default:
					return value;
			}
		});

		super.setFilter(processedFilter, resetPage);
	}

	resetFilters() {
		this.resolveCount = 0;
		this.list && (this.list.$resolved = false);
		super.resetFilters();
	}

	setSearch($event) {
		this.resolveCount = 0;
		const q = trim(`${$event.target.value}`);
		q && this.list && (this.list.$resolved = false);
		super.setSearch($event);
	}

	resetSearch() {
		this.resolveCount = 0;
		this.params$.getValue().q && this.list && (this.list.$resolved = false);
		super.resetSearch();
	}

	createFinding = (post) => {
		const {
			id,
			contentType,
			version,
			smAccountId,
			createdDate,
		} = post;

		const body = {
			originalPostDate: createdDate,
			postKey: {
				smAccountId,
				type: contentType,
				id,
				version,
			},
		};

		const onSuccess = ({ id: findingId }) => {
			this.stateService.go('social-compliance.findings.:actionType.:findingId', {
				actionType: 'edit',
				findingId,
				tab: 'ISSUES',
			});
		};

		const params = {
			harvestDate: post.harvestDate,
		};

		post.$resolving = true;
		this.archiveService.saveFinding(params, body).$promise
			.then(onSuccess)
			.finally(() => {
				post.$resolving = false;
			});
	}

	showPostHistory(post: ArchivePost) {
		const initialState = {
			post,
		};

		this.modalRef = this.modalService.show(PostHistoryModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
			backdrop: 'static',
		});
	}

	showOwners(owners) {
		const initialState = {
			list: owners,
		};

		this.modalRef = this.modalService.show(OwnersModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
			backdrop: 'static',
		});
	}

	getContactData(contact) {
		return {
			...contact,
			contactName: contact.name,
		};
	}

	toggleFilters() {
		this.moreFilters = !this.moreFilters;
	}
}
