import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { ContactsListComponent } from 'tpo/contacts/contacts-list.component';
import { ContactsResourceService } from 'tpo/contacts/contacts-resource.service';

@Component({
	selector: 'comergence-contacts-list',
	templateUrl: '../../../tpo/contacts/contacts-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ComergenceContactsListComponent) },
	],
})

export class ComergenceContactsListComponent extends ContactsListComponent {
	static listName = 'cContacts';

	constructor(
		public User: UserService,
		public TPOContactResource: ContactsResourceService,
		transitionService: TransitionService,
		stateService: StateService,
	) {
		super(User, TPOContactResource, transitionService, stateService);

		this.omitParams.push('tpoId');
		this.omitParams.push('id');
	}

	getLoadListParams(queryParams) {
		return {
			...this.getClearParams(queryParams),
			tpoId: this.stateService.params.id,
			id: null,
		};
	}
}
