import { UIRouterModule } from '@uirouter/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule, QuillService } from 'ngx-quill';
import QuillPasteSmart from 'quill-paste-smart';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuillSmartPasteService {
    constructor(public readonly quillService: QuillService) {
        this.setupQuillSmartPaste();
    }

    async setupQuillSmartPaste() {
        const Quill = await firstValueFrom(this.quillService.getQuill());
        Quill.register('modules/clipboard', QuillPasteSmart, true);
    }
}

export const ThirdPartyModules = [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
        format: 'html',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [ { 'align': [] }, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
            ],
        },
    }),
    UIRouterModule.forRoot({
        useHash: true,
    }),
];
