import { Pipe, PipeTransform } from '@angular/core';

const prefixes = ['', 'K', 'M', 'G', 'T'];
const testFormat = {useGrouping: false, maximumFractionDigits: 0, minimumFractionDigits: 0};

@Pipe({
	name: 'humanPipe',
})
export class HumanSizePipe implements PipeTransform {

	transform(value: number) {
		let tmp = value;
		let pIndex = 0;

		while (tmp.toLocaleString('en-US', testFormat).length > 3) {
			tmp = tmp / 1024;
			pIndex++;
		}

		return tmp.toLocaleString('en-US', {useGrouping: false, maximumFractionDigits: 1, minimumFractionDigits: 0}) + prefixes[pIndex] + 'b';
	}
}
