import { Component, input, model, OnInit, output } from '@angular/core';
import { CcmCustomerProduct } from 'comergence/ccm-customer-product/ccm-customer-product.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { CcmCustomerProductsService } from 'comergence/ccm-customer-product/ccm-customer-products.service';
import { firstValueFrom } from 'rxjs';
import { ConfirmModalComponent } from 'commons/components/modals';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CcmServiceStatusService } from 'comergence/customers/ccm-service-status.service';

@Component({
    selector: 'product-item',
    templateUrl: 'ccm-customer-product-item.component.html',
})
export class CcmCustomerProductItemComponent implements OnInit {
    public item = model<CcmCustomerProduct>();
    public userCanAddEdit = input<boolean>();
    public organizationId = input<number>();
    public productIndex = input<number>();
    public onNewItemCancel = output<CcmCustomerProduct>();
    public onDelete = output<number>();
    isEditing: boolean = false;
    resolving: boolean;
    toggleLoading: boolean;
    private modalRef: BsModalRef;

    productForm = new RealmFormGroup({
        productName: new RealmFormControl(
            'productName',
            {
                label: 'Product Name',
            },
            Validators.required,
        ),
        plan: new RealmFormControl(
            'plan',
            {
                label: 'Plan',
            },
        ),
        visibilityAllowedByComergence: new RealmFormControl(
            'visibilityAllowedByComergence',
            {
                label: 'Visibility',
                value: false,
            },
        ),
    });

    constructor(
        private ccmProductService: CcmCustomerProductsService,
        private readonly modalService: BsModalService,
        private readonly ccmServiceStatusService: CcmServiceStatusService,
    ) {}

    ngOnInit() {
        if (!this.item().productId) {
            return this.isEditing = true;
        }
    }

    doSave() {
        let result: CcmCustomerProduct = this.productForm.value;
        result = { ...this.item(), ...result };
        if(!this.item().productId) {
            this.addNewProduct(result);
        }
        else {
            this.updateProductName(result);
        }
    }

    doEdit() {
        const formValue = ({ ...this.item() } as any);
        this.productForm.patchValue(formValue);
        this.isEditing = true;
    }

    doCancel() {
        this.isEditing = false;
        if (!this.item().productId) {
            this.onNewItemCancel.emit(this.item());
        }
    }

    private async addNewProduct(result: CcmCustomerProduct) {
        try {
            this.resolving = true;
            const newProductItem: CcmCustomerProduct = await firstValueFrom(this.ccmProductService.addCustomerProduct(this.organizationId(), result));
            this.item.set(newProductItem);
            this.isEditing = false;
            this.ccmServiceStatusService.fetchServiceStatuses();
        } finally {
            this.resolving = false;
        }
    }

    private async updateProductName(result: CcmCustomerProduct) {
        try {
            this.resolving = true;
            const updatedProduct: CcmCustomerProduct = await firstValueFrom(this.ccmProductService.updateCustomerProductName(result.productId, this.organizationId(), result));
            this.item.update(item => updatedProduct);
            this.isEditing = false;
        } finally {
            this.resolving = false;
        }
    }

    protected async toggleVisibility(item: CcmCustomerProduct, newValue:boolean) {
        try {
            this.toggleLoading = true;
            const updatedProduct: CcmCustomerProduct = await firstValueFrom(this.ccmProductService.updateCustomerProductVisibilityAllowedByComergence(item.productId, this.organizationId(), newValue));
            this.item.update(item => updatedProduct);
        } finally {
            this.toggleLoading = false;
        }
    }

    private updateProductList(deletedProductIndex: number) {
        this.onDelete.emit(deletedProductIndex);
    }

    async removeProduct(product: CcmCustomerProduct, productIndex: number) {
        const name: string = product.productName
        const initialState = {
            title: `Delete ${name}`,
            message: `
				You are about to delete ${name}. Are you sure you want to proceed?
			`,
            confirmText: 'Confirm',
            onConfirm: async (): Promise<void> => {
                try	{
                    this.modalRef.content.resolving = true;
                    const deletedProduct: CcmCustomerProduct = await firstValueFrom(this.ccmProductService.removeCustomerProduct(this.organizationId(), product.productId));
                    this.modalRef.content.resolving = false;
                    this.modalRef.hide();
                    this.updateProductList(productIndex);
                    this.ccmServiceStatusService.fetchServiceStatuses();
                } catch (e) {
                    this.modalRef.content.errorText = (e.data?.message || e.error?.message || e.statusText);
                } finally {
                    this.modalRef.content.resolving = false;
                }
            },
        };

        this.modalRef = this.modalService.show(
            ConfirmModalComponent,
            {
                initialState,
                class: 'modal-smd modal-new',
            },
        );
    }
}

