import { find } from 'lodash';
import { Component, effect, Injector, OnDestroy } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { RealmFormGroup } from 'commons/forms';
import { Line } from 'commons/components/image-cropper/image-cropper.component';

import { Tag, LoanProgram } from 'shared/solution-center/solution-center.common';

import { UserService } from 'angularjs-providers/user.provider';

import { SolutionCenterComponent } from 'lender/solution-center/solution-center.component';

import {
    InvestorSolutionCenterService,
    InvestorCustomerCard,
    SupportedFileTypes,
} from '../../solution-center.service';
import { OverviewForm } from './form';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';

@Component({
    templateUrl: './overview.component.html',
})
export class SCOverviewComponent implements OnDestroy {
    protected organizationId: number;
    editing = false;
    uploadLogoError: string;
    uploadMarketingLogoError: string;

    tags: Observable<Tag[]>;
    loanPrograms: Observable<LoanProgram[]>;
    readonly marketingLogoPageWidth = [
        { value: 60, label: '60%' },
        { value: 50, label: '50%' },
        { value: 40, label: '40%' },
        { value: 30, label: '30%' },
        { value: 20, label: '20%' },
        { value: 10, label: '10%' },
    ]
    readonly maxLength = 4000;
    readonly defaultCropCoords = { start: { x: 0, y: 0 }, end: { x: 1, y: 1 } };
    readonly defaultMarketingLogoPageWidth = 50;
    originalData: InvestorCustomerCard;
    form: RealmFormGroup = OverviewForm;

    constructor(
        { profile }: UserService,
        protected readonly solutionCenter: SolutionCenterComponent,
        protected readonly investorSolutionCenterService: InvestorSolutionCenterService,
        private readonly injector: Injector,
    ) {
        this.organizationId = profile.organization.organizationId;
        this.tags = this.investorSolutionCenterService.getAvailableTags();
        this.loanPrograms = this.investorSolutionCenterService.getAvailableLoanPrograms();
        this.initForm();
    }

    initForm() {
        const effectRef = effect(() => {
            const cardData = this.solutionCenter.cardData();
            if (cardData) {
                this.form.patchValue(
                    cardData,
                    { emitEvent: false },
                );
                this.form.valueChanges.subscribe((formData) => {
                    if (this.editing) {
                        this.solutionCenter.updateCard(formData as InvestorCustomerCard);
                    }
                });

                effectRef.destroy();
            }
        }, { injector: this.injector });
    }

    edit() {
        this.originalData = this.solutionCenter.cardData();
        this.setEditing(true);
    }

    setEditing(editing: boolean) {
        this.editing = editing;
        if (editing) {
            this.solutionCenter.disableVisibilityToggle = editing;
        } else {
            this.solutionCenter.updateVisibilityToggle();
        }
    }

    async submit() {
        try {
            const data = this.solutionCenter.cardData();
            this.solutionCenter.resolving = true;
            const { availableTabs, ...cardData } = await firstValueFrom(
                await this.investorSolutionCenterService.updateCustomerData(data),
            );
            this.solutionCenter.updateCard(cardData);
            this.originalData = this.solutionCenter.cardData();
            this.solutionCenter.updateVisibilityToggle();
            this.cancel();
        } catch ({ error }) {
            this.form.setServerError(error as ServerErrorResponse);
        } finally {
            this.solutionCenter.resolving = false;
        }
    }

    cancel() {
        this.solutionCenter.updateCard(this.originalData);
        this.form.reset(this.originalData);
        this.uploadMarketingLogoError = null;
        this.uploadLogoError = null;
        this.setEditing(false);
    }

    onLogoPick(files: FileList) {
        this.fileValidation(files, 'uploadLogoError');
        if (this.uploadLogoError) {
            return;
        }
        const url = URL.createObjectURL(files[0]);
        this.form.patchValue({
            hasCompanyLogo: true,
            originalCompanyLogo: url,
            companyLogoCoordinates: this.defaultCropCoords,
            isCompanyLogoModified: true,
        });
    }

    onMarketingLogoPick(files: FileList) {
        this.fileValidation(files, 'uploadMarketingLogoError');
        if (this.uploadMarketingLogoError) {
            return;
        }
        const url = URL.createObjectURL(files[0]);
        this.form.patchValue({
            hasMarketingLogo: true,
            originalMarketingLogo: url,
            marketingLogoCoordinates: this.defaultCropCoords,
            isMarketingLogoModified: true,
            marketingLogoPageWidth: this.defaultMarketingLogoPageWidth,
        });
    }

    removeLogo() {
        this.form.patchValue({
            hasCompanyLogo: false,
            companyLogo: null,
            originalCompanyLogo: null,
            isCompanyLogoModified: true,
        });
    }

    removeMarketingLogo() {
        this.form.patchValue({
            hasMarketingLogo: false,
            marketingLogo: null,
            originalMarketingLogo: null,
            isMarketingLogoModified: true,
        });
    }

    async onCroppedLogoUpdate(url: string) {
        this.form.patchValue({
            companyLogo: url,
            isCompanyLogoModified: true,
        });
    }

    async onCroppedMarketingLogoUpdate(url: string) {
        this.form.patchValue({
            marketingLogo: url,
            isMarketingLogoModified: true,
        });
    }

    onCropChange(crop: Line) {
        this.form.patchValue({
            companyLogoCoordinates: crop,
            isCompanyLogoModified: true,
        });
    }

    onMarketingLogoCropChange(crop: Line) {
        this.form.patchValue({
            marketingLogoCoordinates: crop,
            isMarketingLogoModified: true,
        });
    }

    fileValidation(files: FileList, type: 'uploadLogoError' | 'uploadMarketingLogoError') {
        this[type] = null;

        if (files.length === 0) {
            this[type] = 'You must select a file to upload.';
            this.form.setServerError( { message: this[type] });
            return;
        }

        const file = files[0];
        const fileTypeConfig = find(SupportedFileTypes, (config) => (
            config.regexp.test(file.name)
        ));

        switch (true) {
            case files.length > 1:
                this[type] = 'You can upload only one file at once.';
                break;
            case file.size > 20 * Math.pow(2, 20): // 20Mb
                this[type] = 'You can upload only a file less than 20 Mb.';
                break;
            case !fileTypeConfig:
                this[type] = 'Wrong file type, please use supported file types for upload: PNG, JPEG, JPG';
                break;
        }

        if (this[type]) {
            this.form.setServerError( { message: this[type] });
        }
    }

    ngOnDestroy() {
        this.cancel();
    }
}
