import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { TaskNotificationService } from './task-notification.service';
import { TaskNotificationModalComponent } from './task-notification-modal';
import { TaskNotificationButtonComponent } from './task-notification-button';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BsDropdownModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
    ],
    providers: [
        TaskNotificationService,
    ],
    declarations: [
        TaskNotificationModalComponent,
        TaskNotificationButtonComponent,
    ],
    exports: [
        TaskNotificationButtonComponent,
    ]
})
export class TaskNotificationModule {}
