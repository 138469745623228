import { extend } from 'lodash';
import { Input, Directive } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { LenderNMLSContactsResourceService } from 'lender/contacts/lender-nmls-contacts-resource.service';

import { ContactsResourceService } from './contacts-resource.service';
import { hasContactPermission } from './contact-permissions';
import { TpoContactType } from 'shared/new-contacts/contacts.interface';

@Directive()
export class ContactsListComponent extends PagedListComponent {
	static listName = 'contacts';
    public TYPE = TpoContactType;
	contacts: any = [];
	USER = {
		LOCKED: 'L',
		ACTIVE: 'A',
		INACTIVE: 'I',
		REMOVED: 'R',
	};
	stateParamsId: any;
	@Input() isEmbedded?: boolean;
	@Input() isWizard?: boolean;

	ContactResource: ContactsResourceService | LenderNMLSContactsResourceService;

	constructor(public User: UserService, public TPOContactResource: ContactsResourceService, transitionService: TransitionService, stateService: StateService) {
		super(transitionService, stateService);

		this.ContactResource = TPOContactResource;

		extend(this.defaultFilters, {
			previous: 'false',
			contactType: '',
		});

		this.omitParams.push('previous');
		this.omitParams.push('excludeOriginators');

		this.filters = {
			previous: [
				{value: 'false', title: 'Current'},
				{value: 'true', title: 'Previous'},
			],
			contactType: [
				{value: '', title: 'All'},
				{value: 'individual', title: 'Users'},
				{value: 'originator', title: 'NMLS ID'},
			],
		};
	}

	getLoadListParams(queryParams) {
		return this.getClearParams(queryParams);
	}

	ngOnInit() {
		this.stateParamsId = this.stateService.params.id;
		this.useLocation = !this.isEmbedded;
		this.defaultFilters.excludeOriginators = this.isWizard;
		super.ngOnInit();
	}

	getFixedNmlsSref() {
		if (this.User && this.User.profile && this.User.profile.isTpo) {
			return '^.licenses.nmls.:nmlsId';
		}

		return '^.account.licenses.nmls.:nmlsId';
	}

	loadList(queryParams) {
		this.contacts.$resolved = false;

		return this.ContactResource.query(this.getLoadListParams(queryParams)).$promise
			.then((result) => {
				this.contacts = result;
				this.noMatches = !result.length && (this.filtersApplied || queryParams.q);
			});
	}

	hasPermission(permission) {
		return hasContactPermission(this.User && this.User.profile, permission);
	}
}
