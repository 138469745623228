import { Component, forwardRef, OnInit } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { finalize, catchError, tap } from 'rxjs/operators';

import { StateService as $stateProvider } from 'angularjs-providers/state.provider';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { ListComponent, PagedListComponent } from 'commons/components/list';
import { NgPagedResource, NgResourceArray } from 'commons/interfaces';

import { FinancialRequests, QuestionnaireDocument, QuestionnaireDocumentAvailable } from '../questionnaires.interface';
import { LenderManageQuestionnaireService } from '../questionnaires.service';
import { ConfigureFinancialDocumentsModalComponent } from './configure-financial-documents-modal';
import { QuestionnaireComponent } from 'lender/questionnaires/manage/questionnaire';

@Component({
	templateUrl: './questionnaire-documents.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => QuestionnaireDocumentsComponent) },
	],
})
export class QuestionnaireDocumentsComponent extends PagedListComponent implements OnInit {
	static listName = 'questionnaireDocuments';

	User: UserProfile;
	questionnaireId: number;
	defaultParams: { questionnaireId: number };
	documents: NgPagedResource<QuestionnaireDocument> = [];
	availableDocuments: NgResourceArray<QuestionnaireDocumentAvailable> = [];
	selectedDocuments: Array<QuestionnaireDocumentAvailable> = [];
	editing = false;
	modalRef: BsModalRef;
	isNotificationSet: boolean;

	constructor(
		transitionService: TransitionService,
		protected userService: UserService,
		stateService: StateService,
		protected modalService: BsModalService,
		protected questionnairesService: LenderManageQuestionnaireService,
        protected parent: QuestionnaireComponent,
		protected $state: $stateProvider,
		{ params: { questionnaireId }}: UIRouterGlobals,
	) {
		super(transitionService, stateService);
		this.User = userService.profile;
		this.questionnaireId = questionnaireId;
		this.defaultParams = { questionnaireId };
	}

	async loadList(queryParams): Promise<void> {
		this.documents.$resolved = false;
		const params = { ...this.getClearParams(queryParams), ...this.defaultParams };
		this.availableDocuments = this.questionnairesService.availableDocuments(this.defaultParams);
		this.documents = this.questionnairesService.documents(params);
		this.documents.$resolved = !!await this.documents.$promise && !!await this.availableDocuments.$promise;
	}

	edit(): void {
		this.editing = true;
	}

	cancel(): void {
		this.editing = false;
		this.selectedDocuments = [];
	}

	async update(): Promise<void> {
		try {
			this.documents.$resolved = false;
			await this.questionnairesService.updateDocuments(this.defaultParams, this.selectedDocuments).$promise;
			this.selectedDocuments = [];
			this.cancel();
			// if backend sends full structure of docs we will be able not to update page
			await this.loadList(this.params);
			this.notify(`Document has been successfully added.`, 6000);
		} catch (e) {
			this.documents.$resolved = true;
		}
	}

	notify(message: string, timeout: number): void {
		this.$state.notify(this.$state.current.name, {
			notification: {
				type: 'alert-success',
				message,
				timeout,
			},
		});
		this.isNotificationSet = true;

		setTimeout(() => {
			this.isNotificationSet = false;
		}, timeout);
	}

	remove({ investorClientDocumentId, documentName, documentType, financialDocumentType }: QuestionnaireDocument): void {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: 'Remove Document Confirmation',
				message: `You are removing the document <b>${documentName}</b> from the questionnaire <b>${this.parent.item.name}.</b>`,
				confirmText: 'Confirm',
				onConfirm: async () => {
					this.modalRef.content.resolving = true;
					try {
						const { page } = this.params;
						if (documentType === 'FINANCIAL_DOC') {
							await this.questionnairesService.removeFinancialDocuments(
								this.questionnaireId, financialDocumentType,
							).toPromise();
						} else {
							await this.questionnairesService.removeDocuments(
								{ ...this.defaultParams, investorClientDocumentId },
							).$promise;
						}

						if (this.documents.length === 1 && page > 0) {
							this.params$.next({...this.params$.getValue(), page: page - 1});
						}

						await this.loadList(this.params);
						this.editing = false;
					} catch (e) {
						// handle error
					}
					this.modalRef.content.resolving = false;
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	addFinancialsModal(): void {
		this.modalRef = this.modalService.show(ConfigureFinancialDocumentsModalComponent, {
			initialState: {
				requestsData$: forkJoin({
					requests: this.questionnairesService.financialRequests(this.questionnaireId),
					values: this.questionnairesService.financialRequestValues(this.questionnaireId),
				}).pipe(
					catchError((error) => {
						this.modalRef.hide();
						throw error;
					}),
					tap(({ requests }) => {
						this.modalRef.content.disabledSave = this.modalRef.content.notValid(requests);
					}),
					finalize(() => this.modalRef.content.resolving = false),
				),
				onConfirm: async (requests: FinancialRequests[]) => {
					this.modalRef.content.resolving = true;

					try {
						await this.questionnairesService.updateFinancialRequests(this.questionnaireId, requests).toPromise();

						this.modalRef.content.resolving = false;
						this.modalRef.hide();
						await this.loadList(this.params);
					} catch (e) {
						// error
					}
				},
			},
			class: 'modal-md modal-new',
		});
	}
}
