import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import {
	SupportingDocumentComponent,
	SupportDocumentHistoryComponent,
	SupportingDocumentEditModalComponent,

} from '.';

const components = [
	SupportingDocumentComponent,
	SupportDocumentHistoryComponent,
	SupportingDocumentEditModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        PipesModule,
    ],
    declarations: [
        ...components,
    ],
    exports: [
        ...components,
    ]
})
export class SupportingDocumentsModule {}
