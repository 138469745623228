import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { LenderSolutionCenterComponent } from './solution-center.component';
import { LenderSolutionCenterListComponent } from './search/solution-center-list.component';
import { ProductContactUsModalComponent } from 'tpo/solution-center/tabs/products/product-contact-us-modal/product-contact-us-modal.component';
import { ContactInformationModalComponent } from 'tpo/solution-center//tabs/channels/contact-information-modal/contact-information-modal.component';
import { LenderSolutionCenterService } from 'tpo/solution-center/solution-center.service';
import { ShareSolutionCenterModule } from 'shared/solution-center/solution-center.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        ShareSolutionCenterModule,
        PipesModule,
    ],
    declarations: [
        LenderSolutionCenterComponent,
        LenderSolutionCenterListComponent,
        ProductContactUsModalComponent,
        ContactInformationModalComponent,
    ],
    providers: [
        LenderSolutionCenterService,
    ],
})
export class LenderSolutionCenterModule {
}