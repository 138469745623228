import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { SCProduct } from 'lender/solution-center/solution-center.service';

@Component({
    selector: 'sc-coc-single-product',
    templateUrl: './single-product.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCocSingleProductComponent {
    product = input.required<SCProduct>();
    action = output<SCProduct>();
}
