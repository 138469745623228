import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import {
    SC_CHANNEL_ACTION,
    LenderChannel,
    SC_CHANNEL_STATUS,
    SC_CHANNEL_HISTORY_ACTION,
} from 'shared/solution-center/solution-center.common';
import { ApplicationHistory } from 'tpo/solution-center/tpo-sc-types';

@Component({
    selector: 'sc-coc-channels',
    templateUrl: './channels.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCocChannelsComponent {
    channelList = input.required<LenderChannel[]>();
    applicationHistory = input<ApplicationHistory[]>([]);
    SC_CHANNEL_STATUS = SC_CHANNEL_STATUS;

    channelAction = output<{channel: LenderChannel, action: SC_CHANNEL_ACTION}>();
    applicationHistoryAction = output<{applicationHistory: ApplicationHistory, action: SC_CHANNEL_HISTORY_ACTION}>();
    sortApplicationHistoryAction = output<string>();

    SC_CHANNEL_ACTION = SC_CHANNEL_ACTION;
    SC_CHANNEL_HISTORY_ACTION = SC_CHANNEL_HISTORY_ACTION;

    theOrder: Record<string, 'asc' | 'desc'> = {
        actionDate: 'desc',
    };

    onSortClick(orderKey: string): void {
        const isAsc = this.theOrder[orderKey] === 'asc';
        this.sortApplicationHistoryAction.emit(orderKey);
        this.theOrder = {
            [orderKey] : isAsc ? 'desc': 'asc',
        };
    }
}
