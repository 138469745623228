import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { SCCustomerCardOverview } from 'shared/solution-center/solution-center.common';

@Component({
    selector: 'sc-coc-overview',
    templateUrl: './overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCocOverviewComponent {
    companyData = input.required<SCCustomerCardOverview>();
    labels = computed<string[]>(() => {
        const { loanPrograms, tags } = this.companyData();

        return [
            ...loanPrograms.map(({ name }) => name),
            ...tags.map(({ name }) => name),
        ];
    });
}
