import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';

import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { GenericSideMenuItem } from 'commons/components/navigation/menu/side/side-menu.component';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { CcmServiceStatusService } from '../ccm-service-status.service';
import { TpoServiceInformation } from 'comergence/customers/tpo-services/tpo-service.interface';


export interface CcmCustomerSideMenuItem extends GenericSideMenuItem {
    items?: CcmCustomerSideMenuItem[];
	serviceStatus?: Observable<string>;
}

@Component({
    selector: 'ccm-customers-menu',
    templateUrl: 'ccm-customers-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcmCustomersMenuComponent extends SideMenuContainerComponent<CcmCustomerSideMenuItem> {
    private readonly tpoServiceStatusSubject: Subject<string> = new BehaviorSubject('INACTIVE');
    private readonly investorMarketingServiceStatusSubject: Subject<string> = new BehaviorSubject('INACTIVE');
    private readonly apiServiceStatusSubject: Subject<string> = new BehaviorSubject('INACTIVE');
    private readonly productsServiceStatusSubject: Subject<string> = new BehaviorSubject('INACTIVE');

    private servicesUpdatedSubscription: Subscription;

    statusClasses = {
        ACTIVE: 'status-active-new',
        PENDING: 'status-pending-new',
        INACTIVE: 'status-inactive-new',
        SUSPENDED: 'status-suspended-new',
    };

    constructor(
        changeDetector: ChangeDetectorRef,
        routerGlobals: UIRouterGlobals,
        userService: UserService,
        private readonly ccmServiceStatusService: CcmServiceStatusService,
    ) {
        super(changeDetector, routerGlobals);

        this.menuUpdate$ = combineLatest([ userService.profile$, routerGlobals.success$ ]).pipe(
            takeUntil(this.destroyed$),
        );
    }

    // Override
    ngOnInit() {
        super.ngOnInit();

        const organizationID: string = this.router.params.organizationId;
        const fetchServiceStatuses$ = this.ccmServiceStatusService
            .getServiceInformation(organizationID)
            .pipe(
                shareReplay(1),
                takeUntil(this.destroyed$)
            );
        fetchServiceStatuses$
            .pipe(map((tpoServiceInformation: TpoServiceInformation) => tpoServiceInformation.INVESTOR.statusCode))
            .subscribe(this.tpoServiceStatusSubject);
        fetchServiceStatuses$
            .pipe(map((tpoServiceInformation: TpoServiceInformation) => tpoServiceInformation.INVESTOR_MARKETING.statusCode))
            .subscribe(this.investorMarketingServiceStatusSubject);
        fetchServiceStatuses$
            .pipe(map((tpoServiceInformation: TpoServiceInformation) => tpoServiceInformation.API.statusCode))
            .subscribe(this.apiServiceStatusSubject);
        fetchServiceStatuses$
            .pipe(map((tpoServiceInformation: TpoServiceInformation) => tpoServiceInformation.PRODUCTS.statusCode))
            .subscribe(this.productsServiceStatusSubject);

        this.populateMenu();

        this.ccmServiceStatusService.fetchServiceStatuses();
    }

    // Override
    ngOnDestroy() {
        if (this.servicesUpdatedSubscription) {
            this.servicesUpdatedSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    populateMenu = () => {
        const thisRoute = 'customers.:customerType.:organizationId';
        this.menuItems = [
            {
                title: 'Customer Information',
                link: `${thisRoute}.information`,
            },
            {
                title: 'Manage Users',
                link: `${thisRoute}.manage`,
            },
            {
                title: 'Services',
                items: [
                    {
                        title: 'Third Party Oversight',
                        link: `${thisRoute}.services.tpo`,
                        serviceStatus: this.tpoServiceStatusSubject,
                    },
                    {
                        title: 'Investor Marketing',
                        link: `${thisRoute}.services.crm`,
                        serviceStatus: this.investorMarketingServiceStatusSubject,
                    },
                    {
                        title: 'API',
                        link: `${thisRoute}.services.api`,
                        serviceStatus: this.apiServiceStatusSubject,
                    },
                    {
                        title: 'Products',
                        link: `${thisRoute}.services.products`,
                        serviceStatus: this.productsServiceStatusSubject,
                    },
                ],
            },
        ];
        this.changeDetector.markForCheck();
    }
}
