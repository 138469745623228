import { Injectable } from '@angular/core';

import { DocumentsDocusignConnectivityStatus } from './documents/docusign/documents-docusign-connectivity-status';

@Injectable({ providedIn: 'root' })
export class LenderIssuesStatusService {

	constructor(
		public readonly documentsDocusignConnectivityStatus: DocumentsDocusignConnectivityStatus
    ) { }

    public get documents() {
        return {
            docusign: this.documentsDocusignConnectivityStatus,
        }
    };
}
