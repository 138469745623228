import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { TaskNotificationModule} from 'shared/social-compliance/task-notification';
import { SupportingDocumentsModule } from 'shared/supporting-documents';

import { SocialAccountsModule } from 'tpo/social-accounts';
import { TpoArchiveModule } from 'tpo/social-compliance/archive';

import { AuditsService } from './audits.service';

import { AuditsListComponent } from './list/audits-list.component';
import { AuditsInformationComponent } from './info/audits-information.component';
import { AuditsDetailsComponent } from './details/audits-details.component';
import { ReviewStatusModalComponent } from './info/review-status-modal/review-status-modal.component';
import { AuditStatusModalComponent } from './info/audit-status-modal/audit-status-modal.component';
import { AuditFindingsComponent } from './info/audit-findings/audit-findings.component';
import { AssigneeModalComponent } from './info/assignee-modal/assignee-modal.component';
import { AuditSupportingDocumentsComponent } from './documents/supporting-documents.component';
import { CommentsFilterComponent } from './info/comments-filter/comments-filter.component';
import { AuditSocialAccountsComponent } from './social-accounts/audit-social-accounts.component';
import { AuditAssignedUserModalComponent } from './list/audit-assigned-user-modal/audit-assigned-user-modal.component';
import { AuditListEditDropdownComponent } from './list/audit-list-edit-dropdown/audit-list-edit-dropdown.component';
import { BulkAuditStatusModalComponent } from './list/audit-status-modal/audit-status-modal.component';

const components = [
	// List
	AuditsListComponent,
    AuditAssignedUserModalComponent,
    AuditListEditDropdownComponent,
    BulkAuditStatusModalComponent,
	// Information
	AuditsInformationComponent,
	AuditsDetailsComponent,
	ReviewStatusModalComponent,
	AuditStatusModalComponent,
	AssigneeModalComponent,
	AuditFindingsComponent,
	AuditSupportingDocumentsComponent,
	CommentsFilterComponent,
	// Accounts
	AuditSocialAccountsComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        PopoverModule,
        ButtonsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        TaskNotificationModule,
        SupportingDocumentsModule,
        SocialAccountsModule,
        TpoArchiveModule,
    ],
    declarations: [
        ...components,
    ],
    providers: [
        AuditsService,
    ]
})

export class AuditsModule {}
