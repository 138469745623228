<div class="content">
    <div *ngIf="!editing" class="pull-right margin-bottom-sm">
        <button type="button" class="btn btn-primary" (click)="edit()">
            <span class="glyphicon glyphicon-pencil"></span> Edit
        </button>
    </div>
    <form [formGroup]="form" (onValidSubmit)="submit()" class="form-vertical">
        <form-errors [form]="form"></form-errors>
        <div class="rows-striped" *ngIf="solutionCenter.cardData() as cardData">
            <div class="row-fluid">
                <div class="col-md-6 form-group" has-error>
                    <label class="control-label required">
                        {{form.getFieldLabel('companyName')}}
                    </label>
                    <div [isEditable]="editing">
                        <span view class="control-value">{{cardData.companyName || '&ndash;'}}</span>
                        <input
                            edit
                            type="text"
                            name="companyName"
                            formControlName="companyName"
                            class="form-control"
                            maxlength="100" />
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label">
                        {{form.getFieldLabel('companyContactName')}}
                    </label>
                    <div [isEditable]="editing">
                        <span view class="control-value">{{cardData.companyContactName || '&ndash;'}}</span>
                        <input
                            edit
                            type="text"
                            name="companyContactName"
                            formControlName="companyContactName"
                            class="form-control"
                            maxlength="100" />
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-6 form-group" has-error>
                    <label class="control-label">
                        {{form.getFieldLabel('companyPhone')}}
                    </label>
                    <div [isEditable]="editing">
                        <span view class="control-value">{{cardData.companyPhone || '&ndash;' | tel}}</span>
                        <input
                            phoneFormatter
                            edit
                            name="companyPhone"
                            formControlName="companyPhone"
                            class="form-control"
                            maxlength="100" />
                    </div>
                </div>
                <div class="col-md-6 form-group" has-error>
                    <label class="control-label">
                        {{form.getFieldLabel('companyEmail')}}
                    </label>
                    <div [isEditable]="editing">
                        <span view class="control-value">{{cardData.companyEmail || '&ndash;'}}</span>
                        <input
                            edit
                            type="text"
                            name="companyEmail"
                            formControlName="companyEmail"
                            class="form-control"
                            maxlength="100" />
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="control-label">
                        {{form.getFieldLabel('companyWebsite')}}
                    </label>
                    <div [isEditable]="editing">
                        <span view class="control-value">{{cardData.companyWebsite || '&ndash;'}}</span>
                        <input
                            edit
                            type="text"
                            name="companyWebsite"
                            formControlName="companyWebsite"
                            class="form-control"
                            maxlength="100" />
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label">
                        {{form.getFieldLabel('companyAddress')}}
                    </label>
                    <div [isEditable]="editing">
                        <span view class="control-value">{{cardData.companyAddress || '&ndash;'}}</span>
                        <input
                            edit
                            type="text"
                            name="companyAddress"
                            formControlName="companyAddress"
                            class="form-control"
                            maxlength="300" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid" *ngIf="solutionCenter.cardData() as cardData">
            <div class="rows-white">
                <div class="row-fluid">
                    <div class="col-md-6">
                        <label class="control-label required">
                            {{form.getFieldLabel('companyLogo')}}
                        </label>

                        <div *ngIf="!cardData.hasCompanyLogo && !editing">
                            No Image Uploaded
                        </div>

                        <div class="image-wrapper" *ngIf="cardData.hasCompanyLogo && !editing">
                            <img [src]="cardData.companyLogo" />
                        </div>

                        <ng-container *ngIf="editing">
                            <div [ngSwitch]="!cardData.originalCompanyLogo">
                                <uploader #uploader [customTemplate]="fileTemplate" *ngSwitchCase="true" [config]="{ accept: '.png, .jpg, .jpeg' }" (select)="onLogoPick($event)">
                                    <ng-template #fileTemplate>
                                        <div [ngClass]="{'file-error': uploadLogoError }" class="uploader-container flex-row center gap" (fileDrop)="uploader.select.emit($event)">
                                            <label class="btn btn-secondary">
                                                Upload File
                                                <input
                                                    type="file"
                                                    (input)="uploader.onFileInput($event)"
                                                    [multiple]="uploader.config.multiple"
                                                    [accept]="uploader.config.accept">
                                            </label>
                                            <span class="text-muted">or Drag and Drop here</span>
                                        </div>
                                    </ng-template>
                                </uploader>
                                <ng-container *ngSwitchDefault>
                                    <realm-image-cropper
                                            [image]="cardData.originalCompanyLogo"
                                            [crop]="cardData.companyLogoCoordinates"
                                            (cropChange)="onCropChange($event)"
                                            minHeight="256"
                                            maxHeight="256"
                                            [aspectRange]="[1, 4]"
                                            (update)="onCroppedLogoUpdate($event)"
                                            style="--aspect: 16/9;">
                                    </realm-image-cropper>

                                    <uploader #uploader [customTemplate]="fileTemplate" [config]="{ accept: '.png, .jpg, .jpeg' }" (select)="onLogoPick($event)">
                                        <ng-template #fileTemplate>
                                            <label class="text-link weight-normal">
                                                Replace Image&nbsp;
                                                <input type="file" (input)="uploader.onFileInput($event)" [multiple]="uploader.config.multiple" [accept]="uploader.config.accept">
                                            </label>
                                        </ng-template>
                                    </uploader>
                                    <a class="btn btn-icon btn-tertiary" (click)="removeLogo()">
                                        <span class="glyphicon glyphicon-trash"></span>
                                    </a>

                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-6">
                        <label class="control-label">
                            {{form.getFieldLabel('marketingLogo')}}
                        </label>

                        <div *ngIf="!cardData.hasMarketingLogo && !editing">
                            No Image Uploaded
                        </div>

                        <div class="image-wrapper" *ngIf="cardData.hasMarketingLogo && !editing">
                            <img [src]="cardData.marketingLogo" />
                        </div>

                        <ng-container *ngIf="editing">
                            <div [ngSwitch]="!cardData.originalMarketingLogo">
                                <uploader #uploader [customTemplate]="fileTemplate" *ngSwitchCase="true" [config]="{ accept: '.png, .jpg, .jpeg' }" (select)="onMarketingLogoPick($event)">
                                    <ng-template #fileTemplate>
                                        <div [ngClass]="{'file-error': uploadMarketingLogoError }" class="uploader-container flex-row center gap" (fileDrop)="uploader.select.emit($event)">
                                            <label class="btn btn-secondary">
                                                Upload File
                                                <input
                                                        type="file"
                                                        (input)="uploader.onFileInput($event)"
                                                        [multiple]="uploader.config.multiple"
                                                        [accept]="uploader.config.accept">
                                            </label>
                                            <span class="text-muted">or Drag and Drop here</span>
                                        </div>
                                    </ng-template>
                                </uploader>
                                <ng-container *ngSwitchDefault>
                                    <realm-image-cropper
                                            [image]="cardData.originalMarketingLogo"
                                            [crop]="cardData.marketingLogoCoordinates"
                                            (cropChange)="onMarketingLogoCropChange($event)"
                                            minHeight="256"
                                            maxHeight="256"
                                            (update)="onCroppedMarketingLogoUpdate($event)"
                                            style="--aspect: 16/9;">
                                    </realm-image-cropper>

                                    <uploader #uploader [customTemplate]="fileTemplate" [config]="{ accept: '.png, .jpg, .jpeg' }" (select)="onMarketingLogoPick($event)">
                                        <ng-template #fileTemplate>
                                            <label class="text-link weight-normal">
                                                Replace Image&nbsp;
                                                <input type="file" (input)="uploader.onFileInput($event)" [multiple]="uploader.config.multiple" [accept]="uploader.config.accept">
                                            </label>
                                        </ng-template>
                                    </uploader>
                                    <a class="btn btn-icon btn-tertiary" (click)="removeMarketingLogo()">
                                        <span class="glyphicon glyphicon-trash"></span>
                                    </a>

                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row-fluid" *ngIf="editing && cardData.hasMarketingLogo">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <label class="control-label">
                            {{form.getFieldLabel('marketingLogoPageWidth')}}
                        </label>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{cardData.marketingLogoPageWidth || '&ndash;'}}</span>
                            <ng-select
                                edit
                                formControlName="marketingLogoPageWidth"
                                [items]="marketingLogoPageWidth"
                                [clearable]="false"
                                [closeOnSelect]="true"
                                placeholder="Select Ratio"
                                bindLabel="label"
                                bindValue="value">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rows-white">
                <div class="row-fluid">
                    <div class="col-md-12">
                        <div class="flex-row">
                            <label class="control-label">
                                {{form.getFieldLabel('description')}}
                            </label>
                        </div>
                        <div [ngSwitch]="editing">
                            <quill-view *ngSwitchDefault [content]="cardData.description" [sanitize]="true" theme="snow"></quill-view>
                            <ng-container *ngSwitchCase="true">
                                <quill-editor
                                    [styles]="{ height: '200px' }"
                                    formControlName="description"
                                    theme="snow">
                                </quill-editor>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rows-striped" *ngIf="solutionCenter.cardData() as cardData">
            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="control-label">{{form.getFieldLabel('tags')}}</label>
                    <div [isEditable]="editing">
                        <ng-container view *ngIf="cardData.tags?.length; else dash;">
                            <div *ngFor="let tag of cardData.tags; let isLast = last;">
                                {{ tag.name }}{{isLast ? '' : ', '}}
                            </div>
                        </ng-container>
                        <ng-select
                           edit
                           name="tags"
                           formControlName="tags"
                           [items]="tags | async"
                           [multiple]="true"
                           [clearable]="true"
                           [closeOnSelect]="false"
                           placeholder="Select Tags"
                           bindLabel="name">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label">{{form.getFieldLabel('loanPrograms')}}</label>
                    <div [isEditable]="editing">
                        <ng-container view *ngIf="cardData.loanPrograms?.length; else dash;">
                            <div *ngFor="let program of cardData.loanPrograms; let isLast = last;">
                                {{ program.name }}{{isLast ? '' : ', '}}
                            </div>
                        </ng-container>
                        <ng-select
                            class="z-index-1"
                            edit
                            name="loanPrograms"
                            formControlName="loanPrograms"
                            [items]="loanPrograms | async"
                            [multiple]="true"
                            [clearable]="true"
                            [closeOnSelect]="false"
                            placeholder="Select Programs"
                            bindLabel="name">
                        </ng-select>
                    </div>
                </div>
            </div>
            <ng-container formGroupName="medialinks" *ngIf="form.controls.medialinks as medialinks">
                <div class="row-fluid">
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{medialinks.getFieldLabel('FB')}}
                        </label>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{medialinks.value.FB || '&ndash;'}}</span>
                            <input
                                edit
                                type="text"
                                formControlName="FB"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{medialinks.getFieldLabel('IG')}}
                        </label>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{medialinks.value.IG || '&ndash;'}}</span>
                            <input
                                edit
                                formControlName="IG"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{medialinks.getFieldLabel('LI')}}
                        </label>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{medialinks.value.LI || '&ndash;'}}</span>
                            <input
                                edit
                                type="text"
                                formControlName="LI"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{medialinks.getFieldLabel('X')}}
                        </label>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{medialinks.value.X || '&ndash;'}}</span>
                            <input
                                edit
                                formControlName="X"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{medialinks.getFieldLabel('YT')}}
                        </label>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{medialinks.value.YT || '&ndash;'}}</span>
                            <input
                                edit
                                type="text"
                                formControlName="YT"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>
            </ng-container>
        </div>

        <!-- Comment until needed -->
        <div *ngIf="editing" class="sticky-footer text-right z-index-9">
            <button type="submit" class="btn btn-primary">Publish</button>
            <a (click)="cancel()" class="btn btn-secondary">Cancel</a>
        </div>
    </form>
</div>

<ng-template #dash><div>&ndash;</div></ng-template>
