import { setTheme } from 'ngx-bootstrap/utils';
import { ApplicationRef, DoBootstrap, ErrorHandler, NgModule } from '@angular/core';

import { services } from './commons/services';
import { RealmModules } from './realm.modules';

import { httpInterceptorProviders } from 'commons/services/http/http-interceptors.provider';
import { GlobalErrorHandlerService } from 'commons/services/error/global-error-handler.service';
import { CompatibilityInjectorService } from 'commons/services/compatibility/compat-injector.service';
import { AngularJSServiceProviders } from 'angularjs-providers';
import { RealmApp } from 'realm-app.component';
import { ThirdPartyModules } from 'third-party.modules';
import { GlobalComponents } from 'global-elements/global.components';

@NgModule({
    imports: [
        ...ThirdPartyModules,
        ...RealmModules,
    ],

	providers: [
        { provide: '$injector', useClass: CompatibilityInjectorService },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        ...AngularJSServiceProviders,
		...services,
        httpInterceptorProviders,
	],

	declarations: [
        RealmApp,
        ...GlobalComponents,
    ],
})
export class RealmNewApp implements DoBootstrap {
	constructor( ) {
		setTheme('bs4');
	}

    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(RealmApp);
    }
}
