<div class="split-view remote-resource" [class.resolved]="!resolving">
    <div class="configuration">
        <div class="gray-head">
            <div class="header-block flex-row gap-m">
                <h2 class="no-margin">Solution Center Information</h2>
                <toggle
                    [ngModel]="cardData()?.visible"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="disableVisibilityToggle"
                    [loading]="visibilityResolving"
                    (toggle)="onChangeVisibility()"
                ></toggle>
                <ng-container [ngSwitch]="cardData()?.visible">
                    <span class="label label-success text-smaller" *ngSwitchCase="true">Visible</span>
                    <span class="label label-default text-smaller" *ngSwitchDefault>Hidden</span>
                </ng-container>
                <div class="flex-grow text-right" *ngIf="User.isCustomer">
                    <a class="btn btn-tertiary" href="/help/authenticated/customer/solution-center-information" target="_blank">
                        <i class="glyphicon glyphicon-question-sign"></i> Help
                    </a>
                </div>
            </div>
            <ul class="tab-menu">
                <ng-container *ngFor="let tab of tabList">
                    <li uiSrefActive="active" *ngIf="visibleTabs()[tab.code]">
                        <a [uiSref]="tab.investorRoute"><b>{{tab.title}}</b></a>
                    </li>
                </ng-container>
            </ul>
        </div>

        <div class="tab-content" ui-view></div>
    </div>
    <div class="preview">
        <div class="flex-row">
            <h2 class="flex-grow">Preview</h2>
        </div>
        <div class="scalable-content" #scalableElement [style.scale]="previewScale()">
            <sc-company-card [isPreview]="true" [companyData]="cardData()" class="mb20"></sc-company-card>
            <sc-company-overview-card
                [isPreview]="true"
                [companyData]="cardData()"
                [productList]="productList()"
                [productOverview]="productOverview()"
                [channelList]="channelList()"
                [currentTab]="currentTab()"
                [visibleTabs]="visibleTabs()"
            ></sc-company-overview-card>
        </div>
        <div [style.height]="previewMaxHeight()"></div>
    </div>
</div>
