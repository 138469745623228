import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DocumentCardSummary } from 'shared/document-schedule-settings-card/document-card.interface';
import { FinancialQuarters } from 'commons/services/financial-documents.interface';
import { requestMessageCharsAmount } from 'lender/clients/$id/documents/common/request-document-modal/request-document-modal.form';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { UserService } from 'angularjs-providers/user.provider';
import { ChannelBulkRequestInterface } from 'shared/document-schedule-settings-card/card/channels/bulk-request-modal/channel-bulk-request.interface';
import { FinancialDocumentScheduleSettingsService } from 'shared/financial-documents/financial-document-schedule-settings.service';
import { BulkRequestSnapshot } from 'lender/manage-channels/manage-channel-card.interface';

const currentYear = (new Date).getFullYear();

@Component({
    templateUrl: 'channel-bulk-request-modal.component.html',
})
export class ChannelBulkRequestModalComponent implements OnInit {
    channelId: number;
    lenderId: number;
    document: DocumentCardSummary;
    years: number[] = (new Array(3)).fill(0).map((v, i) => (currentYear - i)).reverse();
    quarters = FinancialQuarters;
    resolved: boolean = true;

    hasSnapshot: boolean = false;
    bulkRequest: BulkRequestSnapshot;

    requestForm = new RealmFormGroup(
        {
            isAudited: new RealmFormControl(
                'isAudited',
                {
                    label: 'Type',
                    updateOn: 'change',
                },
                Validators.required,
            ),
            period: new RealmFormControl(
                'period',
                {
                    label: 'Period',
                },
                Validators.required,
            ),
            year: new RealmFormControl(
                'year',
                {
                    label: 'Year',
                },
                Validators.required,
            ),
            notifyClient: new RealmFormControl(
                'notifyClient',
                {
                    label: 'Notify Client',
                    updateOn: 'change',
                    value: true,
                },
            ),
            notifyAssigned: new RealmFormControl(
                'notifyAssigned',
                {
                    label: 'Notify Assigned',
                    updateOn: 'change',
                    value: true,
                },
            ),
            message: new RealmFormControl(
                'message',
                {
                    label: 'Message',
                    updateOn: 'change',
                },
            ),
        },
        {
            validators: this.messageFieldValidation,
        }
    );

    constructor(
        protected readonly modalRef: BsModalRef,
        protected user: UserService,
        protected automatedRequestSettingsService: FinancialDocumentScheduleSettingsService
    ) {
    }

    ngOnInit(): void {
        if (this.hasSnapshot) {
            this.requestForm.patchValue(this.bulkRequest);
            this.requestForm.disable();
        } else {
            this.changeMessageOnFormFieldUpdate();
            if (this.document) {
                this.getMessage();
            }
        }
    }

    getMessage() {
       this.requestForm?.get('message').patchValue(this.updateMessage());
    }

    changeMessageOnFormFieldUpdate() {
        this.requestForm.valueChanges
            .pipe(
                map(({ isAudited, year, period }) => ({ isAudited, year, period })),
                distinctUntilChanged(isEqual),
            )
            .subscribe(() => {
                this.getMessage()
            });
    }

    updateMessage(): string {
        const { name: organizationName } = this.user.profile.organization;
        const { name: documentName } = this.document;
        const { isAudited, year, period } = this.requestForm.value;
        const periodText = (year && period) ? ` for ${period} ${year}` : ''
        return `${organizationName} is requesting that you upload${this.getAuditedText(isAudited)} ${documentName}${periodText}.` +
            '\n\n' +
            'Navigate to Financials: https://comergence.com/#/account/financials' +
            '\n\n' +
            'Thank you,' +
            '\n' +
            'Optimal Blue Customer Support';
    }

    getAuditedText(value: boolean): string {
        if (value === true) {
            return ' Audited';
        } else if (value === false) {
            return ' Unaudited';
        } else {
            return '';
        }
    }

    async makeRequest() {
        const requestData: ChannelBulkRequestInterface = this.requestForm.value;
        if (!requestData.notifyAssigned && !requestData.notifyClient) {
            requestData.message = null;
        }

        try {
            this.resolved = false;
            await this.automatedRequestSettingsService.makeFinancialDocumentBulkRequest(this.lenderId, this.document.id, this.channelId, requestData).toPromise();
            this.close();
        } catch ({ error }) {
            this.requestForm.setServerError(error);
        } finally {
            this.resolved = true;
        }
    }

    close() {
        this.modalRef.hide();
    }

    getCharsCount(): number {
        return Math.max(0, (requestMessageCharsAmount - (this.requestForm.value?.message?.length ?? 0)));
    }

    messageFieldValidation(formGroup: UntypedFormGroup): ValidationErrors {
        if (formGroup.controls['notifyClient'].value || formGroup.controls['notifyAssigned'].value ) {
            formGroup.controls['message'].setValidators(Validators.required);
            if (!formGroup.controls['message'].value) {
                formGroup.controls['message'].markAsTouched();
            }
        } else {
            formGroup.controls['message'].clearValidators();
            formGroup.controls['message'].markAsUntouched();
        }

        return null;
    }

    resetPeriod() {
        const periodField =  this.requestForm.get('period') as RealmFormControl;
        if (periodField.serverError) {
            periodField.serverError = '';
            periodField.updateValueAndValidity();
        }
    }
}
