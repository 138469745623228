import { every, keys, values, keyBy, forIn, map, pick, defaultsDeep } from 'lodash';
import { Component, forwardRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { IPromise } from 'angular';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';

import { ListComponent, PagedListComponent } from 'commons/components/list';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { NgResourceArray, NgResourceObject } from 'commons/interfaces';

import { ReviewWidgetResourceService } from '../widgets-resource.service';
import { ReviewWidgetService, Widget, Source } from '../widgets.service';
import { widgetFilters } from '../widget-filters';

@Component({
	templateUrl: './edit.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => EditWidgetComponent) },
	],
})
export class EditWidgetComponent extends PagedListComponent {
	static listName = 'edit-sources';

	isNew: boolean;
	sources: NgResourceArray<Source> = [];
	widget: NgResourceObject<Widget> = {
		name: '',
		showLast: 9,
		sources: [],
	};
	widgetId: number;

	selectedSources: { [id: number]: Source } = {};
	selectedAll: boolean = false;
	selectedAmount: number = 0;
	saving: boolean;

	editWidgetForm: RealmFormGroup = new RealmFormGroup({
		widgetName: new RealmFormControl(
			'widgetName',
			{ label: 'Widget Name', updateOn: 'change' },
			[Validators.required],
		),
		showLast: new RealmFormControl(
			'showLast',
			{ label: 'Show Last', updateOn: 'change', value: this.widget.showLast },
		),
	});

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		{ params: { widgetId } }: UIRouterGlobals,
		public widgetsService: ReviewWidgetService,
		public reviewWidgetResourceService: ReviewWidgetResourceService,
		public injector: Injector,
	) {
		super(transitionService, stateService);
		this.isNew = stateService.includes('**.widgets.new.**');
		if (!this.isNew) {
			this.widgetId = widgetId;
			this.widgetsService.widget(widgetId).$promise
				.then((widget: NgResourceObject<Widget>) => {
					this.widget = widget;
					this.editWidgetForm.patchValue({
						widgetName: widget.name,
						showLast: widget.showLast,
					});
					this.addSourceArray(widget.sources);
				});
		}
	}

	ngOnInit(): void {
		this.configureFilters();
		super.ngOnInit();
	}

	configureFilters(): void {
		const { entityTypes } = widgetFilters;
		const accounts = this.isNew
			? this.widgetsService.widgetsAccountTypes()
			: this.widgetsService.widgetAccountTypes(this.widgetId);

		this.filters = {
			accountTypes: accounts,
			entityTypes,
		};

		this.defaultFilters = {
			...this.defaultFilters,
			accountTypes: [],
			entityTypes: [],
		};
	}

	loadList(params): IPromise<void> {
		this.sources.$resolved = false;
		!this.isNew && (params.widgetId = this.widgetId);
		const normalizedParams = this.reviewWidgetResourceService.getParamsFromSources(params);

		return this.widgetsService[this.isNew ? 'sources' : 'widgetSourcesAll'](this.getClearParams(normalizedParams)).$promise
			.then((data: NgResourceArray<Source>) => {
				this.sources = data;
				this.selectedAll = this.isAllVisible();

			}).finally(() => {
				this.sources.$resolved = true;
			});
	}

	setFilter(params, resetPage: boolean = true): void {
		forIn(params, (value, key) => {
			switch (key) {
				default:
					params[key] = map(value || [], 'id');
			}
		});

		super.setFilter(params, resetPage);
	}

	save(): void {
		this.saving = true;
		this.widget.sources = values(this.selectedSources);
		this.widgetsService[this.isNew ? 'save' : 'update'](
			this.widget,
			({ widgetId }: Widget) => {
				this.saving = false;
				this.stateService.go(this.isNew ? '^.:widgetId' : '^', { widgetId });
			},
			({ data }) => {
				this.editWidgetForm.setServerError(data);
				this.saving = false;
			});
	}

	countAmount(): void {
		this.selectedAmount = keys(this.selectedSources).length;
	}

	addSource(source: Source): void {
		this.selectedSources[source.compositeId] = source;
		this.countAmount();
	}

	addAllSources(): void {
		const appliedFilters = { widgetId: this.widgetId };
		// RM-28146: we have to add filters from original list request
		const sourceParams = this.reviewWidgetResourceService.getParamsFromSources(this.params);
		const originalFilters = pick(sourceParams, 'customer', 'entityTypes', 'provider');
		const resultParams = defaultsDeep(appliedFilters, originalFilters);

		this.sources.$resolved = false;
		this.widgetsService[this.isNew ? 'sources' : 'widgetSourcesAll'](this.getClearParams(resultParams)).$promise
			.then((data) => {
				this.addSourceArray(data);
				this.selectedAll = true;
			})
			.finally(() => {
				this.sources.$resolved = true;
			});
	}

	addSourceArray(sources: Array<Source>): void {
		this.resetSources();
		this.selectedSources = keyBy(sources, 'compositeId');
		this.countAmount();
	}

	removeSource(source: Source): void {
		this.selectedAll = false;
		delete this.selectedSources[source.compositeId];
		this.countAmount();
	}

	resetSources(): void {
		this.selectedAll = false;
		this.selectedSources = {};
		this.countAmount();
	}

	checkAll(): void {
		this.selectedAll = true;
		this.sources.forEach((source) => {
			this.selectedSources[source.compositeId] = source;
		});
	}

	handleCheck({ target }: MouseEvent, inputElement: HTMLInputElement): void {
		const { tagName } = target as HTMLElement;
		if (!['INPUT', 'A'].includes(tagName)) {
			inputElement.click();
		}
	}

	isAllVisible = (): boolean => (
		every(this.sources, (source) => this.selectedSources.hasOwnProperty(source.compositeId))
	);

	onInputChange(checked: boolean, source: Source): void {
		checked ? this.addSource(source) : this.removeSource(source);
		this.selectedAll = this.isAllVisible();
	}
}
