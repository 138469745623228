import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

import { GroupsResourceService } from '../../../groups-resource.service';
import { CampaignStatuses } from '../../../../campaigns/campaign-statuses';

@Component({
	selector: 'group-sharing-campaigns',
	templateUrl: './group-sharing-campaigns.component.html',
})
export class GroupSharingCampaignsComponent extends PagedListComponent {
	static listName = 'groupSharingCampaigns';

	list: any = [];
	campaignStatuses: any = CampaignStatuses;
	showAddCampaigns: any;
	addCampaignsForm: RealmFormGroup = new RealmFormGroup({
		campaignIds: new RealmFormControl(
			'campaignIds',
			{
				label: 'Campaigns',
			},
			[
				Validators.required,
			],
		),
	});
	addCampaignsCandidates: any = [];
	removeCampaignId: number;
	modalRef: BsModalRef;
	User: any;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public modalService: BsModalService,
		public userService: UserService,
		public groupsResource: GroupsResourceService,
	) {
		super(transitionService, stateService);

		const { groupId } = stateService.params;
		const params = {
			groupId,
		};

		this.defaultFilters = {
			...this.defaultFilters,
			size: 5,
			...params,
		};

		this.omitParams = [
			...this.omitParams,
			...Object.keys(params),
		];

		this.User = userService.profile;
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.groupsResource.groupSharingCampaigns.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			});
	}

	toggleAddCampaigns(state): void {
		// skip no change
		if (this.showAddCampaigns === state) {
			return;
		}

		this.showAddCampaigns = state;

		if (this.showAddCampaigns) {
			// reset remove
			this.resetRemoveCampaign();

			// refresh select options
			const { groupId } = this.stateService.params;
			const params = {
				groupId,
			};
			this.addCampaignsCandidates = this.groupsResource.groupSharingCampaigns.getCampaignsCandidates(params);
		} else {
			this.addCampaignsForm.reset();
		}
	}

	submitAddCampaigns(): void {
		const { groupId } = this.stateService.params;
		const params = {
			groupId,
		};
		const { campaignIds } = this.addCampaignsForm.getRawValue();
		const request = {
			campaignIds,
		};

		this.list.$resolved = false;
		this.groupsResource.groupSharingCampaigns.add(
			params,
			request,
		).$promise
			.then(
				() => {
					this.toggleAddCampaigns(false);
					this.loadList(this.params);
				},
				({ data }) => {
					this.list.$resolved = true;
					this.addCampaignsForm.setServerError(data);
				},
			);
	}

	removeCampaign(campaignId): void {
		// reset add
		this.toggleAddCampaigns(false);

		this.removeCampaignId = campaignId;
	}

	resetRemoveCampaign = (): void => {
		this.removeCampaignId = null;
	}

	confirmRemoveCampaign(): void {
		const { groupId } = this.stateService.params;
		const { removeCampaignId: campaignId } = this;

		const params = {
			groupId,
			campaignId,
		} as any;

		const processRemoveCampaign = (): void => {
			this.list.$resolved = false;
			this.groupsResource.groupSharingCampaign.remove(params).$promise
				.then((): void => {
					this.list = this.list.filter(({ id }): boolean => id !== campaignId);
					if (this.list.length === 0 && this.params.page > 0) {
						this.params.page = this.params.page - 1;
					}
					this.loadList(this.params);
				})
				.finally(this.resetRemoveCampaign);
		};

		const checkEnrolledUsers = (): Promise<boolean> => {
			this.list.$resolved = false;
			return this.groupsResource.groupSharingCampaign.checkEnrolledUsers(params).$promise
				.then(({ enrolledUsersExist }): boolean => {
					this.list.$resolved = true;
					return enrolledUsersExist;
				});
		};

		const confirmForceRemoveCampaign = (): Promise<boolean> => (
			new Promise((resolve, reject): void => {
				const initialState = {
					message: 'Removing this campaign from this group will remove all members of the group from the campaign.',
					onConfirm: () => {
						this.modalRef.hide();
						resolve(true);
					},
					onCancel: () => {
						this.modalRef.hide();
						reject();
					},
				};

				this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd' });
			})
		);

		checkEnrolledUsers()
			.then((hasEnrolledUsers: boolean) => {
				if (hasEnrolledUsers) {
					confirmForceRemoveCampaign()
						.then(
							processRemoveCampaign,
							this.resetRemoveCampaign,
						);
				} else {
					processRemoveCampaign();
				}
			});
	}
}
