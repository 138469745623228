import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';


@Component({
    selector: 'modal-password-field',
	templateUrl: './modal-password-field.component.html',
})
export class ModalPasswordFieldComponent {
    @Input() public form: UntypedFormGroup;
    @Input() public inputLabel: string = '';
    @Input() public controlName: string = '';
    @Input() public displayEnter: boolean = false;
    @Input() public maxlength: number = 20;
    @Input() public tabindex: string = '';

    public fieldType = 'password';

	toggleType() {
		this.fieldType = (this.fieldType === 'text') ? 'password' : 'text';
	}
}
