import { ExampleComponentsModule } from 'commons/__components';
//LOGIN
import { LoginModule } from 'login/login.module';

// SHARED
import { TwoFactorAuthenticationModule } from 'shared/2FA/2FA.module';
import { RolesModule } from 'shared/roles/roles.module';
import { PasswordPolicyModule } from 'shared/password-policy/password-policy.module';
import { ContactModule } from 'shared/contact';
import { SharedUserManagementModule } from 'shared/user-management';
import { SharedReviewsModule } from 'shared/reviews';
import { SharedFindingsModule } from 'shared/findings';
import { ReportingSwitcherModule } from 'shared/reporting-switcher';
import { SharedSocialComplianceModule } from 'shared/social-compliance';
import { SharedQuestionnairesModule } from 'shared/questionnaires';
import { NewContactsModule } from 'shared/new-contacts';
import { GlobalSearchModule } from 'shared/global-search/global-search.module';
import { CommonProductionModule } from 'shared/production/production.module';

// COMERGENCE
import { ComergenceContactsModule } from 'comergence/contacts/contacts.module';
import { NonQmModule } from 'comergence/non-qm';
import { ComergenceServicesModule } from 'comergence/customers/services';
import { ComergenceCustomerUserManagementModule } from 'comergence/customers/user-management';
import { ComergenceUserManagementModule } from 'comergence/ccm-user-management';
import { TpoBillingModule } from 'comergence/tpo/billing';
import { CustomerModule } from 'comergence/customers/list/customer.module';
import { TpoServiceModule } from 'comergence/customers/tpo-services/tpo-service.module';
import { InvestorMarketingModule } from 'comergence/customers/investor-marketing-service/investor-marketing.module';
import { CcmDataMatchModule } from 'comergence/data-match/ccm-data-match.module';
import { DueDiligenceListModule } from 'comergence/due-diligence/due-diligence-list.module';
import { ClientProductsModule } from 'comergence/tpo/billing/client-products/client-products.module';
import { ComergenceApplicationsModule } from 'comergence/applications/applications.module';
import { CcmCustomerProductModule } from 'comergence/ccm-customer-product/ccm-customer-product.module';

// INVESTOR
import { AppcodesModule } from 'lender/appcodes/appcodes.module';
import { LenderContactsModule } from 'lender/contacts/contacts.module';
import { SolutionCenterModule } from 'lender/solution-center/solution-center.module';
import { LenderUserManagementModule } from 'lender/user-management';
import { GlobalDocumentsModule } from 'lender/documents';
import { ApplicationsModule } from 'lender/applications';
import { InvitesModule } from 'lender/invites';
import { QuestionnairesModule } from 'lender/questionnaires';
import { InvestorCommonModule } from 'lender/common/investor-common.module';
import { AlertsModule } from 'lender/alerts';
import {
	ClientsModule,
	ClientDocumentsModule,
} from 'lender/clients';
import { CRMModule } from 'lender/crm';
import { CustomFieldsModule } from 'shared/fields';
import { ChannelsModule } from './lender/channels';
import { LenderHomeModule } from 'lender/home/lender-home.module';
import { NmlsModule } from 'shared/nmls/nmls.module';
import { InvestorChannelSharesModule } from 'lender/channel-shares/investor-channel-shares.module';

// TPO
import { HomeModule as TpoHomeModule } from 'tpo/home/home.module';
import { TPOContactsModule } from 'tpo/contacts/contacts.module';
import { SocialComplianceModule } from 'tpo/social-compliance';
import { MonitoringSettingsModule } from 'tpo/social-compliance/monitoring-settings';
import { PublisherModule } from 'tpo/social-compliance/publisher';
import { PublisherCalendarModule } from 'tpo/social-compliance/publisher/calendar/calendar.module';
import { ReviewsModule } from 'tpo/social-compliance/reviews';
import { ApplicationModule } from 'tpo/application/application.module';
import { TpoOrdersModule } from 'tpo/social-compliance/orders/orders.module';
import { TpoSCScheduleModule } from 'tpo/social-compliance/schedule/schedule.module';
import { PublishingPermissionsModule } from 'tpo/social-compliance/publishing-permissions';
import { SocialAccountsModule } from 'tpo/social-accounts';
import { TpoAccountModule } from 'tpo/account';
import { ScSettingsModule } from 'tpo/social-compliance/settings/sc-settings.module';
import { TPOExportModule } from 'tpo/export/export.module';
import { TpoArchiveModule } from 'tpo/social-compliance/archive';
import { TpoFindingsModule } from 'tpo/social-compliance/findings';
import { TasksModule } from 'tpo/social-compliance/tasks';
import { ListingsModule } from 'tpo/listings';
import { TpoDocumentsModule } from 'tpo/documents';
import { TpoPaymentsModule } from 'tpo/payments';
import { TpoRolePermissionsModule } from 'tpo/role-permissions/tpo-role-permissions.module';
import { WizardModule } from 'tpo/wizard/wizard.module';
import { SharedServicesModule } from 'shared/services/shared-services.module';
import { ComergenceDocumentsModule } from 'comergence/documents';
import { TpoPeopleModule } from 'tpo/people/people.module';
import { CcmContactsModule } from 'comergence/new-contacts/new-contacts.module';
import { InvestorContactsModule } from 'lender/new-contacts/new-contacts.module';
import { NotificationCenterModule } from 'global-elements/notication-center/notification-center.module';
import { CCMLenderProfile } from 'comergence/tpo/$id/ccm-tpo.module';
import { SharedAccountModule } from 'shared/account/shared-account.module';
import { CcmCustomersModule } from 'comergence/customers/ccm-customers.module';
import { TpoQuestionnaireModule } from 'tpo/questionnaire/questionnaire.module';
import { InvestorChannelsModule } from 'lender/clients/$id/channels/channels.module';
import { DueDiligenceModule } from 'shared/due-diligence/due-diligence.module';
import { ManageChannelModule } from 'lender/manage-channels/manage-channel.module';
import { DocumentScheduleSettingsCardModule } from 'shared/document-schedule-settings-card/document-schedule-settings-card.module';
import { FinancialDocumentsModule } from 'shared/financial-documents/financial-documents.module';
import { LenderSolutionCenterModule } from 'tpo/solution-center/solution-center.module';

// COMMON - USER CAPABILITIES
import { UserCapabilitiesModule } from 'commons/services/user-capabilities/user-capabilities.module';
import { TpoStatusModule } from 'tpo/status';
import { LenderStatusModule } from 'lender/status';
import { NavigationModule } from 'commons/components/navigation';
import { ErrorModalsModule } from 'commons/services/error/error-modals.module';
// COMMON - USER CAPABILITIES


const RealmModules = [
    TpoStatusModule,
    LenderStatusModule,
    NavigationModule,
    ErrorModalsModule,
	ExampleComponentsModule,
    NotificationCenterModule,
    //LOGIN
    LoginModule,
    //end: LOGIN
	// TPO
	TpoHomeModule,
	ApplicationModule,
	TpoOrdersModule,
	TpoSCScheduleModule,
	TPOContactsModule,
	SocialComplianceModule,
	MonitoringSettingsModule,
	PublisherModule,
	PublisherCalendarModule,
	ReviewsModule,
	PublishingPermissionsModule,
	SocialAccountsModule,
	TpoAccountModule,
	ScSettingsModule,
	TPOExportModule,
	TpoArchiveModule,
	TpoFindingsModule,
	TasksModule,
	ListingsModule,
	TpoDocumentsModule,
	TpoPaymentsModule,
    TpoRolePermissionsModule,
    TpoPeopleModule,
    TpoQuestionnaireModule,
	// end:TPO
	// COMERGENCE
	ComergenceContactsModule,
	NonQmModule,
	ComergenceServicesModule,
	CcmCustomersModule,
	ComergenceUserManagementModule,
	ComergenceCustomerUserManagementModule,
    ComergenceDocumentsModule,
    CcmContactsModule,
    CustomerModule,
    ClientProductsModule,
    TpoServiceModule,
    InvestorMarketingModule,
    CCMLenderProfile,
    CcmDataMatchModule,
    DueDiligenceListModule,
	ComergenceApplicationsModule,
    CcmCustomerProductModule,
	// end:COMERGENCE
	// INVESTOR
	LenderContactsModule,
	LenderUserManagementModule,
	AppcodesModule,
	SolutionCenterModule,
	LenderSolutionCenterModule,
	GlobalDocumentsModule,
	InvitesModule,
	QuestionnairesModule,
	InvestorCommonModule,
	AlertsModule,
	ClientsModule,
	CRMModule,
	CustomFieldsModule,
	ClientDocumentsModule,
	ChannelsModule,
    InvestorContactsModule,
    InvestorChannelsModule,
	DueDiligenceModule,
    LenderHomeModule,
	NmlsModule,
    ManageChannelModule,
	InvestorChannelSharesModule,
	// SHARED
	TwoFactorAuthenticationModule,
	RolesModule,
	PasswordPolicyModule,
    SharedServicesModule,
	ContactModule,
	SharedUserManagementModule,
	ApplicationsModule,
	SharedReviewsModule,
	SharedFindingsModule,
	ReportingSwitcherModule,
	TpoBillingModule,
	SharedSocialComplianceModule,
	SharedQuestionnairesModule,
	WizardModule,
    NewContactsModule,
    SharedAccountModule,
    GlobalSearchModule,
	CommonProductionModule,
	DocumentScheduleSettingsCardModule,
	FinancialDocumentsModule,
	// end:SHARED
	// COMMON - USER CAPABILITIES
	UserCapabilitiesModule,
	// end: COMMON - USER CAPABILITIES
];

export { RealmModules };
