import { filter } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Mixin } from 'utils/mixin.decorator';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { SocialConnectResourceService } from 'tpo/social-accounts/company/social-connect-resource.service';
import { IndividualConnectSmMixin, ConnectSmMixin } from 'tpo/social-accounts';

import { CampaignsResourceService } from '../campaigns-resource.service';
import { EnrollAccountsModalComponent } from './enroll-accounts-modal.component';

@Mixin([ConnectSmMixin, IndividualConnectSmMixin])
@Component({
	templateUrl: './campaigns-list.component.html',
	selector: 'campaigns-list',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CampaignsListComponent) },
	],
	host: {
		'(window:message)': 'onMessage($event)',
	},
})
export class CampaignsListComponent extends PagedListComponent implements ConnectSmMixin, IndividualConnectSmMixin {
	static listName = 'campaigns';

	canManage: boolean = false;
	campaigns: any = [];
	modalRef: BsModalRef;
	User: UserProfile;

	// IConnectMixin interface requirements
	networks;
	openConnectWindow;
	connectCommon;
	hideModal;
	connectModalState;
	connecting;
	connect;
	reconnect;
	connectFacebook;
	connectFacebookPage;
	noFacebookPagesToConnect;
	showFacebookPagesSelection;
	connectLinkedIn;
	connectLinkedInPages;
	openLinkedInPagesWindow;
	openNoLinkedInPagesWindow;
	showConnectInstagramModal;
	facebookUserShouldLogout;
	connectSelectedFacebookPages;
	showFacebookMortgageRelatedSelection;
	getStatusesForConnect;
	connectGMB;
	showGMBPages;
	finishGMBConnect;
	callbacks;
	initNetworks;
	onMessage;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public user: UserService,
		public campaignsResource: CampaignsResourceService,
		public modalService: BsModalService,
		public socialConnectService: SocialConnectResourceService,
	) {
		super(transitionService, stateService);

		this.User = user.profile;
		this.canManage = this.User.can('TPO_PB_MANAGE_CAMPAIGNS');

		this.initNetworks();

		this.defaultFilters = {
			...this.defaultFilters,
			enrolledOnly: false,
			size: 25,
		};
	}

	loadList(queryParams) {

		this.campaigns.$resolved = false;

		return this.campaignsResource.campaigns.get(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.campaigns = data;
				this.noMatches = !data.length && (this.filtersApplied || queryParams.q);
			});
	}

	toggleEnrolledFilter(): void {
		const { enrolledOnly } = this.params$.getValue();
		this.setFilter({enrolledOnly: !enrolledOnly});
	}

	enroll(id: number, resource): void {
		const params = {
			id,
		};

		const initialState = {
			resource,
			onConfirm: () => {
				const enrolled = filter(resource, 'enrolled');
				resource.$resolved = false;
				this.campaignsResource.enroll.add(params, enrolled, () => {
					this.loadList(this.params$.getValue());
					this.modalRef.hide();
				});
			},
		};

		this.modalRef = this.modalService.show(EnrollAccountsModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}

	onConnectFinish(): void {
		this.loadList(this.params);
	}

	showModal(id: number): void {
		this.campaigns.$resolved = false;
		const params = {
			id,
		};

		const resource = this.campaignsResource.enroll.get(params, (response) => {
			this.campaigns.$resolved = true;

			const connectedAccounts = filter(response, {connected: true}).length;

			if (connectedAccounts) {
				this.enroll(id, resource);
			} else {
				this.connect();
			}
		});
	}
}
