import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { CompanyPublishingPermissionsComponent } from './company-publishing-permissions.component';
import { PublishingPermissionsResourceService } from './publishing-permissions-resource.service';
import { IndividualPublishingPermissionsComponent } from './individual-publishing-permissions.component';

const components = [
	CompanyPublishingPermissionsComponent,
	IndividualPublishingPermissionsComponent,
];

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        ComponentsModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
    ],
    declarations: [
        ...components,
    ],
    providers: [
        PublishingPermissionsResourceService,
    ]
})

export class PublishingPermissionsModule {}
