import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { findKey } from 'lodash';

import { RealmFormControl } from 'commons/forms';
import {
	socialMediaNetworksConfig,
	SocialMediaNetworkIconConfig,
} from 'commons/components/sm-icons';

const DEFAULT_NETWORK_CODE = 'OT';

@Component({
	selector: 'sm-link-input',
	templateUrl: './sm-link-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaLinkInputComponent implements OnInit, OnDestroy {
	networkIconConfig: SocialMediaNetworkIconConfig = {
		networkCode: DEFAULT_NETWORK_CODE,
	};
	unsubscribe$ = new Subject<void>();

	@Input('formControlRef') formControl: RealmFormControl;
	@Input('showReset') showResetRule: 'always' | 'value' | 'never' = 'value';
	@Output() reset = new EventEmitter<void>();

	// prefix class names to make it more specific and not intersect with existing class names
	// FIXME: replace with a proper implementation (RM-24608)
	smLinkInputClassNamePrefix = 'sm-link-input';
	smLinkInputNetworkIconClassName = `${this.smLinkInputClassNamePrefix}-network-icon`;

	get networkIconClassName(): string[] {
		return [
			'form-control-feedback',
			this.smLinkInputNetworkIconClassName,
			...(!this.formControl.value ? [ 'icon-light-gray' ] : []),
		];
	}

	get showReset(): boolean {
		return (
			this.showResetRule === 'always' ||
			this.showResetRule === 'value' && this.formControl.value
		);
	}

	constructor(
		private changeDetector: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.formControl.valueChanges
			.pipe(
				takeUntil(this.unsubscribe$),
			)
			.subscribe((value) => {
				this.networkIconConfig = {
					networkCode: this.getNetworkCode(value),
				};

				this.changeDetector.markForCheck();
			});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getNetworkCode(value): string {
		const networkCode = value && findKey(socialMediaNetworksConfig, ({ regexp }) => regexp && regexp.test(value));
		return networkCode || DEFAULT_NETWORK_CODE;
	}
}
