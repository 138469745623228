import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FinancialQuarters } from 'commons/services/financial-documents.interface';
import { requestMessageCharsAmount } from 'lender/clients/$id/documents/common/request-document-modal/request-document-modal.form';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { UserService } from 'angularjs-providers/user.provider';
import { FinancialDocumentScheduleSettingsService } from 'shared/financial-documents/financial-document-schedule-settings.service';
import { FinancialDocumentType } from '../../../../shared/financial-documents/financial-document-type.interface';
import { ClientBulkRequestInterface } from './channel-bulk-request.interface';
import { ClientsService } from '../../clients.service';

const currentYear = (new Date).getFullYear();

@Component({
    templateUrl: 'client-financials-bulk-request-modal.component.html',
})
export class ClientFinancialsBulkRequestModalComponent implements OnInit {
    channelId: number;
    lenderIds: number[];
    investorId: number;
    organizationName: string;
    callbackOnSave: () => void;
    years: number[] = (new Array(3)).fill(0).map((v, i) => (currentYear - i)).reverse();
    quarters = FinancialQuarters;
    resolved: boolean = true;
    documentTypes: FinancialDocumentType[] = [];
    resolving: boolean;

    requestForm = new RealmFormGroup(
        {
            isAudited: new RealmFormControl(
                'isAudited',
                {
                    label: 'Type',
                    updateOn: 'change',
                },
                Validators.required,
            ),
            period: new RealmFormControl(
                'period',
                {
                    label: 'Period',
                },
                Validators.required,
            ),
            year: new RealmFormControl(
                'year',
                {
                    label: 'Year',
                },
                Validators.required,
            ),
            notifyClient: new RealmFormControl(
                'notifyClient',
                {
                    label: 'Notify Client',
                    updateOn: 'change',
                    value: true,
                },
            ),
            notifyAssigned: new RealmFormControl(
                'notifyAssigned',
                {
                    label: 'Notify Assigned',
                    updateOn: 'change',
                    value: true,
                },
            ),
            message: new RealmFormControl(
                'message',
                {
                    label: 'Message',
                    updateOn: 'change',
                },
            ),
            documentTypeIds: new RealmFormControl(
                'documentTypeIds',
                {
                    label: 'Financials Documents',
                    updateOn: 'change',
                },
                Validators.required,
            ),
        },
        {
            validators: this.messageFieldValidation,
        }
    );

    constructor(
        protected readonly modalRef: BsModalRef,
        protected user: UserService,
        protected automatedRequestSettingsService: FinancialDocumentScheduleSettingsService,
        protected clientsService: ClientsService,
    ) {
    }

    ngOnInit() {
        this.changeMessageOnFormFieldUpdate();
        if (this.organizationName) {
            this.getMessage();
        }
        this.getFinancialsDocumentTypes();
    }

    getMessage() {
       this.requestForm?.get('message').patchValue(this.updateMessage());
    }

    changeMessageOnFormFieldUpdate() {
        this.requestForm.valueChanges
            .pipe(
                map(({ isAudited, year, period }) => ({ isAudited, year, period })),
                distinctUntilChanged(isEqual),
            )
            .subscribe(() => {
                this.getMessage()
            });
    }

    updateMessage(): string {
        return `${this.organizationName} is requesting that you upload your financial requirements.` +
            '\n\n' +
            'Navigate to Financials: https://comergence.com/#/account/financials' +
            '\n\n' +
            'Thank you,' +
            '\n' +
            'Optimal Blue Customer Support';
    }

    async getFinancialsDocumentTypes() {
        this.resolving = true;
        this.documentTypes = await this.automatedRequestSettingsService.getFinancialDocumentTypes().toPromise();
        this.resolving = false;
    }

    async makeRequest() {
        const requestData: ClientBulkRequestInterface = this.requestForm.value;
        requestData.tpoIds = this.lenderIds;
        if (!requestData.notifyAssigned && !requestData.notifyClient) {
            requestData.message = null;
        }

        try {
            this.resolved = false;
            await this.clientsService.makeBulkRequestForMultipleDocuments(this.investorId, this.channelId, requestData).toPromise();
            this.close();
            this.callbackOnSave();
        } catch ({ error }) {
            this.requestForm.setServerError(error);
        } finally {
            this.resolved = true;
        }
    }

    close() {
        this.modalRef.hide();
    }

    getCharsCount(): number {
        return Math.max(0, (requestMessageCharsAmount - (this.requestForm.value?.message?.length ?? 0)));
    }

    messageFieldValidation(formGroup: UntypedFormGroup): ValidationErrors {
        if (formGroup.controls['notifyClient'].value || formGroup.controls['notifyAssigned'].value) {
            formGroup.controls['message'].setValidators(Validators.required);
            if (!formGroup.controls['message'].value) {
                formGroup.controls['message'].markAsTouched();
            }
        } else {
            formGroup.controls['message'].clearValidators();
            formGroup.controls['message'].markAsUntouched();
        }

        return null;
    }

    resetPeriod() {
        const periodField = this.requestForm.get('period') as RealmFormControl;
        if (periodField.serverError) {
            periodField.serverError = '';
            periodField.updateValueAndValidity();
        }
    }
}
