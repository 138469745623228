import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedListComponent } from 'commons/components/list';
import { ListComponent } from 'commons/components/list/list.component';
import { NgResourceArray } from 'commons/interfaces';

import { QuestionnaireItem } from 'lender/questionnaires/manage/questionnaires.interface';

import { LenderManageQuestionnaireService } from './questionnaires.service';

@Component({
	templateUrl: './questionnaires.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ManageQuestionnairesComponent) },
	],
})
export class ManageQuestionnairesComponent extends PagedListComponent {
	static listName = 'manage-questionnaires-list';

	User: UserProfile;
	list: NgResourceArray<QuestionnaireItem> = [];
	// TODO: separate component for tabs
	tabs = [
		{
			state: 'questionnaires',
			title: 'Questionnaires',
		},
		{
			state: 'question-bank',
			title: 'Question Bank',
		},
	];

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		public questionnairesService: LenderManageQuestionnaireService,
) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.filters = {
			status: [
				{ id: 'A', name: 'Active' },
				{ id: 'I', name: 'Inactive' },
			],
		};

		this.defaultFilters = {
			...this.defaultFilters,
			status: null,
		};
	}

	async loadList(queryParams) {
		this.list.$resolved = false;
		try {
			this.list = await this.questionnairesService.list(queryParams).$promise;
		} catch ( e ) {
			this.list.$resolved = true;
		}
	}

    cancelBubble(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    }
}
