import { filter, forEach, set } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { ContactsListComponent } from 'tpo/contacts/contacts-list.component';
import { ContactsResourceService } from 'tpo/contacts/contacts-resource.service';
import { LenderContactsResourceService as LenderContactsResource } from 'lender/contacts/lender-contacts-resource.service';
import { TpoContactType } from 'shared/new-contacts/contacts.interface';

@Component({
	templateUrl: './board.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => LenderContactsBoardComponent) },
	],
})

export class LenderContactsBoardComponent extends ContactsListComponent {
	static listName = 'lContactsBoard';
    public TYPE = TpoContactType;

	constructor(
		public User: UserService,
		public ContactResource: ContactsResourceService,
		public LenderContactsResourceService: LenderContactsResource,
		public transitionService: TransitionService,
		public stateService: StateService,
	) {
		super(User, ContactResource, transitionService, stateService);

		delete this.filters.contactType;
		this.defaultFilters.letter = '';

		this.filters.letter = this.LenderContactsResourceService.letters();
	}

	setFullAddressProperty(contacts) {
		forEach(contacts, (contact) => {
			const addressArray = filter([contact.city, contact.state]);
			set(contact, 'fullAddress', addressArray.join(', '));
		});
	}

	loadList(queryParams) {
		this.contacts.$resolved = false;

		return this.LenderContactsResourceService.query(this.getLoadListParams(queryParams)).$promise
			.then((result) => {
				this.setFullAddressProperty(result);
				this.contacts = result;
				this.noMatches = !result.length && (this.filtersApplied || queryParams.q);
			});
	}
}
