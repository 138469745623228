<div class="modal-header">
    <span class="modal-title">Contact Information</span>
    <button type="button" class="close" (click)="hide()">×</button>
</div>
<div class="modal-body remote-resource" [class.resolved]="lenderChannelContactInfo | async">
    <ng-container *ngIf="lenderChannelContactInfo | async as contact">
        <div class="row-fluid" *ngIf="contact?.name">
            <div class="col-sm-3 control-label text-right">Contact Name:</div>
            <div>{{ contact.name }}</div>
        </div>
        <div class="row-fluid" *ngIf="contact?.phone">
            <div class="col-sm-3 control-label text-right">Phone:</div>
            <div>{{ contact.phone | tel }}</div>
        </div>
        <div class="row-fluid" *ngIf="contact?.email">
            <div class="col-sm-3 control-label text-right">Email:</div>
            <div>
                <a *ngIf="contact.email" href="mailto:{{ contact.email }}">{{ contact.email }}</a>
            </div>
        </div>
        <div class="row-fluid" *ngIf="contact?.webSite">
            <div class="col-sm-3 control-label text-right">Web-site:</div>
            <div>
                <a target="_blank" *ngIf="contact.webSite" href="{{ contact.webSite | url }}">
                    {{ contact.webSite }}
                </a>
            </div>
        </div>
        <br>
        <p>{{ contact?.description }}</p>
    </ng-container>
</div>
