import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';

import { SharedUsersResourceService } from './users-resource.service';
import { SharedUserManagementResourceService } from './user-management-resource.service';
import { ActionLogComponent } from './action-log';
import { UserActionsService } from '../users/user-actions.service';
import { LoginLogComponent } from './action-log/login-log.component';
import { UserManagementHeaderComponent } from 'shared/user-management/header/user-management-header.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        BsDropdownModule.forRoot(),
    ],
    declarations: [
        ActionLogComponent,
        LoginLogComponent,
        UserManagementHeaderComponent,
    ],
    providers: [
        SharedUsersResourceService,
        SharedUserManagementResourceService,
        UserActionsService,
    ],
    exports: [
        ActionLogComponent,
        LoginLogComponent,
    ]
})
export class SharedUserManagementModule {}
