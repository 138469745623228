<ng-container *ngIf="companyData() as companyData">
    <div class="cc-logo">
        <img *ngIf="companyData.companyLogo" [src]="companyData.companyLogo">
    </div>
    <h3 class="font-bold">{{ companyData.companyName }}</h3>
    <div *ngIf="companyData.companyAddress" class="text-muted text-medium text-normal ellipsis">
        {{ companyData.companyAddress }}
    </div>
    <div *ngIf="labels()" class="label-tags font-bold ellipsis">
        <span class="label-tag" *ngFor="let label of labels()">{{ label }}</span>
    </div>
</ng-container>
