interface BaseEntity {
    code?: string;
    name: string;
}

export interface Tag extends BaseEntity {}

export interface LoanProgram extends BaseEntity {}

export interface InvestorType extends BaseEntity {}

export enum SC_CARD_TAB {
    OVERVIEW = 'OVERVIEW',
    PRODUCTS = 'PRODUCTS',
    CHANNELS = 'CHANNELS',
}
export enum SC_CHANNEL_ACTION {
    CONTACT = 'CONTACT',
    APPLY = 'APPLY',
}
export enum SC_CHANNEL_HISTORY_ACTION {
    PRINT = 'PRINT',
    EDIT = 'EDIT',
}
export type SCTab = {
    title: string;
    investorRoute: string;
    code: SC_CARD_TAB;
}
export const SC_TABS: SCTab[] = [
    { title: 'Overview', investorRoute: 'solution-center.overview', code: SC_CARD_TAB.OVERVIEW },
    { title: 'Products', investorRoute: 'solution-center.products', code: SC_CARD_TAB.PRODUCTS },
    { title: 'Apply Now', investorRoute: 'solution-center.channels', code: SC_CARD_TAB.CHANNELS },
];
export const allwaysVisibleTabs = [SC_CARD_TAB.OVERVIEW];

export interface SCCustomerCard {
    companyName: string;
    hasCompanyLogo: boolean;
    companyLogo?: string;

    customerId: number;
    organizationId: number;
    loanPrograms: LoanProgram[];
    tags: Tag[];
}

export interface SCCustomerCardOverview extends SCCustomerCard {
    availableTabs: SC_CARD_TAB[];
    companyContactName: string;
    companyEmail: string;
    companyPhone: string;
    companyWebsite: string;
    companyAddress: string;
    hasMarketingLogo: boolean;
    marketingLogo?: string;
    marketingLogoPageWidth: number;
    medialinks: { [key: string]: string };
    description: string;
    investorId: number;
}

export type SolutionCenterVisibleTabs = Partial<Record<SC_CARD_TAB, boolean>>;



export interface LenderChannelStatus {
    id: string;
    name: string;
}

export type OrganizationChannelState = ('NONASSOCIATED' | 'PENDING' | 'ACTIVE' | 'SUSPENDED');

export interface LenderChannel {
    id: number;
    name: string;

    applicationPrice?: number;

    description: string;
    screeningRequired: boolean;
    accountExecutiveName?: string;
    phone?: string;
    email?: string;
    contactName: string;
    webSite?: string;
    status?: LenderChannelStatus;
    renewalDate?: string;
    state: OrganizationChannelState;
    zip?: string;
    suite?: string;
    isEligibilityDateInFuture?: boolean;
    channelsStatus?: SC_CHANNEL_STATUS;
    hasRelationships: boolean;
}

export enum SC_CHANNEL_STATUS {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    PENDING_RENEWAL = 'PENDING_RENEWAL',
}

export enum SC_CHANNEL_STATUS_ID {
    PR = 'PR',
    P = 'P',
    A = 'A',
    I = 'I',
    S = 'S',
    W = 'W',
    RS = 'RS',
}

export const processChannel = (channels: any[]): any[] => {
    return channels.map(channel => {
        let channelsStatus: SC_CHANNEL_STATUS | null = null;

        switch (channel.state) {
            case SC_CHANNEL_STATUS.PENDING:
                if (channel.status && [SC_CHANNEL_STATUS_ID.P].includes(channel.status.id)) {
                    channelsStatus = SC_CHANNEL_STATUS.PENDING;
                } else if (channel.status && [SC_CHANNEL_STATUS_ID.PR].includes(channel.status.id)) {
                    channelsStatus = SC_CHANNEL_STATUS.PENDING_RENEWAL;
                }
                break;
            case SC_CHANNEL_STATUS.ACTIVE:
                if (channel.status && [SC_CHANNEL_STATUS_ID.A, SC_CHANNEL_STATUS_ID.I, SC_CHANNEL_STATUS_ID.S, SC_CHANNEL_STATUS_ID.W].includes(channel.status.id)) {
                    channelsStatus = SC_CHANNEL_STATUS.ACTIVE;
                }
                break;
            case SC_CHANNEL_STATUS.SUSPENDED:
                if (channel.status && [SC_CHANNEL_STATUS_ID.RS].includes(channel.status.id)) {
                    channelsStatus = SC_CHANNEL_STATUS.SUSPENDED;
                }
                break;
            default:
                channelsStatus = null;
        }

        return {
            ...channel,
            hasRelationships: channelsStatus !== null,
            channelsStatus,
            accountExecutiveName: !channel.accountExecutiveName || channel.accountExecutiveName === 'UNKNOWN' ? 'Unassigned' : channel.accountExecutiveName,
        }
    });
}
