import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { RealmHttpClient } from 'commons/services/http';
import { Line } from 'commons/components/image-cropper/image-cropper.component';

import { UserService } from 'angularjs-providers/user.provider';
import {
    SCCustomerCardOverview,
    Tag,
    LoanProgram,
    LenderChannel,
    processChannel,
} from 'shared/solution-center/solution-center.common';

export interface InvestorCustomerCard extends SCCustomerCardOverview {
    originalCompanyLogo?: string;
    companyLogoCoordinates: Line;
    isCompanyLogoModified?: boolean;
    originalMarketingLogo?: string;
    marketingLogoCoordinates: Line;
    isMarketingLogoModified?: boolean;
    visible: boolean;
}

export type SCProductOverview = {
    customer: number;
    description: string;
    id: number;
    service: string;
    status: string;
}

export type SCProduct = {
    email: string;
    productDescription: string;
    productId: number
    productName: string;
    type: string; // maybe change to enum?
    visibilityAllowedByComergence: boolean;
    visibilityAllowedByCustomer: boolean;
}

export const SupportedFileTypes = [
    {
        type: 'Image',
        regexp: /^.+\.(JPG|JPE|JPEG|PNG)$/i,
    },
];

const processCustomerCard = (card: InvestorCustomerCard) => {
    const { hasCompanyLogo, organizationId } = card;
    const ts = Date.now();
    return {
        ...card,
        ...hasCompanyLogo ? {
            originalCompanyLogo: `/api/rest/organizations/${organizationId}/solution-center/company-logo/original?nocache=${ts}`,
            companyLogo: `/api/rest/organizations/${organizationId}/solution-center/company-logo?nocache=${ts}`,
        } : {},
        ...card.hasMarketingLogo ? {
            originalMarketingLogo: `/api/rest/organizations/${organizationId}/solution-center/marketing-logo/original?nocache=${ts}`,
            marketingLogo: `/api/rest/organizations/${organizationId}/solution-center/marketing-logo?nocache=${ts}`,
        } : {},
    };
}

declare let apiPath: string;

@Injectable()
export class InvestorSolutionCenterService {
    protected organizationId: number;
    protected lenderId: number;

    constructor(
        { profile }: UserService,
        private readonly http: RealmHttpClient,
    ) {
        this.organizationId = profile.organization.organizationId;
        this.lenderId = profile.organization.id;
    }

    getCustomerData(): Observable<InvestorCustomerCard> {
        return this.http.request<InvestorCustomerCard>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/solution-center`,
        ).pipe(
            map(processCustomerCard),
            shareReplay(1),
        );
    }

    async urlToBlob(url: string): Promise<Blob> {
        return await fetch(url).then(r => r.blob());
    }

    async updateCustomerData({ companyLogo, originalCompanyLogo, originalMarketingLogo, ...overviewData }: InvestorCustomerCard): Promise<Observable<InvestorCustomerCard>> {
        const formData = new FormData();
        const {isCompanyLogoModified, isMarketingLogoModified} = overviewData;
        if (isCompanyLogoModified && originalCompanyLogo) {
            formData.append('companyLogo', await this.urlToBlob(companyLogo), 'companyLogo.png');
            formData.append('originalCompanyLogo', await this.urlToBlob(originalCompanyLogo), 'originalCompanyLogo.png');
        }

        if (isMarketingLogoModified && originalMarketingLogo) {
            formData.append('marketingLogo', await this.urlToBlob(overviewData.marketingLogo), 'marketingLogo.png');
            formData.append('originalMarketingLogo', await this.urlToBlob(originalMarketingLogo), 'originalMarketingLogo.png');
        }

        formData.append(
            'overview',
            new Blob(
                [JSON.stringify(overviewData)],
                { type: 'application/json' }),
        );

        return this.http.requestWithHeaders<InvestorCustomerCard>(
            'PUT',
            `${apiPath}/organizations/${this.organizationId}/solution-center`,
            {},
            formData,
            {
                'enctype': 'multipart/form-data',
            },
        ).pipe(
            map(processCustomerCard),
        );
    }

    updateVisibility(visible: boolean): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/organizations/${this.organizationId}/solution-center/visible`,
            {},
            { visible },
        );
    }

    getAvailableTags(): Observable<Tag[]> {
        return this.http.request<Tag[]>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/solution-center/available-tags`,
        );
    }

    getAvailableLoanPrograms(): Observable<LoanProgram[]> {
        return this.http.request<LoanProgram[]>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/solution-center/available-loan-programs`,
        );
    }

    getProductOverview(): Observable<SCProductOverview> {
        return this.http.request<SCProductOverview>(
            'GET',
            `${apiPath}/customer-products/${this.organizationId}/products-overview`,
        );
    }

    saveProductOverview(data: SCProductOverview): Observable<SCProductOverview> {
        return this.http.request<SCProductOverview>(
            'PUT',
            `${apiPath}/customer-products/${this.organizationId}/products-overview`,
            {},
            data,
        );
    }

    getProductList(): Observable<SCProduct[]> {
        return this.http.request<SCProduct[]>(
            'GET',
            `${apiPath}/customer-products/${this.organizationId}/product-information-list`,
        );
    }

    updateProductVisibility(productId: number, isVisible: boolean): Observable<SCProduct> {
        return this.http.request<SCProduct>(
            'PUT',
            `${apiPath}/customer-products/${this.organizationId}/product/${productId}/visibility`,
            null,
            isVisible,
        )
    }

    updateProductDescriptionAndType(updatedProduct: SCProduct) {
        return this.http.request<SCProduct>(
            'PUT',
            `${apiPath}/customer-products/${this.organizationId}/product/${updatedProduct.productId}/description-and-type`,
            null,
            updatedProduct,
        )
    }

    getActiveChannels(): Observable<LenderChannel[]> {
        return this.http.request<LenderChannel[]>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/channels/preview`,
        ).pipe(
            map(channels => processChannel(channels)),
        );
    }
}
