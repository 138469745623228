import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, mapValues } from 'lodash';

import { Mixin } from 'utils/mixin.decorator';

import { UserService } from 'angularjs-providers/user.provider';

import { ColumnsManagerMixin } from 'commons/mixins';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ListComponent } from 'commons/components/list/list.component';
import { ConfirmModalComponent } from 'commons/components/modals';

import { InvitesResourceService } from '../invites.service';
import { InviteStatuses } from './invite-statuses';
import { columnsConfig } from './columns-config';

@Mixin([ ColumnsManagerMixin ])
@Component({
	templateUrl: './invites-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => InvitesListComponent) },
	],
})
export class InvitesListComponent extends PagedListComponent implements ColumnsManagerMixin {
	static listName = 'lenderInvites';

	User: any;
	InviteStatuses = InviteStatuses;
	list: any = [];
	moreFilters = false;
	modalRef: BsModalRef;

	// ColumnsManagerMixin
	// GOTCHA: objects and arrays should have default value to properly work with mixins
	StorageService;
	localStorageName = 'InvitesListColumns';
	columns = new Map();
	columns$;
    columnValues: () => [];
	prepareColumns;
	toggleColumn;
	getColumnVisibility;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public modalService: BsModalService,
		public service: InvitesResourceService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.configureFilters();
		this.configureColumnsManager();
	}

	configureFilters() {
		this.defaultFilters = {
			...this.defaultFilters,
			channelId: [],
			status: [],
			state: [],
			assigneeContactId: [],
			dateFrom: null,
			dateTo: null,
		};

		this.filters = {
			channelId: this.service.filters.channels.get(),
			status: Array.from(InviteStatuses.values()),
			state: this.service.filters.states.get(),
			assigneeContactId: this.service.filters.accountExecutives.get(),
		};
	}

	configureColumnsManager() {
		if (this.User.crmEnabled) {
			columnsConfig.push({
				id: 'location',
				name: 'Location',
			});
		}

		this.prepareColumns(columnsConfig);
	}

	setFilter(filter, resetPage = true) {
		const processedFilter = mapValues(filter, (value: any, key: string) => {
			switch (key) {
				case 'channelId':
				case 'status':
				case 'assigneeContactId':
					return map(value || [], 'id');
				case 'state':
					return map(value || [], 'shortName');
				default:
					return value;
			}
		});

		super.setFilter(processedFilter, resetPage);
	}

	toggleFilters() {
		this.moreFilters = !this.moreFilters;
	}

	processParams(params) {
		return mapValues(
			this.getClearParams(params),
			(value, key) => (
				['dateFrom', 'dateTo'].includes(key) ?
					value.getTime() :
					value
			),
		);
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.service.list.get(
			this.processParams(queryParams),
		).$promise
			.then((result) => {
				this.list = result;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	cancelInvite({ channelId, channelName, tpoId, tpoName }) {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-smd modal-new',
			initialState: {
				title: 'Cancel Invite',
				message: `Are you sure you want to cancel an invite to <b>${channelName}</b> channel sent to <b>${tpoName}</b>?`,
				onConfirm: () => {
					this.modalRef.content.resolving = true;
					this.service.invite.cancel({
						channelId,
						tpoId,
					}).$promise
						.then(() => {
							this.loadList(this.params);
						})
						.finally(() => {
							this.modalRef.hide();
							this.modalRef.content.resolving = false;
						});
				},
			},
		});
	}

	getLocation = (item) => (
		[item.location.state, item.location.city]
			.filter((value) => !!value)
			.join(', ')
	)
}
