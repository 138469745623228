<ng-container *ngIf="product() as product">
    <div class="flex-grow">
        <b class="text-big">{{ product.productName }}</b>
        <hr>
        <div class="product-description" *ngIf="product.productDescription as description">
            <quill-view [content]="description"></quill-view>
        </div>
    </div>

    <div class="text-center">
        <a class="btn btn-primary" (click)="action.emit(product)">
            Contact Us
        </a>
    </div>
</ng-container>
