import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { isUndefined } from 'lodash';
import { AddEditFindingData } from '../scorecard-add-findings/scorecard-add-finding.interface';
import { ScorecardFindingSupportingDocument } from '../scorecard-finding.interface';
import { SupportingDocumentReplacedEvent } from '../scorecard-supporting-documents/scorecard-supporting-documents.interface';


export const MAXIMUM_TOTAL_FILES_SIZES: number = (20 * Math.pow(2, 20)); // 20Mb

@Injectable()
export class ScorecardFindingFormService {
    confirmationValidator(formGroup: UntypedFormGroup): ValidationErrors {
        if (!formGroup.controls['notify'].value) {
            formGroup.controls['statement'].setValidators(Validators.required);
        } else {
            formGroup.controls['statement'].clearValidators();
            formGroup.controls['statement'].markAsUntouched();
        }

        if (formGroup.controls['statement'].value) {
            const notifyHasError = formGroup.controls['statement'].value !== 'Do Not Notify';
            if (notifyHasError) {
                const validatorError: ValidationErrors = { incorrect: true };
                formGroup.controls['statement'].markAsTouched();
                formGroup.controls['statement'].setErrors({incorrect: true});

                return validatorError;
            }
        }

        return null;
    }

    addNewFile(
        files: File[],
        newFileList: FileList,
    ): void {
        if (!newFileList.length) {
            return;
        }

        const newFiles: File[] = Array.from(newFileList);
        files.push(...newFiles);
    }

    replaceFile(
        event: SupportingDocumentReplacedEvent,
        files: File[],
    ): void {
        const index = files.indexOf(event.fileToReplace);
        files.splice(index, 1, event.newFile);
    }

    removeFile<T = (ScorecardFindingSupportingDocument | File)>(
        fileToRemove: T,
        files: T[],
    ): T {
        const index: number = files.indexOf(fileToRemove);
        if (index !== -1) {
            const removedFile: T = files[index];
            files.splice(index, 1);

            return removedFile;
        }

        return null;
    }

    prepareFindingFormData(
        data: AddEditFindingData
    ): FormData {
        const formData: FormData = new FormData();
        formData.append('summary', data.summary);
        formData.append('notify', JSON.stringify(data.notify));

        if (!isUndefined(data.isVisible)) {
            formData.append('isVisible', JSON.stringify(data.isVisible));
        }

        if (!isUndefined(data.filesToDelete)) {
            data.filesToDelete.forEach(fileToDelete => formData.append('filesToDelete', `${fileToDelete}`));
        }

        data.newFiles.forEach(file => formData.append('file', file));

        return formData;
    }
}
