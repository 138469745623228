import {Component, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SCProduct } from "../../../solution-center.service";
import { RealmFormControl, RealmFormGroup } from "commons/forms";
import {Validators} from "@angular/forms";
import {MaxLengthValidator} from "../../../../../commons/validators";

@Component({
    templateUrl: './manage-product-modal.component.html',
})
export class ManageProductModalComponent implements OnInit {
    product: SCProduct;
    save: (params?) => any;
    resolving: boolean;

    manageProductForm = new RealmFormGroup({
        email: new RealmFormControl(
            'email',
            {
                label: 'Lead Notification Email'
            },
            Validators.compose([ MaxLengthValidator(100), Validators.email, Validators.required ]),
        ),
        productDescription: new RealmFormControl(
            'productDescription',
            {
                label: 'Product Description'
            },
            Validators.compose([ MaxLengthValidator(1000), Validators.required ]),
        ),
        productId: new RealmFormControl(
            'productId',
            {
                label: 'Product Id'
            }
        ),
        productName: new RealmFormControl(
            'productName',
            {
                label: 'Product Name'
            }
        ),
        type: new RealmFormControl(
            'type',
            {
                label: 'Call To Action'
            }
        ),
        visibilityAllowedByCustomer: new RealmFormControl(
            'visibilityAllowedByCustomer',
            {
                label: 'Visibility Allowed By Customer'
            }
        ),
        visibilityAllowedByComergence: new RealmFormControl(
            'visibilityAllowedByComergence',
            {
                label: 'Visibility Allowed By Comergence'
            }
        ),
    })

    constructor(public modalRef: BsModalRef) {}

    ngOnInit() {
        this.manageProductForm.patchValue(this.product);
    }

    onCancel() {
        this.modalRef.hide();
    }

    async onConfirm(productToUpdate: SCProduct) {
        try {
            this.resolving = true;
            await this.save(productToUpdate);
        } finally {
            this.resolving = false;
        }
    }
}
