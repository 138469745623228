import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { RecurlyModule, RecurlyProvider } from 'commons/recurly';
import { TpoDocumentsModule } from 'tpo/documents';

import { TpoRolePermissionsComponent } from './tpo-role-permissions.component';


const components = [
	TpoRolePermissionsComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        RecurlyModule,
        TpoDocumentsModule,
    ],
    declarations: [
        ...components,
    ],
    providers: [
        RecurlyProvider,
    ]
})
export class TpoRolePermissionsModule {}
