<ul class="nav navbar-nav user-nav">
	<li class="dropdown" dropdown>
		<a class="dropdown-toggle" dropdownToggle>
			<span class="c-icon c-icon-user-gear"></span>
		</a>

		<ul *dropdownMenu class="dropdown-menu dropdown-menu-right user-menu" role="menu">
			<li class="organization">
				<div><b>{{ User.firstName }} {{ User.lastName }}</b></div>
				<div *ngIf="User.organization.type !== 'COMERGENCE'">
					<div>{{ User.organization.name }}</div>
					<small *ngIf="User.organization.nmlsId" class="text-muted">
						NMLS ID: {{ User.organization.nmlsId }}
					</small>
				</div>
			</li>
			<li class="divider"></li>
			<li class="dropdown-item" *ngFor="let item of items">
				<a
						*ngIf="!item.rawHref && item.sref"
						class="flex-row space-between"
						uiSref="{{ item.sref }}"
						[uiParams]="item.srefParams"
						[uiOptions]="item.srefOptions"
				>
					{{ item.title }}
					<span class="icon-alert" *ngIf="item.hasAlert | async"></span>
				</a>
				<a
						*ngIf="item.rawHref && item.sref"
						class="flex-row space-between"
						[href]="item.sref"
						[target]="item.rawHrefTarget || '_self'"
				>
					{{ item.title }}
					<span class="icon-alert" *ngIf="item.hasAlert | async"></span>
				</a>

				<a *ngIf="!item.sref" class="flex-row space-between" (click)="item.onClick()">
					{{ item.title }}
					<span class="icon-alert" *ngIf="item.hasAlert | async"></span>
				</a>
			</li>
		</ul>
	</li>
</ul>
