import { Directive, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { each } from 'lodash';
import { AbstractControl } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormArray } from 'commons/forms/form-array.hoc';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Directive({
	selector: '[onValidSubmit][formGroup]',
})
export class OnValidSubmitDirective implements OnDestroy {
	@Output()
	onValidSubmit: EventEmitter<any> = new EventEmitter();

	@Input()
	formGroup: RealmFormGroup | RealmFormArray;

    destroy$ = new Subject<void>();

	constructor(public elementRef: ElementRef) {
        fromEvent(this.elementRef.nativeElement, 'submit')
            .pipe(
                throttleTime(400),
                takeUntil(this.destroy$),
            )
            .subscribe(this.submitHandler);
	}

	submitHandler = () => {
		this.syncFieldValues(this.formGroup);

		// move save to the next tick, so all sync validators finished
		setTimeout(() => {
			if (this.formGroup.invalid) {
				this.highlightErrors(this.formGroup);
				return;
			} else {
				this.triggerSubmit();
			}
		}, 0);
	}

	highlightErrors = (control: AbstractControl) => {
		control.markAsTouched();
		control.updateValueAndValidity();
		if (control instanceof RealmFormArray || control instanceof RealmFormGroup) {
			each(control.controls, this.highlightErrors);
		}
	}

	triggerSubmit() {
		this.onValidSubmit.emit(this.formGroup);
	}

	ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

	syncFieldValues = (formGroup) => {
		if (!formGroup.controls) {
			return null;
		}

		each(formGroup.controls, (control) => {
			if (control instanceof RealmFormArray || control instanceof RealmFormGroup) {
				return this.syncFieldValues(control);
			}

			if (control._pendingValue !== control.value) {
				control.value = control._pendingValue;
				control.markAsTouched();
				control.updateValueAndValidity();
			}
		});
	}
}
