import { Component, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { UIRouterGlobals } from '@uirouter/core';
import { StateService } from '@uirouter/angular';
import { UserService } from 'angularjs-providers/user.provider';
import { ConfirmModalComponent } from 'commons/components/modals';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InvestorCrmContactListBuilderService } from '../../services/investor-crm-contact-list-builder.service';
import { InvestorCrmContactListCriteriaMapperService } from '../../services/investor-crm-contact-list-criteria-mapper.service';
import { ContactList, ContactListCriteria, ContactListField, ContactListDesignatedUser, ContactListOption, FilterOperationFieldType, ContactListClone } from '../../investor-crm-contact-list.interface';
import { InvestorCrmContactListService } from '../../services/investor-crm-contact-list.service';
import { InvestorContactListClauseManager } from './or-clause-manager/InvestorContactListClauseManager';
import { InvestorCrmCloneContactListBuilderService } from '../../services/investor-crm-clone-contact-list-builder.service';
import { BaseInvestorCrmContactListComponent } from '../base-investor-crm-contact-list.component';


@Component({
    selector: 'investor-crm-contact-list',
    templateUrl: './investor-crm-contact-list.component.html',
})
export class InvestorCrmContactListComponent extends BaseInvestorCrmContactListComponent implements OnDestroy {
    isNew: boolean = false;
    isCloning: boolean = false;
    editing: boolean = false;

    private readonly destroyed$: Subject<void> = new Subject();

    availableUsersResolved: boolean = false;
    availableUsers: ContactListDesignatedUser[];
    availableUsersSubscription: Subscription;

    criteriaResolved: boolean = false;
    criteria: ContactListCriteria;
    criteriaSubscription: Subscription;

    private readonly fieldTypesById: Map<string, ContactListOption> = new Map();
    private readonly fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>> = new Map();
    private readonly operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>> = new Map();

    formName: string = 'createListForm';
    listForm = new RealmFormGroup({
        name: new RealmFormControl(
            'name',
            {
                label: 'List Name',
                updateOn: 'change',
            },
            [
                Validators.required,
                Validators.maxLength(255),
            ],
        ),
        visibility: new RealmFormControl(
            'visibility',
            {
                label: 'Visibility',
                value: false,
                updateOn: 'change',
            },
            Validators.required,
        ),
        selectedDesignatedUsers: new RealmFormControl(
            'selectedDesignatedUsers',
            {
                label: 'Select Users',
                updateOn: 'change',
            },
        ),
        includeManuallyAdded: new RealmFormControl(
            'includeManuallyAdded',
            {
                label: 'Include Manually Added',
            },
        ),
        segmentFilter: new RealmFormArray([]),
    });
    orClauseManager: InvestorContactListClauseManager;

    headerText: string = '';

    private bsModalRef: BsModalRef;

    constructor(
        investorCrmContactListService: InvestorCrmContactListService,
        routerGlobals: UIRouterGlobals,
        userService: UserService,
        private readonly investorCrmContactListCriteriaMapperService: InvestorCrmContactListCriteriaMapperService,
        private readonly investorCrmContactListBuilderService: InvestorCrmContactListBuilderService,
        private readonly investorCrmCloneContactListBuilderService: InvestorCrmCloneContactListBuilderService,
        private readonly notificationsService: GlobalNotificationsService,
        private readonly stateService: StateService,
        private readonly modalService: BsModalService,
    ) {
        super(investorCrmContactListService, routerGlobals, userService);

        this.orClauseManager = new InvestorContactListClauseManager(this.listForm.get('segmentFilter') as RealmFormArray);
    }

    // Override
    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        const hasExistingList: boolean = !!this.routerGlobals.params.listId;
        if (hasExistingList) {
            this.isNew = false;
            this.isCloning = this.stateService.includes('**.clone');

            if (this.isCloning && !this.userCanManageContactLists) {
                this.stateService.go('home');

                return;
            }

            await this.retrieveExistingList();

            if (this.isCloning) {
                this.isNew = true;
                this.existingContactList.id = null;
                this.existingContactList.name = null;

                this.listForm.get('includeManuallyAdded').setValidators(Validators.required);
            }

            this.populateGeneralInformationFromExistingList();

            if (!this.isCloning) {
                this.tellBackEndToUpdateExistingList();
            }
        } else {
            this.isNew = true;
        }

        this.determineHeaderText();

        this.availableUsersSubscription = this.investorCrmContactListService
            .getContactListUsers(this.userProfile.organization.organizationId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((availableUsers: ContactListDesignatedUser[]) => {
                this.availableUsersResolved = true;
                this.availableUsers = availableUsers;
            });

        this.criteriaSubscription = this.investorCrmContactListService
            .getContactListCriteria(this.userProfile.organization.organizationId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((criteria: ContactListCriteria) => {
                this.criteriaResolved = true;

                this.criteria = criteria;
                this.investorCrmContactListCriteriaMapperService.mapCriteria(
                    this.criteria,
                    this.fieldTypesById,
                    this.fieldsById_ByFieldTypeId,
                    this.operationsById_ByTypeId
                );

                if (hasExistingList) {
                    this.setUpCriteriaFromExistingList();
                }
            });
    }

    // Override
    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    get resolved(): boolean {
        return (this.availableUsersResolved && this.criteriaResolved && !this.loadingContactList);
    }

    populateGeneralInformationFromExistingList(): void {
        if (this.existingContactList) {
            (this.listForm.get('name') as RealmFormControl).setValue(this.existingContactList.name);
            (this.listForm.get('visibility') as RealmFormControl).setValue(this.existingContactList.designatedOption.enabled);
            if (this.existingContactList.designatedOption.enabled) {
                const selectedDesignatedUsersFormControl: RealmFormControl =
                    (this.listForm.get('selectedDesignatedUsers') as RealmFormControl);
                selectedDesignatedUsersFormControl.enable();
                selectedDesignatedUsersFormControl.setValue(this.existingContactList.designatedOption.designatedUsers);
            }
        }
    }

    setUpCriteriaFromExistingList(): void {
        if (this.existingContactList) {
            this.orClauseManager.configureFrom(
                this.existingContactList,
                this.fieldTypesById,
                this.fieldsById_ByFieldTypeId,
                this.operationsById_ByTypeId
            );
        }
    }

    tellBackEndToUpdateExistingList(): void {
        const subscription: Subscription = this.investorCrmContactListService
            .updateContactList(this.userProfile.organization.organizationId, this.existingContactList)
            .subscribe((updatedList: ContactList) => {
                subscription.unsubscribe();

                this.existingContactList = updatedList;
            });
    }

    changeEdit(editing: boolean): void {
        if (this.isCloning) {
            this.stateService.go('^.details', { listId: this.routerGlobals.params.listId });

            return;
        }

        this.editing = editing;

        if (this.isNew && !this.editing) {
            this.stateService.go('^.^.lists');

            return;
        }

        if (!this.isNew && !this.editing) {
            this.populateGeneralInformationFromExistingList();
            this.setUpCriteriaFromExistingList();
        }
    }

    async submit(): Promise<void> {
        this.loadingContactList = true;

        try {
            const contactList: ContactList = this.investorCrmContactListBuilderService.buildContactList(
                this.userProfile,
                (this.listForm.get('name') as RealmFormControl),
                (this.listForm.get('visibility') as RealmFormControl),
                (this.listForm.get('selectedDesignatedUsers') as RealmFormControl),
                (this.listForm.get('segmentFilter') as RealmFormArray),
            );

            if (this.isCloning) {
                const contactListClone: ContactListClone = this.investorCrmCloneContactListBuilderService.buildContactListClone(
                    contactList,
                    (this.listForm.get('includeManuallyAdded') as RealmFormControl),
                );
                const clonedContactList: ContactList = await this.investorCrmContactListService
                    .cloneContactList(this.userProfile.organization.organizationId, contactListClone)
                    .toPromise();

                this.stateService.go('^.^.:listId.details', { listId: clonedContactList.id });
            } else if (this.isNew) {
                const savedContactList: ContactList = await this.investorCrmContactListService
                    .createContactList(this.userProfile.organization.organizationId, contactList)
                    .toPromise();

                this.stateService.go('^.:listId.details', { listId: savedContactList.id });
            } else {
                contactList.id = this.existingContactList.id;
                this.existingContactList = await this.investorCrmContactListService
                    .saveContactList(this.userProfile.organization.organizationId, contactList)
                    .toPromise();

                this.populateGeneralInformationFromExistingList();
                this.setUpCriteriaFromExistingList();
                this.determineHeaderText();
            }

            this.editing = false;
            // TODO: Notification when the view portion is done?
        } finally {
            this.loadingContactList = false;
        }
    }

    showDeleteListModal(): void {
        const initialState = {
            title: 'Confirm to Delete List',
            confirmText: 'Yes',
            cancelText: 'No',
            message: 'You are about to delete this list. Are you sure you want to continue?',
            onConfirm: async (): Promise<void> => {
                this.bsModalRef.content.resolving = true;
                try {
                    await this.investorCrmContactListService
                        .deleteContactList(this.userProfile.organization.organizationId, this.existingContactList.id)
                        .toPromise();

                    this.bsModalRef.content.resolving = false;
                    this.bsModalRef.hide();

                    this.notificationsService.add({
                        type: GlobalNotificationType.POSITIVE,
                        message: 'This list has been successfully deleted',
                    });
                    this.stateService.go('crm.contact.lists');
                } catch ({ error: { message }, message: httpError }) {
                    this.bsModalRef.content.errorText = message;

                    this.bsModalRef.content.resolving = false;
                }
            },
        };
        this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    determineHeaderText(): void {
        if (this.isCloning) {
            this.headerText = 'Clone List';
        } else if (this.isNew) {
            this.headerText = 'Create List';
        } else {
            this.headerText = this.existingContactList.name;
        }
    }
}
