import { Component, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { map, mapValues, trim, debounce } from 'lodash';

import { Mixin } from 'utils/mixin.decorator';

import { UserService } from 'angularjs-providers/user.provider';
import { StaticValuesService } from 'angularjs-providers/static-values.provider';

import { ColumnsManagerMixin, ExportMixin } from 'commons/mixins';
import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ListComponent } from 'commons/components/list/list.component';

import { RelationStatusColors } from '../../relation-status-colors';
import { ClientsService } from '../clients.service';
import { columnsConfig } from './columns-config';
import { NotificationModalComponent } from 'commons/components/modals';
import { BsModalService } from 'ngx-bootstrap/modal';

@Mixin([
	ColumnsManagerMixin,
	ExportMixin,
])
@Component({
	templateUrl: './paged-clients-list.component.html',
	selector: 'paged-client-list',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => PagedClientsListComponent) },
	],
})
export class PagedClientsListComponent extends PagedListComponent implements OnInit, ColumnsManagerMixin, ExportMixin {
	static listName = 'clients';

	User: any;
	relationStatusColors = RelationStatusColors;
	list: any = [];
	moreFilters: boolean;
	dateFormatter = new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});

	// ColumnsManagerMixin
	StorageService;
	localStorageName = 'LenderClientsColumns';
	columns = new Map();
	columns$;
    columnValues: () => [];
	prepareColumns;
	toggleColumn;
	getColumnVisibility;

	// ExportMixin
	url;
	export;
	getExportParams;

	@Output() bulkEnter = new EventEmitter<void>();

	constructor(
		public modalService: BsModalService,
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public StaticValues: StaticValuesService,
		public clientsService: ClientsService,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
		this.url = `/api/download/lenders/${this.User.organization.id}/clients/export`;
	}

	getColumns() {
		return Array.from(this.columns.values());
	}

	ngOnInit(): void {
		this.configureFilters();
		this.configureColumns();

		super.ngOnInit();
	}

	configureFilters(): void {
		this.setCustomFieldValue = debounce(this.setCustomFieldValue, 1000);
		this.filters = {
			channelName: this.clientsService.lenderChannels({
				activeOnly: true,
				accessibleOnly: true,
			}),
			state: this.StaticValues.get({ code: 'State' }),
			approvalStatus: this.clientsService.approvalStatuses(),
			accountExecutive: this.clientsService.lenderAccountExecutives(),
			statusReason: this.clientsService.lenderStatusReasons(),
			customFieldId: this.clientsService.customFields(),
		};

		this.defaultFilters = {
			...this.defaultFilters,
			channelName: [],
			state: [],
			approvalStatus: [],
			accountExecutive: [],
			statusReason: [],
			customFieldId: null,
			customFieldValue: null,
		};
	}

	configureColumns(): void {
		this.prepareColumns(columnsConfig);
	}

	setFilter(filter, resetPage = true): void {
		const processedFilter = mapValues(filter, (value: any, key: string) => {
			switch (key) {
				case 'channelName':
				case 'approvalStatus':
				case 'accountExecutive':
				case 'statusReason':
					return map(value || [], 'id');
				case 'state':
					return map(value || [], 'shortName');
				case 'customFieldId':
					return value?.id;
				default:
					return value;
			}
		});

		super.setFilter(processedFilter, resetPage);
	}


	setCustomFieldValue ({ target }: Event) {
		const customFieldValue = trim((target as HTMLInputElement).value);

		const currentParams = this.params$.getValue();
		if (currentParams.customFieldValue === customFieldValue) {
			// do not search on same input
			return;
		}

		this.setFilter({ customFieldValue });
	}

	toggleFilters(): void {
		this.moreFilters = !this.moreFilters;
	}

	loadList(queryParams) {
		this.list.$resolved = false;
		return this.clientsService.list(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	reloadList() {
		this.loadList(this.params);
	}

	goBulk(): void {
		if (!(this.params.channelName.length === 1)) {
			const initialState = {
				title: 'Change Account Assignment',
				notification: 'You must first select a channel before you can edit Assignment.',
			};
			this.modalService.show(NotificationModalComponent, { initialState, class: 'modal-smd modal-new' });
		} else {
			this.bulkEnter.emit();
		}
	}
}
