import { Component, forwardRef } from '@angular/core';
import { StateService, TransitionService, UIRouter } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { some } from 'lodash';

import { Mixin } from 'utils/mixin.decorator';

import { StateService as $stateProvider } from 'angularjs-providers/state.provider';
import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';
import { SmConfirmModalComponent } from 'commons/components/modals';
import { SharedSocialMediaService } from 'shared/social-media/social-media.service';
import { AuditsService } from 'shared/social-compliance/audits/audits.service';
import { PublishingPermissionsResourceService } from 'tpo/social-compliance/publishing-permissions';
import { IndividualConnectSmMixin, SocialAccountsConnectComponent } from 'tpo/social-accounts';
import { SocialAccountLink } from 'tpo/social-accounts/company/link.class';
import { SocialConnectResourceService } from 'tpo/social-accounts/company/social-connect-resource.service';
import { ContactSocialAccountsService } from 'tpo/social-accounts/social-accounts.service';

@Mixin([ IndividualConnectSmMixin ])
@Component({
	templateUrl: './audit-social-accounts.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => AuditSocialAccountsComponent) },
	],
	host: {
		'(window:message)': 'onMessage($event)',
	},
})
export class AuditSocialAccountsComponent extends SocialAccountsConnectComponent {
	static listName = 'auditSocialAccounts';

	isCurrentUser: boolean = false;
	canManage: boolean = false;
	canRestore: boolean = false;
	canRemove: boolean = false;
	canManageRss: boolean = false;
	review: any;
	publisherRequests: any;

	// IndividualConnectSmMixin
	initNetworks: () => void;

	get isReviewCompleted(): boolean {
		return this.review.reviewStatus === 'C';
	}

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		$state: $stateProvider,
		modalService: BsModalService,
		sharedSocialMediaService: SharedSocialMediaService,
		socialConnectService: SocialConnectResourceService,
        uiRouter: UIRouter,
		private publishingPermissionsResourceService: PublishingPermissionsResourceService,
		private contactSocialAccountsService: ContactSocialAccountsService,
		private auditsService: AuditsService,
	) {
		super(
			transitionService,
			stateService,
			userService,
			$state,
			modalService,
			sharedSocialMediaService,
            uiRouter,
			socialConnectService,
		);

		this.permissionsResource = publishingPermissionsResourceService.individual;

		this.initNetworks();
	}

	postInit() {
		const { reviewId } = this.stateService.params;
		this.review = this.auditsService.audit({ reviewId });
		this.review.$promise
			.then(() => {
				const { contactId: reviewContactId } = this.review;
				const { contactId: userContactId } = this.User;
				this.isCurrentUser = reviewContactId === userContactId;

				if (this.isReviewCompleted) {
					this.availableStates = this.availableStates.filter(({ value }) => value !== 'removed')
				}

				// init permissions
				this.canManage = (
					!this.isReviewCompleted &&
					(
						this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') ||
						this.User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS')
					)
				);
				this.canManageRss = (
					!this.isReviewCompleted &&
					this.User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS')
				);
				this.canRestore = (
					!this.isReviewCompleted &&
					(
						this.isCurrentUser ||
						this.User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS')
					)
				);
				this.canRemove = this.canRestore;

				// update list filters
				this.defaultFilters.nmlsId = null;
				this.defaultFilters.contactId = reviewContactId;

				// TODO: getter
				this.socialMediaServiceParams = {
					mediaType: 'social-media',
					entityIdType: 'CONTACTID',
					entityId: reviewContactId,
				};
			})
			.finally(() => {
				super.deferredInit();
			});
	}

	getPermissionParams = ({ linkId }) => ({
		tpoId: this.review.tpoId,
		linkId,
		id: this.review.contactId,
	})

	getListParams = (queryParams) => {
		const {
			id: reviewId,
			tpoId,
		} = this.review;
		return {
			...this.getClearParams(queryParams),
			tpoId,
			linkListType: this.apiLinkMap[queryParams.linkListType] || 'SOCIAL_ACCOUNTS',
			includePublisherStatus: this.isTpo,
			includePublisherCount: this.isTpo || this.isComergence,
			reviewId,
		};
	}

	loadList = (queryParams) => {
		if (!this.deferredResolved) {
			return;
		}

        this.list.$resolved = false;
        return this.contactSocialAccountsService.list(
            this.getListParams(queryParams)
        ).$promise
            .then((data) => {
                this.list = data;
                this.isPublisherOnThePage = some(this.list, (entry: SocialAccountLink) => (!!entry.data.publisherStatus));
            })
            .finally(() => {
                this.list.$resolved = true;
            });
	}

	toPublishingPermissions(link) {
		if (!this.isLender) {
			const {
				tpoId,
				contactId,
			} = this.review;
			const {
				linkId,
			} = link.data;

			this.stateService.go(
				`${this.isComergence ? 'tpo.:id' : 'account'}.contacts.:contactId.publishing-permissions`,
				{
					...(this.isComergence && { id: tpoId }),
					contactId,
					linkId,
				}
			);
		}
	}

	requestAccess({data: account}: SocialAccountLink, cancel: boolean = false) {
		const initialState = {
			title: cancel ? 'Cancel Request' : 'Publisher Access',
			message: cancel ? `Are you sure you want to cancel Publisher Request for <strong>${account.name}</strong>?` : `You are requesting permission to post on behalf of <strong>${this.review.contactName}</strong> for this account:`,
			confirmText: cancel ? 'Yes' : 'Request',
			cancelText: cancel ? 'No' : 'Cancel',
			account,
			onConfirm: () => {
				const modalScope = this.modalRef.content;
				modalScope.resolving = true;
				modalScope.errorText = null;

				this.permissionsResource.requests[cancel ? 'cancel' : 'add']({
						...this.getPermissionParams(account),
					}, () => {
						this.modalRef.hide();
						this.loadList(this.getClearParams(this.params));
					}, ({data}) => {
						modalScope.resolving = false;
						modalScope.errorText = data.message;
					},
				);
			},
		};

		this.modalRef = this.modalService.show(SmConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}
}
