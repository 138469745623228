import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { StateService } from '@uirouter/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FileRequiredValidator } from 'commons/validators';
import { CcmDataMatchService } from '../ccm-data-match.service';
import { CcmDataMatchChannel, CcmDataMatchCustomer, CcmDataMatchReport } from '../ccm-data-match.interface';


@Component({
    templateUrl: './new-data-match.component.html',
})
export class NewDataMatchComponent {
    isSaving: boolean = false;
    protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    readonly dataMatchForm: RealmFormGroup = new RealmFormGroup({
        lender: new RealmFormControl(
            'lender',
            {
                label: 'Customer',
            },
        ),
        channel: new RealmFormControl(
            'channel',
            {
                label: 'Channel',
            },
        ),
        customer: new RealmFormControl(
            'customer',
            {
                label: 'Company Name',
            },
            Validators.required,
        ),
        comment: new RealmFormControl(
            'comment',
            {
                label: 'Comments',
            },
        ),
        file: new RealmFormControl(
            'file',
            {
                label: 'File',
            },
            FileRequiredValidator,
        ),
    });

	customerSearchLoading: boolean = false;
	customerSearchInput: Subject<string> = new Subject();
    customers: CcmDataMatchCustomer[] = [];

    channelsLoading: boolean = false;
    channels: CcmDataMatchChannel[] = [];

	constructor(
        private readonly ccmDataMatchService: CcmDataMatchService,
        private readonly stateService: StateService,
    ) {
		this.subscribeCustomerSearch();

        this.dataMatchForm.controls.channel.disable();
    }

    async customerSelected(customer: CcmDataMatchCustomer): Promise<void> {
        this.dataMatchForm.controls.lender.setValue(customer);

        if (!customer) {
            this.dataMatchForm.controls.channel.reset();
            this.dataMatchForm.controls.channel.disable();

            this.dataMatchForm.controls.customer.reset();
            this.dataMatchForm.controls.customer.enable();

            return;
        }

        this.dataMatchForm.controls.channel.enable();

        this.dataMatchForm.controls.customer.setValue(customer.name);
        this.dataMatchForm.controls.customer.disable();

        try {
            this.channelsLoading = true;

            this.channels = await this.ccmDataMatchService.getLenderChannels(customer.id).toPromise();
        } catch (e) {
            this.dataMatchForm.setServerError(e.data || e);
        } finally {
            this.channelsLoading = false;
        }
    }

    fileSelected(files: FileList): void {
		if (!files.length) {
            return;
        }

		this.dataMatchForm.controls.file.setValue(files[0]);
    }

    public async uploadDataMatch(): Promise<void> {
        const dataMatch: CcmDataMatchReport = this.createDataMatch();
        const formValue = this.dataMatchForm.getRawValue();

        try {
            this.isSaving = true;

            const uploadedDocument: CcmDataMatchReport = await this.ccmDataMatchService.createNewDataMatch(
                formValue.file,
                dataMatch
            ).toPromise();

            this.stateService.go(
                'data-match.report',
                {
                    id: uploadedDocument.id,
                }
            );
        } catch (errorResponse) {
            this.dataMatchForm.setServerError(errorResponse.error);
        } finally {
            this.isSaving = false;
        }
    }

    public createDataMatch(): CcmDataMatchReport {
        const formValue = this.dataMatchForm.getRawValue();
        const lender: CcmDataMatchCustomer = formValue.lender;
        const channel: CcmDataMatchChannel = formValue.channel;
        const customer: string = formValue.customer;
        const comment: string = formValue.comment;

        const dataMatch: CcmDataMatchReport = {
            lender,
            channel,
            customer,
            billingAmount: 0,
        };
        if (comment) {
            dataMatch.comment = comment;
        }

        return dataMatch;
    }

	private subscribeCustomerSearch(): void {
		this.customerSearchInput.pipe(
			distinctUntilChanged(),
			debounceTime(500),
			switchMap(async (searchTerm: string) => {
                this.customerSearchLoading = (searchTerm && (searchTerm.length > 1));
                if (!this.customerSearchLoading) {
                    return this.customers;
                }

				this.dataMatchForm.reset();
				try {
					const customers: CcmDataMatchCustomer[] = await this.ccmDataMatchService.getCustomerSearchResults(searchTerm).toPromise();

                    return customers;
				} catch (e) {
					this.dataMatchForm.setServerError(e.data || e);

					return [];
				}
			}),
		).subscribe((customers: CcmDataMatchCustomer[]) => {
			this.customers = customers;
			this.customerSearchLoading = false;

            this.cdr.detectChanges();
		});
	}
}
