import { Component, OnDestroy, signal } from '@angular/core';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { ErrorModalService } from 'commons/services/error/error-modal.service';
import { SolutionCenterComponent } from 'lender/solution-center/solution-center.component';
import {
    InvestorSolutionCenterService,
    SCProduct,
    SCProductOverview,
} from 'lender/solution-center/solution-center.service';
import { firstValueFrom } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManageProductModalComponent } from './manage-product-modal/manage-product-modal.component';

@Component({
    templateUrl: './products.component.html',
})
export class SCProductsComponent implements OnDestroy {
    form = new RealmFormGroup({
        description: new RealmFormControl('description', { label: 'Product Overview', updateOn: 'change', value: '' }),
    });
    originalOverview: SCProductOverview;
    editing = signal<boolean>(false);
    maxLength = 4000;

    modalRef: BsModalRef;

    constructor(
        private readonly errorModalService: ErrorModalService,
        protected readonly solutionCenter: SolutionCenterComponent,
        protected readonly investorSolutionCenterService: InvestorSolutionCenterService,
        protected modalService: BsModalService,
    ) {
        this.form.valueChanges.subscribe((formData: Partial<SCProductOverview>) => {
            if (this.editing()) {
                this.solutionCenter.productOverview.update(overview => ({ ...overview, ...formData }));
            }
        });
    }

    private toggleEdit = () => {
        this.form.reset(this.originalOverview);
        this.editing.update(value => !value);
    }

    edit() {
        this.originalOverview = this.solutionCenter.productOverview();
        this.toggleEdit();
    }

    async save() {
        try {
            //temp code
            const data = this.solutionCenter.productOverview();
            this.solutionCenter.resolving = true;
            const overview = await firstValueFrom(
                this.investorSolutionCenterService.saveProductOverview(data),
            );
            this.solutionCenter.productOverview.set(overview);
            this.originalOverview = overview;
            this.cancel();
        } catch ({ error: { message }, message: httpError }) {
            this.form.setServerError({ message: message || httpError });
        } finally {
            this.solutionCenter.resolving = false;
        }
    }

    cancel() {
        this.toggleEdit();
    }

    async changeProductVisibility(productToUpdate: SCProduct) {
        try {
            this.solutionCenter.resolving = true;
            const updatedProduct = await firstValueFrom(
                this.investorSolutionCenterService.updateProductVisibility(productToUpdate.productId, !productToUpdate.visibilityAllowedByCustomer),
            );
            this.solutionCenter.productList.update(list => this.updateProductsListWithItem(list, updatedProduct));
        } catch (e) {
            this.errorModalService.handleHttpError(403, false);
        } finally {
            this.solutionCenter.resolving = false;
        }
    }

    async changeProductTypeAndDescription(productToUpdate: SCProduct) {
        try {
            const updatedProduct = await firstValueFrom(
                this.investorSolutionCenterService.updateProductDescriptionAndType(productToUpdate),
            );
            this.solutionCenter.productList.update(list => this.updateProductsListWithItem(list, updatedProduct));
            this.modalRef.hide();
        } catch (e) {
            this.modalRef.content.manageProductForm.setServerError({ message: e.error.message || e.message || e });
        }
    }

    openManageModal(product: SCProduct) {
        const initialState = {
            product: product,
            save: async (updatedProduct: SCProduct) => {
                await this.changeProductTypeAndDescription(updatedProduct);
            },
        }
        this.modalRef = this.modalService.show(
            ManageProductModalComponent,
            {
                initialState,
                class: 'modal-smd modal-new',
                backdrop: 'static',
            },
        );
    }

    private updateProductsListWithItem(list: SCProduct[], updatedProduct: SCProduct): SCProduct[] {
        return list.map(item => {
            if(item.productId == updatedProduct.productId) {
                item = updatedProduct;
            }
            return item;
        });
    }

    ngOnDestroy() {
        if (this.editing()) {
            this.cancel();
        }
    }
}
