import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { RealmHttpClient } from 'commons/services/http';
import { NgPagedResource, NgResource, NgResourceArray } from 'commons/interfaces';
import { isArray, isFunction, isPlainObject, omit } from 'lodash';
import { identity, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';

type $rTranReq = (data, headersGetter?) => string;
type $rTranRes = (data, headersGetter?, status?) => any;
type $rParams = Record<string, unknown>;
type $rAction = {
    method?: string;
    isArray?: boolean;
    params?: $rParams;
    url?: string;
    transformRequest?: $rTranReq;
    transformResponse?: $rTranRes;
    cancellable?: boolean;
    responseType?: string;
    cacheConfig?: unknown;
    interceptor?: unknown;
    headers?: {
        [header: string]: string | string[];
    };
};
type $rActions = Record<string, $rAction>;
//list of props to raise error
const deprecatedProps: (keyof $rAction|string)[]  = [
    // 'cacheConfig', ignoring cache
    'responseType',
    'interceptor',
];

const DEFAULT_ACTIONS: $rActions = {
    get: { method: 'GET' },
    save: { method: 'POST' },
    query: { method: 'GET', isArray: true },
    remove: { method: 'DELETE' },
    delete: { method: 'DELETE' },
};
enum CallType {
    SINGLE,
    ARRAY,
    PAGED_ARRAY,
}

const endPointFactory = (http: RealmHttpClient, gUrl: string, gParams: $rParams = null, action: $rAction, paged: boolean) => {
    const {
        isArray,
        cancellable,
        url: localUrl,
        params: localParams,
        method = 'GET',
        headers: rawHeaders,
        transformRequest,
        transformResponse = (json: string) => {
            //if non-valid json - return as is
            try {
                return JSON.parse(json);
            } catch (e) {
                return json;
            }
        },
    } = action;
    const type = isArray ? paged ? CallType.PAGED_ARRAY : CallType.ARRAY : CallType.SINGLE;
    const url = localUrl || gUrl;
    const unsupportedProps = Object.keys(action).filter(prop => deprecatedProps.includes(prop));
    if (unsupportedProps.length) {
        console.error(`Call ${method.toUpperCase()} ${url} has unsupported properties: ${unsupportedProps.join(', ')}`);
    }
    const hasBody = ['POST', 'PUT', 'PATCH'].includes(method.toUpperCase());

    return (...args) => {
        const { params, body: rawBody, success, error } = extractArgs(args, hasBody);
        if (transformRequest?.length > 1) {
            console.error(`${method.toUpperCase()} ${url} Extended "transformRequest" not supported, consider rewriting to RealmHttpClient`);
        }
        const body = transformRequest ? transformRequest(rawBody) : rawBody;
        const result =
            type == CallType.PAGED_ARRAY
                ? [] as NgPagedResource<unknown>
                : type == CallType.ARRAY
                    ? [] as NgResourceArray<unknown>
                    : {} as NgResource<unknown>;
        if (paged && isArray) {
            Object.defineProperty(result, '$page', { enumerable: false, writable: true, value: 0 });
            Object.defineProperty(result, '$pagesTotal', { enumerable: false, writable: true, value: 0 });
            Object.defineProperty(result, '$total', { enumerable: false, writable: true, value: 0 });
        }
        const { url: resultingUrl, params: resultingParams } = exhaustUrlParams(url, mergeParams(filterSV(gParams, body), filterSV(localParams, body), params));

        const cancel$ = cancellable ? new Subject<void>() : null;
        //support for resource cancellable requests
        if (cancellable) {
            Object.defineProperty(result, '$cancelRequest', {
                enumerable: false,
                configurable: true,
                value: () => {
                    cancel$.next();
                    cancel$.complete();
                },
            });
        }
        const headers = Object.fromEntries(Object.entries({
            'Content-Type': 'application/json',
            ...(rawHeaders || {}),
        }).filter(([, value]) => !!value));
        // console.info(`resource$ Calling ${method.toUpperCase()} ${resultingUrl} with params: `, resultingParams, 'details', { body, headers });
        const reqBody = body;
        const $promise = http.fullRequest(method, resultingUrl, resultingParams, body, {
            headers,
        }).pipe(
            cancellable ? takeUntil(cancel$) : identity,
            map((response) => {
                if (result.$cancelRequest) {
                    delete result.$cancelRequest;
                }

                const { body, headers, status } = response;
                const resBody = body || !hasBody ? body : reqBody;
                const data = transformResponse(resBody, headers.get.bind(headers), status);
                switch (type) {
                    case CallType.PAGED_ARRAY:
                        fillPages(result as NgPagedResource<unknown>, response);
                        (result as NgPagedResource<unknown>).push(...data as Array<unknown>);
                        break;
                    case CallType.ARRAY:
                        (result as NgResourceArray<unknown>).push(...data as Array<unknown>);
                        break;
                    default:
                        Object.assign(result, data);
                        break;
                }
                result.$resolved = true;
                return result;
            }),
            catchError((e) => {
                if (!(e instanceof HttpErrorResponse)) {
                    console.error(e);
                }
                const { error: data, ...rest } = e;
                result.$resolved = true;
                return throwError({ data, ...rest });
            }),
            shareReplay(1),
        ).toPromise().then((response) => {
            isFunction(success) && success(response);
            return response;
        }, error);
        Object.defineProperty(result, '$resolved', { enumerable: false, writable: true, value: false });
        Object.defineProperty(result, '$promise', { enumerable: false, value: $promise });
        return result;
    };
};

const fillPages = (result: NgPagedResource<unknown>, response?: HttpResponse<unknown>) => {
    // Reset to zeroes
    result.$page = result.$total = result.$pagesTotal = 0;
    if (response) {
        const { headers } = response;
        result.$page = parseFloat(headers.get('X-Meta-Current-Page'));
        result.$pagesTotal = parseFloat(headers.get('X-Meta-Total-Pages'));
        result.$total = parseFloat(headers.get('X-Meta-Total-Elements'));
    }
}

const mergeParams = (...paramsArr: $rParams[]): $rParams =>
    paramsArr.reduce((params, additionalParams) => ({ ...params, ...(additionalParams || {}) }), {});

// filter substitution values like @id
const filterSV = (params: $rParams, body): $rParams => {
    return Object.fromEntries(Object.entries(params || {})
        .map(([key, value]) => {
            const strVal = String(value);
            if (strVal[0] === '@' && body) {
                const value = body[strVal.substr(1)];
                if (value)
                    return [key, value];
            }
            return [key, value];
        })
        .filter(
        //filter params with values that starts with @ as we are not supporting resource.$methods
        ([,value]) => (String(value)[0] !== '@')
    ));
};

const exhaustUrlParams = (gUrl: string, gParams: $rParams = null): { url: string, params: $rParams } => {
    const foundParams = [];
    const url = gUrl
        .replace(/\/:([^/]+)/g, (matchedStr, name) => {
            if (gParams[name]) {
                foundParams.push(name);
                return `/${gParams[name]}`;
            }
            return '';
        })
        .replace(/[/]+/g, '/')
        .replace(/\/$/, '');
    const params = omit(gParams, foundParams);
    return { url, params };
};

type $rCallArgs = {
    body?: unknown;
    params?: $rParams,
    success?: (data) => unknown,
    error?: (error) => unknown,
}
const extractArgs = (args, hasBody: boolean): $rCallArgs => {
    let body = null, params = {}, success, error, tmp;
    switch (args.length) {
        case 4:
            [params, body, success, error] = args;
            break;
        case 3:
            if (isFunction(args[1])) {
                [tmp, success, error] = args;
                hasBody ? body = tmp : params = tmp;
            } else {
                [params, body, success] = args;
            }
            break;
        case 2:
            if (!isFunction(args[1])) {
                [params, body] = args;
            } else if (!isFunction(args[0])) {
                [tmp, success] = args;
                hasBody ? body = tmp : params = tmp;
            } else {
                [success, error] = args;
            }
            break;
        case 1:
            if (isFunction(args[0])) {
                [success] = args;
            } else if (hasBody) {
                [body] = args;
            } else {
                [params] = args;
            }
            break;
    }
    return { params, body: clear$Props(body), success, error };
};

const clear$Props = (body: unknown) => {
    if (isArray(body))
        return [...body];

    if (isPlainObject(body))
        return Object.fromEntries(
            Object.entries(body)
                .filter(([key]) => (key[0] !== '$'))
        );

    return body;
}

export const $resource = (http: RealmHttpClient, paged = false) => {
    return (gUrl: string, gParams: $rParams = null, addActions: $rActions = {}) => {
        const actions: $rActions = { ...DEFAULT_ACTIONS, ...addActions };
        // let callee = '';
        // try {
        //     throw new Error('');
        // } catch ({ stack }) {
        //     callee = `${stack}`.split('\n')[2].replace(/\(.*(\.\/src.*)\)/, '$1');
        // }
        const result = {};

        Object.entries(actions).forEach(([method, action]) => {
            result[method] = endPointFactory(http, gUrl, gParams, action, paged);
        });

        return result;
    };
};
