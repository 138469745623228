import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService  } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { MoveTemplateModalComponent, TemplatesListComponent } from '.';

import { TemplatesService } from '../templates.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => FolderTemplatesListComponent) },
	],
})
export class FolderTemplatesListComponent extends TemplatesListComponent {
	static listName = 'folder-templates';

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public user: UserService,
		public templatesService: TemplatesService,
		public modalService: BsModalService,
	) {
		super(transitionService, stateService, user, templatesService, modalService);

		this.folderId = parseInt(stateService.params.folderId);
	}

	async loadList(queryParams): Promise<void> {
		this.list.$resolved = false;
		queryParams.folderId = this.folderId;
		this.folder = await this.templatesService.folder(this.folderId).$promise;
		this.groupsCount = this.folder.assetSharing.shareTo.length;
		this.list = await this.templatesService.templates(this.getClearParams(queryParams)).$promise;
	}

	setInitialFilters(): void {
		const { listName } = FolderTemplatesListComponent;
		!this.stateService.params[listName] && (this.stateService.params[listName] = {
			sharingType: 'AV',
		});
	}

	moveTo(templateId): void {
		const initialState = {
			folderId: this.folderId,
			folders: this.templatesService.folders(),
			onConfirm: (targetFolderId: number): void => {
				this.modalRef.content.resolving = true;
				this.templatesService.templateMove(templateId, targetFolderId, () => {
						this.modalRef.hide();
						this.loadList(this.params);
					}, (error) => {
						this.modalRef.content.folderForm.setServerError(error);
					},
				);
			},
		};

		this.modalRef = this.modalService.show(MoveTemplateModalComponent, {
			initialState,
			class: 'modal-smd modal-new modal-restore-link',
		});
	}

}
