import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { UIRouter } from '@uirouter/core';
import { PagedData } from 'commons/services/http';
import { Observable } from 'rxjs';
import { ListComponent } from 'commons/components/list';
import {
    ChannelColumnNameAndSize,
    GenericHistoryEntry,
    GenericHistoryStrategy,
} from 'commons/components/history/generic-history-entry.interface';

@Component({
    templateUrl: 'generic-history.component.html',
    selector: 'generic-history',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => GenericHistoryComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => GenericHistoryComponent) },
    ],
})
export class GenericHistoryComponent extends NewPagedListComponent<GenericHistoryEntry> implements OnInit {
    @Input()
    declare listName: string;

    @Input()
    pageTitle: string;

    @Input()
    useCustomHistoryEntryLine: boolean = false;

    @Input()
    historyActionsStrategy: GenericHistoryStrategy;

    @Input()
    channelColumnsAndHeaders: ChannelColumnNameAndSize[] = [
        {
            columnName: 'Date',
            columnClass: 'col-md-4',
        },
        {
            columnName: 'Action',
            columnClass: 'col-md-4',
        },
        {
            columnName: 'Action By',
            columnClass: 'col-md-4',
        },
    ];

    constructor(
        router: UIRouter,
    ) {
        super(router);
    }

    ngOnInit() {
        super.ngOnInit();

    }

    protected loadList(params: ListParams): Observable<PagedData<GenericHistoryEntry[]>> {
        return this.historyActionsStrategy.getHistoryItems(params);
    }
}
