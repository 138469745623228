<div class="remote-resource" [class.resolved]="!resolving">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Cancel" (click)="onCancel()">&times;</button>
        <h4 class="modal-title">{{ cardData?.companyName }}</h4>
    </div>
	<div class="well warning">
        Please provide your information for {{ cardData?.companyName }} to contact you about this plan
    </div>
    <div class="modal-body">
        <form [formGroup]="contactRequestForm" id="contactRequestForm" name="contactRequestForm" (onValidSubmit)="onSubmit()">
            <form-errors [form]="contactRequestForm"></form-errors>
            <div class="row-fluid">
                <div class="col-md-12">
                    <span class="text-light">
                        Name
                    </span>
                </div>
                <div class="col-md-12"></div>
            </div>
            <div has-error class="row-fluid form-group">
                <div class="col-md-12">
                    <input
                        formControlName="name"
                        type="text"
                        class="form-control has-error"
                    />
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-md-12">
                    <span class="text-light">
                        Email
                    </span>
                </div>
                <div class="col-md-12"></div>
            </div>
            <div has-error class="row-fluid form-group">
                <div class="col-md-12">
                    <input
                        formControlName="email"
                        type="email"
                        class="form-control has-error"
                    />
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-md-12">
                    <span class="text-light">
                        Phone
                    </span>
                </div>
                <div class="col-md-12"></div>
            </div>
            <div has-error class="row-fluid form-group">
                <div class="col-md-12">
                    <input
                        formControlName="phone"
                        type="phoneFormatter"
                        phoneFormatter
                        class="form-control has-error"
                    />
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <div class="text-right">
            <button type="submit" form="contactRequestForm" class="btn btn-primary">Submit</button>
            <button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
        </div>
    </div>
</div>
