<div class="remote-resource" [class.resolved]="resolved && isAttachmentsRendered">
	<div class="post-row">
		<div class="column profile-image">
			<i [style.background-image]="sanitize(profilePictureUrl)" class="profile-image profile"></i>
		</div>
		<div class="column profile-name">
			<a [aDisabled]="!webLink" href="{{webLink}}" target="_blank"><b>{{name}}</b></a>&nbsp;
			<span class="text-muted">&#64;username · {{previewDate | date:'MMM d'}}</span>

			<div class="post-text" *ngIf="textTruncated | textToUrl">
				<formatted-text
					[text]="textTruncated | textToUrl: { regex: regLink, displayProtocol: false }">
				</formatted-text>
			</div>

			<div *ngIf="linkPreview?.url && !attachments?.length" class="link-preview">
				<a [href]="linkPreview?.url" target="_blank" class="link-section clearfix">
					<img *ngIf="linkPreview?.image" [src]="linkPreview?.image">
					<span class="text-holder">
						<span class="title">{{linkPreview?.title}}</span>
						<span class="text-muted description">
							{{linkPreview?.description}}
						</span>
						<span class="twitter-link c-icon c-icon-twitter-link">{{linkPreview?.domain}}</span>
					</span>
				</a>
			</div><!--end: post-preview-->

			<publisher-attachments-preview
				[attachments]="attachments"
				[networkCode]="networkCode"
				(onIsRendered)="attachmentsLoaded($event)"
			></publisher-attachments-preview>
		</div>
	</div>
	<footer>
		<img src="/static/images/posts-preview/tw.png" alt="twitter">
	</footer>
</div>
