import { Component } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { InfiniteScrollForPagedResourceComponent } from 'commons/components/list/infinite-scroll-for-paged-resource.component';

import { SocialMediaRSSResourceService } from '../social-media-rss-resource.service';

@Component({
	templateUrl: './content-list.component.html',
})
export class ContentListComponent extends InfiniteScrollForPagedResourceComponent {
	static listName = 'libraryContent';

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public rssResource: SocialMediaRSSResourceService,
	) {
		super(transitionService, stateService);
		this.endpoint = this.rssResource.feedEntries.query;
	}
}
