import { reduce } from 'lodash';
import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService as UService } from 'angularjs-providers/user.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';

import { SocialMediaRSSResourceService } from '../social-media-rss-resource.service';

@Component({
	templateUrl: './manage-feed.component.html',
})
export class LibraryContentManageFeedComponent extends ListComponent {
	static listName = 'contentManageFeed';

	User: any;
	list: any = [];
	addedList: any = [];
	suggestedList: any = [];
	showAddRssForm: boolean = false;
	addRssForm = new RealmFormGroup({
		url: new RealmFormControl(
			'url',
			{label: 'url'},
			Validators.compose([
				Validators.required,
			]),
		),
	});

	modalRef: BsModalRef;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public SocialMediaRSS: SocialMediaRSSResourceService,
		public $state: $stateProvider,
		public modalService: BsModalService,
		public UserService: UService,
	) {
		super(transitionService, stateService);

		this.User = UserService.profile;
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.SocialMediaRSS.rss.query(queryParams).$promise
			.then((result) => {
				const { addedList, suggestedList } = reduce(result, (acc, entry) => {
					if (entry.suggested) {
						return { ...acc, suggestedList: [ ...acc.suggestedList, entry ] };
					}

					return { ...acc, addedList: [ ...acc.addedList, entry ] };
				}, { addedList: [], suggestedList: [] });

				this.suggestedList = suggestedList;
				this.addedList = addedList;
				this.list = result;
			});
	}

	approveRemoveRSS(entry) {
		const initialState = {
			message: 'Are you sure you want to remove content from this feed?',
			confirmText: 'Remove',
			onConfirm: () => {
				this.removeRSS(entry);
				this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState });
	}

	removeRSS(entry) {
		this.list.$resolved = false;

		this.SocialMediaRSS.rss.remove({
			id: entry.id,
		}, () => {
			this.loadList(this.params);
		}, () => {
			this.list.$resolved = true;
		});
	}

	addFromSuggested(entry) {
		this.list.$resolved = false;

		this.SocialMediaRSS.rss.add({
			url: entry.url,
		}, this.saveSuccessHandler, ({data: response}) => {
			this.addRssForm.setServerError(response);
			this.list.$resolved = true;
		});
	}

	saveSuccessHandler = () => {
		const href = this.$state.href('social-compliance.publisher.library.content', {});
		const link = `<a class="underlined text-success" href="${href}">View Content</a>`;

		this.$state.notify('social-compliance.publisher.library.content.manage-feed', {
			notification: {
				type: 'alert-success',
				message: `Feed added. ${link}`,
				timeout: 4000,
			},
		}, { reload: true });
	}

	toggleRssForm() {
		this.showAddRssForm = !this.showAddRssForm;
	}

	clear(): void {
		this.addRssForm.reset();
	}

	cancel(): void {
		this.clear();
		this.toggleRssForm();
		this.addRssForm.markAsPristine();
		this.addRssForm.serverError = '';
	}

	save(): void {
		this.addFromSuggested(this.addRssForm.getRawValue());
	}
}
