import { NgModule } from '@angular/core';
import { CcmCustomerProductsComponent } from 'comergence/ccm-customer-product/ccm-product/ccm-customer-products.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { CcmCustomerProductsService } from 'comergence/ccm-customer-product/ccm-customer-products.service';
import { CcmCustomerProductItemComponent } from 'comergence/ccm-customer-product/ccm-product/ccm-product-item/ccm-customer-product-item.component';

@NgModule(
    {
        imports: [
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            PipesModule,
            DirectivesModule,
            ComponentsModule,
        ],
        declarations: [
            CcmCustomerProductsComponent,
            CcmCustomerProductItemComponent,
        ],
        providers: [
           CcmCustomerProductsService,
        ],
    },
)
export class CcmCustomerProductModule {

}
