import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { TriggersListComponent } from './list';
import { TriggerDetailsComponent } from './list/details';
import { TriggerHistoryComponent, TriggerHistoryModalComponent } from './list/history';
import { WordEditorComponent } from './word-editor';

const components = [
	TriggersListComponent,
	TriggerDetailsComponent,
	TriggerHistoryComponent,
	TriggerHistoryModalComponent,
	WordEditorComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
    ],
    declarations: [
        ...components,
    ]
})
export class TriggersModule {}
