import {
	find,
	get,
	intersection,
	isEmpty,
	size,
	map,
	isEqual,
	pull,
	reduce,
	without,
	includes,
	assign,
	reject,
	filter,
	cloneDeep,
} from 'lodash';
import { from, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, ElementRef, Injector, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { AbstractControl, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { FormattedTextComponent } from 'commons/components/formatted-text';
import { MaxLengthValidator } from 'commons/validators';
import { filterUniqueByPipe } from 'commons/pipes/array-uniq-by.pipe';

import { SocialMediaRSSResourceService } from 'tpo/social-compliance/publisher/library/content/social-media-rss-resource.service';

import { PostValidator } from '../../../../attachments/attachments.component';
import { PostsResourceService } from '../../../posts-resource.service';
import { TemplatesResourceService } from '../../../../library';
import { CampaignsResourceService } from '../../../../campaigns';
import { DefaultNetworkCodes, socialMediaNetworksConfig } from 'commons/components/sm-icons';
import { PostViewText } from './parse-view-text';
import { TpoCreatePostPrivacySettingsModalComponent } from '.';
import { Privacy } from './Privacy';

// interface Post {
// 	id: number,
// 	linkIds: number[],
// 	linkSettings: any[],
// 	text: string,
// 	mediaLink?: string,
// 	campaigns?: number[],
// }

// interface Account {
// 	linkId: number,
// 	name: string,
// }

// interface Campaign {
// 	id: number,
// 	name: string,
// }

@Component({
	selector: 'publisher-post-edit',
	templateUrl: './post-edit.component.html',
})
export class PostEditComponent implements OnDestroy {

	// on calendar we nee only part of the view, this flag will trigger proper parts to show
	@Input()
	embedded: boolean = false;

	@Output()
	onEdit: EventEmitter<any> = new EventEmitter();

	@Output()
	onSave: EventEmitter<any> = new EventEmitter();

	// global legacy injection
	SocialMediaLinksConfig: any;

	isNew: boolean;
	title: string;
	params: any = {};
	post: any;
	sentDate: Date = new Date();
	accounts: any;
	linkSettings: any = {
		visibilityCache: {},
	};
	template: any;
	// this data should be prepopulated
	// if rssId is set in url params
	rssData: any;
	attachment: any;
	attachmentsErrors = [];
	campaigns: any;
	publish: any;

	resolvedDynamicContent: Record<string, string> = {};

	/**
	 * @description allow to hide logic with non-editable text in the end of a post
	 * @see RM-27910 item 7.
	 */
	viewText: PostViewText;

	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	commonFormControlState: any = { updateOn: 'change' };
	postForm: RealmFormGroup = new RealmFormGroup(
		{
			linkIds: new RealmFormControl(
				'linkIds',
				{
					...this.commonFormControlState,
					label: 'Accounts',
				},
				[
					Validators.required,
				],
			),
			templateId: new RealmFormControl(
				'templateId',
				{
					...this.commonFormControlState,
				},
				[],
			),
			text: new RealmFormControl(
				'text',
				{
					...this.commonFormControlState,
					label: 'Text',
				},
				[
					MaxLengthValidator(63100),
				],
			),
			attachments: new RealmFormControl(
				'attachments',
				{
					label: 'Attachments',
				},
				[],
			),
			campaigns: new RealmFormControl(
				'campaigns',
				{
					...this.commonFormControlState,
					label: 'Campaigns',
				},
				[],
			),
			rssItemId: new RealmFormControl(
				'rssItemId',
				{
					...this.commonFormControlState,
					value: null,
				},
				[],
			),
		},
		{ validators: PostValidator, ...this.commonFormControlState },
	);
	preview: any;
	subscriptions: Subscription[] = [];
	modalRef: BsModalRef;
    createPostModalRef: BsModalRef;
	$scope: any;

	linkPreview: any = {
		entries: [],
		$resolved: false,
	};

	@ViewChild('postFormSubmit', {static: false})
	$postFormSubmit: ElementRef;

	constructor(
		public injector: Injector,
		public state: StateService,
		public routerGlobals: UIRouterGlobals,
		public historyLogService: HistoryLogService,
		public user: UserService,
		public postsResource: PostsResourceService,
		public templatesResource: TemplatesResourceService,
		public campaignsResource: CampaignsResourceService,
		public SocialMediaRSS: SocialMediaRSSResourceService,
		public modalService: BsModalService,
	) {
		this.$scope = this.injector.get('$scope');

		// legacy injections
		this.SocialMediaLinksConfig = socialMediaNetworksConfig;
		const TimezonesList = injector.get('TimezonesList');
		const TimezonesService = injector.get('TimezonesService');

		const { postId } = routerGlobals.params;
		if (postId) {
			Object.assign(this.params, { postId });
		}
		this.isNew = !postId;
		this.title = this.isNew ? 'Create Post' : 'Edit Post';

		this.post = this.isNew ?
            {
                attachments: [],
                selectedThumbnails: {},
                showFullPostEditor: true,
                isEditable: true,
                $resolved: true,
            } :
			postsResource.post.get(this.params);

		const self = this;
		const { post, postForm: form } = this;

		// TODO: this is dump check on data change,
		// but I don't know how to add something smarter in this code
		this.subscriptions.push(
			form.valueChanges.subscribe(() => {
				this.onEdit.emit({
					data: { isDirty: this.postForm.dirty },
				});
			}),
		);

		// TODO: move outside
		this.accounts = {
			options: null,
			options$: null,
			enrollOption: {
				linkId: 'enroll',
				name: 'Enrolled Users',
				networkCode: 'EU',
			},
			fetchOptions(init: boolean = false): Promise<any> {
				const templateId = init
					? routerGlobals.params.templateId || post.templateId
					: form.get('templateId').value;
				const campaignId = init
					? post.campaigns
					: form.get('campaigns').value;
				const promise = new Promise((resolve): void => {
					this.options = postsResource.posts.availableAccounts(
						{
							templateId,
							campaignId,
						},
						({ accounts, enrollingAllowed }): void => {
							if (enrollingAllowed) {
								accounts.push(this.enrollOption);
							}

							const enrolling = enrollingAllowed && post.isEnrolling;
							if (!post.$inited) {
								if (enrolling) {
									post.linkIds.push(this.enrollOption.linkId);
								}
							} else {
								const control: AbstractControl = form.get('linkIds');
								const { value: controlLinkIds } = control;

								const linkIds = new Set(controlLinkIds);
								linkIds[ enrolling ? 'add' : 'delete' ](this.enrollOption.linkId);

								if (linkIds.size !== size(controlLinkIds)) {
									control.patchValue(Array.from(linkIds));
								}
							}

							resolve(accounts);
						},
					);
				});
				this.options$ = from(promise);

				return promise;
			},
			subscriptions: [],
			subscribe(): void {
				this.subscriptions.push(
					merge(
						form.get('templateId').valueChanges,
						form.get('campaigns').valueChanges,
					)
						.subscribe((): void => {
							// skip post initialization
							if (post.$inited) {
								this.fetchOptions();
							}
						}),
				);
			},
			init(): Promise<any> {
				// fetch initial options
				const promise = this.fetchOptions(true);

				this.subscribe();

				return promise;
			},
			finalize(): void {
				pull(post.linkIds, this.enrollOption.linkId);
			},
		};

		this.rssData = {
			patchPost: (postObject, data) => {
				// this field should be saved for later use in edit
				postObject.rssItemId = data.id;
				// description should go to textarea
				postObject.text = `${data.title} ${data.link}`;
			},
			entry: {},
			// rssId is set in url on create, and as a param in the post on edit
			init: (rssItemId) => {
				return new Promise((resolve, reject) => {
					// fetch rss data only for new posts
					if (!this.isNew) {
						resolve(null);
						return;
					}

					const { rssId } = routerGlobals.params;

					if (!rssId && !rssItemId) {
						resolve(null);
						return;
					}

					this.SocialMediaRSS.rss.entry({ entryId: rssId || rssItemId }, (rssData) => {
						this.rssData.entry = rssData;
						resolve(rssData);
					}, () => {
						reject();
					});

					this.params.sync = true;
				});
			},
		};

		// TODO: move outside
		this.template = {
			resolved: true,
			_initialPostState: {
				templated: false,
				text: null,
				attachments: [],
				campaigns: [],
			},
			_applyTemplate(props = this._initialPostState): void {
				const dataToAssign = { ...props };
				dataToAssign.campaigns = map(reject(dataToAssign.campaigns, { status: 'Ended' }), 'id'); // remove all ended campaigns
				Object.assign(post, dataToAssign);
				form.patchValue(dataToAssign);
			},
			_fetchTemplate(templateId): Promise<any> {
				this.resolved = false;
				return templatesResource.template.get({ templateId }).$promise
					.finally((): void => {
						this.resolved = true;
					});
			},
			subscriptions: [],
			subscribe(): void {
				this.subscriptions.push(
					form.get('templateId').valueChanges
						.subscribe((templateId: number): void => {
							if (templateId) {
								this._fetchTemplate(templateId)
									.then(({ id, isEditable, ...templateProps }): void => {
										post.isEditable = isEditable;

										if (!post.templated) {
											this._applyTemplate({
												...templateProps,
												templated: true,
											});
										}

										self.title = templateProps.summary;
									});
							}
						}),
				);
			},
			init(): Promise<any> {
				const { templateId } = routerGlobals.params;

				this.show = !templateId;

				// set initial templateId (convert to number) from state params
				if (templateId) {
					post.templateId = Math.floor(templateId);
				}

				this.subscribe();

				return Promise.resolve();
			},
		};

		this.attachment = {
			resolving: false,
			config: {
				tpoId: this.user.profile.organization.id,
				template: false,
			},
			subscriptions: [],
			subscribe: (): void => {
				this.subscriptions.push(
					form.get('templateId').valueChanges
						.subscribe((): void => {
							const { templateId } = this.routerGlobals.params;
							this.attachment.config.template = !!templateId;
						}),
				);
			},
			status(status: boolean): void {
				this.resolving = status;
				setTimeout((): void => {
					form.patchValue({ attachments: post.attachments });
				});
			},
			error: (errors: [{networkCode: string, error: string}?]): void => {
				this.attachmentsErrors = errors;

				setTimeout((): void => {
					form.patchValue({ attachments: post.attachments }); // clear errors in the sidebar
				});
			},
		};

		// TODO: move outside
		this.campaigns = {
			canManage: user.profile.can('TPO_PB_MANAGE_CAMPAIGNS'),
			options: null,
			options$: null,
			fetchOptions(): Promise<any> {
				this.options = campaignsResource.availableCampaigns.get();
				this.options$ = from(this.options.$promise);

				return this.options.$promise;
			},
			init(): Promise<any> {
				const { campaignId } = routerGlobals.params;

				if (campaignId && !this.canManage) {
					self.$scope.$emit('USER:403');
				}

				if (routerGlobals.params.review) {
					return Promise.resolve();
				}

				// set initial campaignId (convert to number) from state params
				if (campaignId) {
					post.campaigns = [ Math.floor(campaignId) ];
				}

				return this.canManage ?
					this.fetchOptions() :
					Promise.resolve();
			},
		};

		// TODO: move outside
		this.publish = {
			options: [
				{
					id: 'IMMEDIATELY',
					title: 'Immediately',
				},
				{
					id: 'SCHEDULE',
					title: 'Schedule',
				},
			],
			timezones: TimezonesList,
			scheduling: false,
			subscriptions: [],
			subscribe(): void {
				this.subscriptions.push(
					form.get('publish').valueChanges
						.subscribe((publish: string): void => {
							this.scheduling = publish === 'SCHEDULE';
						}),
				);
			},
			init(): void {
				const { scheduledDate, timezone } = post;
				const postDate = (scheduledDate && timezone) ?
					TimezonesService.convertTimezoneToUTC(scheduledDate, timezone) :
					new Date();

				form.addControl('publish', new RealmFormControl(
					'publish',
					{
						...self.commonFormControlState,
                        value: 'IMMEDIATELY',
					},
					[],
				));
				form.addControl('scheduledDate', new RealmFormControl(
					'scheduledDate',
					{
						...self.commonFormControlState,
						label: 'Schedule Date',
						value: cloneDeep(postDate), //@Notice: prevent object mutation in a scheduledTime (line 521)
					},
				));
				form.addControl('scheduledTime', new RealmFormControl(
					'scheduledTime',
					{
						...self.commonFormControlState,
						value: postDate,
					},
				));
				form.addControl('scheduledTimezone', new RealmFormControl(
					'scheduledTimezone',
					{
						...self.commonFormControlState,
						value: Math.floor(post.timezone) || TimezonesService.localTimeZone.id,
					},
				));

				this.subscribe();

				// change value after subscribing to process initial state
				// form.get('publish').setValue(
				// 	post.scheduledDate ? 'SCHEDULE' : 'IMMEDIATELY',
				// );
			},
			finalize(): void {
				const { publish, scheduledDate, scheduledTime, scheduledTimezone } = post;

				// clean-up
				[
					'publish',
					'scheduledDate',
					'scheduledTime',
					'scheduledTimezone',
				].forEach((field: string): void => {
					delete post[field];
				});

				if (publish === 'SCHEDULE' && scheduledDate && scheduledTime && scheduledTimezone) {
					Object.assign(post, {
						scheduledDate: TimezonesService.convertUTCToTimezone(
							scheduledDate
								.setHours(
									scheduledTime.getHours(),
									scheduledTime.getMinutes(),
								),
							scheduledTimezone,
						),
						timezone: scheduledTimezone,
					});
				}
			},
		};

		// TODO: move outside
		this.preview = {
			accounts: [],
			networks: DefaultNetworkCodes,
			subscriptions: [],
			setAccounts: (linkIds: Array<number | string>) => {
				const { isEnrolling } = post;
				const { options } = this.accounts;

				return options.$promise.then((data) => { // @SEE RM-19817
					// generate the array of selected objects
					if (isEnrolling) {
						const targetNetworkCodes = this.post.templateTargetNetworkCodes || this.post.targetNetworkCodes;
						if (targetNetworkCodes?.length) {
							this.preview.accounts = map(targetNetworkCodes, (networkCode) => ({
									name: 'User name',
									networkCode,
								})
							);

							return
						}
						this.preview.accounts = map(without(linkIds, 'enroll'), (linkId) => find(data.accounts, { linkId }));
						this.preview.networks.forEach((networkCode) => {
							if (!find(this.accounts, { networkCode })) {
								this.preview.accounts.push({
									networkCode,
									name: 'User name',
								});
							}
						});
					} else {
						this.preview.accounts = map(linkIds, (linkId) => find(data.accounts, { linkId }));
					}
				});
			},
			subscribe(): void {},
			init(): void {
				this.subscribe();
			},
		};

		const { review } = routerGlobals.params;
		if (review) {
			assign(post, review);
		}

		Promise.all([
			this.post.$promise,
			this.campaigns.init(), // active campaigns list affects initial post state
		])
			.then((): void => {

				// allow to change trailing link for manual posts
				const allowedToChangeTrailingLink = !!post.externalReviewId;
				// || (post.networkId === 'FB')
				// || (post.networkId === 'GMB');

				this.viewText = new PostViewText(post.text || '', allowedToChangeTrailingLink);
				// this.preview.setAccounts(post.linkIds);

				this.trackTextChanges();
				this.trackAccountChanges();

				if (!this.isNew && this.campaigns.canManage) {
					const { options: campaigns } = this.campaigns;
					// filter out inactive accounts
					const activeCampaigns = map(campaigns, 'id');
					this.post.campaigns = intersection(this.post.campaigns, activeCampaigns);
				}

				Promise.all([
					this.accounts.init(), // active accounts list affects initial post state
					this.template.init(), // templateId query param affects initial post state
					this.rssData.init(this.post.rssItemId), // get rss data if post from rss feed
				]).then((): void => {
					if (!this.isNew) {
						const { options: { accounts } } = this.accounts;
						// filter out inactive accounts
						const activeAccountsLinkIds = map(accounts, 'linkId');
						this.post.linkIds = intersection(this.post.linkIds, activeAccountsLinkIds);
					}

					this.subscriptions.push(
						form.get('linkIds').valueChanges
							.subscribe((linkIds: Array<number | string>): void => {
								const { options: { accounts } } = this.accounts;

								// update post.linkSettings on post.linkIds change
								this.post.isEnrolling = false;
								this.post.linkSettings = reduce(linkIds, (result: any[], linkId: number | string): any[] => {
									if (linkId === 'enroll') {
										this.post.isEnrolling = true;
										return result;
									}

									const existingItem = find(this.post.linkSettings, { linkId });
									if (existingItem) {
										result.push(existingItem);
										return result;
									}

									const account = find(accounts, { linkId });
									if (account) {
										const { networkCode } = account as any;
										const { visibility: visibilityOptions, visibilityKey } = this.SocialMediaLinksConfig[networkCode];
										const { [linkId]: cashedVisibility } = this.linkSettings.visibilityCache;

										if (cashedVisibility) {
											result.push({
												linkId,
												[visibilityKey]: cashedVisibility,
											});
											return result;
										} else {
											const defaultVisibility = visibilityOptions[0].code;
											this.linkSettings.visibilityCache[linkId] = defaultVisibility;
											result.push({
												linkId,
												[visibilityKey]: defaultVisibility,
											});
											return result;
										}
									}
								}, []);
							}),
					);

					// put rss data in the post
					if (this.isNew && this.rssData.entry.id) {
						this.rssData.patchPost(this.post, this.rssData.entry);
						// update view text once again with rss data text
						this.viewText = new PostViewText(this.post.text || '', allowedToChangeTrailingLink);
					}

					this.attachment.subscribe();
					this.preview.init(); // depends on post

					// Populate form after all subscriptions to process initial state
					form.patchValue({
						...this.post,
						text: this.viewText?.text ?? '',
					});

					this.publish.init(); // depends on post, modifies scheduled date

					this.post.$inited = true;
				});
			});

		this.modalService.onHide.subscribe(() => {
			this.post.$resolved = true;
		});
	}

	ngOnDestroy() {
		[
			...this.accounts.subscriptions,
			...this.template.subscriptions,
			...this.attachment.subscriptions,
			...this.publish.subscriptions,
			...this.preview.subscriptions,
			...this.subscriptions,
		].forEach((subscription: Subscription): void => {
			subscription.unsubscribe();
		});
	}

	trackTextChanges(): void {
		this.postForm.get('text').valueChanges.pipe(
			debounceTime(1000),
			distinctUntilChanged(isEqual),
		).subscribe((change) => {
			// reflect textarea change to viewText object
			if (this.viewText) {
				this.viewText.setText(change ?? '');
			}

			const selectedAccounts = this.postForm.value.linkIds;

			// if no accounts selected no need to fetch data
			if (!selectedAccounts) {
				return;
			}

			const networkCodes = reduce(this.accounts.options.accounts, (memo, account) => {
				if (!includes(selectedAccounts, account.linkId)) {
					return memo;
				}

				if (includes(selectedAccounts, 'enroll')) {
					memo = this.preview.networks;
					return memo;
				}

				if (includes(memo, account.networkCode)) {
					return memo;
				}
				return [ ...memo, account.networkCode ];
			}, []);

			// RM-28497: ACCOUNTS needed for dynamic content only,
			// if no accounts passed, BE skip string replacement
			const request = {
				networkCodes,
				text: `${change}`,
				accounts: this._filterAccountsForPreview(selectedAccounts),
			};

			this.linkPreview.$resolved = false;

			if (change) {
				this.postsResource.post.preview(request, ({ linkPreviews, networkCodeToResolvedText }) => {
					this.resolvedDynamicContent = networkCodeToResolvedText;
					this.linkPreview = {
						entries: linkPreviews,
						$resolved: true,
					};
				});
			} else {
				this.resolvedDynamicContent = {};
				this.linkPreview = {
					entries: [],
					$resolved: true,
				};
			}
		});
	}

	_filterAccountsForPreview(selectedAccounts: string[] | null): (string | number)[] {
		const selectedAccountEntries = filter(this.preview.accounts, (entry) => (
			(entry.linkId !== 'enroll') && selectedAccounts?.includes(entry.linkId)
		));

		const previewAccountsFromView = filterUniqueByPipe(selectedAccountEntries, 'networkCode');

		return map(previewAccountsFromView, (account) => (account.linkId));
	}

	trackAccountChanges(): void {
		this.postForm.get('linkIds').valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged(isEqual),
		).subscribe((change) => {
			this.getPreview(change);
		});
	}

	getPreview = async (change: string[] | null): Promise<void> => {
		await this.preview.setAccounts(change);

		// if no accounts selected, or no text entered
		// no need to get post preview
		if (!change || change.length === 0 || !this.postForm.value.text) {
			this.linkPreview.$resolved = true;
			return;
		}

		const networkCodes = reduce(this.accounts.options.accounts, (memo, account) => {
			if (!includes(change, account.linkId)) {
				return memo;
			}

			if (includes(account, 'enroll')) {
				memo = this.preview.networks;
				return memo;
			}

			if (includes(memo, account.networkCode)) {
				return memo;
			}

			return [ ...memo, account.networkCode ];
		}, []);

		// RM-28497: ACCOUNTS needed for dynamic content only,
		// if no accounts passed, BE skip string replacement
		const request = {
			networkCodes,
			text: this.postForm.value.text,
			accounts: this._filterAccountsForPreview(change),
		};

		this.linkPreview.$resolved = false;

		this.postsResource.post.preview(request, ({ linkPreviews, networkCodeToResolvedText }) => {
			this.resolvedDynamicContent = networkCodeToResolvedText;
			this.linkPreview = {
				entries: linkPreviews,
				$resolved: true,
			};
		});
	}

	noAccounts = (): boolean => (
		isEmpty(this.postForm.get('linkIds').value)
	)

	removedAccounts = (): boolean => (
		!this.isNew &&
		this.post.$resolved &&
		(
			get(this, 'accounts.options.$resolved') &&
			this.postForm.get('linkIds').pristine &&
			this.postForm.get('templateId').pristine &&
			this.postForm.get('campaigns').pristine
		) &&
		this.noAccounts()
	)

	isResolved = (): boolean => (
		this.post.$resolved &&
		(this.post.$inited || get(this, 'accounts.options.$resolved')) &&
		this.template.resolved &&
		(this.post.externalReviewId || !this.campaigns.canManage || get(this, 'campaigns.options.$resolved'))
	)

	openPrivacySettingsModal(): void {
		const { post, linkSettings: { visibilityCache } } = this;
		const privacySettings: Privacy = {};

		if (post.linkSettings.length) {
			privacySettings.selected = post.linkSettings.map(({ linkId, ...linkSettings }) => {
				const { accounts } = this.accounts.options;
				const account = find(accounts, { linkId });
				if (account) {
					const { name, networkCode, profilePictureUrl } = account as any;
					const { visibility: visibilityOptions, visibilityKey } = this.SocialMediaLinksConfig[networkCode];
					const { [visibilityKey]: visibility } = linkSettings;
					return {
						linkId,
						name,
						networkCode,
						profilePictureUrl,
						visibility,
						visibilityKey,
						visibilityOptions,
					};
				}
			});
		}

		if (post.isEnrolling) {
			const enrollNetworks = [
				{
					networkCode: 'FB',
					visibilityKey: 'enrollFacebookVisibility',
				},
				{
					networkCode: 'TW',
					visibilityKey: 'enrollTwitterVisibility',
				},
				{
					networkCode: 'LI',
					visibilityKey: 'enrollLinkedInVisibility',
				},
			];
			privacySettings.enroll = enrollNetworks.map(({ networkCode, visibilityKey }) => {
				const { title: name, visibility: visibilityOptions } = this.SocialMediaLinksConfig[networkCode];
				const { [visibilityKey]: visibility = visibilityOptions[0].code } = this.post;
				return {
					name,
					networkCode,
					visibility,
					visibilityKey,
					visibilityOptions,
				};
			});
		}

        const initialState = {
            privacySettings,
            confirm: () => {
                if (privacySettings.selected) {
                    privacySettings.selected.forEach(({ linkId, visibility, visibilityKey }) => {
                        find(post.linkSettings, { linkId })[visibilityKey] = visibilityCache[linkId] = visibility;
                    });
                }

                if (privacySettings.enroll) {
                    privacySettings.enroll.forEach(({ visibility, visibilityKey }) => {
                        post[visibilityKey] = visibility;
                    });
                }

                this.createPostModalRef.hide();
            },
        };
        this.createPostModalRef = this.modalService.show(
            TpoCreatePostPrivacySettingsModalComponent,
            {
                initialState,
                class: 'social-media social-media-posts post-privacy-settings-modal',
            });
	}

	// getToState = (): State => {
	// 	const defaultState: State = {
	// 		name: 'social-compliance.publisher.posts',
	// 		params: {},
	// 	};
	//
	// 	const { backToState, templateId, postType } = this.stateService.params;
	//
	// 	if (backToState) {
	// 		return backToState;
	// 	}
	//
	// 	if (templateId) {
	// 		return {
	// 			name: 'social-compliance.publisher.library.templates.:templateId',
	// 			params: { templateId },
	// 		};
	// 	}
	//
	// 	if (postType) {
	// 		return {
	// 			name: `${defaultState.name}.:postType`,
	// 			params: { postType },
	// 		};
	// 	}
	//
	// 	return defaultState;
	// };

	submit(saveAsDraft = false): void {
		if (saveAsDraft) {
			this.publish.saveAsDraft = true;
			// scheduledDate should be removed to save post as draft
			delete this.post.scheduledDate;
			delete this.post.timezone;
		}
		this.$postFormSubmit.nativeElement.click();
	}

	save(): void {

		Object.assign(
			this.post,
			this.postForm.getRawValue(),
			// rewrite form value text to contain non-editable postfix
			{ text: this.viewText?.fullText ?? '' },
		);

		let saveAsDraft = false;
		if (this.publish.saveAsDraft) {
			saveAsDraft = true;
			this.publish.saveAsDraft = false;
			this.post.publish = 'DRAFT';
		}

		let method: string;
		const params = {};

		if (this.post.publish === 'IMMEDIATELY') {
			method = 'doPost';
		} else {
			method = this.isNew ? 'doCreate' : 'doUpdate';
		}

		Object.assign(params, this.params);

		this.accounts.finalize();
		this.publish.finalize();

		if (!saveAsDraft) {
			this.post.$resolved = false;
			this.postsResource.post.validate({}, {text: this.post.text}, (check) => {
				const formattedText = FormattedTextComponent.setLineBreaks(check.text);
				const initialState = {
					title: 'Please Review',
					message: `<div class="text-muted">Your post contains terms that may create a compliance event:</div><br><div class="text-max-height">${formattedText}</div>`,
					confirmText: 'Continue',
					cancelText: 'Edit',
					onConfirm: () => {
						this.post.isApproved = true;
						this.modalRef.content.resolving = true;
						this.sendRequest(method, params);
					},
				};

				if (check.hasTriggers) {
					this.modalRef = this.modalService.show(ConfirmModalComponent, {initialState, class: 'social-compliance modal-smd modal-new'});
				} else {
					this.sendRequest(method, params);
				}
			});
		} else {
			this.sendRequest(method, params);
		}
	}

	sendRequest(method, params) {
        this.postsResource[method](params, this.post).$promise
			.then(
				() => {
					this.onSave.emit();
					this.close();
				},
				({ data }) => {
					this.postForm.setServerError(data);
				},
			)
			.finally(() => {
                this.post.$resolved = true;
                if (this.modalRef) {
					this.modalRef.hide();
				}
			});
	}

	// Reusable for all publisher edit components
	cancel = (withRedirect = true): void => {
		this.onEdit.emit({
			postId: this.post.id,
			data: { isEditing: false },
		});

		if (withRedirect) {
			this.state.go('^', {}, { location: 'replace' });
		}
	}

	// Reusable for all publisher full-page components
	close = (): void => {
		if (!this.embedded) {
			const backState = this.historyLogService.findBackState([
				'**.new', // create post from scratch
				'**.:templateId', // create post from template
				'**.:postId.**',  // edit post
			], false);

            if (backState) {
                return this.historyLogService.goBackTo(backState);
            }

			this.state.go('social-compliance.publisher.posts');
		}
	}
}
