import { Component, Input, Output, HostListener, forwardRef, EventEmitter, ChangeDetectorRef, inject, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { pick, defaults } from 'lodash';

const DefaultValues = { true: true, false: false };
const ComponentDefinition = {
    selector: 'toggle',
};

export const TOGGLE_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleComponent),
    multi: true,
};

const noop = () => {
};

@Component({
    templateUrl: './toggle.component.html',
    selector: `${ComponentDefinition.selector}[ngModel],${ComponentDefinition.selector}[formControl],${ComponentDefinition.selector}[formControlName],${ComponentDefinition.selector}[value]`,
    providers: [TOGGLE_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ToggleComponent implements ControlValueAccessor {
    protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    @Input('values')
    set _values(inputValue: unknown) {
        const newValue = pick(inputValue, ['true', 'false']);
        this.values = defaults(newValue, DefaultValues);
    }

    @Input('value')
    // get accessor
    get value(): unknown {
        return this.innerValue;
    }

    // set accessor including call the onchange callback
    set value(v: unknown) {
        if (v !== this.innerValue) {
            this.writeValue(v);
            this.onChangeCallback(v);
        }
    }

    public values: { true, false } = DefaultValues;

    @Input() loading?: boolean = false;
    @Input() disabled?: boolean = false;

    @Output('toggle') toggle: EventEmitter<unknown> = new EventEmitter();

    state: boolean = null;
    // The internal data model
    public innerValue: unknown = '';

    // Placeholders for the callbacks which are later provided by the Control Value Accessor
    public onTouchedCallback: () => void = noop;
    public onChangeCallback: (_: unknown) => void = noop;

    @HostListener('click', ['$event']) onClick($event: Event) {
        if (this.disabled || this.loading) {
            return;
        }
        if (this.toggle.observers.length) {
            this.toggle.next($event);
        } else {
            this.toggleValue();
        }
    }

    toggleValue() {
        this.state = !this.state;
        this.value = this.values[String(this.state)];
    }

    // Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: unknown) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.setState(value);
            this.cdr.detectChanges();
        }
    }

    setState(value: unknown) {
        if (this.values.true === value) {
            this.state = true;
        } else if (this.values.false === value) {
            this.state = false;
        } else {
            this.state = null;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: () => unknown) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: () => unknown) {
        this.onTouchedCallback = fn;
    }
}
