import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { SharedUserManagementModule } from 'shared/user-management';

import { LenderUsersResourceService } from './users-resource.service';
import { LenderUsersListComponent } from './list';
import { LenderUserComponent, LenderUserDetailsComponent } from './user';
import { LenderMySettingsComponent } from './my-settings';
import { NewContactsModule } from 'shared/new-contacts';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        PipesModule,
        DirectivesModule,
        BsDropdownModule.forRoot(),
        SharedUserManagementModule,
        NewContactsModule,
    ],
    declarations: [
        LenderUsersListComponent,
        LenderUserComponent,
        LenderUserDetailsComponent,
        LenderMySettingsComponent,
    ],
    providers: [
        LenderUsersResourceService,
        LenderUsersListComponent,
    ]
})
export class LenderUserManagementModule {}
