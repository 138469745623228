<h2>Products</h2>
<!--
TODO: uncomment and rework according to requirements this after BE is completed
<div class="row">
    <div class="col-md-2">
        <span class="text-muted">
            Search
        </span>
    </div>
    <div class="col-md-2">
        <span class="text-muted">
            Purchased
        </span>
    </div>
</div>
<div class="row">
    <div class="row filter-selectors">
        <div class="col-md-2">
            <search-list placeholder="Name"></search-list>
        </div>
    </div>
    <div class="col-md-2">
        <ng-select
            tabindex="4"
            [items]="TBD"
            bindLabel="name"
            bindValue="id"
            placeholder="All"
            [clearable]="false"
        >
        </ng-select>
    </div>
</div>-->
<div class="rows-bordered">
    <div class="row-fluid header">
        <div class="col-md-4">Company Name</div>
        <div class="col-md-3">Product</div>
        <div class="col-md-2">Purchased</div>
        <div class="col-md-2">Users</div>
        <div class="col-md-1"></div>
    </div>
</div>
<div class="rows-bordered">
    <div class="row-fluid" *ngFor="let clientProduct of clientProducts">
        <div class="col-md-4">
            {{ clientProduct?.companyName }}
        </div>
        <div class="col-md-3">
            {{ clientProduct?.productName }}
        </div>
        <div class="col-md-2">
            {{ clientProduct?.purchased }}
        </div>
        <div class="col-md-2">
            {{ clientProduct?.users }}
        </div>
        <div class="col-md-1">
            <button class="btn btn-primary">
                Manage
            </button>
        </div>
    </div>
</div>
